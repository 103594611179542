export interface PhoneMask {
  mode: string;
  mask: string;
  min: number;
  max: number;
}

export interface PhoneMaskDefinition {
  mask: string;
  startsWith: string;
  lazy: boolean;
  country: string;
  mode: string;
  minLimit: number;
  maxLimit: number;
  conditional: boolean;
}

export interface AllowedDeliveryCountry {
  code: string;
  name: string;
  native: string;
  phone: string;
  phoneMask?: string | Array<PhoneMask> | null;
  currency: string;
  locale: string;
  direction: string;
}

export type AllowedDeliveryCountries = 'SA' | 'AE' | 'QA' | 'OM' | 'KW' | 'BH';

export const LIST_OF_ALLOWED_DELIVERY_COUNTRY_CODES: Array<AllowedDeliveryCountries> = [
  'SA',
  'AE',
  'QA',
  'OM',
  'KW',
  'BH',
];

const ALLOWED_DELIVERY_COUNTRIES_AMERICA: Array<AllowedDeliveryCountry> = [
  // {
  //   code: 'BR',
  //   name: 'Brazil',
  //   native: 'Brasil',
  //   phone: '55',
  //   phoneMask: [
  //     {
  //       mode: 'short',
  //       mask: '(00) 0000-00000',
  //       min: 0,
  //       max: 13,
  //     },
  //     {
  //       mode: 'long',
  //       mask: '(00) 00000-0000',
  //       min: 13,
  //       max: 15,
  //     },
  //   ],
  //   currency: 'BRL',
  //   locale: 'pt',
  //   direction: 'ltr',
  // },
  {
    code: 'CL',
    name: 'Chile',
    native: 'Chile',
    phone: '56',
    phoneMask: '000 000 0000',
    currency: 'CLF',
    locale: 'es',
    direction: 'ltr',
  },
  {
    code: 'CO',
    name: 'Colombia',
    native: 'Colombia',
    phone: '57',
    phoneMask: '000-000-0000',
    currency: 'COP',
    locale: 'es',
    direction: 'ltr',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    native: 'Costa Rica',
    phone: '506',
    phoneMask: '000-000-0000',
    currency: 'CRC',
    locale: 'es',
    direction: 'ltr',
  },
  {
    code: 'MX',
    name: 'Mexico',
    native: 'Mexico',
    phone: '52',
    phoneMask: '000 000 0000',
    currency: 'MXN',
    locale: 'es',
    direction: 'ltr',
  },
  {
    code: 'CA',
    name: 'Canada',
    native: 'Canada',
    phone: '1',
    phoneMask: '000-000-0000',
    currency: 'CAD',
    locale: 'en',
    direction: 'ltr',
  },
  {
    code: 'US',
    name: 'USA',
    native: 'USA',
    phone: '1',
    phoneMask: '000-000-0000',
    currency: 'USD',
    locale: 'en',
    direction: 'ltr',
  },
  {
    code: 'PE',
    name: 'Peru',
    native: 'Perú',
    phone: '51',
    phoneMask: '000 000 000',
    currency: 'PEN',
    locale: 'es',
    direction: 'ltr',
  },
];

const ALLOWED_DELIVERY_COUNTRIES_ASIA: Array<AllowedDeliveryCountry> = [
  {
    code: 'SA',
    name: 'Saudi Arabia',
    native: 'العربية السعودية',
    phone: '966',
    phoneMask: '000 000 0000',
    currency: 'SAR',
    locale: 'ar',
    direction: 'rtl',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    native: 'دولة الإمارات العربية المتحدة',
    phone: '971',
    phoneMask: '0000000000',
    currency: 'AED',
    locale: 'ar',
    direction: 'rtl',
  },
  {
    code: 'QA',
    name: 'Qatar',
    native: 'قطر',
    phone: '974',
    phoneMask: '00000000',
    currency: 'QAR',
    locale: 'ar',
    direction: 'rtl',
  },
  {
    code: 'OM',
    name: 'Oman',
    native: 'عمان',
    phone: '968',
    phoneMask: '00000000',
    currency: 'OMR',
    locale: 'ar',
    direction: 'rtl',
  },
  {
    code: 'KW',
    name: 'Kuwait',
    native: 'الكويت',
    phone: '965',
    phoneMask: '000 000 0000',
    currency: 'KWD',
    locale: 'ar',
    direction: 'rtl',
  },
  {
    code: 'BH',
    name: 'Bahrain',
    native: '‏البحرين',
    phone: '973',
    phoneMask: '000 000 0000',
    currency: 'BHD',
    locale: 'ar',
    direction: 'rtl',
  },
  {
    code: 'TR',
    name: 'Turkey',
    native: 'Türkiye',
    phone: '90',
    phoneMask: '(0000) 000 0000',
    currency: 'TRY',
    locale: 'tr',
    direction: 'ltr',
  },
  {
    code: 'EG',
    name: 'Egypt',
    native: 'مصر‎',
    phone: '20',
    phoneMask: '00-0000-0000',
    currency: 'EGP',
    locale: 'ar',
    direction: 'rtl',
  },
  {
    code: 'LB',
    name: 'Lebanon',
    native: 'لبنان',
    phone: '961',
    phoneMask: '00 000 000',
    currency: 'LBP',
    locale: 'ar',
    direction: 'rtl',
  },
  {
    code: 'JO',
    name: 'Jordan',
    native: 'الأردن',
    phone: '962',
    phoneMask: [
      {
        mode: 'short',
        mask: '0 000 0000',
        min: 0,
        max: 12,
      },
      {
        mode: 'long',
        mask: '00 000 0000',
        min: 12,
        max: 17,
      },
    ],
    currency: 'JOD',
    locale: 'ar',
    direction: 'rtl',
  },
  {
    code: 'IL',
    name: 'Israel',
    native: 'יִשְׂרָאֵל',
    phone: '972',
    phoneMask: [
      {
        mode: 'short',
        mask: '0-000-0000',
        min: 0,
        max: 12,
      },
      {
        mode: 'long',
        mask: '00-000-0000',
        min: 12,
        max: 17,
      },
    ],
    currency: 'ILS',
    locale: 'he',
    direction: 'rtl',
  },
];

const ALLOWED_DELIVERY_COUNTRIES_EUROPE: Array<AllowedDeliveryCountry> = [
  {
    code: 'ES',
    name: 'Spain',
    native: 'España',
    phone: '34',
    phoneMask: '000 000 000',
    currency: 'EUR',
    locale: 'es',
    direction: 'ltr',
  },
];

export const ALLOWED_CASH_ON_DELIVERY_COUNTRIES: Array<string> = [
  'SA',
  'AE',
  'QA',
  'OM',
  'KW',
  'BH',
  'TR',
  'EG',
  'LB',
  'JO',
  'IL',
];

const ALLOWED_DELIVERY_COUNTRIES: Array<AllowedDeliveryCountry> = [
  ...ALLOWED_DELIVERY_COUNTRIES_AMERICA,
  ...ALLOWED_DELIVERY_COUNTRIES_ASIA,
  ...ALLOWED_DELIVERY_COUNTRIES_EUROPE,
];
export const getAllowedDeliveryCountries = (
  reverse?: boolean | null
): Array<AllowedDeliveryCountry> => {
  if (!reverse) {
    return ALLOWED_DELIVERY_COUNTRIES;
  } else {
    return [
      ...ALLOWED_DELIVERY_COUNTRIES_ASIA,
      ...ALLOWED_DELIVERY_COUNTRIES_AMERICA,
      ...ALLOWED_DELIVERY_COUNTRIES_EUROPE,
    ];
  }
};

export const findCountry = (code?: string | null): AllowedDeliveryCountry | null => {
  let output: AllowedDeliveryCountry | null = null;
  if (code) {
    for (const country of ALLOWED_DELIVERY_COUNTRIES) {
      if (country.code === code) {
        output = country;
        break;
      }
    }
  }
  return output;
};

export const getPhoneMaskDefinitions = (): Array<PhoneMaskDefinition> => {
  const maskDefinitions = new Array(0);
  for (const country of ALLOWED_DELIVERY_COUNTRIES) {
    if (country.phoneMask) {
      if (typeof country.phoneMask === 'string') {
        maskDefinitions.push({
          mask: country.phoneMask,
          lazy: true,
          override: true,
          country: `${country.code}`.toUpperCase(),
        });
      } else {
        for (const phoneMask of country.phoneMask || []) {
          maskDefinitions.push({
            mask: phoneMask.mask,
            lazy: true,
            country: `${country.code}`.toUpperCase(),
            mode: phoneMask.mode,
            minLimit: phoneMask.min,
            maxLimit: phoneMask.max,
            conditional: true,
            override: true,
          });
        }
      }
    } else {
      maskDefinitions.push({
        mask: '00000000000',
        lazy: true,
        override: true,
        country: `${country.code}`.toUpperCase(),
      });
    }
  }

  maskDefinitions.push({
    mask: '00000000000',
    lazy: true,
    override: true,
    country: '',
  });
  return maskDefinitions;
};
