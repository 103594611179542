import { Params } from '@angular/router';
import { PriceModel } from '@app/shared/models/api/price.model';
import { generateURL } from '@app/shared/utils/url.utils';
import { parseProductMetadata, ProductMetadata } from './product-metadata.model';
import { Insurance, parseInsurance, parseInsuranceList } from './insurance.model';

export const SUPPORTED_IPHONE_COLORS = [
  `PURPLE`,
  `WHITE`,
  `BLACK`,
  `SILVER`,
  `GOLD`,
  `SPACE GRAY`,
  `ROSE GOLD`,
  `(PRODUCT)RED`,
  `YELLOW`,
  `GREEN`,
];

export const SUPPORTED_IPHONE_COLORS_LABELS: { [key: string]: string } = {
  WHITE: 'label_phone_color_white',
  BLACK: 'label_phone_color_black',
  SILVER: 'label_phone_color_silver',
  GOLD: 'label_phone_color_gold',
  SPACE_GRAY: 'label_phone_color_space_gray',
  ROSE_GOLD: 'label_phone_color_rose_gold',
  '(PRODUCT)RED': 'label_phone_color_product_red',
  YELLOW: 'label_phone_color_yellow',
  GREEN: 'label_phone_color_green',
  PURPLE: 'label_phone_color_purple',
  JET_BLACK: 'label_phone_color_jet_black',
  CORAL: 'label_phone_color_coral',
  BLUE: 'label_phone_color_blue',
  MIDNIGHT_GREEN: 'label_phone_color_midnight_green',
  GRAPHITE: 'label_phone_color_graphite',
  PACIFIC_BLUE: 'label_phone_color_pacific_blue',
  RED: 'label_phone_color_red',
  PINK: 'label_phone_color_pink',
};

export const IPHONE_COLOR_CODES: { [key: string]: string } = {
  WHITE: '#F8F6EF',
  BLACK: '#1F2120',
  SILVER: '#F1F3EE',
  GOLD: '#F8E8D1',
  SPACE_GRAY: '#272729',
  ROSE_GOLD: '#EBD1CD',
  RED: '#CF233D',
  '(PRODUCT)RED': '#C72333',
  YELLOW: '#F0DA77',
  GREEN: '#A8DBC8',
  PURPLE: '#C1B8ED',
  JET_BLACK: '#2F2F2F',
  CORAL: '#F1816A',
  BLUE: '#437791',
  MIDNIGHT_GREEN: '#43484d',
  GRAPHITE: '#5E5D59',
  SIERRA_BLUE: '#ACC5DB',
  PACIFIC_BLUE: '#3F5C68',
  PINK: '#FBE2DD',
  STARLIGHT: '#FAF7F4',
};

export class Product {
  public id: string;
  public title: string;
  public titleEn: string;
  public description: string;
  public images: Array<string>;
  public brand: string;
  public model: string;
  public color: string;
  public colorCode: string;
  public storage: string;
  public launchDate: number;
  public metadata: ProductMetadata;
  public rawMetadata: Params;
  public quantity?: number;

  public mpn: string;
  public upc: string;

  public condition: string;
  public category: string;
  public price: PriceModel;
  public originalPrice: PriceModel;
  public country: string;
  public language: string;
  public isFeatured: boolean;
  public inStock?: boolean;
  public insuranceIds?: Array<string>;
  public insurances?: Array<Insurance>;
  public selectedInsurance: Insurance | null;
  public updatedAt?: Date;
  // shopify ids
  public variantId?: string;
  public productId?: string;

  constructor() {
    this.id = '';
    this.title = '';
    this.titleEn = '';
    this.description = '';
    this.images = new Array(0);
    this.brand = '';
    this.model = '';
    this.color = '';
    this.colorCode = '#fff';
    this.storage = '';
    this.launchDate = 0;
    this.metadata = new ProductMetadata();
    this.rawMetadata = {};
    this.mpn = '';
    this.upc = '';
    this.condition = '';
    this.category = '';
    this.price = new PriceModel();
    this.originalPrice = new PriceModel();
    this.country = '';
    this.language = '';
    this.selectedInsurance = null;

    this.isFeatured = false;
  }
}

export const parseProduct = (data?: Record<string, any> | null): Product | null => {
  let product: Product | null = null;
  if (data) {
    product = new Product();
    product.id = data.id || '';
    product.title = data.title || '';
    product.titleEn = data.title_en || data.titleEn || '';
    product.description = data.description || '';
    product.images = [...(data.images || [])];
    if (data.image_url) {
      product.images.push(data.image_url);
    }
    if (data.image) {
      product.images.push(data.image);
    }
    product.brand = data.brand || '';
    product.model = data.model || '';
    product.color = data.color || '';
    product.colorCode = data.color_code || data.colorCode || '#fff';
    product.storage = data.storage || '';
    product.launchDate = Number(`${data.launch_date || data.launchDate || 0}`);
    product.rawMetadata = {
      ...(data.raw_metadata || data.rawMetadata || data.metadata || {}),
    };
    product.metadata = parseProductMetadata(data.metadata);
    product.mpn = data.mpn || '';
    product.upc = data.upc || '';
    product.condition = data.condition || '';
    product.category = data.category || '';
    product.price = PriceModel.fromJson(data.price) || new PriceModel();
    product.originalPrice =
      PriceModel.fromJson(data.original_price || data.originalPrice) || new PriceModel();
    product.country = data.country || '';
    product.language = data.language || '';
    product.isFeatured = Boolean(data.is_featured || data.isFeatured);
    product.inStock = Boolean(data.in_stock || data.inStock);

    if (data.updatedAt || data.updated_at) {
      product.updatedAt = data.updatedAt || data.updated_at;
    }

    if (data.quantity) {
      product.quantity = data.quantity;
    }

    if (data.variantId || data.variant_id) {
      product.variantId = data.variantId || data.variant_id;
    }
    if (data.productId || data.product_id) {
      product.productId = data.productId || data.product_id;
    }

    if (data.insurance_ids || data.insuranceIds) {
      product.insuranceIds = data.insurance_ids || data.insuranceIds || [];
    }

    if (data.insurances) {
      product.insurances = parseInsuranceList(data.insurances);
    }

    if (data.selectedInsurance) {
      product.selectedInsurance = parseInsurance(data.selectedInsurance);
    }
  }
  return product;
};

export const parseProductList = (
  data?: Array<Record<string, any>> | null
): Array<Product> => {
  const products: Array<Product> = new Array(0);
  if (data) {
    for (const item of data || []) {
      const parsed = parseProduct(item);
      if (parsed) {
        products.push(parsed);
      }
    }
  }
  return products;
};

export const generateProductUrlSlug = (
  product: Product,
  lang?: string | null
): string => {
  if (product) {
    const slug = generateURL(product.id, product.titleEn || product.title);
    const params = new Array(0);

    if (lang) {
      params.push(`lang=${lang}`);
    }

    /*
    if (country) {
      params.push(`country=${country}`);
    } */

    if (params.length >= 1) {
      return `${slug}?${params.join('&')}`;
    } else {
      return slug;
    }
  } else {
    return '';
  }
};

export const colorNameToTranslationLabel = (name?: string | null): string => {
  const cleanName = `${name || ''}`.replace(/\s/g, '_').toUpperCase();
  let label = SUPPORTED_IPHONE_COLORS_LABELS[cleanName] || '';

  if (!label && name !== 'label_all') {
    label = `label_phone_color_${cleanName}`.toLowerCase();
  }

  return label;
};

export const colorNameToHex = (name?: string | null): string => {
  const cleanName = `${name || ''}`.replace(/\s/g, '_').toUpperCase();

  let color = '#f4f5f7';
  switch (cleanName) {
    case 'SPACE_GRAY':
      color = '#272729';
      break;
    case 'GOLD':
      color = '#FFF0DD';
      break;
    case 'SILVER':
      color = '#D1D2D4';
      break;
    case 'ROSE_GOLD':
      color = '#EBD1CD';
      break;
    case 'BLACK':
      color = '#191919';
      break;
    case 'RED':
    case '(PRODUCT)RED':
      color = '#CF233D';
      break;
    case 'JET_BLACK':
      color = '#2F2F2F';
      break;
    case 'WHITE':
      color = '#F2EBE6';
      break;
    case 'YELLOW':
      color = '#F0DA77';
      break;
    case 'CORAL':
      color = '#F1816A';
      break;
    case 'BLUE':
      color = '#215C79';
      break;
    case 'GREEN':
      color = '#A8DBC8';
      break;
    case 'PURPLE':
      color = '#C1B8ED';
      break;
    case 'MIDNIGHT_GREEN':
      color = '#4A544F';
      break;
  }
  return color;
};
