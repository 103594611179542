import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Params, RouterLink } from '@angular/router';
import { configureScope } from '@sentry/browser';
import {
  Observable,
  of,
  Subject,
  Subscription,
  map,
  distinctUntilChanged,
  takeUntil,
  switchMap,
} from 'rxjs';

import { WindowUtils } from '@app/shared/utils/window.util';
import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';
import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { ScreenSizeInfoState } from '@app/akita/screen/state/screen.store';
import { AkitaUsersService } from '@app/akita/api/users/state/users.service';
import {
  ActivityType,
  ACTIVITY_TYPE_IDLE,
  ACTIVITY_TYPE_FETCHING_PRODUCTS,
  ACTIVITY_TYPE_PLEASE_WAIT,
} from '@app/akita/navigation/models/activity-indicator-type.model';
import { UserModel } from '@app/shared/models/api/user.model';
import { PictureModel } from '@app/shared/models/api/picture.model';
import { AuthOverlayDynamicService } from '@app/overlays/auth-overlay/services/auth-overlay-dynamic.service';
// eslint-disable-next-line max-len
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { AkitaOverlaysService } from '@app/akita/overlays/state/overlays.service';
import { CartOverlayDynamicService } from '@app/overlays/cart-overlay/services/cart-overlay-dynamic.service';
import { AkitaCartQuery } from '@app/akita/cart/state/cart.query';
import { CartItem } from '@app/akita/cart/models/cart.model';
import { logAuthDialogShown } from '@app/core/services/google-analytics.lazy';
import { BurguerMenuOverlayDynamicService } from '@app/overlays/burguer-menu-overlay/services/burguer-menu-overlay-dynamic.service';
import { SingleUserIconComponent } from '../icons/generated/single-user/single-user.component';
import { PictureComponent } from '../picture/picture.component';
import { ShoppingBagIconComponent } from '../icons/generated/shopping-bag/shopping-bag.component';
import { SearchBoxComponent } from '../search-box/search-box.component';
import { PopsyDuoIconComponent } from '../icons/generated/popsy-duo/popsy-duo.component';
import { BurguerMenuIconComponent } from '../icons/generated/burguer-menu/burguer-menu.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { AkitaRouterService } from '@app/akita/router/state/router.service';

const MAX_UNREAD_COUNT = 9;
const MAX_AVATAR = 5;

const AUTH_VIEWS = ['SIGN_IN', 'SIGN_UP', 'RESET_PASSWORD', 'RESET_PASSWORD_CHANGE'];
const NO_SEARCH_VIEW = ['INFORMATION'];

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.template.html',
  styleUrls: ['./toolbar.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    NgIf,
    BurguerMenuIconComponent,
    RouterLink,
    PopsyDuoIconComponent,
    SearchBoxComponent,
    ShoppingBagIconComponent,
    PictureComponent,
    SingleUserIconComponent,
    AsyncPipe,
  ],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  public isAuthView$: Observable<boolean>;
  public isNoSearchView$: Observable<boolean>;
  public isBrowser: boolean;
  public inboxView$: Observable<boolean>;
  public avatarId: number;
  public ssrXsPhoneSize: boolean;

  public readonly cartItems$: Observable<Array<CartItem>>;
  public readonly cartItemsCount$: Observable<number>;
  public readonly alwaysShowCart$: Observable<boolean>;

  public showActivity: boolean;
  public activityType: ActivityType;
  public user: UserModel | null = null;

  public user$: Observable<UserModel | null>;
  public userAvatar$: Observable<SafeStyle | null>;
  public userAvatarUrl$: Observable<string | null>;
  public biggerThan$: Observable<ScreenSizeInfoState>;
  public homeUrlFragment$: Observable<Array<string>>;
  public phoneSearchFragment$: Observable<Array<string>>;
  public queryParamsToKeep$: Observable<Params>;
  public signUpQueryParams$: Observable<Params>;

  public routeData: Params = {};
  public showDefaultAvatar: boolean;

  public unreadCount: number;
  public showAuthOverlay: boolean;

  public showCreateListingOverlay: boolean;

  public showingCartOverlay: boolean;
  private openCartOverlaySubscription?: Subscription | null;

  public showingBurguerMenuOverlay: boolean;
  private openBurguerMenuOverlaySubscription?: Subscription | null;

  public showNotificationsDrawerOverlay: boolean;

  private userSubscription?: Subscription | null;
  private authOverlaySubscription?: Subscription | null;

  private readonly destroySubject: Subject<void>;

  private readonly sanitizer = inject(DomSanitizer);
  private readonly changeDetector = inject(ChangeDetectorRef);

  constructor(
    private readonly akitaOverlaysService: AkitaOverlaysService,
    private readonly authOverlayDynamicService: AuthOverlayDynamicService,
    private readonly akitaAuthQuery: AkitaAuthQuery,
    private readonly akitaUsersService: AkitaUsersService,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaRouterService: AkitaRouterService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly cartOverlayDynamicService: CartOverlayDynamicService,
    private readonly burguerMenuOverlayDynamicService: BurguerMenuOverlayDynamicService,
    private readonly akitaCartQuery: AkitaCartQuery
  ) {
    this.destroySubject = new Subject();
    this.activityType = ACTIVITY_TYPE_IDLE;
    this.showActivity = false;
    this.showDefaultAvatar = false;
    this.showAuthOverlay = false;
    this.showingCartOverlay = false;
    this.showingBurguerMenuOverlay = false;
    this.showCreateListingOverlay = false;
    this.showNotificationsDrawerOverlay = false;
    this.unreadCount = 0;
    this.isBrowser = this.akitaRouterQuery.isBrowser;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    this.ssrXsPhoneSize = !this.isBrowser && WindowUtils.windowInnerWidth() <= 320;
    this.avatarId = 1 + Math.ceil(Math.random() * MAX_AVATAR);

    this.cartItems$ = this.akitaCartQuery.selectCartItems();

    this.alwaysShowCart$ = of(true);

    this.cartItemsCount$ = this.akitaRouterQuery.selectCountry().pipe(
      switchMap(() =>
        this.akitaCartQuery.selectCartItems().pipe(
          map((cart: Array<CartItem>) => {
            let count = 0;
            if (cart?.length) {
              for (const item of cart) {
                count += item.quantity || 0;
              }
            }
            return count;
          })
        )
      )
    );

    this.user$ = this.akitaAuthQuery.selectUser();
    this.userAvatar$ = this.user$.pipe(map(this.userAvatar.bind(this)));
    this.userAvatarUrl$ = this.user$.pipe(map(this.userAvatarUrl.bind(this)));
    this.biggerThan$ = this.akitaScreenQuery.selectBiggerThan();

    this.homeUrlFragment$ = this.akitaRouterQuery.selectHomeUrlFragment();
    this.phoneSearchFragment$ = this.akitaRouterQuery.selectPhoneSearchFragment();
    this.queryParamsToKeep$ = this.akitaRouterQuery.selectQueryParamsToKeep();

    this.inboxView$ = this.akitaRouterQuery
      .selectRouteData()
      .pipe(map((data: Params) => Boolean(data.step === 'CHAT')));
    this.isAuthView$ = this.akitaRouterQuery
      .selectRouteData()
      .pipe(map((data: Params) => Boolean(AUTH_VIEWS.indexOf(data.step) !== -1)));
    this.isNoSearchView$ = this.akitaRouterQuery
      .selectRouteData()
      .pipe(map((data: Params) => Boolean(NO_SEARCH_VIEW.indexOf(data.step) !== -1)));

    this.signUpQueryParams$ = this.queryParamsToKeep$.pipe(
      map((params: Params) => {
        const email =
          this.akitaRouterQuery.params.email ||
          this.akitaRouterQuery.queryParams.email ||
          '';
        if (email) {
          params = { ...params, email: email };
        }
        const username =
          this.akitaRouterQuery.params.username ||
          this.akitaRouterQuery.queryParams.username ||
          '';
        if (username) {
          params = { ...params, username: username };
        }
        const redirect = decodeURI(
          this.akitaRouterQuery.params.redirect ||
            this.akitaRouterQuery.queryParams.redirect ||
            ''
        );
        if (redirect) {
          params = { ...params, redirect: redirect };
        }
        return params;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public ngOnInit(): void {
    this.akitaOverlaysService.loadDialogList(['auth-overlay-module']);

    this.userSubscription = this.user$.pipe(takeUntil(this.destroySubject)).subscribe({
      next: (user: UserModel | null) => {
        this.user = UserModel.fromJson(user);
        this.changeDetector.markForCheck();

        if (user) {
          configureScope((scope) => {
            scope.setUser({
              id: user.id,
              username: user.firstName,
              email: user.email,
            });
          });

          if (user.id) {
            this.akitaUsersService.getUserAsync(user.id);
          }
        }
      },
      error: () => {},
    });
  }

  public openAuthDialog(mode: string | null): boolean {
    if (AUTH_VIEWS.indexOf(this.akitaRouterQuery.routeData.step) === -1) {
      if (this.authOverlaySubscription) {
        this.authOverlaySubscription.unsubscribe();
      }

      this.authOverlaySubscription = this.authOverlayDynamicService.observe
        .pipe(takeUntil(this.destroySubject))
        .subscribe({
          next: (data?: any | null) => {
            this.showAuthOverlay = Boolean(data);
            this.changeDetector.markForCheck();
          },
        });

      logAuthDialogShown(this.googleAnalyticsService.logEventWrapper, 'toolbar');
      this.authOverlayDynamicService.open({
        data: {
          mode: mode || 'auth-intro',
        },
        isBrowser: this.isBrowser,
      });
    }

    return false;
  }

  public getUnreadCount(unreadCount: number): string | number {
    if (unreadCount > 0 && unreadCount <= MAX_UNREAD_COUNT) {
      return `${unreadCount}`;
    } else if (unreadCount > MAX_UNREAD_COUNT) {
      return `9+`;
    }
    return 0;
  }

  public userAvatar(user?: UserModel | null): SafeStyle {
    let imagePath = new PictureModel();
    this.showDefaultAvatar = false;
    let defaultAvatar = `url('assets/images/avatar.${this.avatarId}.png')`;
    if (user?.picture) {
      defaultAvatar = `url('assets/images/avatar.${user?.avatarId}.png')`;
      imagePath = PictureModel.fromJson(user?.picture) || new PictureModel();

      if (imagePath.url.indexOf('/avatar/00000000000000000000000000000000') !== -1) {
        return this.sanitizer.bypassSecurityTrustStyle(defaultAvatar);
      }
    }

    if (imagePath.url) {
      return this.sanitizer.bypassSecurityTrustStyle(`url(${imagePath.url})`);
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(defaultAvatar);
    }
  }

  public userAvatarUrl(user?: UserModel | null): string {
    let imagePath = new PictureModel();
    this.showDefaultAvatar = false;
    let defaultAvatar = `assets/images/avatar.${this.avatarId}.png`;
    if (user?.picture) {
      defaultAvatar = `assets/images/avatar.${user?.avatarId}.png`;
      imagePath = PictureModel.fromJson(user?.picture) || new PictureModel();

      if (imagePath.url.indexOf('/avatar/00000000000000000000000000000000') !== -1) {
        return defaultAvatar;
      }
    }

    if (imagePath.url) {
      return `${imagePath.url}`;
    } else {
      return defaultAvatar;
    }
  }

  public openCart(): void {
    if (this.openCartOverlaySubscription) {
      this.openCartOverlaySubscription.unsubscribe();
    }

    this.openCartOverlaySubscription = this.cartOverlayDynamicService.observe
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: (data?: any | null) => {
          this.showingCartOverlay = Boolean(data);
          this.changeDetector.markForCheck();
        },
      });
    this.cartOverlayDynamicService.open({
      data: {},
      isBrowser: this.isBrowser,
    });
  }

  public goToCart(): void {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('cart');
    this.akitaRouterService.navigate(fragment);
  }

  public openBurguerMenu(): void {
    if (this.openBurguerMenuOverlaySubscription) {
      this.openBurguerMenuOverlaySubscription.unsubscribe();
    }

    this.openBurguerMenuOverlaySubscription =
      this.burguerMenuOverlayDynamicService.observe
        .pipe(takeUntil(this.destroySubject))
        .subscribe({
          next: (data?: any | null) => {
            this.showingBurguerMenuOverlay = Boolean(data);
            this.changeDetector.markForCheck();
          },
        });
    this.burguerMenuOverlayDynamicService.open({
      data: {},
      isBrowser: this.isBrowser,
    });
  }

  public get userUrlFragment(): Array<string> {
    return this.akitaRouterQuery.userUrlFragment;
  }

  public get signInUrlFragment(): Array<string> {
    return this.akitaRouterQuery.getAuthUrlFragment('sign-in');
  }

  public get signUpUrlFragment(): Array<string> {
    return this.akitaRouterQuery.getAuthUrlFragment('sign-up');
  }

  public get inboxUrlFragment(): Array<string> {
    return this.akitaRouterQuery.userInboxUrlFragment;
  }

  public get activityTypeAriaLabel(): string {
    if (this.activityType === ACTIVITY_TYPE_FETCHING_PRODUCTS) {
      return 'aria_activity_fetching_listings';
    } else if (ACTIVITY_TYPE_PLEASE_WAIT) {
      return 'aria_activity_please_wait';
    }
    return '';
  }

  public ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.authOverlaySubscription) {
      this.authOverlaySubscription.unsubscribe();
    }
    if (this.openBurguerMenuOverlaySubscription) {
      this.openBurguerMenuOverlaySubscription.unsubscribe();
    }
    if (this.openCartOverlaySubscription) {
      this.openCartOverlaySubscription.unsubscribe();
    }
  }
}
