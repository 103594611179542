import { NgModule, Inject, Optional, ModuleWithProviders } from '@angular/core';

import {
  LOADABLE_CONFIG,
  LoadableService,
  LOADABLE_ROOT_OPTIONS,
} from './loadable.service';
import { ModulesConfig, LoadableRootConfig } from './loadable.config';

@NgModule({
  declarations: [],
  imports: [],
  providers: [],
  exports: [],
})
export class LoadableModule {
  public static forRoot(
    config?: LoadableRootConfig | null
  ): ModuleWithProviders<LoadableModule> {
    return {
      ngModule: LoadableModule,
      providers: [
        { provide: LOADABLE_CONFIG, useValue: {}, multi: true, deps: [LoadableService] },
        { provide: LOADABLE_CONFIG, useValue: (config || {}).moduleConfigs, multi: true },
        { provide: LOADABLE_ROOT_OPTIONS, useValue: (config || {}).rootOptions || {} },
      ],
    };
  }

  constructor(
    ls: LoadableService,
    @Optional() @Inject(LOADABLE_CONFIG) configs: ModulesConfig[] = []
  ) {
    if (!configs) {
      return;
    }

    ls.addConfig(configs[configs.length - 1]);
  }
}
