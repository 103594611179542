import { Model } from '../../model.interface';

export enum VerifyUserStatus {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  VALID = 'VALID',
  NOT_VALID = 'NOT_VALID',
}

/**
 * Function to convert a string into a valid VerifyUserStatus Instance
 * @param {string|undefined} option the string to convert to the enum.
 * @returns {VerifyUserStatus} the valid enum instance
 * - if option is not valid, it returns the default value
 */
export const verifyUserStatusFromString = (option?: string | null): VerifyUserStatus => {
  option = (option || '').toUpperCase();

  if (option === VerifyUserStatus.ALREADY_EXISTS) {
    return VerifyUserStatus.ALREADY_EXISTS;
  } else if (option === VerifyUserStatus.VALID) {
    return VerifyUserStatus.VALID;
  }
  return VerifyUserStatus.NOT_VALID;
};

/**
 * Class used to hold the response of the /oauth/verify endpoint
 */
export class VerifyUserModel implements Model {
  public email: VerifyUserStatus;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.email = VerifyUserStatus.VALID;
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   *
   * @param data The Raw JSON Object
   * @returns {VerifyUserModel} An instance of the VerifyUserModel class.
   */
  public static fromJson(data?: Record<string, any> | null): VerifyUserModel | null {
    let verifyUser: VerifyUserModel | null = null;

    if (data) {
      verifyUser = new VerifyUserModel();
      verifyUser.email = verifyUserStatusFromString(data.email);
    }

    return verifyUser;
  }
}
