import {
  Injectable,
  RendererFactory2,
  Injector,
  Renderer2,
  NgZone,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { AkitaConfigurationStore } from './configuration.store';
import {
  getLanguageCode,
  isLanguageRtl,
  SentryLocaleMetadata,
} from '@app/shared/utils/url.utils';
import { environment } from '@environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { MetaService } from '@app/shared/services/meta.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { applyTransaction, logAction } from '@datorama/akita';
import { CurrencyInformation } from '@app/shared/data/currencies.data';
import { UserTiming } from '@app/shared/utils/performance.utils';
import { Subscription } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { configureScope, Scope } from '@sentry/browser';

const DETECT_LANG_PERFORMANCE_MARK_NAME = 'Detect Language';
const DETECT_LANG_PERFORMANCE_MARK_START = `${DETECT_LANG_PERFORMANCE_MARK_NAME} - Start`;
const DETECT_LANG_PERFORMANCE_MARK_END = `${DETECT_LANG_PERFORMANCE_MARK_NAME} - End`;

const APPLY_LANG_PERFORMANCE_MARK_NAME = 'Apply Detected Language';
const APPLY_LANG_PERFORMANCE_MARK_START = `${APPLY_LANG_PERFORMANCE_MARK_NAME} - Start`;
const APPLY_LANG_PERFORMANCE_MARK_END = `${APPLY_LANG_PERFORMANCE_MARK_NAME} - End`;

const LOCALE_TO_COUNTRY: { [key: string]: string } = {
  es: 'ES',
  en: 'US',
  fr: 'FR',
  pt: 'BR',
  ar: 'SA',
};

@Injectable({ providedIn: 'root' })
export class AkitaConfigurationService {
  private readonly isBrowser: boolean;
  private readonly renderer: Renderer2;
  private readonly document: Document | null;

  private languageChangeObserver?: Subscription | null;

  constructor(
    private readonly injector: Injector,
    private readonly platform: Platform,
    private readonly zone: NgZone,
    private readonly store: AkitaConfigurationStore,
    private readonly translateService: TranslocoService,
    private readonly metaService: MetaService,
    private readonly rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, any>
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.renderer = this.rendererFactory.createRenderer(null, null);
    try {
      this.document = this.injector.get<Document | null>(DOCUMENT, null);
    } catch (err) {
      this.document = null;
    }

    let features = {
      lossy: false,
      lossless: false,
      alpha: false,
      animation: false,
    };

    if (this.platform.BLINK) {
      features = {
        lossy: true,
        lossless: true,
        alpha: true,
        animation: true,
      };
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('detectWebPFeatures() - done');
        this.store.update({
          webP: features,
        });
      });
    });
  }

  public changeLanguage(languageCode: string): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('changeLanguage() - done');
        this.store.update({
          languageCode: languageCode,
        });
      });
    });
  }

  public updateCurrency(currency?: CurrencyInformation | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateCurrency()');
        this.store.updateCurrency(currency);
      });
    });
  }

  private refreshSentryLocaleMetadata(metadata: SentryLocaleMetadata): void {
    configureScope((scope: Scope) => {
      scope.setExtra('i18n:requested', metadata.requestedCode);
      scope.setExtra('i18n:subDomain', metadata.hasSubDomain || '-');
      scope.setExtra('i18n:langParam', metadata.hasLangParam || '-');
      scope.setExtra('i18n:browser', metadata.hasBrowser);
      scope.setExtra('i18n:dateLocale', metadata.hasDateLocale);
      scope.setExtra('i18n:final', metadata.chosenLanguage);
    });
  }

  public detectLanguage(fallbackLanguageCode: string, requestURL: string): void {
    UserTiming.createMark(DETECT_LANG_PERFORMANCE_MARK_START);

    // process the language code from the action and decide which language should be set
    let locale = getLanguageCode(
      fallbackLanguageCode,
      requestURL,
      this.isBrowser,
      this.refreshSentryLocaleMetadata.bind(this)
    );

    let lang = locale;
    if (locale.indexOf('-') === -1) {
      if (LOCALE_TO_COUNTRY[locale.toLowerCase()]) {
        locale = `${locale}-${LOCALE_TO_COUNTRY[locale].toUpperCase()}`;
      }
    } else {
      const tokens = locale.toLowerCase().split('-');
      lang = (environment.languages as any)[tokens[1] || ''] || tokens[0] || 'en';
    }

    // Apply the new language code
    UserTiming.createMark(APPLY_LANG_PERFORMANCE_MARK_START);
    if (this.languageChangeObserver) {
      this.languageChangeObserver.unsubscribe();
    }

    this.languageChangeObserver = this.translateService.langChanges$.subscribe({
      next: (newLang: string) => {
        if (newLang === lang) {
          this.refreshLanguageMetadata(newLang, locale);

          UserTiming.createMark(APPLY_LANG_PERFORMANCE_MARK_END);
          UserTiming.measure(
            APPLY_LANG_PERFORMANCE_MARK_NAME,
            APPLY_LANG_PERFORMANCE_MARK_START,
            APPLY_LANG_PERFORMANCE_MARK_END
          );
        }
      },
      error: () => {},
    });

    this.translateService.setActiveLang(lang);

    this.changeLanguage(locale);

    UserTiming.createMark(DETECT_LANG_PERFORMANCE_MARK_END);
    UserTiming.measure(
      DETECT_LANG_PERFORMANCE_MARK_NAME,
      DETECT_LANG_PERFORMANCE_MARK_START,
      DETECT_LANG_PERFORMANCE_MARK_END
    );
  }

  public refreshLanguageMetadata(lang: string, locale?: string | null): void {
    this.zone.runOutsideAngular(() => {
      const isRtl = isLanguageRtl(lang);
      this.metaService.setTag({ name: 'og:locale', content: `${locale || 'en-US'}` });

      if (this.document) {
        this.document.dir = isRtl ? 'rtl' : 'ltr';
        const htmlElement = this.document.querySelector('html');
        if (htmlElement) {
          this.renderer.setAttribute(htmlElement, 'lang', locale || 'en-US');
          this.renderer.setAttribute(htmlElement, 'dir', isRtl ? 'rtl' : 'ltr');
        }

        if (this.document.body) {
          if (lang === 'ar') {
            this.renderer.addClass(this.document.body, 'arabic');
          } else {
            this.renderer.removeClass(this.document.body, 'arabic');
          }
        }
      }
    });
  }
}
