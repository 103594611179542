const DEFAULT_ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz';
import JSBI from 'jsbi';

export const parseBigIntBase = (
  text: string,
  base: number,
  alphabet?: string | null,
  caseSensitive?: boolean | null
): JSBI => {
  alphabet = alphabet || DEFAULT_ALPHABET;
  text = String(text);
  if (!caseSensitive) {
    text = text.toLowerCase();
    alphabet = alphabet.toLowerCase();
  }
  const length = text.length;
  let i;
  const absBase = Math.abs(base);
  const alphabetValues: { [key: string]: number } = {};
  for (i = 0; i < alphabet.length; i++) {
    alphabetValues[alphabet[i]] = i;
  }
  for (i = 0; i < length; i++) {
    const character: string = text[i];
    if (character === '-') {
      continue;
    }
    if (character in alphabetValues) {
      if (alphabetValues[character] >= absBase) {
        if (character === '1' && absBase === 1) {
          continue;
        }
        throw new Error(character + ' is not a valid digit in base ' + base + '.');
      }
    }
  }

  const newBase = parseBigIntValue(base);
  const digits = [];
  const isNegative = text[0] === '-';
  for (i = isNegative ? 1 : 0; i < text.length; i++) {
    const c: string = text[i];
    if (c in alphabetValues) {
      digits.push(parseBigIntValue(alphabetValues[c]));
    } else if (c === '<') {
      const start = i;
      do {
        i++;
      } while (text[i] !== '>' && i < text.length);
      digits.push(parseBigIntValue(text.slice(start + 1, i)));
    } else {
      throw new Error(c + ' is not a valid character');
    }
  }
  return parseBigIntBaseFromArray(digits, newBase, isNegative);
};

export const parseBigIntBaseFromArray = (
  digits: Array<JSBI>,
  base: JSBI,
  isNegative: boolean
): JSBI => {
  let val = JSBI.BigInt(0);
  let pow = JSBI.BigInt(1);
  for (let i = digits.length - 1; i >= 0; i--) {
    val = JSBI.add(val, JSBI.multiply(digits[i], pow));
    pow = JSBI.multiply(pow, base);
  }
  return isNegative ? JSBI.subtract(JSBI.BigInt(0), val) : val;
};

export const parseBigIntValue = (value: unknown): JSBI => {
  if (typeof value === 'number') {
    return JSBI.BigInt(value);
  }
  return value as JSBI;
};
