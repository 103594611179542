import { PriceModel } from '@app/shared/models/api/price.model';

export class Insurance {
  public id: string;
  public price: PriceModel;
  public description: string;
  public image: string;
  public quantity: number | null;
  public productId: string | null;

  constructor() {
    this.id = '';
    this.price = new PriceModel();
    this.description = '';
    this.image = '';
    this.quantity = null;
    this.productId = null;
  }
}

export const parseInsurance = (data?: Record<string, any> | null): Insurance | null => {
  let insurance: Insurance | null = null;
  if (data) {
    insurance = new Insurance();
    insurance.id = data.id || data.insurance_id || data.insuranceId || '';
    insurance.description = data.description || '';
    insurance.image = data.image || '';

    if (data.price) {
      insurance.price = PriceModel.fromJson(data.price) || new PriceModel();
    } else {
      insurance.price =
        PriceModel.fromJson({
          amount: data?.value,
          currency: data?.currency,
        }) || new PriceModel();
    }

    if (data.quantity) {
      insurance.quantity = data.quantity;
    }

    if (data.productId || data.product_id) {
      insurance.productId = data.productId || data.product_id;
    }
  }
  return insurance;
};

export const parseInsuranceList = (
  data?: Array<Record<string, any>> | null
): Array<Insurance> => {
  const insurances: Array<Insurance> = new Array(0);
  if (data) {
    for (const item of data || []) {
      const parsed = parseInsurance(item);
      if (parsed) {
        insurances.push(parsed);
      }
    }
  }
  return insurances;
};
