export class PhoneInfo {
  public areaCode: string;
  public countryCode: string;
  public national: string;
  public display: string;

  constructor() {
    this.areaCode = '';
    this.countryCode = '';
    this.national = '';
    this.display = '';
  }
}

export const parsePhoneInfo = (data?: any | null): PhoneInfo => {
  const out = new PhoneInfo();
  if (data) {
    out.areaCode = `${
      data.phone_area_code || data.phoneAreaCode || data.area_code || data.areaCode || ''
    }`;
    out.countryCode = `${
      data.phone_country_code ||
      data.phoneCountryCode ||
      data.country_code ||
      data.countryCode ||
      ''
    }`;
    out.national = `${data.phone_number || data.phoneNumber || data.national || ''}`;
    out.display = `${data.display || ''}`;
    if (!out?.display) {
      let phone = '';
      if (out?.countryCode) {
        phone = `+${out.countryCode} `;
      }
      if (out?.areaCode) {
        phone = `${phone}${out.areaCode}`;
      }
      if (out?.national) {
        phone = `${phone}${out.national}`;
      }
      out.display = phone;
    }
  }
  return out;
};
