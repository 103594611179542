import { StoreConfig, Store } from '@datorama/akita';
import { UserModel } from '@app/shared/models/api/user.model';
import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { AkitaAuthState, createInitialState } from '../models/auth.state';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { SocialAuthInfo } from '../models/social-auth-info.model';
import { AkitaRouterService } from '@app/akita/router/state/router.service';
import { VerifyUserStatus } from '@app/shared/models/api/auth/verify-user.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AkitaAuthStore extends Store<AkitaAuthState> {
  constructor(
    private readonly akitaRouterService: AkitaRouterService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    super(createInitialState());
  }

  public setCookieSession(pid: string | null, sec: string | null): void {
    this.update({
      cookiePid: pid,
      cookieSec: sec,
    });
  }

  public updateLastTokenRefresh(reset?: boolean): void {
    this.update({
      lastTokenRefresh: reset ? new Date(0) : new Date(),
    });
  }

  public setSessionId(sessionId?: string | null): void {
    this.update({
      sessionId: sessionId || null,
    });
  }

  public setUser(user: UserModel | null): void {
    this.update((state: AkitaAuthState) => {
      this.googleAnalyticsService.refreshUserInfo(
        user || null,
        state.numberOfListings,
        state.numberOfMessages,
        state.numberOfFavorites
      );

      this.akitaRouterService.updateSessionUser(user);

      return {
        user: user,
        lastUpdated: new Date(),
      };
    });
  }

  public updateListingsCount(count: number): void {
    this.update((state: AkitaAuthState) => {
      if (state.user) {
        this.googleAnalyticsService.refreshUserInfo(
          state.user || null,
          state.numberOfListings,
          state.numberOfMessages,
          state.numberOfFavorites
        );
        return {
          numberOfListings: count || 0,
        };
      }

      return {
        numberOfListings: 0,
      };
    });
  }

  public updateMessagesCount(count: number): void {
    this.update((state: AkitaAuthState) => {
      if (state.user) {
        this.googleAnalyticsService.refreshUserInfo(
          state.user || null,
          state.numberOfListings,
          state.numberOfMessages,
          state.numberOfFavorites
        );
        return {
          numberOfMessages: count || 0,
        };
      }

      return {
        numberOfMessages: 0,
      };
    });
  }

  public updateFavoritesCount(count: number): void {
    this.update((state: AkitaAuthState) => {
      if (state.user) {
        this.googleAnalyticsService.refreshUserInfo(
          state.user || null,
          state.numberOfListings,
          state.numberOfMessages,
          state.numberOfFavorites
        );
        return {
          numberOfFavorites: count || 0,
        };
      }

      return {
        numberOfFavorites: 0,
      };
    });
  }

  public toggleSingingWithApple(isSingingIn?: boolean | null): void {
    this.update({
      signingInWithApple: Boolean(isSingingIn),
    });
  }

  public setSignWithAppleError(error?: ApiError | null): void {
    this.update({
      signWithAppleError: error || null,
    });
  }

  public toggleSingingWithAppleStep2(isSingingIn?: boolean | null): void {
    this.update({
      signingInWithAppleStep2: Boolean(isSingingIn),
    });
  }

  public setSignWithAppleStep2Error(error?: ApiError | null): void {
    this.update({
      signWithAppleStep2Error: error || null,
    });
  }

  public setSignWithAppleInfo(info?: SocialAuthInfo | null): void {
    this.update({
      signWithAppleInfo: {
        name: `${info?.name || ''}`,
        email: `${info?.email || ''}`,
        secret: `${info?.secret || ''}`,
      },
    });
  }

  public toggleSigningInWithGoogleSDKLoaded(hasLoaded?: boolean | null): void {
    this.update({
      signingInWithGoogleSDKLoaded: Boolean(hasLoaded),
    });
  }

  public toggleSingingWithGoogle(isSingingIn?: boolean | null): void {
    this.update({
      signingInWithGoogle: Boolean(isSingingIn),
    });
  }

  public setSignWithGoogleError(error?: ApiError | null): void {
    this.update({
      signWithGoogleError: error || null,
    });
  }

  public toggleSingingWithGoogleStep2(isSingingIn?: boolean | null): void {
    this.update({
      signingInWithGoogleStep2: Boolean(isSingingIn),
    });
  }

  public setSignWithGoogleStep2Error(error?: ApiError | null): void {
    this.update({
      signWithGoogleStep2Error: error || null,
    });
  }

  public setSignWithGoogleInfo(info?: SocialAuthInfo | null): void {
    this.update({
      signWithGoogleInfo: {
        name: `${info?.name || ''}`,
        email: `${info?.email || ''}`,
        picture: `${info?.picture || ''}`,
        secret: `${info?.secret || ''}`,
      },
    });
  }

  public toggleSingingWithFacebook(isSingingIn?: boolean | null): void {
    this.update({
      signingInWithFacebook: Boolean(isSingingIn),
    });
  }

  public setSignWithFacebookError(error?: ApiError | null): void {
    this.update({
      signWithFacebookError: error || null,
    });
  }

  public toggleSingingWithFacebookStep2(isSingingIn?: boolean | null): void {
    this.update({
      signingInWithFacebookStep2: Boolean(isSingingIn),
    });
  }

  public setSignWithFacebookStep2Error(error?: ApiError | null): void {
    this.update({
      signWithFacebookStep2Error: error || null,
    });
  }

  public setSignWithFacebookInfo(info?: SocialAuthInfo | null): void {
    this.update({
      signWithFacebookInfo: {
        name: `${info?.name || ''}`,
        email: `${info?.email || ''}`,
        picture: `${info?.picture || ''}`,
        secret: `${info?.secret || ''}`,
      },
    });
  }

  public toggleInitializingSignInWithApple(initializing?: boolean | null): void {
    this.update({
      initializingSignInWithApple: Boolean(initializing),
    });
  }

  public setInitializingSignInWithAppleError(error?: ApiError | null): void {
    this.update({
      initializingSignInWithAppleError: error || null,
    });
  }

  public setSignInWithAppleInitialized(initialized?: boolean | null): void {
    this.update({
      signInWithAppleInitialized: Boolean(initialized),
    });
  }

  public setRegisteringInBackgroundError(error?: ApiError | null): void {
    this.update({
      errorRegisteringInBackground: error || null,
    });
  }

  public toggleRegisteringInBackground(isRegistering?: boolean | null): void {
    this.update({
      registeringInBackground: Boolean(isRegistering),
    });
  }

  public setRegisteringError(error?: ApiError | null): void {
    this.update({
      errorRegistering: error || null,
    });
  }

  public toggleRegistering(isRegistering?: boolean | null): void {
    this.update({
      registering: Boolean(isRegistering),
    });
  }

  public setCheckingIfEmailValidError(error?: ApiError | null): void {
    this.update({
      errorCheckingIfEmailValid: error || null,
    });
  }

  public toggleCheckingIfEmailValid(isChecking?: boolean | null): void {
    this.update({
      checkingIfEmailValid: Boolean(isChecking),
    });
  }

  public updateEmailStatus(email: string, status: VerifyUserStatus): void {
    this.update((state: AkitaAuthState) => {
      const validEmailCheckResults = { ...state.validEmailCheckResults };
      validEmailCheckResults[email] = status;
      return { validEmailCheckResults: validEmailCheckResults };
    });
  }
}
