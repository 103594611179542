import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  AkitaOverlaysState,
  INITIAL_LOAD_STATUS,
  AkitaOverlaysLoadStatus,
  AkitaOverlayType,
} from '../models/overlay.state';
import { AkitaOverlaysStore } from './overlays.store';
import { map, distinctUntilChanged, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AkitaOverlaysQuery extends Query<AkitaOverlaysState> {
  constructor(protected store: AkitaOverlaysStore) {
    super(store);
  }

  public get loadStatus(): AkitaOverlaysLoadStatus {
    return { ...(this.getValue().loaded || INITIAL_LOAD_STATUS) };
  }

  public get loadingStatus(): AkitaOverlaysLoadStatus {
    return { ...(this.getValue().loading || INITIAL_LOAD_STATUS) };
  }

  public get errorStatus(): AkitaOverlaysLoadStatus {
    return { ...(this.getValue().loading || INITIAL_LOAD_STATUS) };
  }

  public getOverlayStatus(name: AkitaOverlayType): boolean {
    return Boolean(this.loadStatus[name]);
  }

  public isOverlayLoading(name: AkitaOverlayType): boolean {
    return Boolean(this.loadingStatus[name]);
  }

  public overlayFailedToLoad(name: AkitaOverlayType): boolean {
    return Boolean(this.errorStatus[name]);
  }

  public selectLoadStatus(): Observable<AkitaOverlaysLoadStatus> {
    return this.select().pipe(
      map((state: AkitaOverlaysState) => ({ ...(state.loaded || INITIAL_LOAD_STATUS) })),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectLoadingStatus(): Observable<AkitaOverlaysLoadStatus> {
    return this.select().pipe(
      map((state: AkitaOverlaysState) => ({ ...(state.loading || INITIAL_LOAD_STATUS) })),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectErrorStatus(): Observable<AkitaOverlaysLoadStatus> {
    return this.select().pipe(
      map((state: AkitaOverlaysState) => ({ ...(state.error || INITIAL_LOAD_STATUS) })),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectOverlayLoadStatus(name: AkitaOverlayType): Observable<boolean> {
    return this.selectLoadStatus().pipe(
      map((status: AkitaOverlaysLoadStatus) => Boolean(status[name]))
    );
  }
}
