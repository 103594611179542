import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map, distinctUntilChanged, Observable } from 'rxjs';
import {
  AkitaScreenState,
  AkitaScreenStore,
  ScreenSizes,
  ScreenOrientations,
  ScreenSizeInfoState,
} from './screen.store';
import { Direction } from '@angular/cdk/bidi';

@Injectable({ providedIn: 'root' })
export class AkitaScreenQuery extends Query<AkitaScreenState> {
  constructor(protected store: AkitaScreenStore) {
    super(store);
  }

  public get isScreenShort(): boolean {
    return this.getValue().isScreenShort;
  }

  public get size(): ScreenSizes {
    return this.getValue().size;
  }

  public get orientation(): ScreenOrientations {
    return this.getValue().orientation;
  }

  public get direction(): Direction {
    return this.getValue().direction;
  }

  public get lessThan(): ScreenSizeInfoState {
    return this.getValue().lessThan;
  }

  public get biggerThan(): ScreenSizeInfoState {
    return this.getValue().biggerThan;
  }

  public get browser(): string | null {
    return this.getValue().browser;
  }

  // Async

  public selectSize(): Observable<ScreenSizes> {
    return this.select().pipe(
      map((state: AkitaScreenState) => state.size),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectDirection(): Observable<Direction> {
    return this.select().pipe(
      map((state: AkitaScreenState) => state.direction),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectOrientation(): Observable<ScreenOrientations> {
    return this.select().pipe(
      map((state: AkitaScreenState) => state.orientation),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectLessThan(): Observable<ScreenSizeInfoState> {
    return this.select().pipe(
      map((state: AkitaScreenState) => state.lessThan),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectBiggerThan(): Observable<ScreenSizeInfoState> {
    return this.select().pipe(
      map((state: AkitaScreenState) => state.biggerThan),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIsScreenShort(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaScreenState) => Boolean(state.isScreenShort)),
      distinctUntilChanged()
    );
  }
}
