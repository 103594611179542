import { HttpErrorResponse } from '@angular/common/http';

// Angular
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Sentry
import { showReportDialog } from '@sentry/browser';
import { SentryUtil } from '@app/shared/utils/sentry.util';

// Configuration
import { environment } from '@environments/environment';
import { HttpStatusCode } from '@app/shared/utils/http-status-codes';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

// Create the Sentry Error Handler
@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  private readonly isBrowser: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;
  }

  public handleError(error?: any | null): void {
    let router: Router | null = null;
    let activatedRoute: ActivatedRoute | null = null;
    try {
      activatedRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
      router = this.injector.get<Router>(Router);
    } catch (err) {}

    let route = activatedRoute?.snapshot;
    while (route?.firstChild) {
      route = route.firstChild;
    }
    const routeData = route?.data || {};

    // Server or connection error happened
    if (error instanceof HttpErrorResponse) {
      // Handle offline error
      if (this.isBrowser && navigator && !navigator.onLine) {
        this.googleAnalyticsService.appException(
          `[ ${error.name} ] ${error.message}`,
          'network',
          `${error.status}`,
          false,
          routeData.analyticsName
        );
        this.reportError(error);

        if (router) {
          router.navigate(['/offline']).catch((navigationError: unknown) => {
            console.log(navigationError);
          });
        }

        // Handle Http Error (error.status === 5**)
      } else {
        if (error.status !== 0 && error.status >= HttpStatusCode.INTERNAL_SERVER_ERROR) {
          this.googleAnalyticsService.appException(
            `[ ${error.name} ] ${error.message}`,
            'network',
            `${error.status}`,
            false,
            routeData.analyticsName
          );
          this.reportError(
            new Error(
              `Status: ${error.status}\nName: ${error.name}\n Message: ${error.message}\n URL: ${error.url}`
            )
          );

          if (router) {
            router
              .navigate(['/error'], { queryParams: { code: error.status } })
              .catch((navigationError: unknown) => {
                console.log(navigationError);
              });
          }
        }
      }

      // Handle Client Error (Angular Error, ReferenceError...)
    } else {
      this.googleAnalyticsService.appException(
        `[ ${error?.name || ''} ] ${error?.message || ''}`,
        'application',
        `${error?.status || ''}`,
        false,
        routeData.analyticsName
      );
      this.reportError(error);
      // router.navigate(['/error'], { queryParams: { code: 'error_unknown' } });
    }
  }

  public reportError(error: unknown): void {
    // Log the Error
    if (environment.reportToSentry) {
      SentryUtil.reportException((error as any)?.originalError || error, false);
      if (environment.sentryAskFeedback) {
        showReportDialog();
      }
    } else {
      console.error(error);
    }
  }
}
