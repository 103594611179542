import {
  Insurance,
  parseInsurance,
  parseInsuranceList,
} from '@app/akita/api/products/models/insurance.model';
import { parseProduct, Product } from '@app/akita/api/products/models/product.model';
import { PriceModel } from '@app/shared/models/api/price.model';

export class CartItem {
  public product: Product;
  public insurance?: Insurance | null;

  public metadata: {
    color: string;
    storage: number;
    model: string;
  };
  public quantity: number;
  // Internal
  public displayDate: string;

  constructor() {
    this.product = new Product();
    this.metadata = {
      color: '',
      storage: 0,
      model: '',
    };
    this.displayDate = '';
    this.quantity = 0;
  }
}

export const cartItemFromJson = (data?: Record<string, any> | null): CartItem | null => {
  let parsed: CartItem | null = null;
  if (data) {
    parsed = new CartItem();
    parsed.product = parseProduct(data.product) || new Product();

    parsed.insurance = parseInsurance(data.insurance);

    parsed.metadata.color = data.metadata?.color || '';
    parsed.metadata.storage = data.metadata?.storage;
    parsed.metadata.model = data.metadata?.model || '';

    parsed.displayDate = data.display_date || data.displayDate || '';
    parsed.quantity = data.quantity || 0;
  }
  return parsed;
};

export const cartItemListFromJson = (data?: any | null): Array<CartItem> => {
  const list: Array<CartItem> = new Array(0);

  if (data?.id) {
    for (const item of data.orders) {
      const insurance = data.insurances?.find(
        (i: any) => i.product_id === item.product.id
      );
      item.insurance = insurance;
      const parsed = cartItemFromJson(item);
      if (parsed) {
        list.push(parsed);
      }
    }
  }
  return list;
};

export const parseCartAPIResponse = (data?: any | null): any => {
  const response: any = {
    id: null,
    items: new Array(0),
    insurances: new Array(0),
    isLocked: false,
    isProtected: false,
    protectionPrice: null,
    sessionId: '',
  };
  let currency: string | null = null;

  const list: Array<CartItem> = new Array(0);

  if (data?.id) {
    for (const item of data.orders) {
      const insurance = data.insurance?.find(
        (i: any) => i.product_id === item.product.id
      );
      item.insurance = insurance;
      const parsed = cartItemFromJson(item);
      if (parsed) {
        list.push(parsed);
      }
      if (!currency) {
        currency = item.product.price.currency;
      }
    }
    response.sessionId = data.session_id || data.sessionId || '';
    response.id = data.id;
    response.items = list;
    response.insurances = parseInsuranceList(data.insurance);
    response.isLocked = data.is_locked || data.isLocked || false;
    if (data.protection_price) {
      response.protectionPrice = {
        amount: data.protection_price,
        currency: currency,
      } as PriceModel;
      response.isProtected = true;
    }
  }
  return response;
};
