export interface TimeZoneCountryInfoMap {
  [name: string]: TimeZoneCountryInfo;
}

export interface TimeZoneCountryInfo {
  lat: number;
  lng: number;
  country: string;
  continent?: string | null;
  city?: string | null;
}

const TIMEZONE_TO_COUNTRY: TimeZoneCountryInfoMap = {
  'Asia/Kabul': {
    lat: 34.98300013,
    lng: 63.13329964,
    country: 'AF',
  },
  'Europe/Mariehamn': {
    lat: 60.09699618,
    lng: 19.94900447,
    country: 'AX',
  },
  'Europe/Tirane': {
    lat: 41.51899817,
    lng: 19.79700359,
    country: 'AL',
  },
  'Africa/Algiers': {
    lat: 36.82199703,
    lng: 5.76600356,
    country: 'DZ',
  },
  'Pacific/Pago_Pago': {
    lat: -14.2766105,
    lng: -170.7066451,
    country: 'AS',
  },
  'Europe/Andorra': {
    lat: 42.50000144,
    lng: 1.516485961,
    country: 'AD',
  },
  'Africa/Luanda': {
    lat: -18.01953449,
    lng: 21.42999914,
    country: 'AO',
  },
  'America/Antigua': {
    lat: 17.11803652,
    lng: -61.85003382,
    country: 'AG',
  },
  'America/Santiago': {
    lat: -51.65003986,
    lng: -72.30001612,
    country: 'AR',
  },
  'America/Argentina/Rio_Gallegos': {
    lat: -48.76659829,
    lng: -70.25001205,
    country: 'AR',
  },
  'America/Argentina/Catamarca': {
    lat: -45.68337563,
    lng: -70.26657434,
    country: 'AR',
  },
  'America/Argentina/Mendoza': {
    lat: -32.82503904,
    lng: -68.80167668,
    country: 'AR',
  },
  'America/Argentina/Salta': {
    lat: -38.94001463,
    lng: -69.24002202,
    country: 'AR',
  },
  'America/Argentina/Buenos_Aires': {
    lat: -38.87996662,
    lng: -62.0799681,
    country: 'AR',
  },
  'America/Argentina/Cordoba': {
    lat: -31.43003375,
    lng: -62.08996749,
    country: 'AR',
  },
  'America/Argentina/Jujuy': {
    lat: -22.7166638,
    lng: -65.6999797,
    country: 'AR',
  },
  'America/Argentina/La_Rioja': {
    lat: -31.34998696,
    lng: -66.5999506,
    country: 'AR',
  },
  'America/Argentina/Tucuman': {
    lat: -26.21665688,
    lng: -65.28331262,
    country: 'AR',
  },
  'America/Asuncion': {
    lat: -22.47998574,
    lng: -62.29998051,
    country: 'AR',
  },
  'America/Argentina/San_Juan': {
    lat: -30.21558592,
    lng: -69.1399506,
    country: 'AR',
  },
  'America/Argentina/San_Luis': {
    lat: -33.68958576,
    lng: -65.4699679,
    country: 'AR',
  },
  'America/Argentina/Ushuaia': {
    lat: -53.79144552,
    lng: -67.6989952,
    country: 'AR',
  },
  'Asia/Yerevan': {
    lat: 40.3016667,
    lng: 44.3591667,
    country: 'AM',
  },
  'America/Aruba': {
    lat: 12.53038373,
    lng: -70.02899195,
    country: 'AW',
  },
  'Australia/Sydney': {
    lat: -33.42004148,
    lng: 151.3000048,
    country: 'AU',
  },
  'Australia/Brisbane': {
    lat: -26.67998777,
    lng: 153.0500272,
    country: 'AU',
  },
  'Australia/Darwin': {
    lat: -13.81617348,
    lng: 131.816698,
    country: 'AU',
  },
  'Australia/Perth': {
    lat: -33.58287392,
    lng: 120.0333345,
    country: 'AU',
  },
  'Australia/Melbourne': {
    lat: -36.12959186,
    lng: 144.750017,
    country: 'AU',
  },
  'Australia/Adelaide': {
    lat: -34.28293455,
    lng: 140.6000378,
    country: 'AU',
  },
  'Australia/Hobart': {
    lat: -40.83292234,
    lng: 145.1166613,
    country: 'AU',
  },
  'Australia/Broken_Hill': {
    lat: -31.94995034,
    lng: 141.4331136,
    country: 'AU',
  },
  'Europe/Vienna': {
    lat: 47.51669707,
    lng: 9.766701588,
    country: 'AT',
  },
  'Europe/Berlin': {
    lat: 48.56704714,
    lng: 13.46660925,
    country: 'DE',
  },
  'Asia/Baku': {
    lat: 40.5655556,
    lng: 45.8161111,
    country: 'AZ',
  },
  'Asia/Bahrain': {
    lat: 26.23613629,
    lng: 50.58305172,
    country: 'BH',
  },
  'Asia/Dhaka': {
    lat: 24.24997845,
    lng: 89.92003048,
    country: 'BD',
  },
  'America/Barbados': {
    lat: 13.10200258,
    lng: -59.61652674,
    country: 'BB',
  },
  'Europe/Minsk': {
    lat: 53.13684572,
    lng: 26.01344031,
    country: 'BY',
  },
  'Europe/Brussels': {
    lat: 50.44599911,
    lng: 3.939003561,
    country: 'BE',
  },
  'America/Belize': {
    lat: 17.15599807,
    lng: -89.06100252,
    country: 'BZ',
  },
  'Africa/Porto-Novo': {
    lat: 6.615000092,
    lng: 1.715004457,
    country: 'BJ',
  },
  'Atlantic/Bermuda': {
    lat: 32.29419029,
    lng: -64.78393742,
    country: 'BM',
  },
  'Asia/Thimphu': {
    lat: 27.3833011,
    lng: 89.51670065,
    country: 'BT',
  },
  'America/La_Paz': {
    lat: -17.55000242,
    lng: -65.83997115,
    country: 'BO',
  },
  'America/Lima': {
    lat: -12.49961302,
    lng: -68.66656865,
    country: 'BO',
  },
  'America/Rio_Branco': {
    lat: -11.03334593,
    lng: -68.73330876,
    country: 'BO',
  },
  'Europe/Sarajevo': {
    lat: 44.21997398,
    lng: 17.91998083,
    country: 'BA',
  },
  'Africa/Gaborone': {
    lat: -24.377004,
    lng: 26.15200256,
    country: 'BW',
  },
  'America/Fortaleza': {
    lat: -5.809995505,
    lng: -46.14998438,
    country: 'BR',
  },
  'America/Belem': {
    lat: -1.190019105,
    lng: -47.17999903,
    country: 'BR',
  },
  'America/Porto_Velho': {
    lat: -11.64002724,
    lng: -61.20999536,
    country: 'BR',
  },
  'America/Campo_Grande': {
    lat: -22.53000853,
    lng: -55.7299681,
    country: 'BR',
  },
  'America/Sao_Paulo': {
    lat: -23.549411199362915,
    lng: -46.638163146362295,
    country: 'BR',
  },
  'America/Cuiaba': {
    lat: -15.65001504,
    lng: -56.14002059,
    country: 'BR',
  },
  'America/Maceio': {
    lat: -9.48000405,
    lng: -35.83996769,
    country: 'BR',
  },
  'America/Bahia': {
    lat: -16.28000242,
    lng: -39.0299797,
    country: 'BR',
  },
  'America/Recife': {
    lat: -8.110010153,
    lng: -35.02004358,
    country: 'BR',
  },
  'America/Manaus': {
    lat: -3.289580873,
    lng: -60.6199797,
    country: 'BR',
  },
  'America/Santarem': {
    lat: -1.939585756,
    lng: -54.78999964,
    country: 'BR',
  },
  'America/Araguaina': {
    lat: -6.319576804,
    lng: -47.41998438,
    country: 'BR',
  },
  'America/Boa_Vista': {
    lat: 1.816231505,
    lng: -61.12767481,
    country: 'BR',
  },
  'America/Eirunepe': {
    lat: -6.66002114,
    lng: -69.87380762,
    country: 'BR',
  },
  'Asia/Brunei': {
    lat: 4.883331115,
    lng: 114.9332841,
    country: 'BN',
  },
  'Europe/Sofia': {
    lat: 43.13799911,
    lng: 24.71900459,
    country: 'BG',
  },
  'Africa/Ouagadougou': {
    lat: 12.05499605,
    lng: 0.360999451,
    country: 'BF',
  },
  'Africa/Bujumbura': {
    lat: -3.166703921,
    lng: 30.51669662,
    country: 'BI',
  },
  'Asia/Phnom_Penh': {
    lat: 11.4519961,
    lng: 104.5189986,
    country: 'KH',
  },
  'Africa/Douala': {
    lat: 4.155003087,
    lng: 9.231003513,
    country: 'CM',
  },
  'America/Winnipeg': {
    lat: 50.15002545,
    lng: -96.88332178,
    country: 'CA',
  },
  'America/Regina': {
    lat: 50.93331097,
    lng: -102.7999891,
    country: 'CA',
  },
  'America/Edmonton': {
    lat: 53.01669802,
    lng: -112.8166386,
    country: 'CA',
  },
  'America/Creston': {
    lat: 49.09996035,
    lng: -116.516697,
    country: 'CA',
  },
  'America/Vancouver': {
    lat: 54.49999249,
    lng: -128.5833248,
    country: 'CA',
  },
  'America/Iqaluit': {
    lat: 68.76746684,
    lng: -81.23608303,
    country: 'CA',
  },
  'America/Yellowknife': {
    lat: 62.40005292,
    lng: -110.7333291,
    country: 'CA',
  },
  'America/Toronto': {
    lat: 44.56664532,
    lng: -80.84998519,
    country: 'CA',
  },
  'America/Montreal': {
    lat: 49.82257774,
    lng: -64.34799504,
    country: 'CA',
  },
  'America/Blanc-Sablon': {
    lat: 51.2423102,
    lng: -58.64699935,
    country: 'CA',
  },
  'America/Halifax': {
    lat: 45.58327578,
    lng: -62.63331934,
    country: 'CA',
  },
  'America/St_Johns': {
    lat: 49.17440025,
    lng: -57.42691878,
    country: 'CA',
  },
  'America/Goose_Bay': {
    lat: 55.44996035,
    lng: -60.21667098,
    country: 'CA',
  },
  'America/Dawson_Creek': {
    lat: 55.76696942,
    lng: -120.233266,
    country: 'CA',
  },
  'America/Coral_Harbour': {
    lat: 64.15377016,
    lng: -83.17658736,
    country: 'CA',
  },
  'America/Rankin_Inlet': {
    lat: 64.31699017,
    lng: -96.01665633,
    country: 'CA',
  },
  'America/Whitehorse': {
    lat: 61.35037539,
    lng: -139.0000017,
    country: 'CA',
  },
  'America/Nipigon': {
    lat: 49.01704551,
    lng: -88.24997278,
    country: 'CA',
  },
  'America/Atikokan': {
    lat: 48.75039512,
    lng: -91.61658899,
    country: 'CA',
  },
  'America/Moncton': {
    lat: 45.26704185,
    lng: -66.07667505,
    country: 'CA',
  },
  'America/Pangnirtung': {
    lat: 66.13331341,
    lng: -65.75002832,
    country: 'CA',
  },
  'America/Detroit': {
    lat: 42.33329327,
    lng: -83.03334029,
    country: 'CA',
  },
  'America/Fort_Nelson': {
    lat: 58.81670575,
    lng: -122.5329706,
    country: 'CA',
  },
  'America/Cambridge_Bay': {
    lat: 68.63329002,
    lng: -95.91666244,
    country: 'CA',
  },
  'America/Inuvik': {
    lat: 68.34997398,
    lng: -133.6999893,
    country: 'CA',
  },
  'America/Dawson': {
    lat: 64.0666437,
    lng: -139.4166687,
    country: 'CA',
  },
  'America/Glace_Bay': {
    lat: 46.06611452,
    lng: -60.17998071,
    country: 'CA',
  },
  'America/Resolute': {
    lat: 74.68333417,
    lng: -94.90000615,
    country: 'CA',
  },
  'America/Thunder_Bay': {
    lat: 48.44615013,
    lng: -89.27497481,
    country: 'CA',
  },
  'Atlantic/Cape_Verde': {
    lat: 16.88376141,
    lng: -25.0000092,
    country: 'CV',
  },
  'America/Cayman': {
    lat: 19.28043683,
    lng: -81.32998173,
    country: 'KY',
  },
  'Africa/Bangui': {
    lat: 4.320000059,
    lng: 21.17999753,
    country: 'CF',
  },
  'Africa/Ndjamena': {
    lat: 9.395001123,
    lng: 16.30500349,
    country: 'TD',
  },
  'Asia/Urumqi': {
    lat: 39.83003522,
    lng: 97.72999304,
    country: 'CN',
  },
  'Asia/Chongqing': {
    lat: 35.60000917,
    lng: 103.2000468,
    country: 'CN',
  },
  'Asia/Shanghai': {
    lat: 39.54005292,
    lng: 115.789976,
    country: 'CN',
  },
  'Asia/Harbin': {
    lat: 45.49999921,
    lng: 124.2999991,
    country: 'CN',
  },
  'Asia/Kashgar': {
    lat: 32.20039756,
    lng: 79.98332434,
    country: 'CN',
  },
  'America/Bogota': {
    lat: 5.346999095,
    lng: -72.4059986,
    country: 'CO',
  },
  'Indian/Comoro': {
    lat: -11.7041577,
    lng: 43.2402441,
    country: 'KM',
  },
  'Africa/Brazzaville': {
    lat: -4.164002942,
    lng: 13.55400049,
    country: 'CG',
  },
  'Africa/Lubumbashi': {
    lat: -0.756998889,
    lng: 28.52800254,
    country: 'CD',
  },
  'Africa/Kinshasa': {
    lat: 4.330341613,
    lng: 18.61502885,
    country: 'CD',
  },
  'Pacific/Rarotonga': {
    lat: -21.25003497,
    lng: -159.7500013,
    country: 'CK',
  },
  'America/Costa_Rica': {
    lat: 9.991997986,
    lng: -84.12000251,
    country: 'CR',
  },
  'Europe/Zagreb': {
    lat: 43.7272222,
    lng: 15.9058333,
    country: 'HR',
  },
  'America/Havana': {
    lat: 21.83999636,
    lng: -78.76194727,
    country: 'CU',
  },
  'America/Curacao': {
    lat: 12.20042971,
    lng: -69.01998377,
    country: 'CW',
  },
  'Asia/Nicosia': {
    lat: 34.9170031,
    lng: 33.63599757,
    country: 'CY',
  },
  'Europe/Prague': {
    lat: 50.66299816,
    lng: 14.08100455,
    country: 'CZ',
  },
  'Europe/Copenhagen': {
    lat: 55.70900103,
    lng: 9.534996498,
    country: 'DK',
  },
  'Africa/Djibouti': {
    lat: 11.10400201,
    lng: 42.37200058,
    country: 'DJ',
  },
  'America/Dominica': {
    lat: 15.30101564,
    lng: -61.38701298,
    country: 'DM',
  },
  'America/Santo_Domingo': {
    lat: 19.50499807,
    lng: -71.34498854,
    country: 'DO',
  },
  'Asia/Dili': {
    lat: -8.559388409,
    lng: 125.5794559,
    country: 'TL',
  },
  'America/Guayaquil': {
    lat: -1.483002014,
    lng: -77.98699756,
    country: 'EC',
  },
  'Pacific/Galapagos': {
    lat: -0.933342266,
    lng: -91.01665145,
    country: 'EC',
  },
  'Africa/Cairo': {
    lat: 30.59199913,
    lng: 30.89999749,
    country: 'EG',
  },
  'America/El_Salvador': {
    lat: 13.91900399,
    lng: -89.84500155,
    country: 'SV',
  },
  'Africa/Malabo': {
    lat: 1.449999085,
    lng: 10.56670255,
    country: 'GQ',
  },
  'Africa/Asmara': {
    lat: 15.11038129,
    lng: 36.65749345,
    country: 'ER',
  },
  'Europe/Tallinn': {
    lat: 58.9430556,
    lng: 23.5413889,
    country: 'EE',
  },
  'Africa/Addis_Ababa': {
    lat: 7.059996077,
    lng: 38.47699862,
    country: 'ET',
  },
  'Atlantic/Stanley': {
    lat: -51.95058999,
    lng: -60.08696314,
    country: 'FK',
  },
  'Atlantic/Faroe': {
    lat: 62.2374783,
    lng: -6.53901149,
    country: 'FO',
  },
  'Pacific/Pohnpei': {
    lat: 6.916643696,
    lng: 158.1499743,
    country: 'FM',
  },
  'Pacific/Fiji': {
    lat: -17.79959959,
    lng: 177.4166019,
    country: 'FJ',
  },
  'Europe/Helsinki': {
    lat: 60.99699611,
    lng: 24.47199954,
    country: 'FI',
  },
  'Europe/Paris': {
    lat: 45.89997479,
    lng: 6.116670287,
    country: 'FR',
  },
  'America/Cayenne': {
    lat: 4.729981302,
    lng: -52.33002059,
    country: 'GF',
  },
  'America/Martinique': {
    lat: 14.6104118,
    lng: -61.08002914,
    country: 'MQ',
  },
  'America/Guadeloupe': {
    lat: 16.24147504,
    lng: -61.5329989,
    country: 'GP',
  },
  'Indian/Reunion': {
    lat: -21.03351072,
    lng: 55.71281612,
    country: 'RE',
  },
  'Indian/Mayotte': {
    lat: -12.78708901,
    lng: 45.27500362,
    country: 'YT',
  },
  'Pacific/Tahiti': {
    lat: -17.53336261,
    lng: -149.5666694,
    country: 'PF',
  },
  'Africa/Libreville': {
    lat: -2.856995957,
    lng: 11.02699849,
    country: 'GA',
  },
  'Asia/Tbilisi': {
    lat: 42.24999086,
    lng: 42.72999101,
    country: 'GE',
  },
  'Africa/Accra': {
    lat: 7.335998991,
    lng: -2.336003416,
    country: 'GH',
  },
  'Europe/Gibraltar': {
    lat: 36.13243495,
    lng: -5.37807483,
    country: 'GI',
  },
  'Europe/Athens': {
    lat: 38.89899915,
    lng: 22.43400358,
    country: 'GR',
  },
  'America/Godthab': {
    lat: 68.81927014,
    lng: -51.17851854,
    country: 'GL',
  },
  'America/Danmarkshavn': {
    lat: 70.48335797,
    lng: -21.9666543,
    country: 'GL',
  },
  'America/Thule': {
    lat: 76.01947624,
    lng: -65.11248065,
    country: 'GL',
  },
  'America/Grenada': {
    lat: 12.0526334,
    lng: -61.74164323,
    country: 'GD',
  },
  'Pacific/Guam': {
    lat: 13.4700163,
    lng: 144.750017,
    country: 'GU',
  },
  'America/Guatemala': {
    lat: 15.10299903,
    lng: -90.31400061,
    country: 'GT',
  },
  'Africa/Conakry': {
    lat: 12.08400302,
    lng: -12.30100143,
    country: 'GN',
  },
  'Africa/Bissau': {
    lat: 12.26899803,
    lng: -16.16499854,
    country: 'GW',
  },
  'America/Guyana': {
    lat: 5.900039082,
    lng: -57.16998356,
    country: 'GY',
  },
  'America/Port-au-Prince': {
    lat: 18.63393473,
    lng: -74.11842526,
    country: 'HT',
  },
  'America/Tegucigalpa': {
    lat: 15.05999711,
    lng: -87.29000054,
    country: 'HN',
  },
  'Asia/Hong_Kong': {
    lat: 22.3049809,
    lng: 114.1850093,
    country: 'HK',
  },
  'Europe/Budapest': {
    lat: 47.09099714,
    lng: 17.91099957,
    country: 'HU',
  },
  'Atlantic/Reykjavik': {
    lat: 64.56950277,
    lng: -21.86232219,
    country: 'IS',
  },
  'Asia/Kolkata': {
    lat: 15.491997,
    lng: 73.81800065,
    country: 'IN',
  },
  'Asia/Calcutta': {
    lat: 19.07283,
    lng: 72.88261,
    country: 'IN',
  },
  'Asia/Jakarta': {
    lat: 3.620359109,
    lng: 98.50007524,
    country: 'ID',
  },
  'Asia/Jayapura': {
    lat: 0.696377379,
    lng: 127.4359834,
    country: 'ID',
  },
  'Asia/Makassar': {
    lat: -8.579542217,
    lng: 116.1350195,
    country: 'ID',
  },
  'Asia/Pontianak': {
    lat: 0.911980927,
    lng: 108.9654697,
    country: 'ID',
  },
  'Asia/Tehran': {
    lat: 30.65900412,
    lng: 51.59400361,
    country: 'IR',
  },
  'Asia/Baghdad': {
    lat: 36.86670013,
    lng: 43.00000263,
    country: 'IQ',
  },
  'Europe/Dublin': {
    lat: 53.6333333,
    lng: -8.1833333,
    country: 'IE',
  },
  'Europe/Isle_of_Man': {
    lat: 54.15042727,
    lng: -4.480021404,
    country: 'IM',
  },
  'Asia/Jerusalem': {
    lat: 31.91670012,
    lng: 34.86670252,
    country: 'IL',
  },
  'Europe/Rome': {
    lat: 40.64200213,
    lng: 15.7989965,
    country: 'IT',
  },
  'Africa/Abidjan': {
    lat: 8.280000029,
    lng: -7.684001549,
    country: 'CI',
  },
  'America/Jamaica': {
    lat: 18.44299809,
    lng: -78.17900362,
    country: 'JM',
  },
  'Asia/Tokyo': {
    lat: 34.67202964,
    lng: 133.9170865,
    country: 'JP',
  },
  'Asia/Amman': {
    lat: 32.28329707,
    lng: 36.23329852,
    country: 'JO',
  },
  'Asia/Qyzylorda': {
    lat: 49.62600011,
    lng: 63.49899651,
    country: 'KZ',
  },
  'Asia/Aqtau': {
    lat: 43.69045506,
    lng: 51.14173561,
    country: 'KZ',
  },
  'Asia/Atyrau': {
    lat: 47.64824017,
    lng: 53.32650183,
    country: 'KZ',
  },
  'Asia/Almaty': {
    lat: 52.49967531,
    lng: 73.0997135,
    country: 'KZ',
  },
  'Asia/Aqtobe': {
    lat: 49.47306419,
    lng: 57.44490678,
    country: 'KZ',
  },
  'Asia/Oral': {
    lat: 50.19149579,
    lng: 51.14492956,
    country: 'KZ',
  },
  'Africa/Nairobi': {
    lat: -0.41699699,
    lng: 36.95100363,
    country: 'KE',
  },
  'Pacific/Tarawa': {
    lat: 1.338187506,
    lng: 173.0175708,
    country: 'KI',
  },
  'Asia/Kuwait': {
    lat: 29.33334002,
    lng: 47.99999756,
    country: 'KW',
  },
  'Asia/Bishkek': {
    lat: 42.82987795,
    lng: 75.28459306,
    country: 'KG',
  },
  'Asia/Vientiane': {
    lat: 20.2775,
    lng: 100.4127778,
    country: 'LA',
  },
  'Europe/Riga': {
    lat: 56.50002545,
    lng: 27.3165649,
    country: 'LV',
  },
  'Asia/Beirut': {
    lat: 33.83330406,
    lng: 35.53329652,
    country: 'LB',
  },
  'Africa/Maseru': {
    lat: -29.15299794,
    lng: 27.75300351,
    country: 'LS',
  },
  'Africa/Monrovia': {
    lat: 4.799996997,
    lng: -8.166698518,
    country: 'LR',
  },
  'Africa/Tripoli': {
    lat: 30.1679118,
    lng: 10.45666378,
    country: 'LY',
  },
  'Europe/Vaduz': {
    lat: 47.13372377,
    lng: 9.516669473,
    country: 'LI',
  },
  'Europe/Vilnius': {
    lat: 55.74002016,
    lng: 24.37002641,
    country: 'LT',
  },
  'Europe/Luxembourg': {
    lat: 49.88330105,
    lng: 6.166701555,
    country: 'LU',
  },
  'Asia/Macau': {
    lat: 22.20299746,
    lng: 113.5450484,
    country: 'MO',
  },
  'Europe/Skopje': {
    lat: 42.00923037,
    lng: 20.9700789,
    country: 'MK',
  },
  'Indian/Antananarivo': {
    lat: -14.26617186,
    lng: 50.16659135,
    country: 'MG',
  },
  'Africa/Blantyre': {
    lat: -11.9,
    lng: 33.6,
    country: 'MW',
  },
  'Asia/Kuala_Lumpur': {
    lat: 6.433001991,
    lng: 100.1899987,
    country: 'MY',
  },
  'Asia/Kuching': {
    lat: 5.046396097,
    lng: 118.3359704,
    country: 'MY',
  },
  'Indian/Maldives': {
    lat: 4.16670819,
    lng: 73.49994747,
    country: 'MV',
  },
  'Africa/Bamako': {
    lat: 16.41699404,
    lng: -3.666582684,
    country: 'ML',
  },
  'Europe/Malta': {
    lat: 35.89973248,
    lng: 14.51471065,
    country: 'MT',
  },
  'Pacific/Majuro': {
    lat: 7.103004311,
    lng: 171.3800002,
    country: 'MH',
  },
  'Africa/Nouakchott': {
    lat: 22.67900113,
    lng: -12.70700053,
    country: 'MR',
  },
  'Africa/Dakar': {
    lat: 16.01998985,
    lng: -16.51001062,
    country: 'MR',
  },
  'Indian/Mauritius': {
    lat: -20.31619017,
    lng: 57.51663367,
    country: 'MU',
  },
  'America/Tijuana': {
    lat: 30.76405113,
    lng: -116.0092603,
    country: 'MX',
  },
  'America/Mazatlan': {
    lat: 26.01333335,
    lng: -111.3516635,
    country: 'MX',
  },
  'America/Monterrey': {
    lat: 28.32998781,
    lng: -100.8499789,
    country: 'MX',
  },
  'America/Chihuahua': {
    lat: 26.93335472,
    lng: -105.6666358,
    country: 'MX',
  },
  'America/Ojinaga': {
    lat: 31.10002545,
    lng: -107.979983,
    country: 'MX',
  },
  'America/Hermosillo': {
    lat: 27.58000775,
    lng: -109.9299931,
    country: 'MX',
  },
  'America/Mexico_City': {
    lat: 22.35001691,
    lng: -102.88001,
    country: 'MX',
  },
  'America/Matamoros': {
    lat: 26.07999595,
    lng: -98.30003117,
    country: 'MX',
  },
  'America/Merida': {
    lat: 21.09998985,
    lng: -89.27998743,
    country: 'MX',
  },
  'America/Cancun': {
    lat: 21.20839057,
    lng: -86.7114549,
    country: 'MX',
  },
  'Europe/Chisinau': {
    lat: 47.2630556,
    lng: 29.1608333,
    country: 'MD',
  },
  'Asia/Ulaanbaatar': {
    lat: 50.24999712,
    lng: 106.2000006,
    country: 'MN',
  },
  'Asia/Hovd': {
    lat: 48.93369143,
    lng: 89.95000281,
    country: 'MN',
  },
  'Asia/Choibalsan': {
    lat: 46.69997764,
    lng: 113.2833073,
    country: 'MN',
  },
  'Europe/Podgorica': {
    lat: 42.46597251,
    lng: 19.26630692,
    country: 'ME',
  },
  'Africa/Casablanca': {
    lat: 35.02038047,
    lng: -5.909985801,
    country: 'MA',
  },
  'Africa/El_Aaiun': {
    lat: 27.46290895,
    lng: -12.99218917,
    country: 'MA',
  },
  'Africa/Maputo': {
    lat: -16.09954832,
    lng: 33.95001013,
    country: 'MZ',
  },
  'Africa/Lusaka': {
    lat: -15.61957762,
    lng: 30.41001949,
    country: 'MZ',
  },
  'Asia/Rangoon': {
    lat: 19.66500009,
    lng: 97.20600363,
    country: 'MM',
  },
  'Africa/Windhoek': {
    lat: -21.43600193,
    lng: 15.95099754,
    country: 'NA',
  },
  'Asia/Kathmandu': {
    lat: 28.35000004,
    lng: 82.18330255,
    country: 'NP',
  },
  'Europe/Amsterdam': {
    lat: 53.00000109,
    lng: 6.550002585,
    country: 'NL',
  },
  'Pacific/Noumea': {
    lat: -22.26252776,
    lng: 166.4442852,
    country: 'NC',
  },
  'Pacific/Auckland': {
    lat: -42.47274975,
    lng: 171.2087246,
    country: 'NZ',
  },
  'America/Managua': {
    lat: 13.47599712,
    lng: -86.58299659,
    country: 'NI',
  },
  'Africa/Niamey': {
    lat: 13.98740074,
    lng: 10.2700085,
    country: 'NE',
  },
  'Africa/Lagos': {
    lat: 5.532003041,
    lng: 7.486002487,
    country: 'NG',
  },
  'Asia/Pyongyang': {
    lat: 38.50700411,
    lng: 125.7620047,
    country: 'KP',
  },
  'Pacific/Saipan': {
    lat: 15.21125368,
    lng: 145.7505761,
    country: 'MP',
  },
  'Europe/Oslo': {
    lat: 58.46475606,
    lng: 8.766000553,
    country: 'NO',
  },
  'Asia/Muscat': {
    lat: 23.3031887,
    lng: 57.97820756,
    country: 'OM',
  },
  'Asia/Karachi': {
    lat: 33.89918276,
    lng: 70.10082678,
    country: 'PK',
  },
  'Pacific/Palau': {
    lat: 7.345226355,
    lng: 134.4695009,
    country: 'PW',
  },
  'Asia/Hebron': {
    lat: 31.90294475,
    lng: 35.20620938,
    country: 'PS',
  },
  'Asia/Gaza': {
    lat: 31.52999921,
    lng: 34.44501868,
    country: 'PS',
  },
  'America/Panama': {
    lat: 9.541686355,
    lng: -78.97196299,
    country: 'PA',
  },
  'Pacific/Port_Moresby': {
    lat: -5.490000005,
    lng: 143.7180037,
    country: 'PG',
  },
  'Pacific/Bougainville': {
    lat: -6.228000033,
    lng: 155.5659907,
    country: 'PG',
  },
  'Asia/Manila': {
    lat: 10.55037539,
    lng: 123.3800036,
    country: 'PH',
  },
  'Europe/Warsaw': {
    lat: 53.80003522,
    lng: 20.48003129,
    country: 'PL',
  },
  'Europe/Lisbon': {
    lat: 40.64100311,
    lng: -8.650997534,
    country: 'PT',
  },
  'Atlantic/Azores': {
    lat: 38.53465595,
    lng: -28.64475681,
    country: 'PT',
  },
  'Atlantic/Madeira': {
    lat: 32.64998252,
    lng: -16.88003972,
    country: 'PT',
  },
  'America/Puerto_Rico': {
    lat: 18.00038576,
    lng: -66.61664209,
    country: 'PR',
  },
  'Asia/Qatar': {
    lat: 25.28655601,
    lng: 51.53296789,
    country: 'QA',
  },
  'Europe/Bucharest': {
    lat: 45.04500004,
    lng: 23.27400062,
    country: 'RO',
  },
  'Europe/Moscow': {
    lat: 43.23300312,
    lng: 44.78300151,
    country: 'RU',
  },
  'Asia/Irkutsk': {
    lat: 52.83299713,
    lng: 104.6999977,
    country: 'RU',
  },
  'Asia/Anadyr': {
    lat: 66.95000775,
    lng: -171.8166032,
    country: 'RU',
  },
  'Asia/Srednekolymsk': {
    lat: 69.42444039,
    lng: 161.4811431,
    country: 'RU',
  },
  'Europe/Kaliningrad': {
    lat: 54.6316382,
    lng: 21.81085445,
    country: 'RU',
  },
  'Europe/Volgograd': {
    lat: 50.01610598,
    lng: 45.42610551,
    country: 'RU',
  },
  'Asia/Yekaterinburg': {
    lat: 54.4599691,
    lng: 53.45998205,
    country: 'RU',
  },
  'Europe/Kirov': {
    lat: 58.55437034,
    lng: 50.04437659,
    country: 'RU',
  },
  'Europe/Samara': {
    lat: 52.97432334,
    lng: 49.72434444,
    country: 'RU',
  },
  'Asia/Novokuznetsk': {
    lat: 54.66000856,
    lng: 86.16997514,
    country: 'RU',
  },
  'Asia/Krasnoyarsk': {
    lat: 53.8313253,
    lng: 91.22268998,
    country: 'RU',
  },
  'Asia/Novosibirsk': {
    lat: 54.65093935,
    lng: 83.28653357,
    country: 'RU',
  },
  'Asia/Chita': {
    lat: 51.87056643,
    lng: 116.0306331,
    country: 'RU',
  },
  'Asia/Vladivostok': {
    lat: 44.16230308,
    lng: 133.2823449,
    country: 'RU',
  },
  'Asia/Yakutsk': {
    lat: 73.48330406,
    lng: 113.6300044,
    country: 'RU',
  },
  'Asia/Magadan': {
    lat: 61.63327801,
    lng: 147.9166971,
    country: 'RU',
  },
  'Asia/Sakhalin': {
    lat: 49.08334637,
    lng: 142.0333353,
    country: 'RU',
  },
  'Asia/Kamchatka': {
    lat: 60.5815851,
    lng: 169.0499808,
    country: 'RU',
  },
  'Asia/Tomsk': {
    lat: 56.49260988,
    lng: 84.90359249,
    country: 'RU',
  },
  'Europe/Saratov': {
    lat: 51.50040814,
    lng: 46.12001664,
    country: 'RU',
  },
  'Europe/Ulyanovsk': {
    lat: 54.25042116,
    lng: 49.56001339,
    country: 'RU',
  },
  'Asia/Omsk': {
    lat: 54.16047833,
    lng: 74.82002193,
    country: 'RU',
  },
  'Asia/Barnaul': {
    lat: 52.53406598,
    lng: 85.18000972,
    country: 'RU',
  },
  'Asia/Khandyga': {
    lat: 60.80002138,
    lng: 135.1833142,
    country: 'RU',
  },
  'Europe/Astrakhan': {
    lat: 48.27871848,
    lng: 46.16869584,
    country: 'RU',
  },
  'Asia/Ust-Nera': {
    lat: 64.56658734,
    lng: 143.1999825,
    country: 'RU',
  },
  'Africa/Kigali': {
    lat: -2.483297945,
    lng: 29.5667016,
    country: 'RW',
  },
  'America/St_Kitts': {
    lat: 17.30203046,
    lng: -62.71700932,
    country: 'KN',
  },
  'America/St_Lucia': {
    lat: 14.00197349,
    lng: -61.00000818,
    country: 'LC',
  },
  'America/St_Vincent': {
    lat: 13.14827883,
    lng: -61.21206242,
    country: 'VC',
  },
  'Pacific/Apia': {
    lat: -13.84154504,
    lng: -171.7386416,
    country: 'WS',
  },
  'Europe/San_Marino': {
    lat: 43.91715008,
    lng: 12.46667029,
    country: 'SM',
  },
  'Africa/Sao_Tome': {
    lat: 1.645002051,
    lng: 7.412004483,
    country: 'ST',
  },
  'Asia/Riyadh': {
    lat: 24.70959,
    lng: 46.6752105,
    country: 'SA',
  },
  'Europe/Belgrade': {
    lat: 46.07001609,
    lng: 19.68002844,
    country: 'RS',
  },
  'Indian/Mahe': {
    lat: -4.616631654,
    lng: 55.44998979,
    country: 'SC',
  },
  'Africa/Freetown': {
    lat: 8.880425638,
    lng: -12.04997278,
    country: 'SL',
  },
  'Asia/Singapore': {
    lat: 1.293033466,
    lng: 103.8558207,
    country: 'SG',
  },
  'Europe/Bratislava': {
    lat: 48.73329022,
    lng: 19.14998328,
    country: 'SK',
  },
  'Europe/Ljubljana': {
    lat: 46.54047833,
    lng: 15.65004187,
    country: 'SI',
  },
  'Pacific/Guadalcanal': {
    lat: -8.09962319,
    lng: 156.8350158,
    country: 'SB',
  },
  'Africa/Mogadishu': {
    lat: 4.183298973,
    lng: 43.86670261,
    country: 'SO',
  },
  'Africa/Johannesburg': {
    lat: -30.71953449,
    lng: 25.10000769,
    country: 'ZA',
  },
  'Atlantic/South_Georgia': {
    lat: -54.28057697,
    lng: -36.50798893,
    country: 'GS',
  },
  'Asia/Seoul': {
    lat: 36.93525067,
    lng: 127.6897147,
    country: 'KR',
  },
  'Africa/Juba': {
    lat: 9.2333333,
    lng: 29.8333333,
    country: 'SS',
  },
  'Europe/Madrid': {
    lat: 38.91200402,
    lng: -6.337997512,
    country: 'ES',
  },
  'Atlantic/Canary': {
    lat: 28.96904923,
    lng: -13.53783283,
    country: 'ES',
  },
  'Africa/Ceuta': {
    lat: 35.88898378,
    lng: -5.30699935,
    country: 'ES',
  },
  'Asia/Colombo': {
    lat: 8.568999036,
    lng: 81.23300155,
    country: 'LK',
  },
  'Africa/Khartoum': {
    lat: 11.77040428,
    lng: 34.34998572,
    country: 'SD',
  },
  'America/Paramaribo': {
    lat: 5.599998125,
    lng: -55.19999648,
    country: 'SR',
  },
  'Arctic/Longyearbyen': {
    lat: 78.21668439,
    lng: 15.5499963,
    country: 'SJ',
  },
  'Africa/Mbabane': {
    lat: -25.96100399,
    lng: 31.2470015,
    country: 'SZ',
  },
  'Europe/Stockholm': {
    lat: 60.61300204,
    lng: 15.64700455,
    country: 'SE',
  },
  'Europe/Zurich': {
    lat: 47.36999713,
    lng: 7.344999488,
    country: 'CH',
  },
  'Asia/Damascus': {
    lat: 32.62500014,
    lng: 36.10500351,
    country: 'SY',
  },
  'Asia/Taipei': {
    lat: 24.9575,
    lng: 121.2988889,
    country: 'TW',
  },
  'Asia/Dushanbe': {
    lat: 39.75000301,
    lng: 69.00000365,
    country: 'TJ',
  },
  'Africa/Dar_es_Salaam': {
    lat: -5.063463959,
    lng: 39.725799,
    country: 'TZ',
  },
  'Asia/Bangkok': {
    lat: 19.30100405,
    lng: 97.96899665,
    country: 'TH',
  },
  'America/Nassau': {
    lat: 26.53327578,
    lng: -78.70001306,
    country: 'BS',
  },
  'Africa/Banjul': {
    lat: 13.55100308,
    lng: -14.76700152,
    country: 'GM',
  },
  'Africa/Lome': {
    lat: 9.261000068,
    lng: 0.789003574,
    country: 'TG',
  },
  'Pacific/Tongatapu': {
    lat: -18.64957355,
    lng: -173.9832927,
    country: 'TO',
  },
  'America/Port_of_Spain': {
    lat: 10.28046166,
    lng: -61.45937678,
    country: 'TT',
  },
  'Africa/Tunis': {
    lat: 33.399999,
    lng: 10.41669956,
    country: 'TN',
  },
  'Europe/Istanbul': {
    lat: 41.74299917,
    lng: 27.22599962,
    country: 'TR',
  },
  'Asia/Ashgabat': {
    lat: 38.97553957,
    lng: 56.27779455,
    country: 'TM',
  },
  'America/Grand_Turk': {
    lat: 21.46642743,
    lng: -71.13597864,
    country: 'TC',
  },
  'Pacific/Funafuti': {
    lat: -8.516651999,
    lng: 179.2166471,
    country: 'TV',
  },
  'Africa/Kampala': {
    lat: -0.3088889,
    lng: 32.225,
    country: 'UG',
  },
  'Europe/Kiev': {
    lat: 46.96773907,
    lng: 31.984342,
    country: 'UA',
  },
  'Europe/Uzhgorod': {
    lat: 48.62998903,
    lng: 22.25000077,
    country: 'UA',
  },
  'Europe/Zaporozhye': {
    lat: 46.75682172,
    lng: 36.78683956,
    country: 'UA',
  },
  'Europe/Simferopol': {
    lat: 45.71704022,
    lng: 34.4000085,
    country: 'UA',
  },
  'Asia/Dubai': {
    lat: 25.56527285,
    lng: 55.55334265,
    country: 'AE',
  },
  'Europe/London': {
    lat: 55.93329002,
    lng: -4.750030763,
    country: 'GB',
  },
  'America/Chicago': {
    lat: 44.29048647,
    lng: -93.26801274,
    country: 'US',
  },
  'America/Denver': {
    lat: 45.731768,
    lng: -107.612486,
    country: 'US',
  },
  'America/Boise': {
    lat: 42.53581321,
    lng: -113.7918763,
    country: 'US',
  },
  'America/Los_Angeles': {
    lat: 47.47421979,
    lng: -115.9268881,
    country: 'US',
  },
  'America/Phoenix': {
    lat: 33.58194114,
    lng: -112.1958238,
    country: 'US',
  },
  'America/New_York': {
    lat: 41.3555235,
    lng: -72.10002832,
    country: 'US',
  },
  'America/Indiana/Indianapolis': {
    lat: 39.82889833,
    lng: -84.89028121,
    country: 'US',
  },
  'America/Kentucky/Louisville': {
    lat: 38.3108773,
    lng: -85.82128382,
    country: 'US',
  },
  'America/Menominee': {
    lat: 45.82246014,
    lng: -88.06409265,
    country: 'US',
  },
  'America/Anchorage': {
    lat: 55.33970868,
    lng: -160.4971908,
    country: 'US',
  },
  'America/Sitka': {
    lat: 55.21397992,
    lng: -132.8006385,
    country: 'US',
  },
  'America/Nome': {
    lat: 60.38864671,
    lng: -166.1899372,
    country: 'US',
  },
  'Pacific/Honolulu': {
    lat: 21.98151227,
    lng: -159.3710063,
    country: 'US',
  },
  'America/Juneau': {
    lat: 58.11540489,
    lng: -135.438617,
    country: 'US',
  },
  'America/Adak': {
    lat: 52.19648968,
    lng: -174.2004887,
    country: 'US',
  },
  'America/Yakutat': {
    lat: 59.54730715,
    lng: -139.7272183,
    country: 'US',
  },
  'America/St_Thomas': {
    lat: 17.75037518,
    lng: -64.749986,
    country: 'VI',
  },
  'America/Montevideo': {
    lat: -34.47999901,
    lng: -57.84000247,
    country: 'UY',
  },
  'Asia/Samarkand': {
    lat: 42.4047101,
    lng: 59.45165767,
    country: 'UZ',
  },
  'Asia/Tashkent': {
    lat: 40.49573102,
    lng: 68.79072587,
    country: 'UZ',
  },
  'Pacific/Efate': {
    lat: -15.51255573,
    lng: 167.1766068,
    country: 'VU',
  },
  'America/Caracas': {
    lat: 9.657999007,
    lng: -68.58999854,
    country: 'VE',
  },
  'Asia/Ho_Chi_Minh': {
    lat: 11.32299911,
    lng: 106.1469997,
    country: 'VN',
  },
  'Asia/Aden': {
    lat: 13.9789981,
    lng: 45.57400265,
    country: 'YE',
  },
  'Africa/Harare': {
    lat: -17.51961668,
    lng: 30.97003699,
    country: 'ZW',
  },
};

export const countryFromTimeZone = (
  timeZone?: string | null
): TimeZoneCountryInfo | null => {
  const info = TIMEZONE_TO_COUNTRY[`${timeZone || ''}`];
  const parts = `${timeZone || ''}`.split('/');

  if (info) {
    return {
      ...info,
      continent: `${parts[0] || ''}`.replace(/_/g, ' ') || null,
      city: parts.length > 1 ? `${parts[1] || ''}`.replace(/_/g, ' ') || null : null,
    };
  }
  return null;
};
