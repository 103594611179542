import { Injectable, NgZone, Injector, Type, createNgModule } from '@angular/core';
import { AkitaOverlayType } from '../models/overlay.state';
import { applyTransaction, logAction } from '@datorama/akita';
import { AkitaOverlaysStore } from './overlays.store';
import { UserTiming } from '@app/shared/utils/performance.utils';
import { LoadableService } from '@app/ngx-loadable/loadable.service';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { AkitaOverlaysQuery } from './overlays.query';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { Subscription } from 'rxjs';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

const MAIN_DIALOGS: Array<AkitaOverlayType> = [];

const SIGNED_IN_DIALOGS: Array<AkitaOverlayType> = [];

const FOOTER_DIALOGS: Array<AkitaOverlayType> = [];

const HEADER_DIALOGS: Array<AkitaOverlayType> = [];

const FILTERS_BAR_DIALOGS: Array<AkitaOverlayType> = [];

@Injectable({ providedIn: 'root' })
export class AkitaOverlaysService {
  constructor(
    private readonly zone: NgZone,
    private readonly injector: Injector,
    private readonly store: AkitaOverlaysStore,
    private readonly query: AkitaOverlaysQuery,
    private readonly loadableService: LoadableService,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {}

  public loadDialog(name: AkitaOverlayType): Subscription {
    const subscription = new Subscription();
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        // If the Overlay is already Loading or has loaded already, skip
        if (this.query.getOverlayStatus(name) || this.query.isOverlayLoading(name)) {
          return;
        }

        this.zone.run(() => {
          applyTransaction(() => {
            logAction(`loadDialog( ${name} )`);
            this.store.toggleLoaded(name, false);
            this.store.toggleLoading(name, true);
            this.store.toggleError(name, false);
          });
        });

        SentryUtil.addBreadcrumb({
          category: 'overlays',
          message: `loadDialog( ${name} ) - start`,
          level: 'info',
          type: 'app',
        });

        const OVERLAY_PERFORMANCE_MARK_NAME = `Preload Overlay ( ${name} )`;
        const OVERLAY_PERFORMANCE_MARK_START = `${OVERLAY_PERFORMANCE_MARK_NAME} - Start`;
        const OVERLAY_PERFORMANCE_MARK_END = `${OVERLAY_PERFORMANCE_MARK_NAME} - End`;

        UserTiming.createMark(OVERLAY_PERFORMANCE_MARK_START);
        try {
          subscription.add(
            this.loadableService.preload(name).subscribe({
              next: (component: Type<unknown>) => {
                if (component) {
                  createNgModule(component, this.injector);

                  this.zone.run(() => {
                    applyTransaction(() => {
                      logAction(`loadDialog( ${name} ) - done`);
                      this.store.toggleLoaded(name, true);
                      this.store.toggleLoading(name, false);
                      this.store.toggleError(name, false);

                      UserTiming.createMark(OVERLAY_PERFORMANCE_MARK_END);
                      UserTiming.measure(
                        OVERLAY_PERFORMANCE_MARK_NAME,
                        OVERLAY_PERFORMANCE_MARK_START,
                        OVERLAY_PERFORMANCE_MARK_END
                      );
                    });
                  });

                  SentryUtil.addBreadcrumb({
                    category: 'overlays',
                    message: `loadDialog( ${name} ) - done`,
                    level: 'info',
                    type: 'app',
                  });
                } else {
                  this.zone.run(() => {
                    applyTransaction(() => {
                      logAction(`loadDialog( ${name} ) - error`);
                      this.store.toggleLoaded(name, false);
                      this.store.toggleLoading(name, false);
                      this.store.toggleError(name, true);
                    });
                  });
                  SentryUtil.reportException(
                    `ERROR_LOADING_DIALOG ( ${name} ) - Component Empty`,
                    false,
                    () => (parsedError: string) => {
                      this.googleAnalyticsService.appException(
                        parsedError,
                        'application',
                        '-',
                        false,
                        'OVERLAYS',
                        'AkitaOverlaysService -> loadDialog'
                      );
                    }
                  );
                  SentryUtil.addBreadcrumb({
                    category: 'overlays',
                    message: `loadDialog( ${name} ) - error`,
                    level: 'info',
                    type: 'app',
                  });
                }
              },
              error: (err: unknown) => {
                this.failedToPreloadDialog(name, err);
              },
            })
          );
        } catch (err: unknown) {
          this.failedToPreloadDialog(name, err);
        }
      });
    }
    return subscription;
  }

  private failedToPreloadDialog(name: AkitaOverlayType, err: unknown): void {
    const parsed = err as any;
    this.zone.run(() => {
      applyTransaction(() => {
        logAction(`loadDialog( ${name} ) - error`);
        this.store.toggleLoaded(name, false);
        this.store.toggleLoading(name, false);
        this.store.toggleError(name, true);
      });
    });
    SentryUtil.reportException(parsed, false, () => (parsedError: string) => {
      this.googleAnalyticsService.appException(
        parsedError,
        'application',
        `${parsed?.status || '-'}`,
        false,
        'OVERLAYS',
        'AkitaOverlaysService -> loadDialog'
      );
    });
    SentryUtil.reportException(
      `ERROR_LOADING_DIALOG ( ${name} )`,
      false,
      () => (parsedError: string) => {
        this.googleAnalyticsService.appException(
          parsedError,
          'application',
          `${parsed?.status || '-'}`,
          false,
          'OVERLAYS',
          'AkitaOverlaysService -> loadDialog'
        );
      }
    );
    SentryUtil.addBreadcrumb({
      category: 'overlays',
      message: `loadDialog( ${name} ) - error`,
      level: 'info',
      type: 'app',
    });
  }

  public loadDialogList(dialogs: Array<AkitaOverlayType>): Subscription {
    const subscriptions = new Subscription();
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        if (dialogs && dialogs.length > 0) {
          for (const name of dialogs) {
            subscriptions.add(this.loadDialog(name));
          }
        }
      });
    }
    return subscriptions;
  }

  public preloadMainDialogs(): void {
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        this.preloadHeaderDialogs();
        this.preloadFooterDialogs();
        this.preloadFiltersBarDialogs();

        this.loadDialogList(MAIN_DIALOGS);
      });
    }
  }

  public preloadSignedInDialogs(): void {
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        this.loadDialogList(SIGNED_IN_DIALOGS);
      });
    }
  }

  public preloadFooterDialogs(): void {
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        this.loadDialogList(FOOTER_DIALOGS);
      });
    }
  }

  public preloadHeaderDialogs(): void {
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        this.loadDialogList(HEADER_DIALOGS);
      });
    }
  }

  public preloadFiltersBarDialogs(): void {
    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(() => {
        this.loadDialogList(FILTERS_BAR_DIALOGS);
      });
    }
  }
}
