import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AkitaLocationStore } from './location.store';
import {
  Coordinate,
  CoordinatePrecission,
} from '@app/akita/api/location/models/coordinate.model';
import { Observable, map, distinctUntilChanged } from 'rxjs';
import { AkitaConfigurationQuery } from '@app/akita/configuration/state/configuration.query';
import { AkitaLocationState } from '../models/location.state';

const COORDINATE_PRECISSION = 6;

@Injectable({ providedIn: 'root' })
export class AkitaLocationQuery extends Query<AkitaLocationState> {
  constructor(
    protected store: AkitaLocationStore,
    private readonly akitaConfigurationQuery: AkitaConfigurationQuery
  ) {
    super(store);
  }

  public get isLoading(): boolean {
    return this.getValue().loading;
  }

  public get lastUpdate(): Date {
    return this.getValue().lastUpdate;
  }

  public get hasPermission(): boolean {
    return this.getValue().hasPermission;
  }

  public get coordinatePrecission(): CoordinatePrecission {
    return this.deviceLocation ? this.deviceLocation.precission : '';
  }

  public get fromEnviroment(): boolean {
    return this.deviceLocation
      ? Boolean(
          this.deviceLocation.precission === 'ENVIROMENT' ||
            this.deviceLocation.precission === 'FALLBACK'
        )
      : true;
  }

  public get locationMetadata(): {
    device: {
      country: string;
      region: string;
      city: string;
    };
    user: {
      country: string;
      region: string;
      city: string;
    };
    country: string;
  } {
    const currentLocationInfo = {
      country: '',
      region: '',
      city: '',
    };
    const currentLocation = this.currentLocation;
    if (currentLocation) {
      currentLocationInfo.country = (currentLocation.country || '').toLowerCase();
      currentLocationInfo.region = (currentLocation.region || '').toLowerCase();
      currentLocationInfo.city = (currentLocation.city || '').toLowerCase();
    }

    const userLocationInfo = {
      country: '',
      region: '',
      city: '',
    };
    const userLocation = this.definedByUser;
    if (userLocation) {
      userLocationInfo.country = (userLocation.country || '').toLowerCase();
      userLocationInfo.region = (userLocation.region || '').toLowerCase();
      userLocationInfo.city = (userLocation.city || '').toLowerCase();
    }

    return {
      device: currentLocationInfo,
      user: userLocationInfo,
      country: (this.getValue().country || '').toLowerCase(),
    };
  }

  public get country(): string {
    const metadata = this.locationMetadata;
    return metadata.user.country || metadata.device.country || metadata.country || '';
  }

  public get city(): string {
    const metadata = this.locationMetadata;
    return metadata.user.city || metadata.device.city || '';
  }

  public get region(): string {
    const metadata = this.locationMetadata;
    return metadata.user.region || metadata.device.region || '';
  }

  public get permissionRequested(): boolean {
    return this.getValue().requested;
  }

  public get currentLocation(): Coordinate | null {
    return Coordinate.fromJson(this.getValue().currentLocation);
  }

  public get definedByUser(): Coordinate | null {
    return Coordinate.fromJson(this.getValue().definedByUser);
  }

  public get userLocation(): Coordinate | null {
    return this.definedByUser || this.currentLocation || null;
  }

  public get deviceLocation(): Coordinate | null {
    return this.currentLocation || null;
  }

  public get deviceCurrency(): string | null {
    const state = this.getValue();
    if (
      state.suggestedDeviceCurrency &&
      state.currentLocation &&
      (state.currentLocation.precission === 'GPS' ||
        state.currentLocation.precission === 'IP')
    ) {
      return this.getValue().suggestedDeviceCurrency?.code || null;
    }
    return this.akitaConfigurationQuery.currencyCode;
  }

  // Async

  public selectIsLoading(): Observable<boolean> {
    return this.select().pipe(map((state: AkitaLocationState) => Boolean(state.loading)));
  }

  public selectLocationInfo(): Observable<{
    device: Coordinate | null;
    user: Coordinate | null;
  }> {
    return this.select().pipe(
      map((state: AkitaLocationState) => ({
        device: Coordinate.fromJson(state.currentLocation),
        user: Coordinate.fromJson(state.definedByUser),
      })),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectUserLocation(): Observable<Coordinate | null> {
    return this.selectLocationInfo().pipe(
      map(
        (locationInfo: { device: Coordinate | null; user: Coordinate | null }) =>
          locationInfo.user || locationInfo.device || null
      ),
      distinctUntilChanged(
        (a: Coordinate | null, b: Coordinate | null) =>
          (a
            ? JSON.stringify({
                ...a,
                latitude: a.latitude.toPrecision(COORDINATE_PRECISSION),
                longitude: a.longitude.toPrecision(COORDINATE_PRECISSION),
                updatedAt: null,
              })
            : null) ===
          (b
            ? JSON.stringify({
                ...b,
                latitude: b.latitude.toPrecision(COORDINATE_PRECISSION),
                longitude: b.longitude.toPrecision(COORDINATE_PRECISSION),
                updatedAt: null,
              })
            : null)
      )
    );
  }

  public selectDeviceLocation(): Observable<Coordinate | null> {
    return this.select().pipe(
      map((state: AkitaLocationState) => Coordinate.fromJson(state.currentLocation)),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectDeviceCurrency(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaLocationState) => {
        if (
          state.suggestedDeviceCurrency &&
          state.currentLocation &&
          (state.currentLocation.precission === 'GPS' ||
            state.currentLocation.precission === 'IP')
        ) {
          return state.suggestedDeviceCurrency.code;
        }
        return this.akitaConfigurationQuery.currencyCode;
      })
    );
  }

  public selectCountry(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaLocationState) => {
        const userCountry = state.definedByUser ? state.definedByUser.country : '';
        const deviceCountry = state.currentLocation ? state.currentLocation.country : '';
        return userCountry || deviceCountry || state.country || 'US';
      }),
      distinctUntilChanged()
    );
  }

  public selectCoordinatePrecission(): Observable<CoordinatePrecission> {
    return this.selectDeviceLocation().pipe(
      map((location: Coordinate | null) => {
        if (location && location.precission !== '') {
          return location.precission;
        }
        return 'FALLBACK';
      })
    );
  }
}
