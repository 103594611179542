export class FeaturedBanner {
  public termTitle: string;
  public termHeadline: string;
  public termAction: string;
  public image: string;
  public background_color: string;
  public model: string;

  constructor() {
    this.termTitle = '';
    this.termHeadline = '';
    this.termAction = '';
    this.image = '';
    this.background_color = '';
    this.model = '';
  }
}

export const parseFeaturedBannerFromJsonString = (
  text?: string | null
): FeaturedBanner | null => {
  let out = null;
  try {
    out = parseFeaturedBannerFromJson(JSON.parse(`${text || ''}`));
  } catch (error) {}
  return out;
};

export const parseFeaturedBannerFromJson = (data?: any | null): FeaturedBanner | null => {
  let out = null;
  if (data) {
    out = new FeaturedBanner();
    out.termTitle = data['term_title'] || data.termTitle || '';
    out.termHeadline = data['term_headline'] || data.termHeadline || '';
    out.termAction = data['term_action'] || data.termAction || '';
    out.image = data['image'] || '';
    out.background_color = data['background_color'] || '';
    out.model = data['model'] || '';
  }
  return out;
};
