import { Injectable, Inject, SecurityContext } from '@angular/core';
import { Title, Meta, MetaDefinition, DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { DOCUMENT } from '@angular/common';
import { getBaseCanonicalUrl, getCurrentHost } from '../utils/url.utils';
import {
  generateProductUrlSlug,
  Product,
} from '@app/akita/api/products/models/product.model';
import { SUPPORTED_LANGUAGES } from '@app/core/services/popsy-transloco-http-loader.service';

export const HREF_DOMAIN_COUNTRY: {
  [domain: string]: string | null;
} = {
  qa: 'www.popsy.app',
  om: 'www.popsy.app',
  bh: 'www.popsy.app',
  ae: 'popsy.ae',
  sa: 'popsy.sa',
  kw: 'popsy.com.kw',
};

const NO_INDEX_DOMAINS = [
  'localhost:4200', // test
  'sa.popsy.app',
  'ae.popsy.app',
  'kw.popsy.app',
  'www.popsy.app',
];

/**
 * Class to easily handle SEO Meta Tags
 */
@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly translateService: TranslocoService,
    private readonly domSanitizer: DomSanitizer
  ) {}

  public setTitle(text: string, shouldTranslate?: boolean): void {
    let title = text;
    if (shouldTranslate) {
      title = this.translateService.translate(text) || text;
    }

    this.title.setTitle(title);
    this.setTag({ name: 'og:title', content: title });
  }

  public setCanonical(url?: string): void {
    const baseCanonical = this.domSanitizer
      .sanitize(SecurityContext.URL, getBaseCanonicalUrl(this.document.URL))
      ?.toString();
    const linkElement = this.document.head.querySelector('head > link[rel=canonical]');
    if (linkElement) {
      linkElement.setAttribute('href', url || baseCanonical || this.document.URL);
    } else {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url || this.document.URL);
      this.document?.head?.appendChild(link);
    }
  }

  public setAlternateHreflangsTags(product: Product | null): void {
    const languages = SUPPORTED_LANGUAGES;
    this.clearAlternateTags();

    if (!product) {
      // generic alternate tags for the current url
      const currentURL: URL = new URL(`${this.document.URL || ''}`);
      // remove first fragment from path
      const path = currentURL.pathname.replace(/^\/[^/]+/, '');

      languages.forEach((language) => {
        for (const [key, value] of Object.entries(HREF_DOMAIN_COUNTRY)) {
          const url = `https://${value}/${language}-${key}${path}${currentURL.search}`;
          const hreflang = `${language}-${key}`;
          this.addTag({ name: 'link', rel: 'alternate', hreflang, href: url });
        }
      });
      return;
    }

    languages.forEach((language) => {
      const productSlug = generateProductUrlSlug(product);
      for (const [key, value] of Object.entries(HREF_DOMAIN_COUNTRY)) {
        const url = `https://${value}/${language}-${key}/p/${productSlug}`;
        const hreflang = `${language}-${key}`;
        this.addTag({ name: 'link', rel: 'alternate', hreflang, href: url });
      }
    });
  }

  // Stop indexing subdomains when proper domain is set on canonical
  public checkRobotsNoIndexTag(): void {
    const url = getCurrentHost();
    if (NO_INDEX_DOMAINS.some((domain) => url.includes(domain))) {
      this.setTag({ name: 'robots', content: 'noindex' });
    }
  }

  public setTag(tag: MetaDefinition): void {
    const selector = `name="${tag.name}"`;
    const currentTag = this.meta.getTag(selector);
    if (currentTag) {
      this.meta.updateTag(tag, selector);
    } else {
      this.meta.addTag(tag, true);
    }
  }

  public setTags(tags: Array<MetaDefinition>): void {
    for (const tag of tags) {
      this.setTag(tag);
    }
  }

  public addTag(tag: MetaDefinition): void {
    this.meta.addTag(tag, true);
  }

  public clearTagsWithName(name: string): void {
    const selector = `name="${name}"`;
    for (const tag of this.meta.getTags(selector) || []) {
      this.meta.removeTagElement(tag);
    }
  }
  public clearAlternateTags(): void {
    const selector = `rel="alternate"`;
    for (const tag of this.meta.getTags(selector) || []) {
      this.meta.removeTagElement(tag);
    }
  }
}
