export class BestSellersPerCategory {
  [category: string]: Array<number> | null;

  constructor() {}
}

export const parseBestSellersPerCategory = (
  data?: any | null
): BestSellersPerCategory | null => {
  let out = new BestSellersPerCategory();
  data = JSON.parse(data);

  if (data && data.categories.length) {
    out = new BestSellersPerCategory();
    data.categories.forEach((category: any) => {
      out[category.value] = category.best_sellers;
    });
  }

  return out;
};

export const parseBestProductsTamaraTest = (
  data?: any | null
): BestSellersPerCategory | null => {
  let out = new BestSellersPerCategory();
  data = JSON.parse(data);

  out = data;

  return out;
};
