import { parseISO } from 'date-fns';

const NS_TO_MS = 1000;
const S_TO_MS_BREAK = 999999999999;

export class PopsyDateParser {
  public static isValidDate(date: unknown): boolean {
    if (Object.prototype.toString.call(date) === '[object Date]') {
      return Boolean(!isNaN((date as any).getTime()));
    } else {
      return false;
    }
  }

  public static parseApiDate(
    key: string,
    internalKey: string,
    data: any | null,
    options?: {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      additionalDigits?: 0 | 1 | 2;
    }
  ): Date | null {
    let date: Date | null = null;

    if (data) {
      if (data[`${key}_ms`]) {
        date = PopsyDateParser.parseRawDate(`${key}_ms`, data, true, options);
      } else if (data[key]) {
        date = PopsyDateParser.parseRawDate(key, data, false, options);
      } else if (data[internalKey]) {
        date = PopsyDateParser.parseInternalDate(internalKey, data, options);
      }

      if (!PopsyDateParser.isValidDate(date)) {
        date = null;
      }
    }

    return date;
  }

  public static parseRawDate(
    key: string,
    data: unknown,
    inMilliseconds: boolean,
    options?: {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      additionalDigits?: 0 | 1 | 2;
    }
  ): Date | null {
    let date: Date | null;
    if (typeof (data as any)[key] === 'number') {
      const dateNumber = (data as any)[key];
      if (inMilliseconds || dateNumber >= S_TO_MS_BREAK) {
        date = new Date((data as any)[key]);
      } else {
        date = new Date((data as any)[key] * NS_TO_MS);
      }
    } else if ((data as any)[key] instanceof Date) {
      date = (data as any)[key];
    } else {
      date = PopsyDateParser.parseISO((data as any)[key], options);
    }
    return date || null;
  }

  public static parseInternalDate(
    key: string,
    data: unknown,
    options?: {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      additionalDigits?: 0 | 1 | 2;
    }
  ): Date | null {
    let date: Date | null;
    if ((data as any)[key] instanceof Date) {
      date = (data as any)[key];
    } else if (typeof (data as any)[key] === 'number') {
      const dateNumber = (data as any)[key];
      if (dateNumber >= S_TO_MS_BREAK) {
        date = new Date((data as any)[key]);
      } else {
        date = new Date((data as any)[key] * NS_TO_MS);
      }
    } else {
      date = PopsyDateParser.parseISO((data as any)[key], options);
    }
    return date || null;
  }

  public static parseISO(
    rawDate: string,
    options?: {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      additionalDigits?: 0 | 1 | 2;
    }
  ): Date | null {
    let date: Date | null = null;
    try {
      date = parseISO(rawDate, options);
    } catch {
      // Invalid options passed to parseISO, Ignore and return null
    }
    return date;
  }
}
