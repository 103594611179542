export class ProductSearchFilterOptions {
  public brand: Array<string>;
  public category: Array<string>;
  public color: Array<string>;
  public color_hex: Array<Record<string, string>>;
  public cpu: Array<string>;
  public graphics: Array<string>;
  public launch_date: Array<number>;
  public model: Array<string>;
  public ram: Array<string>;
  public screen_size: Array<string>;
  public storage: Array<string>;
  public storage_type: Array<string>;
  public keyboard: Array<string>;
  public condition: Array<string>;
  public strap_color_hex: Array<Record<string, string>>;
  public wifi_cellular: Array<string>;
  public sim: Array<string>;
  public series: Array<string>;

  constructor() {
    this.brand = new Array(0);
    this.category = new Array(0);
    this.color = new Array(0);
    this.color_hex = new Array(0);
    this.cpu = new Array(0);
    this.graphics = new Array(0);
    this.launch_date = new Array(0);
    this.model = new Array(0);
    this.ram = new Array(0);
    this.screen_size = new Array(0);
    this.storage = new Array(0);
    this.storage_type = new Array(0);
    this.keyboard = new Array(0);
    this.condition = new Array(0);
    this.strap_color_hex = new Array(0);
    this.wifi_cellular = new Array(0);
    this.sim = new Array(0);
    this.series = new Array(0);
  }
}

export const parseProductSearchFilterOptions = (
  data?: Record<string, any> | null
): ProductSearchFilterOptions => {
  const results = new ProductSearchFilterOptions();
  if (data) {
    results.brand = data.brand || new Array(0);
    results.category = data.category || new Array(0);
    results.color = data.color || new Array(0);
    results.color_hex = data.color_hex || new Array(0);
    results.cpu = data.cpu || new Array(0);
    results.graphics = data.graphics || new Array(0);
    results.launch_date = data.launch_date || new Array(0);
    results.model = data.model || new Array(0);
    results.ram = data.ram || new Array(0);
    results.screen_size = data.screen_size || new Array(0);
    results.storage = data.storage || new Array(0);
    results.storage_type = data.storage_type || new Array(0);
    results.keyboard = data.keyboard || new Array(0);
    results.condition = data.condition.reverse() || new Array(0);
    results.strap_color_hex = data.strap_color_hex || new Array(0);
    results.wifi_cellular = data.wifi_cellular || new Array(0);
    results.sim = data.sim || new Array(0);
    results.series = data.series || new Array(0);
  }
  return results;
};
