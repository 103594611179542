import { Injectable } from '@angular/core';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import {
  AllowedDeliveryCountries,
  LIST_OF_ALLOWED_DELIVERY_COUNTRY_CODES,
} from '@app/shared/data/delivery-countries.data';
import { ApiError, parseApiError } from '@app/shared/models/api/api-error.model';
import { Query } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, distinctUntilChanged, map, Observable } from 'rxjs';
import {
  parseProductSearchFilterOptions,
  ProductSearchFilterOptions,
} from '../models/product-search.filter-options.model';
import {
  parseProductSearchResults,
  ProductSearchResults,
} from '../models/product-search.results.model';
import { ProductVariant } from '../models/product-variant.model';
import { parseProduct, Product } from '../models/product.model';
import {
  AkitaProductsState,
  createInitialAkitaProductSearchState,
  DEFAULT_AVAILABLE_COUNTRIES,
  DEFAULT_AVAILABLE_LANGUAGES,
} from '../models/products.state';
import { AkitaProductsStore } from './products.store';
import {
  ProductSearchFilters,
  generateFiltersID,
} from '../models/product-search.filters.model';
import { Insurance } from '../models/insurance.model';

@Injectable({ providedIn: 'root' })
export class AkitaProductsQuery extends Query<AkitaProductsState> {
  constructor(
    protected store: AkitaProductsStore,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly translateService: TranslocoService
  ) {
    super(store);
  }

  public get language(): string {
    return `${this.translateService.getActiveLang() || 'en'}`.toLowerCase();
  }

  public get country(): string {
    return `${this.akitaRouterQuery.country || 'SA'}`.toUpperCase();
  }

  public get availableCountries(): Array<string> {
    return this.getValue().availableProductCountries || DEFAULT_AVAILABLE_COUNTRIES;
  }

  public get availableLanguages(): Array<string> {
    return this.getValue().availableProductLocales || DEFAULT_AVAILABLE_LANGUAGES;
  }

  public validateLocale(
    suggestedLanguage?: string | null,
    suggestedCountry?: string | null
  ): { country: string; language: string } {
    const output = {
      language: `${suggestedLanguage || this.language || 'en'}`.toLowerCase(),
      country: `${suggestedCountry || this.country || 'SA'}`.toUpperCase(),
    };

    const isSupportedLang = this.availableLanguages.includes(output.language);
    if (!isSupportedLang) {
      output.language = 'en';
    }

    const isSupportedCountry = this.availableCountries.includes(output.country);
    if (!isSupportedCountry) {
      output.country = 'SA';
    }

    return output;
  }

  public selectLanguage(): Observable<string> {
    return this.translateService.langChanges$.pipe(
      distinctUntilChanged(),
      map((lang?: string | null) => `${lang || 'en'}`.toLowerCase())
    );
  }

  public selectCountry(): Observable<string> {
    return this.akitaRouterQuery
      .selectCountry()
      .pipe(
        map((country?: string | null) =>
          `${
            LIST_OF_ALLOWED_DELIVERY_COUNTRY_CODES.indexOf(
              country as AllowedDeliveryCountries
            ) >= 0
              ? country
              : 'US'
          }`.toUpperCase()
        )
      );
  }

  public getIsFetchingProduct(id?: string | null): boolean {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const country = validLocale.country;

    if (id) {
      const fetchingProducts = this.getValue().fetchingProducts || {};
      const langProducts = fetchingProducts[language] || {};
      const countryProducts = langProducts[country] || {};
      return Boolean(countryProducts[id]);
    } else {
      return false;
    }
  }

  public getIsFetchingProductVariants(model?: string | null): boolean {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const country = validLocale.country;

    if (model) {
      const fetchingVariants = this.getValue().fetchingProductVariants || {};
      const langProducts = fetchingVariants[language] || {};
      const countryProducts = langProducts[country] || {};
      const idProducts = countryProducts[model] || false;
      const fetchingVariant = idProducts || false;

      return fetchingVariant;
    } else {
      return false;
    }
  }

  public getErrorFetchingProduct(model?: string | null): ApiError | null {
    const language = this.language;
    const country = this.country;

    if (model) {
      const errorFetchingProducts = this.getValue().errorFetchingProducts || {};
      const langProducts = errorFetchingProducts[language] || {};
      const countryProducts = langProducts[country] || {};
      return countryProducts[model] ? parseApiError(countryProducts[model]) : null;
    } else {
      return null;
    }
  }

  public getProduct(
    id?: string | null | undefined,
    lang?: string | null,
    country?: string | null
  ): Product | null {
    const validLocale = this.validateLocale(lang, country);
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (id) {
      const products = this.getValue().products || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || {};
      return parseProduct(countryProducts[id] || null);
    } else {
      return null;
    }
  }

  public getProductVariant(
    id?: string | null,
    model?: string | null,
    lang?: string | null,
    country?: string | null
  ): ProductVariant | null {
    const validLocale = this.validateLocale(lang, country);
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (id && model) {
      const products = this.getValue().productVariants || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || [];
      return countryProducts[model]
        ? countryProducts[model].find((x) => x.id === id) || null
        : null;
    } else {
      return null;
    }
  }

  public getMatchingColorVariant(
    model?: string | null,
    variant?: ProductVariant | null,
    colorCode?: string | null,
    lowestPrice?: boolean | null
  ): ProductVariant | null {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (variant && colorCode && model) {
      const products = this.getValue().productVariants || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || [];

      if (lowestPrice) {
        countryProducts[model] =
          countryProducts[model].sort(
            (a, b) => (a.price?.amount || 0) - (b.price?.amount || 0)
          ) || [];
      }
      return countryProducts[model]
        ? countryProducts[model].find(
            (x) =>
              x.colorCode === colorCode &&
              x.storage === variant.storage &&
              x.strapColorCode === variant.strapColorCode &&
              x.keyboard === variant.keyboard &&
              x.wifiCellular === variant.wifiCellular &&
              x.sim === variant.sim &&
              x.condition === variant.condition &&
              x.inStock
          ) ||
            countryProducts[model].find(
              (x) =>
                x.colorCode === colorCode &&
                x.storage === variant.storage &&
                x.strapColorCode === variant.strapColorCode &&
                x.keyboard === variant.keyboard &&
                x.inStock
            ) ||
            countryProducts[model].find((x) => x.colorCode === colorCode && x.inStock) ||
            null
        : null;
    } else {
      return null;
    }
  }

  public getMatchingStorageVariant(
    model?: string | null,
    variant?: ProductVariant | null,
    storage?: string | null
  ): ProductVariant | null {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (variant && storage && model) {
      const products = this.getValue().productVariants || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || [];
      return countryProducts[model]
        ? countryProducts[model].find(
            (x) =>
              x.colorCode === variant.colorCode &&
              x.storage === storage &&
              x.strapColorCode === variant.strapColorCode &&
              x.keyboard === variant.keyboard &&
              x.wifiCellular === variant.wifiCellular &&
              x.sim === variant.sim &&
              x.inStock
          ) || null
        : null;
    } else {
      return null;
    }
  }

  public getMatchingScreenSizeVariant(
    model?: string | null,
    variant?: ProductVariant | null,
    screenSize?: string | null
  ): ProductVariant | null {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (variant && screenSize && model) {
      const products = this.getValue().productVariants || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || [];
      return countryProducts[model]
        ? countryProducts[model].find(
            (x) =>
              x.colorCode === variant.colorCode &&
              x.screenSize === screenSize &&
              x.strapColorCode === variant.strapColorCode &&
              x.keyboard === variant.keyboard &&
              x.condition === variant.condition &&
              x.inStock
          ) || null
        : null;
    } else {
      return null;
    }
  }

  public getMatchingKeyboardVariant(
    model?: string | null,
    variant?: ProductVariant | null,
    keyboard?: string | null
  ): ProductVariant | null {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (variant && keyboard && model) {
      const products = this.getValue().productVariants || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || [];
      return countryProducts[model]
        ? countryProducts[model].find(
            (x) =>
              x.colorCode === variant.colorCode &&
              x.storage === variant.storage &&
              x.keyboard === keyboard &&
              x.strapColorCode === variant.strapColorCode &&
              x.condition === variant.condition &&
              x.inStock
          ) || null
        : null;
    } else {
      return null;
    }
  }

  public getMatchingNetworkVariant(
    model?: string | null,
    variant?: ProductVariant | null,
    network?: string | null
  ): ProductVariant | null {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (variant && network && model) {
      const products = this.getValue().productVariants || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || [];
      return countryProducts[model]
        ? countryProducts[model].find(
            (x) =>
              x.colorCode === variant.colorCode &&
              x.storage === variant.storage &&
              x.wifiCellular === network &&
              x.strapColorCode === variant.strapColorCode &&
              x.condition === variant.condition &&
              x.inStock
          ) || null
        : null;
    } else {
      return null;
    }
  }

  public getMatchingSimVariant(
    model?: string | null,
    variant?: ProductVariant | null,
    sim?: string | null
  ): ProductVariant | null {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (variant && sim && model) {
      const products = this.getValue().productVariants || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || [];
      return countryProducts[model]
        ? countryProducts[model].find(
            (x) =>
              x.colorCode === variant.colorCode &&
              x.storage === variant.storage &&
              x.sim === sim &&
              x.strapColorCode === variant.strapColorCode &&
              x.condition === variant.condition &&
              x.inStock
          ) || null
        : null;
    } else {
      return null;
    }
  }

  public getMatchingConditionVariant(
    model?: string | null,
    variant?: ProductVariant | null,
    condition?: string | null
  ): ProductVariant | null {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (variant && condition && model) {
      const products = this.getValue().productVariants || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || [];
      return countryProducts[model]
        ? countryProducts[model].find(
            (x) =>
              x.colorCode === variant.colorCode &&
              x.storage === variant.storage &&
              x.keyboard === variant.keyboard &&
              x.wifiCellular === variant.wifiCellular &&
              x.sim === variant.sim &&
              x.strapColorCode === variant.strapColorCode &&
              x.screenSize === variant.screenSize &&
              x.condition === condition &&
              x.inStock
          ) || null
        : null;
    } else {
      return null;
    }
  }

  public getMatchingStrapVariant(
    model?: string | null,
    variant?: ProductVariant | null,
    strapColorCode?: string | null
  ): ProductVariant | null {
    const validLocale = this.validateLocale();
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (variant && strapColorCode && model) {
      const products = this.getValue().productVariants || {};
      const langProducts = products[language] || {};
      const countryProducts = langProducts[countryCode] || [];

      return countryProducts[model]
        ? countryProducts[model].find(
            (x) =>
              x.colorCode === variant.colorCode &&
              x.storage === variant.storage &&
              x.keyboard === variant.keyboard &&
              x.strapColorCode === strapColorCode &&
              x.condition === variant.condition &&
              x.inStock
          ) || null
        : null;
    } else {
      return null;
    }
  }

  public getProductFromSearchID(id?: string | null): Product | null {
    if (id) {
      const products = this.getValue().productSearch || {};
      let productFound: Product | null | undefined;

      for (const path of Object.keys(products)) {
        const pages = products[path]?.pages || [];
        for (const page of pages) {
          productFound = page.results.find((x) => x.id === id);
        }
      }
      /* const langProducts = products[this.language] || {};
      const countryProducts = langProducts[this.countryCode] || {}; */
      return parseProduct(productFound);
    } else {
      return null;
    }
  }

  public getProductFilters(path?: string | null): ProductSearchFilterOptions {
    const productFilters = this.getValue().availableProductFilters || {};

    return parseProductSearchFilterOptions(productFilters[path || '-']);
  }

  public hasProductFilters(path?: string | null): boolean {
    const productFilters = this.getValue().availableProductFilters || {};

    return (path || '-') in productFilters;
  }

  public getIsFetchingProductFilters(path?: string | null): boolean {
    const productFilters = this.getValue().fetchingAvailableProductFilters || {};
    return Boolean(productFilters[path || '-']);
  }

  public getErrorFetchingProductFilters(path?: string | null): ApiError | null {
    const productFilters = this.getValue().fetchingAvailableProductFilters || {};
    return productFilters[path || '-']
      ? parseApiError(productFilters[path || '-'])
      : null;
  }

  public getSearchPageCount(path?: string | null): number {
    const productSearch = this.getValue().productSearch || {};
    const currentSearch =
      productSearch[path || '-'] || createInitialAkitaProductSearchState();
    const pages = currentSearch.pages || [];
    return pages.length || 0;
  }

  public getIsSearchingPage(path?: string | null, page?: number | null): boolean {
    if (page || page === 0) {
      const productSearch = this.getValue().productSearch || {};
      const currentSearch =
        productSearch[path || '-'] || createInitialAkitaProductSearchState();
      const loadingPages = currentSearch.loadingPage || [];
      return Boolean(loadingPages[page]);
    } else {
      return false;
    }
  }

  public getErrorSearchingPage(
    path?: string | null,
    page?: number | null
  ): ApiError | null {
    if (page || page === 0) {
      const productSearch = this.getValue().productSearch || {};
      const currentSearch =
        productSearch[path || '-'] || createInitialAkitaProductSearchState();
      const loadingPages = currentSearch.errorLoadingPage || [];
      return loadingPages[page] ? parseApiError(loadingPages[page]) : null;
    } else {
      return null;
    }
  }

  public getSearchPage(
    path?: string | null,
    page?: number | null
  ): ProductSearchResults | null {
    if (page || page === 0) {
      const productSearch = this.getValue().productSearch || {};
      const currentSearch =
        productSearch[path || '-'] || createInitialAkitaProductSearchState();
      const pages = currentSearch.pages || [];
      return pages[page] ? parseProductSearchResults(pages[page]) : null;
    } else {
      return null;
    }
  }

  public getIsSearchLoading(path?: string | null): boolean {
    const productSearch = this.getValue().productSearch || {};
    const currentSearch =
      productSearch[path || '-'] || createInitialAkitaProductSearchState();
    const loadingPages = currentSearch.loadingPage || [];
    return loadingPages.includes(true);
  }

  public getErrorSearchLoading(path?: string | null): ApiError | null {
    const productSearch = this.getValue().productSearch || {};
    const currentSearch =
      productSearch[path || '-'] || createInitialAkitaProductSearchState();
    const pageErrors = currentSearch.errorLoadingPage || [];

    for (const err of pageErrors) {
      if (err) {
        return parseApiError(err);
      }
    }

    return null;
  }

  public hasSearchResultsPage(path?: string | null, page?: number | null): boolean {
    if (page || page === 0) {
      const productSearch = this.getValue().productSearch || {};
      const currentSearch =
        productSearch[path || '-'] || createInitialAkitaProductSearchState();
      const pages = currentSearch.pages || [];
      return Boolean(pages[page]);
    } else {
      return false;
    }
  }

  public getSearchPages(path?: string | null): Array<Product> {
    const combinedPages: Array<Product> = new Array(0);
    const productSearch = this.getValue().productSearch || {};
    const currentSearch =
      productSearch[path || '-'] || createInitialAkitaProductSearchState();
    const pages = currentSearch.pages || [];

    for (const page of pages) {
      combinedPages.push(...page.results);
    }

    return combinedPages;
  }

  public getIsFetchingInsurance(language: string, country: string, ids: string): boolean {
    const fetchingInsurance = this.getValue().fetchingInsurance || {};
    const langProducts = fetchingInsurance[language] || {};
    const countryProducts = langProducts[country] || {};
    return Boolean(countryProducts[ids]);
  }

  public getInsurance(
    id?: string | null | undefined,
    lang?: string | null,
    country?: string | null
  ): Insurance | null {
    const validLocale = this.validateLocale(lang, country);
    const language = validLocale.language;
    const countryCode = validLocale.country;

    if (id) {
      const insurances = this.getValue().insurances || {};
      const langProducts = insurances[language] || {};
      const countryProducts = langProducts[countryCode] || {};
      return countryProducts[id] || null;
    } else {
      return null;
    }
  }

  /// Async

  public selectIsFetchingProduct(id?: string | null): Observable<boolean> {
    return combineLatest([
      this.select(),
      this.selectLanguage(),
      this.selectCountry(),
    ]).pipe(
      map(([state, language, country]) => {
        if (id) {
          language = DEFAULT_AVAILABLE_LANGUAGES.includes(language) ? language : 'en';
          const fetchingProducts = state?.fetchingProducts || {};
          const langProducts = fetchingProducts[language] || {};
          const countryProducts = langProducts[country] || {};
          return Boolean(countryProducts[id]);
        } else {
          return false;
        }
      }),
      distinctUntilChanged()
    );
  }

  public selectErrorFetchingProduct(id?: string | null): Observable<ApiError | null> {
    return combineLatest([
      this.select(),
      this.selectLanguage(),
      this.selectCountry(),
    ]).pipe(
      map(([state, language, country]) => {
        if (id) {
          language = DEFAULT_AVAILABLE_LANGUAGES.includes(language) ? language : 'en';
          const errorFetchingProducts = state?.errorFetchingProducts || {};
          const langProducts = errorFetchingProducts[language] || {};
          const countryProducts = langProducts[country] || {};
          return countryProducts[id] ? parseApiError(countryProducts[id]) : null;
        } else {
          return null;
        }
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectProduct(id?: string | null): Observable<Product | null> {
    return combineLatest([
      this.select(),
      this.selectLanguage(),
      this.selectCountry(),
    ]).pipe(
      map(([state, language, country]) => {
        if (id) {
          const products = state?.products || {};
          language = DEFAULT_AVAILABLE_LANGUAGES.includes(language) ? language : 'en';

          const langProducts = products[language] || {};
          country = DEFAULT_AVAILABLE_COUNTRIES.includes(country) ? country : 'SA';
          const countryProducts = langProducts[country] || {};

          return parseProduct(countryProducts[id]);
        } else {
          return null;
        }
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectProductFilters(
    path?: string | null
  ): Observable<ProductSearchFilterOptions> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        const productFilters = state?.availableProductFilters || {};
        return parseProductSearchFilterOptions(productFilters[path || '-']);
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectAvailableProductOptions(
    model: string | null
  ): Observable<ProductSearchFilterOptions | null> {
    const filters: ProductSearchFilters = {
      ...new ProductSearchFilters(),
      country: this.country,
      language: this.language,
    };
    if (model && model.length) {
      filters.model = [...new Array(0), model];
    }

    const path = generateFiltersID(filters);
    return this.selectProductFilters(path);
  }

  public selectIsFetchingProductFilters(path?: string | null): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        const productFilters = state?.fetchingAvailableProductFilters || {};
        return Boolean(productFilters[path || '-']);
      }),
      distinctUntilChanged()
    );
  }

  public selectErrorFetchingProductFilters(
    path?: string | null
  ): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        const productFilters = state?.fetchingAvailableProductFilters || {};
        return productFilters[path || '-']
          ? parseApiError(productFilters[path || '-'])
          : null;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIsSearchingPage(
    path?: string | null,
    page?: number | null
  ): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        if (page || page === 0) {
          const productSearch = state?.productSearch || {};
          const currentSearch =
            productSearch[path || '-'] || createInitialAkitaProductSearchState();
          const loadingPages = currentSearch.loadingPage || [];
          return Boolean(loadingPages[page]);
        } else {
          return false;
        }
      }),
      distinctUntilChanged()
    );
  }

  public selectErrorSearchingPage(
    path?: string | null,
    page?: number | null
  ): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        if (page || page === 0) {
          const productSearch = state?.productSearch || {};
          const currentSearch =
            productSearch[path || '-'] || createInitialAkitaProductSearchState();
          const loadingPages = currentSearch.errorLoadingPage || [];
          return loadingPages[page] ? parseApiError(loadingPages[page]) : null;
        } else {
          return null;
        }
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectSearchPage(
    path?: string | null,
    page?: number | null
  ): Observable<ProductSearchResults | null> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        if (page || page === 0) {
          const productSearch = state?.productSearch || {};
          const currentSearch =
            productSearch[path || '-'] || createInitialAkitaProductSearchState();
          const pages = currentSearch.pages || [];
          return pages[page] ? parseProductSearchResults(pages[page]) : null;
        } else {
          return null;
        }
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIsSearching(path?: string | null): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        const productSearch = state?.productSearch || {};
        const currentSearch =
          productSearch[path || '-'] || createInitialAkitaProductSearchState();
        const loadingPages = currentSearch.loadingPage || [];
        return loadingPages.includes(true);
      }),
      distinctUntilChanged()
    );
  }

  public selectSearchHasNextPage(path?: string | null): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        const productSearch = state?.productSearch || {};
        const currentSearch =
          productSearch[path || '-'] || createInitialAkitaProductSearchState();
        return currentSearch.hasNextPage;
      }),
      distinctUntilChanged()
    );
  }

  public selectErrorSearching(path?: string | null): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        const productSearch = state?.productSearch || {};
        const currentSearch =
          productSearch[path || '-'] || createInitialAkitaProductSearchState();
        const pageErrors = currentSearch.errorLoadingPage || [];

        for (const err of pageErrors) {
          if (err) {
            return parseApiError(err);
          }
        }

        return null;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectSearch(path?: string | null): Observable<Array<Product>> {
    return this.select().pipe(
      map((state: AkitaProductsState) => {
        const combinedPages: Array<Product> = new Array(0);
        const productSearch = state?.productSearch || {};
        const currentSearch =
          productSearch[path || '-'] || createInitialAkitaProductSearchState();
        const pages = currentSearch.pages || [];

        for (const page of pages) {
          combinedPages.push(...(page?.results || []));
        }

        return combinedPages;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectProductVariants(model?: string | null): Observable<Array<ProductVariant>> {
    return this.select().pipe(
      map((state) => {
        const locale = this.validateLocale();
        if (model) {
          const productVariants = state?.productVariants || {};
          const langProducts = productVariants[locale.language] || {};
          const countryProducts = langProducts[locale.country] || {};
          const products = countryProducts[model] || [];
          return products;
        } else {
          return new Array(0);
        }
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIsSearchingProductVariants(model?: string | null): Observable<boolean> {
    return combineLatest([
      this.select(),
      this.selectLanguage(),
      this.selectCountry(),
    ]).pipe(
      map(([state, language, country]) => {
        if (model) {
          const fetchingProductVariants = state?.fetchingProductVariants || {};
          const langProducts = fetchingProductVariants[language] || {};
          const countryProducts = langProducts[country] || {};
          const products = countryProducts[model] || false;

          return Boolean(products);
        } else {
          return false;
        }
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectErrorSearchingProductVariants(
    model?: string | null
  ): Observable<ApiError | null> {
    return combineLatest([
      this.select(),
      this.selectLanguage(),
      this.selectCountry(),
    ]).pipe(
      map(([state, language, country]) => {
        if (model) {
          const errorFetchingProductVariants = state?.errorFetchingProductVariants || {};
          const langProducts = errorFetchingProductVariants[language] || {};
          const countryProducts = langProducts[country] || {};
          const products = countryProducts[model] || null;
          return products || null;
        } else {
          return null;
        }
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectValidateLocale(
    suggestedLanguage?: string | null,
    suggestedCountry?: string | null
  ): Observable<{ country: string; language: string }> {
    return combineLatest([this.selectLanguage(), this.selectCountry()]).pipe(
      map(([language, country]) => {
        const output = {
          language: `${suggestedLanguage || language || 'en'}`.toLowerCase(),
          country: `${suggestedCountry || country || 'US'}`.toUpperCase(),
        };

        const isSupportedLang = this.availableLanguages.includes(output.language);
        if (!isSupportedLang) {
          output.language = 'en';
        }

        const isSupportedCountry = this.availableCountries.includes(output.country);
        if (!isSupportedCountry) {
          output.country = 'US';
        }

        return output;
      })
    );
  }

  public selectProductSearchHints(): Observable<Array<string>> {
    return this.select().pipe(
      map((state) => state?.productSearchHints || []),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public getGettingProductSearchHints(): boolean {
    return Boolean(this.getValue().fetchingProductSearchHints);
  }

  public selectProductInsurance(ids: Array<string>): Observable<Array<Insurance>> {
    return this.select().pipe(
      map((state) => {
        const language = this.validateLocale().language;
        const country = this.validateLocale().country;
        const insurances = state?.insurances || {};
        const langInsurances = insurances[language] || {};
        const countryInsurances = langInsurances[country] || {};
        const output = new Array<Insurance>();
        ids.forEach((id) => {
          if (countryInsurances[id]) {
            output.push(countryInsurances[id]);
          }
        });

        return output;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  // deprecated
  public selectIsFetchingInsurances(): Observable<boolean> {
    return this.select().pipe(
      map((state) => Boolean(state.fetchingInsurances)),
      distinctUntilChanged()
    );
  }

  public selectIsFetchingInsurance(ids: Array<string>): Observable<boolean> {
    return combineLatest([
      this.select(),
      this.selectLanguage(),
      this.selectCountry(),
    ]).pipe(
      map(([state, language, country]) => {
        const fetchingInsurance = state?.fetchingInsurance || {};
        const langProducts = fetchingInsurance[language] || {};
        const countryProducts = langProducts[country] || {};
        return ids.some((id) => Boolean(countryProducts[id]));
      }),
      distinctUntilChanged()
    );
  }
}
