import { Injectable } from '@angular/core';
import { Observable, throwError, map, catchError } from 'rxjs';
import { SocialAuthInfo } from '../models/social-auth-info.model';
import { LoginResponse } from '@app/ngx-facebook/models/login-response';
import { FacebookService } from '@app/ngx-facebook/providers/facebook';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { parseApiError } from '@app/shared/models/api/api-error.model';

const SCOPE = 'public_profile,email';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class SignWithFacebookService {
  public isBrowser: boolean;

  constructor(
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly facebookService: FacebookService
  ) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;
  }

  public signIn(): Observable<SocialAuthInfo | null> {
    return this.facebookService
      .login({
        scope: SCOPE,
        return_scopes: true,
      })
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((response?: LoginResponse | null): SocialAuthInfo | null => {
          if (response && response.authResponse) {
            return {
              userId: response?.authResponse?.userID,
              secret: response?.authResponse?.accessToken,
            };
          } else {
            return null;
          }
        })
      );
  }
}
