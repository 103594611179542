import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ApiError } from '@app/shared/models/api/api-error.model';
import {
  AkitaStartingPricesState,
  createInitialStartingPricesState,
} from '../models/starting-prices.state';
import { StartingPriceModel } from '@app/shared/models/api/starting-price.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'starting-prices', resettable: true })
export class AkitaStartingPricesStore extends Store<AkitaStartingPricesState> {
  constructor() {
    super(createInitialStartingPricesState());
  }

  public setStartingPrices(
    startingPrices: Array<StartingPriceModel> | null,
    country: string
  ): void {
    this.update((state: AkitaStartingPricesState) => {
      const stateStartingPrices = {
        ...(state.startingPrices || {}),
      };
      stateStartingPrices[country] = startingPrices || [];
      return {
        startingPrices: stateStartingPrices,
      };
    });
  }

  public toggleFetchingStartingPrices(fetching?: boolean | null): void {
    this.update({
      fetchingStartingPrices: Boolean(fetching),
    });
  }

  public setErrorFetchingStartingPrices(error?: ApiError | null): void {
    this.update({
      errorFetchingStartingPrices: error || null,
    });
  }
}
