export class NewsletterSubtitle {
  [lang: string]: string;

  constructor() {
    this['en'] = '';
    this['ar'] = '';
  }
}

export const parseNewsletterSubtitleFromJsonString = (
  text?: string | null
): NewsletterSubtitle | null => {
  let out = null;
  try {
    out = parseNewsletterSubtitleFromJson(JSON.parse(`${text || ''}`));
  } catch (error) {}
  return out;
};

export const parseNewsletterSubtitleFromJson = (
  data?: any | null
): NewsletterSubtitle | null => {
  let out = null;
  if (data) {
    out = new NewsletterSubtitle();
    out['en'] = data['en'] || '';
    out['ar'] = data['ar'] || '';
  }
  return out;
};
