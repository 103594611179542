import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
  Observable,
  Subject,
  Subscription,
  throwError,
  catchError,
  switchMap,
  tap,
} from 'rxjs';
import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';
import { AkitaAuthService } from '@app/akita/api/auth/state/auth.service';
import { AuthTokenModel } from '@app/shared/models/api/auth/auth-token.model';
import { HttpStatusCode } from '@app/shared/utils/http-status-codes';
import { SentryUtil } from '@app/shared/utils/sentry.util';

@Injectable({
  providedIn: 'root',
})
export class SessionRestoreInterceptor implements HttpInterceptor {
  private akitaAuthService?: AkitaAuthService | null;

  constructor(
    private readonly injector: Injector,
    private readonly akitaAuthQuery: AkitaAuthQuery
  ) {}

  private refreshSubject: Subject<AuthTokenModel | null> =
    new Subject<AuthTokenModel | null>();

  private ifTokenExpired(subscription: Subscription): Subject<AuthTokenModel | null> {
    subscription.add(
      this.refreshSubject.subscribe({
        error: (err: unknown) => {
          if (err) {
            SentryUtil.reportException(err, false);
          }
        },
        complete: () => {
          this.refreshSubject = new Subject<AuthTokenModel | null>();
        },
      })
    );

    if (this.refreshSubject.observers.length === 1) {
      if (this.akitaAuthService) {
        // Hit refresh-token API passing the refresh token stored into the request
        // to get new access token and refresh token pair
        subscription.add(
          this.akitaAuthService.renewSessionSync().subscribe(this.refreshSubject)
        );
      } else {
        this.refreshSubject.error(new Error('AkitaAuthService not available'));
      }
    }
    return this.refreshSubject;
  }

  private checkTokenExpiryError(error: HttpErrorResponse): boolean {
    return Boolean(
      error.status &&
        error.status === HttpStatusCode.UNAUTHORIZED &&
        error.error &&
        error.error.message === 'TokenExpired'
    );
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    try {
      this.akitaAuthService = this.injector.get(AkitaAuthService);
    } catch (err) {
      this.akitaAuthService = null;
    }

    if (request.url.endsWith('/logout')) {
      return next.handle(request);
    } else {
      // const hasAuthorizationHeader = Boolean(request.headers.get('Authorization'));
      // const isNotApiCall = Boolean(
      //   `${request.url || ''}`.indexOf('/api') === -1 &&
      //     `${request.url || ''}`.indexOf('/oauth') === -1
      // );
      // if (!this.akitaAuthQuery.isLoggedIn || hasAuthorizationHeader || isNotApiCall) {
      //   return this.performApiCall(request, next);
      // } else {
      //   // Check if token was already expired
      //   if (this.akitaAuthQuery.isSessionActive) {
      return this.performApiCall(request, next);
      //   } else {
      // If token was already expired, do the renewal and hold the API call
      // return this.ifTokenExpired().pipe(
      //   switchMap(() => next.handle(this.updateHeader(request)))
      // );
      //   }
      // }
    }
  }

  private performApiCall(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: unknown, caught) => {
        if (error instanceof HttpErrorResponse) {
          // If API failed because an expired API token, renew Token
          if (this.checkTokenExpiryError(error)) {
            const subscription = new Subscription();
            return this.ifTokenExpired(subscription).pipe(
              switchMap(() =>
                next.handle(this.updateHeader(request)).pipe(
                  tap(() => {
                    if (subscription) {
                      subscription.unsubscribe();
                    }
                  })
                )
              )
            );
          } else {
            return throwError(() => error);
          }
        }
        return caught;
      })
    );
  }

  public updateHeader(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this.akitaAuthQuery.accessToken;
    if (
      accessToken &&
      // If the endpoint has basic auth header (do not replace)
      `${request?.headers?.get('Authorization') || ''}`.indexOf('Basic') !== -1
    ) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${accessToken}`),
      });
    } else {
      request = request.clone();
    }
    return request;
  }
}
