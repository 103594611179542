<ng-container *ngIf="!isBrowser">
  <div class="picture-component">
    <img
      #image
      class="image"
      [src]="pictureSafeUrl"
      [alt]="alt || 'picture'"
      [title]="alt || 'picture'"
      (error)="onLoadPictureError()" />
  </div>
</ng-container>
<ng-container *ngIf="isBrowser">
  <div
    inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="onIntersection($event)"
    class="picture-component">
    <ng-container
      *ngIf="loadedPictureUrl || useErrorPlaceholder"
      [style.backgroundColor]="bgColor || ''">
      <ng-container *ngIf="useErrorPlaceholder">
        <popsy-icon-error-image
          #image
          class="image"
          [aspectRatio]="'fill'"></popsy-icon-error-image>
      </ng-container>
      <ng-container *ngIf="!useErrorPlaceholder">
        <img
          #image
          class="image"
          [src]="loadedPictureUrl"
          referrerpolicy="no-referrer"
          [alt]="alt || 'picture'"
          [title]="alt || 'picture'"
          (error)="onLoadPictureError()" />
      </ng-container>
    </ng-container>
    <ng-container *ngIf="pictureLoading || loading">
      <app-spinner class="loading-spinner" [contrast]="true"></app-spinner>
    </ng-container>
  </div>
</ng-container>
