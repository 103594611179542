import { Model } from '../model.interface';
import { AuthTokenModel } from './auth/auth-token.model';
import { PictureModel } from './picture.model';
import { PositionModel } from './position.model';
import { WindowUtils } from '@app/shared/utils/window.util';
import { PopsyDateParser } from '@app/shared/utils/api-date.parser';

export interface OauthInfo {
  publicId: string;
  secret: string;
  token: AuthTokenModel | null;
}

const MAX_AVATAR = 5;

export const MIN_USER_DESCRIPTION_LENGTH = 1;
export const MAX_USER_DESCRIPTION_LENGTH = 300;
export const MIN_USERNAME_LENGTH = 3;
export const MAX_USERNAME_LENGTH = 30;

/**
 * Class used to represent a User
 */
export class UserModel implements Model {
  public avatarId: number;
  public id: string;
  public email: string;
  public firstName: string;
  public description: string;
  public timezone: number;
  public gender: string;
  public phone: string;
  public facebook: {
    friends: number;
  };
  public picture: PictureModel;
  public locale: string;
  public lastSeen: Date;
  public createdAt: Date;
  public updatedAt: Date;
  public location: PositionModel;
  public oauth: OauthInfo;
  public rating: number; // Conversations Endpoint Only
  public smsVerifiedAt: Date | null;
  public opt_in: boolean | null;

  // Internal
  public cachedOn: Date;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.avatarId = UserModel.getAvatarId();
    this.id = '';
    this.email = '';
    this.firstName = '';
    this.description = '';
    this.timezone = 0;
    this.gender = '';
    this.phone = '';
    this.facebook = {
      friends: 0,
    };
    this.picture = new PictureModel();
    this.picture.avatarId = this.avatarId;

    this.locale = '';
    this.lastSeen = new Date();
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.smsVerifiedAt = null;
    this.location = new PositionModel();
    this.oauth = {
      publicId: '',
      secret: '',
      token: null,
    };
    this.rating = 0;
    this.cachedOn = new Date(0);
    this.opt_in = false;
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   *
   * @param data The Raw JSON Object
   * @returns {UserModel} An instance of the UserModel class.
   */
  public static fromJson(data?: Record<string, any> | null): UserModel | null {
    let user: UserModel | null = null;

    if (data) {
      user = new UserModel();
      user.avatarId = data.avatarId || UserModel.getAvatarId(data.id);
      user.id = data.id || '';
      user.email = data.email || '';
      user.firstName =
        data.first_name || data.firstname || data.firstName || data.username || '';
      user.description = data.description || '';
      user.timezone = data.timezone || 0;
      user.gender = data.gender || '';
      user.phone = data.phone || data.contact || '';
      user.rating = data.rating || 0;
      user.facebook = data.facebook || {
        friends: 0,
      };
      user.locale = data.locale || '';
      user.location = PositionModel.fromJson(data.location) || new PositionModel();

      user.smsVerifiedAt =
        PopsyDateParser.parseApiDate('sms_verified_at', 'smsVerifiedAt', data) || null;
      user.lastSeen =
        PopsyDateParser.parseApiDate('last_seen', 'lastSeen', data) || new Date(0);
      user.createdAt =
        PopsyDateParser.parseApiDate('created_at', 'createdAt', data) || new Date(0);
      user.updatedAt =
        PopsyDateParser.parseApiDate('updated_at', 'updatedAt', data) || new Date(0);
      user.cachedOn =
        PopsyDateParser.parseApiDate('cached_on', 'cachedOn', data) || new Date();

      if (data.picture) {
        user.picture = PictureModel.fromJson(data.picture) || new PictureModel();
      } else if (data.image) {
        user.picture = PictureModel.fromJson(data.image) || new PictureModel();
      } else {
        user.picture = new PictureModel();
      }
      user.picture.avatarId = user.avatarId;

      user.opt_in = data.opt_in || data.optIn || false;

      if (data.oauth_client) {
        user.oauth = {
          publicId: data.oauth_client.public_id || '',
          secret: data.oauth_client.secret || '',
          token: data.oauth_client.token || null,
        };
      } else if (data.oauth) {
        user.oauth = {
          publicId: data.oauth.publicId || '',
          secret: data.oauth.secret || '',
          token: data.oauth.token || null,
        };
      }
    }

    return user;
  }

  /**
   * Helper function to sanitize an array of data that comes from the Backend.
   * @param data The Raw JSON Array
   * @returns {Array<UserModel>} An Array of UserModel instances.
   */
  public static fromJsonArray(
    data?: Array<Record<string, any>> | null
  ): Array<UserModel> {
    const users: Array<UserModel> = new Array(0);

    // Ensure data is not null, and that is an array
    if (data && data.length > 0) {
      for (const userData of data) {
        const user: UserModel | null = UserModel.fromJson(userData);
        if (user) {
          users.push(user);
        }
      }
    }

    return users;
  }

  public static getAvatarId(id?: string): number {
    const avatarId = 1 + Math.ceil(Math.random() * MAX_AVATAR);

    if (id) {
      const window = WindowUtils.window;
      if (window) {
        try {
          // guard against old safari browsers that don't support local storage
          const rawAvatarCache = window.localStorage.getItem('user_avatar_cache');
          const avatarCache = JSON.parse(rawAvatarCache || '{}');
          if (avatarCache[id]) {
            return avatarCache[id];
          } else {
            avatarCache[id] = avatarId;
            try {
              window.localStorage.setItem(
                'user_avatar_cache',
                JSON.stringify(avatarCache)
              );
            } catch (error) {}
          }
        } catch (error) {}
      }
    }

    return avatarId;
  }
}
