import { PopsyDateParser } from '@app/shared/utils/api-date.parser';

export const COORDINATE_PRECISSION = 6;

export type CoordinatePrecission =
  | 'GPS'
  | 'IP'
  | 'USER'
  | 'ENVIROMENT'
  | 'FALLBACK'
  | 'SUBDOMAIN'
  | '';

/**
 * Class used to store a simple coordinate from the GeoLocation API
 */
export class Coordinate {
  public latitude: number;
  public longitude: number;
  public userDefined: boolean;
  public googleGeocoder: boolean;
  public address: string;
  public addressDetails: string;
  public shortAddress: string;
  public country: string;
  public countryName: string;
  public city: string;
  public area: string;
  public streetName: string;
  public streetNumber: string;
  public postalCode: string;
  public region: string;
  public regionCode: string;
  public extra: string;
  public updatedAt: Date;
  public precission: CoordinatePrecission;
  public withError: any | null = null;
  public trackingId: number | null = null;

  constructor() {
    this.latitude = 0;
    this.longitude = 0;
    this.precission = '';
    this.userDefined = false;
    this.googleGeocoder = false;
    this.address = '';
    this.addressDetails = '';
    this.shortAddress = '';
    this.country = '';
    this.countryName = '';
    this.city = '';
    this.area = '';
    this.streetName = '';
    this.streetNumber = '';
    this.postalCode = '';
    this.region = '';
    this.regionCode = '';
    this.extra = '';
    this.updatedAt = new Date();
    this.withError = null;
    this.trackingId = null;
  }

  public static buildShortAddress(
    city?: string,
    country?: string,
    postalCode?: string
  ): string {
    const address = new Array(0);
    if (postalCode) {
      address.push(postalCode);
    }
    if (city) {
      address.push(city);
    }
    if (country) {
      address.push(country);
    }

    return address.join(', ');
  }

  /**
   * Function used to convert a JSON object into a valid Coordinate instance
   * @param data The JSON Object
   * @returns {Coordinate} The coordinate
   */
  public static fromJson(
    data?: Record<string, any> | null,
    avoidParsing?: boolean | null
  ): Coordinate | null {
    let coordinate: Coordinate | null = null;
    if (data) {
      coordinate = new Coordinate();
      coordinate.trackingId =
        data.trackingId !== null && data.trackingId !== undefined
          ? data.trackingId
          : null;
      coordinate.latitude = Number(data.latitude);
      coordinate.longitude = Number(data.longitude);
      coordinate.precission = data.precission || '';
      coordinate.userDefined = data.userDefined === true;
      coordinate.googleGeocoder = data.googleGeocoder === true;
      coordinate.address =
        data.formatted_address || data.formattedAddress || data.address || '';

      coordinate.addressDetails = data.address_details || data.addressDetails || '';

      let parsedAddress = {
        streetName: '',
        streetNumber: '',
        neighborhood: '',
        region: '',
        city: '',
        postalCode: '',
        country: '',
      };
      if (!avoidParsing) {
        parsedAddress = Coordinate.parseAddressString(coordinate.address);
      }

      coordinate.country = (data.country || '').toUpperCase();
      coordinate.countryName = data.countryName || parsedAddress.country || '';
      coordinate.area = data.area || parsedAddress.neighborhood || '';
      coordinate.city = data.city || data.locality || parsedAddress.city || '';
      coordinate.region = data.region || parsedAddress.region || '';
      coordinate.regionCode = data.regionCode || '';
      coordinate.streetName = data.streetName || parsedAddress.streetName || '';
      coordinate.streetNumber = data.streetNumber || parsedAddress.streetNumber || '';
      coordinate.postalCode = data.postalCode || parsedAddress.postalCode || '';
      coordinate.extra = data.extra || '';
      coordinate.withError = data.withError || null;
      coordinate.updatedAt =
        PopsyDateParser.parseApiDate('updated_at', 'updatedAt', data) || new Date();
      coordinate.shortAddress =
        data.short_address ||
        data.shortAddress ||
        data.address ||
        Coordinate.buildShortAddress(
          coordinate.city,
          coordinate.country,
          coordinate.region
        ) ||
        '';
    }
    return coordinate;
  }

  /**
   * Function used to convert a JSON object into a valid Map of Coordinates
   * @param data The JSON Object
   * @returns {Coordinate} The map of coordinates
   */
  public static fromJsonDictionary(data: Record<string, any>): {
    [latLng: string]: Coordinate | null;
  } {
    const dictionary: { [latLng: string]: Coordinate | null } = {};
    if (data) {
      for (const latLng of Object.keys(data)) {
        dictionary[latLng] = Coordinate.fromJson(data[latLng]) || null;
      }
    }
    return dictionary;
  }

  public getLatLng(): string {
    return `${(this.latitude || 0).toFixed(COORDINATE_PRECISSION)},${(
      this.longitude || 0
    ).toFixed(COORDINATE_PRECISSION)}`;
  }

  public static parseAddressString(text?: string | null): {
    streetName: string;
    streetNumber: string;
    neighborhood: string;
    region: string;
    city: string;
    postalCode: string;
    country: string;
  } {
    const result: {
      streetName: string;
      streetNumber: string;
      neighborhood: string;
      region: string;
      city: string;
      postalCode: string;
      country: string;
    } = {
      streetName: '',
      streetNumber: '',
      neighborhood: '',
      region: '',
      city: '',
      postalCode: '',
      country: '',
    };

    const addressParts = `${text || ''}`.split(' - ');
    if (addressParts.length > 0) {
      const lineComponents = `${addressParts[0] || ''}`.split(',');
      result.streetName = `${lineComponents[0] || ''}`.trim();

      if (lineComponents.length > 1) {
        const cleanText = `${lineComponents[1] || ''}`.trim();
        if (cleanText.indexOf(' ') !== -1) {
          const innerParts = cleanText.split(' ');
          result.postalCode = innerParts[0];
          result.city = innerParts[1];
        } else {
          result.streetNumber = `${lineComponents[1] || ''}`.trim();
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (lineComponents.length > 2) {
        const cleanText = `${lineComponents[2] || ''}`.trim();
        if (cleanText.indexOf(' ') !== -1) {
          const innerParts = cleanText.split(' ');
          result.postalCode = innerParts[0];
          result.city = innerParts[1];
        } else {
          result.region = `${lineComponents[2] || ''}`.trim();
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (lineComponents.length > 3) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (lineComponents.length > 4) {
          result.region = `${lineComponents[3] || ''}`.trim();
        } else {
          result.country = `${lineComponents[3] || ''}`.trim();
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (lineComponents.length > 4) {
        result.country = `${lineComponents[4] || ''}`.trim();
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (addressParts.length >= 2) {
      const lineComponents = `${addressParts[1] || ''}`.split(',');
      if (lineComponents.length > 1) {
        result.neighborhood = `${lineComponents[0] || ''}`.trim();
        result.city = `${lineComponents[1] || ''}`.trim();
      } else {
        result.city = `${lineComponents[0] || ''}`.trim();
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (addressParts.length >= 3) {
      const lineComponents = `${addressParts[2] || ''}`.split(',');
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (lineComponents.length >= 3) {
        result.region = `${lineComponents[0] || ''}`.trim();
        result.postalCode = `${lineComponents[1] || ''}`.trim();
        result.country = `${lineComponents[3] || ''}`.trim();
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      } else if (lineComponents.length >= 2) {
        result.region = `${lineComponents[0] || ''}`.trim();
        result.postalCode = `${lineComponents[1]}`.trim();
      } else {
        const cleanText = `${lineComponents[0] || ''}`.trim();
        const onlyDigits = cleanText.replace(/\D+/gi, '');
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (onlyDigits.length === 8 || onlyDigits.length === 9) {
          result.postalCode = cleanText;
        } else {
          result.region = cleanText;
        }
      }
    }

    return result;
  }

  public get asApiJson(): {
    address: string;
    formated_address: string | null;
    latitude: string;
    longitude: string;
    city: string;
    country: string;
    region: string;
  } {
    return {
      address: this.address || this.shortAddress || '',
      formated_address: null,
      latitude: `${this.latitude || 0}`,
      longitude: `${this.longitude || 0}`,
      city: this.city || '',
      country: (this.country || '').toUpperCase(),
      region: this.region || '',
    };
  }
}
