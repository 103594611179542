import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';
import { AkitaProductsQuery } from '@app/akita/api/products/state/products.query';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { Cart, cartFromJson } from '../models/cart-multiple.model';
import {
  CartItem,
  cartItemListFromJson,
  parseCartAPIResponse,
} from '../models/cart.model';
import { AkitaCartQuery } from '../state/cart.query';
import { AkitaCartStore } from '../state/cart.store';
import { Buffer } from 'buffer';
import { PackageProtectionModel } from '@app/akita/api/checkout/models/package-protection.model';

@Injectable({ providedIn: 'root' })
export class CartApiService {
  private readonly http = inject(HttpClient);
  constructor(
    private readonly akitaAuthQuery: AkitaAuthQuery,
    private readonly akitaCartStore: AkitaCartStore,
    private readonly akitaCartQuery: AkitaCartQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaProductsQuery: AkitaProductsQuery
  ) {}

  public readCartItems(): Observable<Array<CartItem>> {
    const country = this.akitaProductsQuery.validateLocale().country;
    const accessToken = this.akitaAuthQuery.accessToken || null;
    const queryCartId = this.akitaRouterQuery.queryParams.cart || null;
    let apiUrl = '';
    let headers = {};

    if (queryCartId) {
      // query params
      // eslint-disable-next-line deprecation/deprecation
      const credentials = btoa(`admin:fortytwo42`);
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Basic ${credentials}`,
      };
      apiUrl = getApiEndpoint(`api/v2/cart/anonymous/${queryCartId}`, true);
    } else if (accessToken) {
      // user cart
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
      apiUrl = getApiEndpoint(`api/v2/cart/`, true);
    } else {
      // Anonymous Cart: using state cart or local storage ID
      // eslint-disable-next-line deprecation/deprecation

      const credentials = Buffer.from(`admin:fortytwo42`).toString('base64');
      const cartId =
        this.akitaCartStore.getValue()[country] &&
        this.akitaCartStore.getValue()[country].id
          ? this.akitaCartStore.getValue()[country].id
          : this.loadCartId();

      if (!cartId) {
        return of(new Array(0));
      }
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Basic ${credentials}`,
      };
      apiUrl = getApiEndpoint(`api/v2/cart/anonymous/${cartId}`, true);
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError(() => of(null)),
        map(
          (
            data // verify if the cart is from the same country
          ) =>
            data.orders.map((order: any) => order.product.country)[0] === country
              ? parseCartAPIResponse(data)
              : []
        )
      );
  }

  // deprecated
  public createCartItems(
    items: Array<CartItem>,
    insuranceItems?: any | null
  ): Observable<boolean> {
    const accessToken = this.akitaAuthQuery.accessToken || null;
    let apiUrl = '';
    let headers = {};
    let anonymousAccess = false;

    if (!items.length) {
      return throwError(() => parseApiError({}));
    }

    if (accessToken) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
      apiUrl = getApiEndpoint(`api/v2/cart/`, true);
    } else {
      anonymousAccess = true;
      // eslint-disable-next-line deprecation/deprecation
      const credentials = btoa(`admin:fortytwo42`);
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Basic ${credentials}`,
      };
      apiUrl = getApiEndpoint(`api/v2/cart/anonymous`, true);
    }

    const locale = this.akitaProductsQuery.validateLocale();
    const orders: Array<any> = Array(0);

    items.forEach((item) => {
      if (item.product.id) {
        orders.push({
          product_id: item.product.id,
          quantity: item.quantity,
        });
      }
    });

    return this.http
      .post<any>(
        // Assemble the full API URL
        apiUrl,
        // Body
        {
          client: 'WEB', // required: ANDROID or IOS or WEB
          orders: orders, // required, on creating alway 1 product/listing
          insurances: insuranceItems || [],
          country: locale.country, // required
          language: locale.language, // required
        },
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((data) => {
          this.akitaCartStore.updateCartId(locale.country, data?.id || '');
          this.akitaCartStore.updateSessionId(locale.country, null);
          this.akitaCartStore.updateCartInsurances(locale.country, data.insurance);
          if (anonymousAccess) {
            this.saveCartId(data?.id || null);
          }
          return data;
        })
      );
  }

  /* refactor -> use this */
  public createCartAPI(
    items: Array<CartItem>,
    insuranceItems?: any | null,
    isProtected: boolean | null = true
  ): Observable<boolean> {
    const accessToken = this.akitaAuthQuery.accessToken || null;
    let apiUrl = '';
    let headers = {};
    let anonymousAccess = false;

    if (!items.length) {
      return throwError(() => parseApiError({}));
    }

    if (accessToken) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
      apiUrl = getApiEndpoint(`api/v2/cart/`, true);
    } else {
      anonymousAccess = true;
      // eslint-disable-next-line deprecation/deprecation
      const credentials = btoa(`admin:fortytwo42`);
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Basic ${credentials}`,
      };
      apiUrl = getApiEndpoint(`api/v2/cart/anonymous`, true);
    }

    const locale = this.akitaProductsQuery.validateLocale();
    const orders: Array<any> = Array(0);

    items.forEach((item) => {
      if (item.product.id) {
        orders.push({
          product_id: item.product.id,
          quantity: item.quantity,
        });
      }
    });

    return this.http
      .post<any>(
        // Assemble the full API URL
        apiUrl,
        // Body
        {
          client: 'WEB', // required: ANDROID or IOS or WEB
          orders: orders, // required, on creating alway 1 product/listing
          insurances: insuranceItems || [],
          country: locale.country, // required
          language: locale.language, // required
          is_protected_order: isProtected,
        },
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((data) => {
          if (anonymousAccess) {
            this.saveCartId(data?.id || null);
          }
          return parseCartAPIResponse(data);
        })
      );
  }

  /* refactor -> use this */
  public updateCartAPI(
    items: Array<CartItem>,
    insuranceItems?: any | null,
    isProtected: boolean | null = true
  ): Observable<any> {
    const accessToken = this.akitaAuthQuery.accessToken || null;
    const country = this.akitaProductsQuery.validateLocale().country;
    let apiUrl = '';
    let headers = {};
    let cartId: string | null;

    if (accessToken) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
      cartId = this.akitaCartQuery.getId();
      apiUrl = getApiEndpoint(`api/v2/cart/${cartId}`, true);
    } else {
      // eslint-disable-next-line deprecation/deprecation
      const credentials = btoa(`admin:fortytwo42`);
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Basic ${credentials}`,
      };
      cartId =
        this.akitaCartStore.getValue()[country] &&
        this.akitaCartStore.getValue()[country].id
          ? this.akitaCartStore.getValue()[country].id
          : this.loadCartId();
      apiUrl = getApiEndpoint(`api/v2/cart/anonymous/${cartId}`, true);
    }

    const parsedOrders: Array<any> = new Array(0);
    for (const item of items) {
      if (item.product) {
        parsedOrders.push({ product_id: item.product.id, quantity: item.quantity });
      }
    }

    const parsedInsuranceItems: Array<any> = new Array(0);
    if (insuranceItems && insuranceItems.length) {
      for (const item of insuranceItems) {
        parsedInsuranceItems.push({
          insurance_id: item.id || item.insurance_id,
          product_id: item.productId || item.product_id,
          quantity: item.quantity,
        });
      }
    }

    return this.http
      .put<any>(
        // Assemble the full API URL
        apiUrl,
        // Body
        {
          client: 'WEB', // required: ANDROID or IOS or WEB
          orders: parsedOrders, // required, for now we should only give the option to 1 listing
          insurances: parsedInsuranceItems,
          is_protected_order: isProtected,
        },
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((data) => parseCartAPIResponse(data))
      );
  }

  public deleteCart(): Observable<boolean> {
    const accessToken = this.akitaAuthQuery.accessToken || null;
    const country = this.akitaProductsQuery.validateLocale().country;
    let apiUrl = '';
    let headers = {};
    let cartId: string | null;

    if (accessToken) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
      cartId = this.akitaCartQuery.getId();
      apiUrl = getApiEndpoint(`api/v2/cart/${cartId}`, true);
    } else {
      // eslint-disable-next-line deprecation/deprecation
      const credentials = btoa(`admin:fortytwo42`);
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Basic ${credentials}`,
      };
      cartId =
        this.akitaCartStore.getValue()[country] &&
        this.akitaCartStore.getValue()[country].id
          ? this.akitaCartStore.getValue()[country].id
          : this.loadCartId();
      apiUrl = getApiEndpoint(`api/v2/cart/anonymous/${cartId}`, true);
    }

    return this.http
      .delete<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((data) => {
          this.removeCartId();
          return data;
        })
      );
  }

  public saveCartId(id: string | null): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        (window as any).localStorage.setItem('popsy_cart', id);
      } catch (err) {
        // Window not supported on SSR
      }
    }
  }

  public loadCartId(): string | null {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        const cartId = (window as any).localStorage.getItem('popsy_cart') || null;
        return cartId;
      } catch (err) {
        // Window not supported on SSR
        return null;
      }
    }
    return null;
  }

  public removeCartId(): string | null {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        const cartId = (window as any).localStorage.removeItem('popsy_cart') || null;
        return cartId;
      } catch (err) {
        // Window not supported on SSR
        return null;
      }
    }
    return null;
  }

  public restoreAnonymousCart(): Observable<Array<CartItem>> {
    const cartId = this.loadCartId();
    let apiUrl = '';
    let headers = {};

    if (!cartId) {
      return of(new Array(0));
    }
    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`admin:fortytwo42`);

    headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${credentials}`,
    };
    apiUrl = getApiEndpoint(`api/v2/cart/anonymous/${cartId}`, true);

    return this.http
      .get<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError(() => of(null)),
        map((data) => cartItemListFromJson(data) || [])
      );
  }

  public transferAnonymousCart(): Observable<Cart | null> {
    const accessToken = this.akitaAuthQuery.accessToken || null;

    const cartId = this.loadCartId();
    if (accessToken && cartId) {
      const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
      const apiUrl = getApiEndpoint(`cart/${cartId}/transfer`, true);

      return this.http
        .put<any>(
          // Assemble the full API URL
          apiUrl,
          // Add the Options
          {
            responseType: 'json',
            headers: headers,
          }
        )
        .pipe(
          catchError(() => of(null)),
          map((data) => {
            this.akitaCartStore.updateCartId(data?.id || '');
            return cartFromJson(data);
          })
        );
    } else {
      return of(null);
    }
  }

  public getAvailableProtection(id: string): Observable<PackageProtectionModel | null> {
    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:GKbwgZ9rJBf8oggS`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${credentials}`,
    });
    const apiUrl = getApiEndpoint(
      `api/v1/insurance/orderprotection?session_id=${id}`,
      true
    );

    return this.http
      .get<any>(
        // Assemble the full API URL
        apiUrl,
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        catchError(() => of(null)),
        map((data) => PackageProtectionModel.fromJson(data))
      );
  }
}
