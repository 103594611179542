import { Injectable } from '@angular/core';
import { InitParams } from '../models/init-params';
import { LoginOptions } from '../models/login-options';
import { LoginResponse } from '../models/login-response';
import { Observer, Observable } from 'rxjs';

/**
 * @hidden
 */
export type ApiMethod = 'get' | 'post' | 'delete';

/**
 * @shortdesc
 * Angular 2 service to inject to use Facebook's SDK
 * @description
 * You only need to inject this service in your application if you aren't using [`FacebookModule`](../facebook-module).
 * @usage
 * ```typescript
 * import { FacebookService, InitParams } from 'ng2-facebook-sdk';
 *
 * constructor(private fb: FacebookService) {
 *
 *   const params: InitParams = {
 *
 *   };
 *
 *   fb.init(params);
 *
 * }
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  /**
   * This method is used to initialize and setup the SDK.
   * @param params {InitParams} Initialization parameters
   * @returns {Promise<any>}
   */
  public init(params: InitParams): Promise<any> {
    return new Promise(
      (resolve: (value?: any) => void, reject: (value?: any) => void) => {
        try {
          FB.init(params);
          return resolve();
        } catch (error) {
          return reject(error);
        }
      }
    );
  }

  /**
   * This method lets you make calls to the Graph API
   * @usage
   * ```typescript
   * this.fb.api('somepath')
   *   .then(res => console.log(res))
   *   .catch(e => console.log(e));
   * ```
   * @param path {string} The Graph API endpoint path that you want to call.
   * @param [method=get] {string} The HTTP method that you want to use for the API request.
   * @param [params] {Object} An object consisting of any parameters that you want to pass into your Graph API call.
   * @returns {Promise<any>}
   */
  public api(path: string, method: ApiMethod = 'get', params: any = {}): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      try {
        FB.api(path, method, params, (response: any) => {
          if (!response) {
            reject();
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Login the user
   * @usage
   * ```typescript
   * // login without options
   * this.fb.login()
   *   .then((response: LoginResponse) => console.log('Logged in', response))
   *   .catch(e => console.error('Error logging in'));
   *
   * // login with options
   * const options: LoginOptions = {
   *   scope: 'public_profile,user_friends,email,pages_show_list',
   *   return_scopes: true,
   *   enable_profile_selector: true
   * };
   * this.fb.login(options)
   *   .then(...)
   *   .catch(...);
   * ```
   * @param [options] {LoginOptions} Login options
   * @returns {Observable<LoginResponse | null>} returns a promise that resolves with
   * [LoginResponse](../login-response) object, or rejects with an error
   */
  public login(options?: LoginOptions): Observable<LoginResponse | null> {
    return new Observable((observer: Observer<LoginResponse | null>) => {
      try {
        FB.login((response: any) => {
          if (response?.authResponse) {
            observer.next(response);
            observer.complete();
          } else {
            observer.next(null);
            observer.complete();
          }
        }, options);
      } catch (error) {
        observer.error(error);
        observer.complete();
      }
    });
  }

  /**
   * Logout the user
   * @usage
   * ```typescript
   * this.fb.logout().then(() => console.log('Logged out!'));
   * ```
   * @returns {Promise<any>} returns a promise that resolves when the user is logged out
   */
  public logout(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      try {
        FB.logout((response: any) => {
          resolve(response);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
