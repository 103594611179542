import { Model } from '../model.interface';

/**
 * Class used to represent a Price Object
 */
export class PriceModel implements Model {
  public amount: number;
  public currency: string;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.amount = 0;
    this.currency = 'USD';
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   *
   * @param data The Raw JSON Object
   * @returns {PriceModel} An instance of the Price class.
   */
  public static fromJson(data?: Record<string, any> | null): PriceModel | null {
    let price: PriceModel | null = null;

    if (data && data.amount >= 0) {
      price = new PriceModel();
      price.amount = data.amount;
      price.currency = data.currency;
    }

    return price;
  }

  /**
   * Helper function to sanitize an array of data that comes from the Backend.
   * @param data The Raw JSON Array
   * @returns {Array<PriceModel>} An Array of Price instances.
   */
  public static fromJsonArray(
    data?: Array<Record<string, any>> | null
  ): Array<PriceModel> {
    const prices: Array<PriceModel> = new Array(0);

    // Ensure data is not null, and that is an array
    if (data && data.length > 0) {
      for (const priceData of data) {
        const price: PriceModel | null = PriceModel.fromJson(priceData);
        if (price) {
          prices.push(price);
        }
      }
    }

    return prices;
  }
}
