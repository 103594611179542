<ng-container *transloco="let t">
  <div
    [attr.psid]="sessionId$ | async"
    class="view-app"
    [class.with-toolbar]="
      !(redirectUri$ | async) &&
      !(isInternalView$ | async) &&
      !(inFullScreenMode$ | async) &&
      (viewName$ | async) !== 'DELIVERY' &&
      (viewName$ | async) !== 'SESSION'
    ">
    <ng-container
      *ngIf="
        !(redirectUri$ | async) &&
        !(isInternalView$ | async) &&
        !(inFullScreenMode$ | async) &&
        (viewName$ | async) !== 'DELIVERY' &&
        (viewName$ | async) !== 'SESSION'
      ">
      <app-announcement-bar *ngIf="(biggerThan$ | async)?.mobileL"></app-announcement-bar>
      <app-toolbar class="toolbar"></app-toolbar>
    </ng-container>
    <router-outlet class="content"></router-outlet>
    <meta
      data-clarity-unmask
      name="firebase_rc_debug"
      [content]="currentConditionSet$ | async" />
    <app-json-ld [json]="schema"></app-json-ld>
    <app-json-ld [json]="companySchema"></app-json-ld>
    <app-json-ld [json]="appSchema"></app-json-ld>
    <div *ngIf="pageLoading" class="page-loading">
      <app-please-wait></app-please-wait>
    </div>

    <!-- <button class="feedback-button" (click)="onAskForFeedback()">
      <span class="label">{{ t('label_feedback') }}</span>
    </button> -->
  </div>
</ng-container>
