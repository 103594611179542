import { Configuration } from '@app/shared/models/configuration/configuration.model';
import { environment } from '@environments/environment';

export const CONFIGURATION: Configuration = {
  app: {
    version: environment.app.version,
    commit: environment.app.commit,
    branch: environment.app.branch,
  },
  api: {
    protocol: environment.api.protocol,
    url: environment.api.url,
    port: environment.api.port,
    path: environment.api.path,
    version: environment.api.version,
  },
  blogApi: {
    protocol: environment.blogApi.protocol,
    url: environment.blogApi.url,
    port: environment.blogApi.port,
    path: environment.blogApi.path,
    version: environment.blogApi.version,
  },
  firebase: {
    appId: environment.firebase.appId,
    apiKey: environment.firebase.apiKey,
    authDomain: environment.firebase.authDomain,
    databaseURL: environment.firebase.databaseURL,
    projectId: environment.firebase.projectId,
    storageBucket: environment.firebase.storageBucket,
    messagingSenderId: environment.firebase.messagingSenderId,
    measurementId: environment.firebase.measurementId,
  },
  stripe: {
    country: {
      br: environment.stripe.country.br,
      us: environment.stripe.country.us,
    },
  },
  apple: {
    clientId: environment.apple.clientId,
    baseURL: environment.apple.clientId,
  },
  google: {
    clientId: environment.google.clientId,
    baseURL: environment.google.clientId,
  },
  name: environment.name,
  domain: environment.domain,
  production: environment.production,
  enableAnalitycs: environment.enableAnalitycs,
  debugAnalitycs: environment.debugAnalitycs,
  reportToSentry: environment.reportToSentry,
  sentryAskFeedback: environment.sentryAskFeedback,
  serviceWorker: environment.serviceWorker,
  debugMode: environment.debugMode,
  languageCode: environment.languageCode,
  languages: environment.languages,
  fbAppId: environment.fbAppId,
  fbAppVersion: environment.fbAppVersion,
  gmapsApiKey: environment.gmapsApiKey,
  sentryUrl: environment.sentryUrl,
  enableHotjar: environment.enableHotjar,
  enableSmartLook: environment.enableSmartLook,
};
