import { Model } from '../model.interface';
import { PopsyColor } from '../../utils/color.utils';
import { guid } from '@datorama/akita';

const MAX_AVATAR = 5;
const DEFAULT_SIZE = 200;

/**
 * Class used to represent a Picture from the Picture Results
 */
export class PictureModel implements Model {
  public path: string | ArrayBuffer;
  public color: number;
  public width: number;
  public height: number;
  public avatarId: number;

  // Internal
  public preview: string | ArrayBuffer;
  public raw: File | null = null;
  public rotation: number;
  public serverId: string;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.path = '';
    this.color = 0;
    this.width = 0;
    this.height = 0;
    this.avatarId = 1 + Math.ceil(Math.random() * MAX_AVATAR);
    this.raw = null;
    this.preview = '';
    this.rotation = 0;
    this.serverId = PictureModel.generateServerID(`${this.path || ''}`);
  }

  public static placeholder(): PictureModel {
    const placeholder = new PictureModel();
    placeholder.path = 'assets/error-image.svg';
    placeholder.preview = 'assets/error-image.svg';
    placeholder.color = 0;
    placeholder.width = DEFAULT_SIZE;
    placeholder.height = DEFAULT_SIZE;
    placeholder.avatarId = 1 + Math.ceil(Math.random() * MAX_AVATAR);
    placeholder.rotation = 0;
    placeholder.serverId = PictureModel.generateServerID(`${placeholder.path || ''}`);
    return placeholder;
  }

  /**
   * Getter to force HTTPS images (to avoid chrome insecure content warnings)
   * @returns {string}
   */
  public get url(): string {
    if (this.path === '-') {
      return `${this.preview}`;
    }

    if (`${this.path}`.indexOf('/avatar/00000000000000000000000000000000') !== -1) {
      return `assets/images/avatar.${this.avatarId}.png`;
    }

    return (
      (`${this.path}` || '').replace('http://', 'https://') || `${this.preview || ''}`
    );
  }

  private static generateServerID(path: string): string {
    /* eslint-disable @typescript-eslint/no-magic-numbers */

    if (`${path || ''}`.indexOf('googleusercontent.com') !== -1) {
      const expression = /\/([\dA-z-]+)=/;
      const matches = expression.exec(`${path || ''}`);

      if (matches && matches.length >= 2) {
        return matches[1];
      }
    }
    return guid();

    /* eslint-enable @typescript-eslint/no-magic-numbers */
  }

  /**
   * Getter to force HTTPS images (to avoid chrome insecure content warnings)
   * @returns {string}
   */
  public get hexColor(): string {
    return PopsyColor.fromBackend(this.color, '#fafafa');
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   *
   * @param data The Raw JSON Object
   * @returns {PictureModel} An instance of the Picture class.
   */
  public static fromJson(data: Record<string, any> | null): PictureModel | null {
    if (data) {
      const picture = new PictureModel();
      picture.path = data.path;
      picture.preview = data.preview;
      picture.color = data.color;
      picture.width = data.width;
      picture.height = data.height;
      picture.avatarId = data.avatarId;
      picture.rotation = data.rotation || 0;
      picture.serverId =
        data.serverId || PictureModel.generateServerID(`${picture.path || ''}`);
      return picture;
    }

    return null;
  }

  /**
   * Helper function to sanitize an array of data that comes from the Backend.
   * @param data The Raw JSON Array
   * @returns {Array<PictureModel>} An Array of Picture instances.
   */
  public static fromJsonArray(data: unknown | null): Array<PictureModel> {
    const pictures: Array<PictureModel> = new Array(0);

    // Ensure data is not null, and that is an array
    if ((data as any)?.length) {
      for (const pictureData of data as any) {
        const picture: PictureModel | null = PictureModel.fromJson(pictureData);
        if (picture) {
          pictures.push(picture);
        }
      }
    }

    return pictures;
  }
}
