import { ApiError } from '@app/shared/models/api/api-error.model';
import { ProductSearchFilterOptions } from './product-search.filter-options.model';
import { ProductSearchResults } from './product-search.results.model';
import { ProductVariant } from './product-variant.model';
import { Product } from './product.model';
import { LanguageOption, LocationOption } from '@app/akita/router/models/router.state';
import { Insurance } from './insurance.model';

export const DEFAULT_AVAILABLE_LANGUAGES = ['ar', 'en'];

export const DEFAULT_AVAILABLE_COUNTRIES = ['AE', 'SA', 'QA', 'OM', 'KW', 'BH'];

export const AVAILABLE_LOCATION_OPTION: Array<LocationOption> = [
  { display: 'Saudi Arabia', country: 'sa', domain: 'popsy.sa' },
  { display: 'United Arab Emirates', country: 'ae', domain: 'popsy.ae' },
  { display: 'Kuwait', country: 'kw', domain: 'popsy.com.kw' },
  { display: 'Qatar', country: 'qa' },
  { display: 'Oman', country: 'om' },
  { display: 'Bahrain', country: 'bh' },
];

export const AVAILABLE_LANGUAGE_OPTION: Array<LanguageOption> = [
  { display: 'English', country: 'us', locale: 'en', rtl: false },
  { display: 'عربي', country: 'sa', locale: 'ar', rtl: true },
];

export interface AkitaProductSearchState {
  pages: Array<ProductSearchResults>;
  loadingPage: Array<boolean>;
  errorLoadingPage: Array<ApiError | null>;
  hasNextPage: boolean;
}

export interface AkitaProductsState {
  products: {
    [lang: string]: {
      [country: string]: {
        [id: string]: Product | null;
      };
    };
  };

  fetchingProducts: {
    [lang: string]: {
      [country: string]: {
        [id: string]: boolean | null;
      };
    };
  };

  errorFetchingProducts: {
    [lang: string]: {
      [country: string]: {
        [id: string]: ApiError | null;
      };
    };
  };

  productVariants: {
    [lang: string]: {
      [country: string]: {
        [model: string]: Array<ProductVariant>;
      };
    };
  };

  fetchingProductVariants: {
    [lang: string]: {
      [country: string]: {
        [model: string]: boolean | null;
      };
    };
  };

  errorFetchingProductVariants: {
    [lang: string]: {
      [country: string]: {
        [model: string]: ApiError | null;
      };
    };
  };

  availableProductFilters: {
    [path: string]: ProductSearchFilterOptions | null;
  };

  fetchingAvailableProductFilters: {
    [path: string]: boolean | null;
  };

  errorFetchingAvailableProductFilters: {
    [path: string]: ApiError | null;
  };

  productSearch: {
    [path: string]: AkitaProductSearchState | null;
  };

  insurances: {
    [lang: string]: {
      [country: string]: {
        [id: string]: Insurance;
      };
    };
  };

  fetchingInsurance: {
    [lang: string]: {
      [country: string]: {
        [model: string]: boolean | null;
      };
    };
  };

  errorFetchingInsurances: {
    [lang: string]: {
      [country: string]: {
        [model: string]: ApiError | null;
      };
    };
  };

  productSearchHints: Array<string>;
  fetchingProductSearchHints: boolean;
  errorFetchingProductSearchHints: ApiError | null;
  fetchingInsurances: boolean;

  fetchingAvailableProductLocaleInfo: boolean;
  errorFetchingAvailableProductLocaleInfo: ApiError | null;
  availableProductCountries: Array<string>;
  availableProductLocales: Array<string>;
}

export const createInitialAkitaProductSearchState = (): AkitaProductSearchState => ({
  pages: new Array(0),
  loadingPage: new Array(0),
  errorLoadingPage: new Array(0),
  hasNextPage: false,
});

export const createInitialAkitaProductsState = (): AkitaProductsState => ({
  products: {},
  fetchingProducts: {},
  errorFetchingProducts: {},

  productVariants: {},
  fetchingProductVariants: {},
  errorFetchingProductVariants: {},

  availableProductFilters: {},
  fetchingAvailableProductFilters: {},
  errorFetchingAvailableProductFilters: {},

  productSearch: {},

  productSearchHints: [],

  insurances: {},
  fetchingInsurance: {},
  errorFetchingInsurances: {},

  fetchingProductSearchHints: false,
  errorFetchingProductSearchHints: null,
  fetchingInsurances: false,

  fetchingAvailableProductLocaleInfo: false,
  errorFetchingAvailableProductLocaleInfo: null,
  availableProductCountries: DEFAULT_AVAILABLE_COUNTRIES,
  availableProductLocales: DEFAULT_AVAILABLE_LANGUAGES,
});
