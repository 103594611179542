import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription, take, filter } from 'rxjs';
import { AkitaOverlaysQuery } from '@app/akita/overlays/state/overlays.query';
import { AkitaOverlaysService } from '@app/akita/overlays/state/overlays.service';

const OVERLAY_NAME = 'footer-overlay';

@Injectable({
  providedIn: 'root',
})
export class FooterOverlayDynamicService {
  private readonly currencyInboxSubject: Subject<any | null>;
  private readonly languageInboxSubject: Subject<any | null>;
  private readonly locationInboxSubject: Subject<any | null>;

  constructor(
    private readonly akitaOverlaysQuery: AkitaOverlaysQuery,
    private readonly akitaOverlaysService: AkitaOverlaysService
  ) {
    this.currencyInboxSubject = new Subject();
    this.languageInboxSubject = new Subject();
    this.locationInboxSubject = new Subject();
  }

  public open(dialogType: 'currency' | 'language' | 'location', data?: any | null): void {
    const hasError = this.akitaOverlaysQuery.overlayFailedToLoad(OVERLAY_NAME);
    const hasLoaded = this.akitaOverlaysQuery.getOverlayStatus(OVERLAY_NAME);
    const isLoading = this.akitaOverlaysQuery.isOverlayLoading(OVERLAY_NAME);

    if (hasError || (!hasLoaded && !isLoading)) {
      this.akitaOverlaysService.loadDialog(OVERLAY_NAME);
      this.openWhenReady(data);
    } else if (hasLoaded) {
      if (dialogType === 'currency') {
        this.currencyInboxSubject.next(data || {});
      } else if (dialogType === 'language') {
        this.languageInboxSubject.next(data || {});
      } else if (dialogType === 'location') {
        this.locationInboxSubject.next(data || {});
      }
    } else {
      this.openWhenReady(data);
    }
  }

  public openWhenReady(
    dialogType: 'currency' | 'language' | 'location',
    data?: any | null
  ): void {
    const loadSubscription = new Subscription();
    loadSubscription.add(
      this.akitaOverlaysQuery
        .selectOverlayLoadStatus(OVERLAY_NAME)
        .pipe(
          filter((loaded) => Boolean(loaded)),
          take(1)
        )
        .subscribe({
          next: () => {
            if (dialogType === 'currency') {
              this.currencyInboxSubject.next(data || {});
            } else if (dialogType === 'language') {
              this.languageInboxSubject.next(data || {});
            } else if (dialogType === 'location') {
              this.locationInboxSubject.next(data || {});
            }
            loadSubscription.unsubscribe();
          },
        })
    );
  }

  public closeCurrency(): void {
    this.currencyInboxSubject.next(null);
  }

  public get observeCurrency(): Observable<any | null> {
    return this.currencyInboxSubject.asObservable();
  }

  public closeLanguage(): void {
    this.languageInboxSubject.next(null);
  }

  public get observeLanguage(): Observable<any | null> {
    return this.languageInboxSubject.asObservable();
  }

  public closeLocation(): void {
    this.locationInboxSubject.next(null);
  }

  public get observeLocation(): Observable<any | null> {
    return this.locationInboxSubject.asObservable();
  }
}
