export const getWindowRef = (): Window | null => {
  try {
    return window;
  } catch (error) {
    return null;
  }
};

export class WindowUtils {
  public static get window(): Window | null {
    try {
      return window;
    } catch (error) {
      return null;
    }
  }

  public static getLocationHref(): string {
    if (WindowUtils.window) {
      return WindowUtils.window.location.href;
    }
    return '';
  }

  public static getLocationProtocol(): string {
    if (WindowUtils.window) {
      return WindowUtils.window.location.protocol;
    }
    return '';
  }

  public static setLocationHref(url?: string | null): void {
    if (WindowUtils.window && url) {
      WindowUtils.window.location.href = url;
    }
  }

  public static getLocationPathname(): string {
    if (WindowUtils.window) {
      return WindowUtils.window.location.pathname;
    }
    return '';
  }

  public static getLocationOrigin(): string {
    if (WindowUtils.window) {
      return WindowUtils.window.location.origin;
    }
    return '';
  }

  public static getLocationHostname(): string {
    if (WindowUtils.window) {
      return WindowUtils.window.location.hostname;
    }
    return '';
  }

  public static getLocationHost(): string {
    if (WindowUtils.window) {
      return WindowUtils.window.location.host;
    }
    return '';
  }

  public static windowInnerWidth(): number {
    if (WindowUtils.window) {
      return WindowUtils.window.innerWidth;
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return 1280;
  }

  public static windowInnerHeight(): number {
    if (WindowUtils.window) {
      return WindowUtils.window.innerHeight;
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return 1024;
  }

  public static querySelector(selector: string): Element | null {
    if (WindowUtils.window) {
      return window.document.querySelector(selector);
    }
    return null;
  }
}
