import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { WindowUtils } from '@app/shared/utils/window.util';
import { UserTiming } from '@app/shared/utils/performance.utils';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

const PERFORMANCE_MARK_NAME = 'Redirect Guard';
const PERFORMANCE_MARK_START = `${PERFORMANCE_MARK_NAME} - Start`;
const PERFORMANCE_MARK_END = `${PERFORMANCE_MARK_NAME} - End`;

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  private readonly isBrowser: boolean;

  constructor(private readonly akitaRouterQuery: AkitaRouterQuery) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;
  }

  public canActivate(): boolean {
    UserTiming.createMark(PERFORMANCE_MARK_START);
    if (this.isBrowser) {
      if (WindowUtils.getLocationPathname() === '/android') {
        WindowUtils.setLocationHref(
          'https://play.google.com/store/apps/details?id=com.mypopsy.android&hl=en'
        );
      } else if (WindowUtils.getLocationPathname() === '/ios') {
        WindowUtils.setLocationHref(
          'https://itunes.apple.com/app/apple-store/id1387389249&ct=web&mt=8'
        );
      }
    }

    UserTiming.createMark(PERFORMANCE_MARK_END);
    UserTiming.measure(
      PERFORMANCE_MARK_NAME,
      PERFORMANCE_MARK_START,
      PERFORMANCE_MARK_END
    );
    return true;
  }
}
