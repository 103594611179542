import { inject, Injectable } from '@angular/core';
import { Observable, throwError, of, map, switchMap, tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { UserModel } from '@app/shared/models/api/user.model';
import { Coordinate } from '@app/akita/api/location/models/coordinate.model';
import { DataCacheService } from '@app/shared/services/data-cache.service';
import { timeZoneFromIntl } from '@app/shared/utils/locale.utils';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { Params } from '@angular/router';
import { parseApiError } from '@app/shared/models/api/api-error.model';

const SKIP_GEOCODER_METADATA = true;

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class LocationAPIService {
  private readonly isBrowser: boolean;
  private readonly http = inject(HttpClient);

  constructor(
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly dataCacheService: DataCacheService
  ) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;
  }

  public updateLocation(
    accessToken: string,
    newLocation: Coordinate | null
  ): Observable<UserModel | null> {
    if (!accessToken) {
      return throwError(() => new Error(`USER_NOT_LOGGED_IN`));
    }

    if (
      !newLocation ||
      !newLocation.country ||
      newLocation.latitude === 0 ||
      newLocation.longitude === 0
    ) {
      return throwError(() => new Error(`INVALID_COORDINATE`));
    }

    const timeZoneInfo = timeZoneFromIntl();

    let headers: Params = {
      'Content-Type': 'application/json; charset=utf-8',
    };
    if (accessToken) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return this.http
      .put<any>(
        // Assemble the full API URL
        getApiEndpoint('users/me/location'),
        {
          location: newLocation.asApiJson,
          locale: timeZoneInfo.locale || undefined,
        },
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(map((results) => UserModel.fromJson(results)));
  }

  public findLocationByIp(
    latitude?: number | null,
    longitude?: number | null,
    country?: string | null,
    region?: string | null,
    locality?: string | null
  ): Observable<Coordinate | null> {
    if (!this.isBrowser) {
      return throwError(() => parseApiError('SERVER_RENDERING - findLocationByIp()'));
    }

    const timeZoneInfo = timeZoneFromIntl();

    let params = new HttpParams();
    if (latitude && longitude) {
      params = params.set('latitude', `${latitude}`);
      params = params.set('longitude', `${longitude}`);
    }

    if (!SKIP_GEOCODER_METADATA) {
      if (country) {
        params = params.set('country', `${country}`);
      }

      if (region) {
        params = params.set('region', `${region}`);
      }

      if (locality) {
        params = params.set('locality', `${locality}`);
      }

      if (timeZoneInfo?.locale) {
        params = params.set('locale', `${timeZoneInfo.locale}`);
      }
    }

    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
    const apiCall = this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`api/v2/geocoder`, true),
        // Add the Options
        {
          responseType: 'json',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
          params: params,
        }
      )
      .pipe(
        map((results: any) => {
          const coordinate = Coordinate.fromJson(results);
          if (coordinate) {
            coordinate.latitude = latitude || results.latitude || 0;
            coordinate.longitude = longitude || results.longitude || 0;
            coordinate.precission = 'IP';
            coordinate.address = Coordinate.buildShortAddress(
              coordinate.city,
              coordinate.country
            );
            coordinate.shortAddress = coordinate.address;
            coordinate.googleGeocoder = true;
            coordinate.updatedAt = new Date();
          }
          return coordinate;
        }),
        tap((coordinate: Coordinate | null) => {
          this.dataCacheService.saveCoordinate(coordinate, latitude, longitude);
        })
      );

    // If Lat / Lng are given, try to go for the cache first
    if (latitude && longitude) {
      return this.dataCacheService.readCoordinate(latitude, longitude).pipe(
        switchMap((cacheCoordinate: Coordinate | null) => {
          if (cacheCoordinate) {
            return of(cacheCoordinate);
          }

          return apiCall;
        })
      );
    } else {
      return apiCall;
    }
  }
}
