import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {
  AkitaOverlaysState,
  AkitaOverlayType,
  INITIAL_LOAD_STATUS,
} from '../models/overlay.state';
import { UserTiming } from '@app/shared/utils/performance.utils';

const OVERLAY_PERFORMANCE_MARK_NAME = 'Preload All Overlays';
const OVERLAY_PERFORMANCE_MARK_START = `${OVERLAY_PERFORMANCE_MARK_NAME} - Start`;
const OVERLAY_PERFORMANCE_MARK_END = `${OVERLAY_PERFORMANCE_MARK_NAME} - End`;

export function createInitialState(): AkitaOverlaysState {
  return {
    loaded: { ...INITIAL_LOAD_STATUS },
    loading: { ...INITIAL_LOAD_STATUS },
    error: { ...INITIAL_LOAD_STATUS },
    preloadedAll: false,
    preloadStarted: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'overlays', resettable: false })
export class AkitaOverlaysStore extends Store<AkitaOverlaysState> {
  constructor() {
    super(createInitialState());
  }

  public toggleLoaded(name: AkitaOverlayType, hasLoaded?: boolean | null): void {
    this.update((state: AkitaOverlaysState) => {
      const loaded = { ...(state.loaded || INITIAL_LOAD_STATUS) };
      loaded[name] = Boolean(hasLoaded);

      let allLoaded = true;
      for (const key of Object.keys(loaded || {}) as Array<AkitaOverlayType>) {
        if (!loaded[key]) {
          allLoaded = false;
          break;
        }
      }

      if (allLoaded && !state.preloadedAll) {
        UserTiming.createMark(OVERLAY_PERFORMANCE_MARK_END);
        UserTiming.measure(
          OVERLAY_PERFORMANCE_MARK_NAME,
          OVERLAY_PERFORMANCE_MARK_START,
          OVERLAY_PERFORMANCE_MARK_END
        );
      }

      return {
        loaded: loaded,
        preloadedAll: allLoaded,
      };
    });
  }

  public toggleLoading(name: AkitaOverlayType, isLoading?: boolean | null): void {
    this.update((state: AkitaOverlaysState) => {
      const loading = { ...(state.loading || INITIAL_LOAD_STATUS) };
      loading[name] = Boolean(isLoading);

      if (!state.preloadStarted) {
        UserTiming.createMark(OVERLAY_PERFORMANCE_MARK_START);
      }

      return {
        loading: loading,
        preloadStarted: true,
      };
    });
  }

  public toggleError(name: AkitaOverlayType, failed?: boolean | null): void {
    this.update((state: AkitaOverlaysState) => {
      const error = { ...(state.error || INITIAL_LOAD_STATUS) };
      error[name] = Boolean(failed);

      return {
        error: error,
      };
    });
  }
}
