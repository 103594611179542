import { Injectable, NgZone } from '@angular/core';
import { applyTransaction, logAction } from '@datorama/akita';
import { AkitaRouterStore } from '@app/akita/router/state/router.store';
import { Params, Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UserModel } from '@app/shared/models/api/user.model';
import { AkitaRouterQuery } from './router.query';
import { DiscountCodeApiService } from '../service/discount-code-api.service';
import { catchError, Observable, of, Subscription, tap, throwError } from 'rxjs';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { DiscountInfo } from '../models/discount-info.model';
import { TranslocoService } from '@ngneat/transloco';
import { isLanguageRtl } from '@app/shared/utils/url.utils';

@Injectable({ providedIn: 'root' })
export class AkitaRouterService {
  constructor(
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: AkitaRouterStore,
    private readonly query: AkitaRouterQuery,
    private readonly discountCodeApi: DiscountCodeApiService,
    private readonly translateService: TranslocoService
  ) {}

  public replayOnNavigationEnd(): void {
    let route = this.activatedRoute.snapshot;
    while (route.firstChild) {
      route = route.firstChild;
    }

    this.onNavigationEnd(
      route.params,
      route.queryParams,
      {
        ...(route.data || {}),
        rtl: isLanguageRtl(this.translateService.getActiveLang()),
      },
      route.fragment
    );
  }

  public addParamToKeepQueryParams(params?: Params | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('addParamToKeepQueryParams()');
        this.store.addParamToKeepQueryParams(params);
      });
    });
  }

  public removeParamToKeepQueryParams(param: string): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('removeParamToKeepQueryParams()');
        this.store.removeParamToKeepQueryParams(param);
      });
    });
  }

  public onNavigationEnd(
    params?: Params | null,
    queryParams?: Params | null,
    routeData?: Params | null,
    fragment?: string | null
  ): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('onNavigationEnd()');
        this.store.onNavigationEnd(
          params,
          queryParams,
          {
            ...(routeData || {}),
            rtl: isLanguageRtl(this.translateService.getActiveLang()),
          },
          fragment
        );
      });
    });
  }

  public navigate(
    fragment: Array<string>,
    params?: Params,
    config?: NavigationExtras | null
  ): void {
    this.zone.run(() => {
      this.router
        .navigate(fragment, {
          ...(config || {}),
          queryParams: {
            ...this.query.queryParamsToKeep,
            ...(params || {}),
          },
        })
        .catch((error: unknown) => {
          console.warn(error);
        });
    });
  }

  public updateSessionUser(user: UserModel | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateSessionUser()');
        this.store.updateSessionUser(user);
      });
    });
  }

  public setDiscountCode(discountCode: string): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setDiscountCode()');
        this.store.setDiscountCode(discountCode);
      });
    });
  }

  public getDiscountPriceModel(currency?: string | null): Observable<DiscountInfo> {
    const discountCode = this.query.discountCode;
    if (!discountCode) {
      return of(new DiscountInfo());
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getDiscountPriceModel()');
        this.store.toggleDiscountLoading(true);
        this.store.setDiscountLoadingError(null);
      });
    });

    return this.discountCodeApi.getDiscountFromEstimation(currency, discountCode).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getDiscountPriceModel() - error');
            this.store.toggleDiscountLoading(false);
            this.store.setDiscountLoadingError(parsed);
          });
        });

        return throwError(() => parsed);
      }),
      tap((discountInfo: DiscountInfo) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getDiscountPriceModel() - done');
            this.store.toggleDiscountLoading(false);
            if (discountInfo && discountInfo?.amount && discountInfo?.currency) {
              this.store.setDiscountForListing(
                discountInfo?.code || discountCode,
                currency,
                {
                  amount: discountInfo.amount,
                  currency: discountInfo.currency,
                }
              );
            } else {
              this.store.setDiscountForListing(discountCode, currency, null);
            }
          });
        });
      })
    );
  }

  public getDiscountPriceModelAsync(currency?: string | null): Subscription {
    return this.getDiscountPriceModel(currency).subscribe({
      next: () => {},
      error: () => {},
    });
  }
}
