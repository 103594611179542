import { Injectable } from '@angular/core';

import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';

import { UserModel } from '@app/shared/models/api/user.model';
import { AkitaUsersService } from '@app/akita/api/users/state/users.service';
import { decode, BASE_16 } from '@app/shared/utils/url.utils';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { Subscription, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProfileResolver implements Resolve<string | null> {
  private readonly isBrowser: boolean;

  private readonly subscriptions: Subscription;

  constructor(
    private readonly router: Router,
    private readonly akitaUsersService: AkitaUsersService,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;
    this.subscriptions = new Subscription();
  }

  private static parseUserId(rawId: string): string {
    // Parse the ID from the Semantic URL
    const idSlices = (rawId || '').split('-');
    let id = '';
    if (idSlices.length <= 1) {
      if (idSlices[0]) {
        id = idSlices[0];
      }
    } else {
      id = decode(idSlices[idSlices.length - 1], BASE_16);
    }
    return id;
  }

  public async resolve(route: ActivatedRouteSnapshot): Promise<string | null> {
    const id = UserProfileResolver.parseUserId(route.params.id || route.queryParams.id);
    if (!this.isBrowser) {
      const output: any = await this.fetchUserSync(id);
      route.data = {
        ...route.data,
        user: output,
      };
      if (route.firstChild) {
        route.firstChild.data = {
          ...route.firstChild.data,
          user: output,
        };
      }
    }

    return id;
  }

  public async fetchUserSync(id: string): Promise<UserModel | null> {
    let data: UserModel | null = null;
    try {
      data = await new Promise(
        (
          resolve: (value: UserModel | null) => void,
          reject: (reason?: unknown) => void
        ) => {
          this.subscriptions.add(
            this.akitaUsersService.getUser(id).pipe(take(1)).subscribe({
              next: resolve,
              error: reject,
            })
          );
        }
      );
    } catch {
      // do not complete the guard after a redirection
      this.router.navigate(['not-found']).catch((error) => {
        console.log(error);
      });
    }

    return data || null;
  }
}
