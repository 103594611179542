import { ApiError } from '@app/shared/models/api/api-error.model';
import { StartingPriceModel } from '@app/shared/models/api/starting-price.model';

export interface AkitaStartingPricesState {
  startingPrices: {
    [country: string]: Array<StartingPriceModel>;
  };

  errorFetchingStartingPrices: ApiError | null;

  fetchingStartingPrices: boolean;
}

export function createInitialStartingPricesState(): AkitaStartingPricesState {
  return {
    startingPrices: {},
    errorFetchingStartingPrices: null,
    fetchingStartingPrices: false,
  };
}
