import { Component, HostBinding, Input, ChangeDetectionStrategy } from '@angular/core';
import { WithContext } from 'schema-dts';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-json-ld',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NgxJsonLdComponent {
  @HostBinding('innerHTML') public jsonLD?: SafeHtml | null;

  @Input() public formatted?: boolean | null;

  @Input()
  public set json(currentValue: WithContext<any> | null) {
    if (currentValue) {
      this.jsonLD = this.getSafeHTML(currentValue);
    } else {
      this.jsonLD = null;
    }
  }

  constructor(private readonly sanitizer: DomSanitizer) {}

  public getSafeHTML(value: Record<string, unknown>): SafeHtml {
    let json: string;

    if (this.formatted) {
      json = JSON.stringify(value, null, '  ');
    } else {
      json = JSON.stringify(value);
    }

    if (json) {
      json = json.replace(/<\/script>/g, '<\\/script>');
    }

    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
