export class FAQuestion {
  public question: string;
  public answer: string;
  public openTab?: boolean | null;

  constructor() {
    this.question = '';
    this.answer = '';
  }
}

export class ProductMetadata {
  public brand: string;
  public model: string;
  public height: string;
  public width: string;
  public depth: string;
  public weight: string;
  public externalMemory: string;
  public storageCapacity: string;
  public screenSize: string;
  public screenResolution: string;
  public pixelDensity: string;
  public hdr: string;
  public cameraResolution: string;
  public flash: string;
  public opticalZoom: string;
  public maxVideoResolution: string;
  public videoCall: string;
  public opticalImageStabilization: string;
  public frontCamera: string;
  public lidarScanner: string;
  public processor: string;
  public ram: string;
  public simCardFormat: string;
  public connector: string;
  public headphoneJack: string;
  public wifi: string;
  public bluetooth: string;
  public lte4G: string;
  public supports5G: string;
  public accelerometer: string;
  public gyroscope: string;
  public barometer: string;
  public ambientLightSensor: string;
  public proximitySensor: string;
  public fingerprintSensor: string;
  public nfc: string;
  public wirelessPayment: string;
  public voiceRecognition: string;
  public faceRecognition: string;
  public waterproof: string;
  public color: string;
  public releaseDate: string;
  public wirelessCharging: string;
  public batteryCapacity: string;
  public modelNumbers: string;
  public modelNumber: string;
  public shortText: string;
  public faq: Array<FAQuestion>;

  constructor() {
    this.brand = '';
    this.model = '';
    this.height = '';
    this.width = '';
    this.depth = '';
    this.weight = '';
    this.externalMemory = '';
    this.storageCapacity = '';
    this.screenSize = '';
    this.screenResolution = '';
    this.pixelDensity = '';
    this.hdr = '';
    this.cameraResolution = '';
    this.flash = '';
    this.opticalZoom = '';
    this.maxVideoResolution = '';
    this.videoCall = '';
    this.opticalImageStabilization = '';
    this.frontCamera = '';
    this.lidarScanner = '';
    this.processor = '';
    this.ram = '';
    this.simCardFormat = '';
    this.connector = '';
    this.headphoneJack = '';
    this.wifi = '';
    this.bluetooth = '';
    this.lte4G = '';
    this.supports5G = '';
    this.accelerometer = '';
    this.gyroscope = '';
    this.barometer = '';
    this.ambientLightSensor = '';
    this.proximitySensor = '';
    this.fingerprintSensor = '';
    this.nfc = '';
    this.wirelessPayment = '';
    this.voiceRecognition = '';
    this.faceRecognition = '';
    this.waterproof = '';
    this.color = '';
    this.releaseDate = '';
    this.wirelessCharging = '';
    this.batteryCapacity = '';
    this.modelNumbers = '';
    this.modelNumber = '';
    this.shortText = '';
    this.faq = new Array(0);
  }
}

export const parseProductMetadata = (
  data?: Record<string, any> | null
): ProductMetadata => {
  const metadata = new ProductMetadata();
  if (data) {
    metadata.brand = data.brand || '';
    metadata.model = data.model || '';
    metadata.height = data.height || '';
    metadata.width = data.width || '';
    metadata.depth = data.depth || '';
    metadata.weight = data.weight || '';
    metadata.externalMemory = data.external_memory || data.externalMemory || '';
    metadata.storageCapacity = data.storage_capacity || data.storageCapacity || '';
    metadata.screenSize = data.screen_size || data.screenSize || '';
    metadata.screenResolution = data.screen_resolution || data.screenResolution || '';
    metadata.pixelDensity = data.pixel_density || data.pixelDensity || '';
    metadata.hdr = data.hdr || '';
    metadata.cameraResolution = data.cameraResolution || data.camera_resolution || '';
    metadata.flash = data.flash || '';
    metadata.opticalZoom = data.optical_zoom || data.opticalZoom || '';
    metadata.maxVideoResolution =
      data.max_video_resolution || data.maxVideoResolution || '';
    metadata.videoCall = data.video_call || data.videoCall || '';
    metadata.opticalImageStabilization =
      data.optical_image_stabilization || data.opticalImageStabilization || '';
    metadata.frontCamera = data.front_camera || data.frontCamera || '';
    metadata.lidarScanner = data.lidar_scanner || data.lidarScanner || '';
    metadata.processor = data.processor || '';
    metadata.ram = data.ram || '';
    metadata.simCardFormat = data.sim_card_format || data.simCardFormat || '';
    metadata.connector = data.connector || '';
    metadata.headphoneJack = data.headphone_jack || data.headphoneJack || '';
    metadata.wifi = data.wifi || '';
    metadata.bluetooth = data.bluetooth || '';
    metadata.lte4G = data['4g_lte'] || data.lte4G || '';
    metadata.supports5G = data['5g'] || data.supports5G || '';
    metadata.accelerometer = data.accelerometer || '';
    metadata.gyroscope = data.gyroscope || '';
    metadata.barometer = data.barometer || '';
    metadata.ambientLightSensor =
      data.ambient_light_sensor || data.ambientLightSensor || '';
    metadata.proximitySensor = data.proximity_sensor || data.proximitySensor || '';
    metadata.fingerprintSensor = data.fingerprint_sensor || data.fingerprintSensor || '';
    metadata.nfc = data.nfc || '';
    metadata.wirelessPayment = data.wireless_payment || data.wirelessPayment || '';
    metadata.voiceRecognition = data.voice_recognition || data.voiceRecognition || '';
    metadata.faceRecognition = data.face_recognition || data.faceRecognition || '';
    metadata.waterproof = data.waterproof || '';
    metadata.color = data.color || '';
    metadata.releaseDate = data.releaseDate || '';
    metadata.wirelessCharging = data.wireless_charging || data.wirelessCharging || '';
    metadata.batteryCapacity = data.battery_capacity || data.batteryCapacity || '';
    metadata.modelNumbers = data.model_numbers || data.modelNumbers || '';
    metadata.modelNumber = data.model_number || data.modelNumber || '';
    metadata.shortText = data.short_text || data.shortText || '';

    if (!data.faq) {
      metadata.faq = parseFAQ(data);
    } else {
      metadata.faq = data.faq;
    }
  }

  return metadata;
};

export const parseFAQ = (data?: Record<string, any> | null): Array<FAQuestion> => {
  const faqs: Array<FAQuestion> = new Array(0);

  if (data) {
    for (const key of Object.keys(data || [])) {
      if (key.includes('question_')) {
        const faq = new FAQuestion();
        faq.question = data[key] || '';
        faq.answer = data[`answer_${key.split('_')[1]}`] || '';
        faq.openTab = data[`open_${key.split('_')[1]}`] || false;
        faqs.push(faq);
      }
    }
  }

  return faqs;
};
