import { Model } from '../model.interface';
import { Coordinate } from '@app/akita/api/location/models/coordinate.model';

const DEFAULT_RADIUS = 1000;

/**
 * Class used to represent a Position Object
 */
export class PositionModel implements Model {
  public country: string;
  public address: string;
  public city: string;
  public radius: number;
  public longitude: number;
  public latitude: number;
  public zipCode: string;
  public shortAddress: string;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.country = 'US';
    this.address = '';
    this.city = '';
    this.radius = DEFAULT_RADIUS;
    this.longitude = 0;
    this.latitude = 0;
    this.zipCode = '';
    this.shortAddress = '';
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   *
   * @param data The Raw JSON Object
   * @returns {PositionModel} An instance of the Position class.
   */
  public static fromJson(data?: Record<string, any> | null): PositionModel | null {
    if (data) {
      const position = new PositionModel();
      position.country = `${data.country || 'US'}`.toUpperCase();
      position.address = data.address || '';
      position.city = data.city || data.locality || '';
      position.radius = data.radius || 0;
      position.latitude = data.latitude || 0;
      position.longitude = data.longitude || 0;
      position.zipCode = data.zipcode || data.zipCode || '';
      position.shortAddress =
        Coordinate.buildShortAddress(position.city, position.country, position.zipCode) ||
        data.short_address ||
        data.shortAddress ||
        data.address ||
        '';
      return position;
    }

    return null;
  }

  /**
   * Helper function to sanitize an array of data that comes from the Backend.
   * @param data The Raw JSON Array
   * @returns {Array<PositionModel>} An Array of Position instances.
   */
  public static fromJsonArray(
    data?: Array<Record<string, any>> | null
  ): Array<PositionModel> {
    const positions: Array<PositionModel> = new Array(0);

    // Ensure data is not null, and that is an array
    if (data && data.length > 0) {
      for (const positionData of data) {
        const position: PositionModel | null = PositionModel.fromJson(positionData);
        if (position) {
          positions.push(position);
        }
      }
    }

    return positions;
  }
}
