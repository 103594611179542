export interface ApiError extends Error {
  code: string;
  message: string;
}

export const parseApiError = (error?: any | null): ApiError => {
  const DEFAULT: ApiError = {
    name: '',
    code: '',
    message: '',
  };

  let parsedError = DEFAULT;
  if (error) {
    if (typeof error === 'string') {
      parsedError = {
        name: error,
        code: error,
        message: '',
      };
    } else if (error?.error && typeof error.error === 'string') {
      parsedError = {
        name: error.error,
        code: error.error,
        message: `${error.message || ''}`,
      };
    } else if (error?.error && error.error.length > 0) {
      parsedError = {
        name: `${error.error[0].error || ''}`,
        code: `${error.error[0].error || ''}`,
        message: `${error.error[0].message || ''}`,
      };
    } else if (error?.error?.error && typeof error.error.error === 'string') {
      parsedError = {
        name: error.error.error,
        code: error.error.error,
        message: `${error.error.message || ''}`,
      };
    } else if (error?.error?.message && typeof error.error.message === 'string') {
      parsedError = {
        name: `${error.error.message || ''}`,
        code: `${error.error.message || ''}`,
        message: `${error.error.message || ''}`,
      };
    } else if (error.code) {
      parsedError = {
        name: error.code,
        code: error.code,
        message: `${error.message || ''}`,
      };
    } else if (error.name) {
      parsedError = {
        name: error.name,
        code: error.name,
        message: `${error.message || ''}`,
      };
    } else if (error?.message) {
      parsedError = {
        name: `${error.message || ''}`,
        code: `${error.message || ''}`,
        message: `${error.message || ''}`,
      };
    }
  }

  return parsedError;
};
