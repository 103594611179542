import { Observable, EMPTY, Observer, Subscription, catchError } from 'rxjs';
import { Coordinate } from '@app/akita/api/location/models/coordinate.model';
import { LocationAPIService } from '@app/akita/api/location/services/location.service';
import { GoogleMapsUtils } from '@app/shared/utils/google-maps.utils';

/**
 * Class used for some helper tools related to location
 */
export class LocationUtils {
  public static readonly PI = Math.PI; // Math.PI / 180
  public static readonly MILES_IN_DEGREE = 60; // there are 60 nautical miles to one degree
  public static readonly STATUTE_MILES = 1.1515; // number of statute miles in a nautical mile
  public static readonly METERS_IN_KM = 1000; // number of statute miles in a nautical mile
  public static readonly EARTH_RADIUS_BY_2 = 12742; // The Average Radius of the earth in km (6,371km) by 2

  public static deg2rad(degrees: number): number {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return degrees * (LocationUtils.PI / 180);
  }

  public static rad2deg(radians: number): number {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return radians * (180 / LocationUtils.PI);
  }

  /**
   * Helper function that gives the distance in miles from two coordinates
   *
   *          + C
   *         /|
   *        / |
   *     a /  | b
   *      /   |
   *     /    |
   *    /     |
   * B +------+ A
   *     c
   *
   * cos a = cos Δλ . cos Δφ + sin Δλ . sin Δφ . cos 90°
   * a = arccos (cos Δλ . cos Δφ)
   *
   * @param {number} lat1 Latitude of the first coordinate
   * @param {number} lat2 Latitude of the second coordinate
   * @param {number} long1 Longitude of the first coordinate
   * @param {number} long2 Longitude of the second coordinate
   * @returns {number} The distance in miles
   */
  public static distanceInMiles(
    lat1: number,
    lat2: number,
    long1: number,
    long2: number
  ): number {
    const theta = long1 - long2;
    let distance =
      Math.sin(LocationUtils.deg2rad(lat1)) * Math.sin(LocationUtils.deg2rad(lat2)) +
      Math.cos(LocationUtils.deg2rad(lat1)) *
        Math.cos(LocationUtils.deg2rad(lat2)) *
        Math.cos(LocationUtils.deg2rad(theta));
    distance = Math.acos(distance);
    distance = LocationUtils.rad2deg(distance);
    return distance * LocationUtils.MILES_IN_DEGREE * LocationUtils.STATUTE_MILES;
  }

  /**
   * Helper function that gives the distance in KM from two coordinates
   * @param {number} lat1 Latitude of the first coordinate
   * @param {number} lat2 Latitude of the second coordinate
   * @param {number} long1 Longitude of the first coordinate
   * @param {number} long2 Longitude of the second coordinate
   * @returns {number} The distance in KM
   */
  public static distanceInKilometers(
    lat1: number,
    lat2: number,
    long1: number,
    long2: number
  ): number {
    const radLat1 = LocationUtils.deg2rad(lat1);
    const radLat2 = LocationUtils.deg2rad(lat2);
    const radLong1 = LocationUtils.deg2rad(long1);
    const radLong2 = LocationUtils.deg2rad(long2);

    /* eslint-disable @typescript-eslint/no-magic-numbers */

    const a =
      (1 -
        Math.cos(radLat2 - radLat1) +
        (1 - Math.cos(radLong2 - radLong1)) * Math.cos(radLat1) * Math.cos(radLat2)) *
      0.5;

    /* eslint-enable @typescript-eslint/no-magic-numbers */

    return LocationUtils.EARTH_RADIUS_BY_2 * Math.asin(Math.sqrt(a));
  }

  /**
   * This routine calculates the distance between two points (given the
   * latitude/longitude of those points).
   *
   * @param {number} lat1 The latitude value of point 1.
   * @param {number} long1 The longitude value of point 1.
   * @param {number} lat2 The latitude value of point 2.
   * @param {number} long2 The longitude value of point 2.
   * @param {string} countryCode The country code to decide the unit of measure.
   * @return string The distance string
   */
  public static distance(
    lat1: number,
    lat2: number,
    long1: number,
    long2: number,
    countryCode?: string
  ): string {
    countryCode = (countryCode || '').toUpperCase();

    let imperial = false;
    if (countryCode === 'US' || countryCode === 'LR' || countryCode === 'MM') {
      imperial = true;
    }

    let distanceValue = 0;
    let distanceUnit = 'm';
    if (imperial) {
      const miles = LocationUtils.distanceInMiles(lat1, lat2, long1, long2);
      distanceUnit = 'mi';
      if (miles < 1) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        distanceValue = Number(miles.toFixed(2));
      } else {
        distanceValue = Math.round(miles);
      }
    } else {
      const kilometers = LocationUtils.distanceInKilometers(lat1, lat2, long1, long2);
      if (kilometers > 1) {
        distanceUnit = 'km';
        distanceValue = Math.round(kilometers);
      } else if (distanceValue < 1) {
        distanceUnit = 'm';
        distanceValue = Math.round(kilometers * LocationUtils.METERS_IN_KM);
      }
    }
    return `${distanceValue} ${distanceUnit}`;
  }

  /**
   * Checks if the Geolocation Permission has been granted.
   * @returns {boolean | null} true / false if the browser supports this feature, null if not supported
   */
  public static get hasLocationPermission(): Observable<boolean | null> {
    return new Observable((observer: Observer<boolean | null>) => {
      try {
        if (window && window.navigator && window.navigator.permissions) {
          navigator.permissions
            .query({ name: 'geolocation' })
            .then((permission?: PermissionStatus | null) => {
              observer.next(Boolean(permission?.state === 'granted'));

              if (permission && permission.addEventListener) {
                permission.addEventListener('change', () => {
                  observer.next(Boolean(permission?.state === 'granted'));
                });
              } else {
                observer.complete();
              }
            })
            .catch(() => {
              observer.next(null);
              observer.complete();
            });
        } else {
          observer.next(null);
          observer.complete();
        }
      } catch {
        observer.next(null);
        observer.complete();
      }
    });
  }

  /**
   * Function that wraps the browser GeoLocation API in a Observable Stream
   * @returns {Observable<Coordinate | null>} A Observable for the location
   */
  public static findCurrentLocation(
    locationAPIService: LocationAPIService,
    hasPermission = true,
    safeToAsk = true,
    locale: string,
    country?: string | null,
    region?: string | null,
    locality?: string | null
  ): Observable<Coordinate | null> {
    if (hasPermission || safeToAsk) {
      return LocationUtils.locationFromBrowser(
        locationAPIService,
        locale,
        country,
        region,
        locality
      );
    } else {
      return LocationUtils.locationFromIPOnly(
        locationAPIService,
        country,
        region,
        locality
      );
    }
  }

  public static locationFromIPOnly(
    locationAPIService: LocationAPIService,
    country?: string | null,
    region?: string | null,
    locality?: string | null
  ): Observable<Coordinate | null> {
    return locationAPIService.findLocationByIp(
      undefined,
      undefined,
      country,
      region,
      locality
    );
  }

  public static locationFromBrowserOnly(locale: string): Observable<Coordinate> {
    return new Observable((observer: Observer<Coordinate>) => {
      try {
        if (window && window.navigator && window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const coordinate = Coordinate.fromJson(position.coords) || new Coordinate();
              coordinate.precission = 'GPS';
              coordinate.updatedAt = new Date();

              // Complement the information of the browser coordinate using the GeoCoder
              GoogleMapsUtils.geoCodeCoordinateAddress(coordinate, locale)
                .then((ipCoordinate: Coordinate | null) => {
                  if (ipCoordinate) {
                    coordinate.city = ipCoordinate.city;
                    coordinate.country = ipCoordinate.country;
                    coordinate.region = ipCoordinate.region;
                    coordinate.address = ipCoordinate.address;
                    coordinate.shortAddress = ipCoordinate.shortAddress;
                    coordinate.updatedAt = new Date();
                  }
                  observer.next(coordinate);
                  observer.complete();
                })
                .catch((error: unknown) => {
                  coordinate.withError = error;
                  observer.next(coordinate);
                  observer.complete();
                });
            },
            (error: GeolocationPositionError) => {
              observer.error(LocationUtils.locationErrorName(error));
              observer.complete();
            }
          );
        } else {
          observer.error('SERVER_RENDERING - locationFromBrowserOnly( window )');
          observer.complete();
        }
      } catch (error) {
        observer.error('SERVER_RENDERING - locationFromBrowserOnly( exception )');
        observer.complete();
      }
    });
  }

  public static locationFromBrowser(
    locationAPIService: LocationAPIService,
    locale: string,
    country?: string | null,
    region?: string | null,
    locality?: string | null
  ): Observable<Coordinate> {
    const subscription = new Subscription();
    return new Observable((observer) => {
      try {
        if (window?.navigator?.geolocation) {
          window.navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const coordinate = Coordinate.fromJson(position.coords) || new Coordinate();
              coordinate.precission = 'GPS';
              coordinate.updatedAt = new Date();

              // Complement the information of the browser coordinate using the GeoCoder
              GoogleMapsUtils.geoCodeCoordinateAddress(coordinate, locale)
                .then((ipCoordinate: Coordinate | null) => {
                  if (ipCoordinate) {
                    coordinate.city = ipCoordinate.city;
                    coordinate.country = ipCoordinate.country;
                    coordinate.region = ipCoordinate.region;
                    coordinate.address = ipCoordinate.address;
                    coordinate.shortAddress = ipCoordinate.shortAddress;
                    coordinate.updatedAt = new Date();
                  }

                  observer.next(coordinate);
                  observer.complete();

                  if (subscription) {
                    subscription.unsubscribe();
                  }
                })
                .catch((error: unknown) => {
                  coordinate.withError = error;

                  observer.next(coordinate);
                  observer.complete();

                  if (subscription) {
                    subscription.unsubscribe();
                  }
                });
            },
            (error: GeolocationPositionError) => {
              subscription.add(
                locationAPIService
                  .findLocationByIp(undefined, undefined, country, region, locality)
                  .pipe(
                    catchError(() => {
                      observer.error(LocationUtils.locationErrorName(error));

                      if (subscription) {
                        subscription.unsubscribe();
                      }
                      return EMPTY;
                    })
                  )
                  .subscribe({
                    next: (coordinate: Coordinate | null) => {
                      if (coordinate) {
                        observer.next(coordinate);
                        observer.complete();

                        if (subscription) {
                          subscription.unsubscribe();
                        }
                      } else {
                        observer.error('UNKNOWN');

                        if (subscription) {
                          subscription.unsubscribe();
                        }
                      }
                    },
                    error: (err: unknown) => {
                      observer.error(err);

                      if (subscription) {
                        subscription.unsubscribe();
                      }
                    },
                  })
              );
            }
          );
        } else {
          subscription.add(
            locationAPIService
              .findLocationByIp(undefined, undefined, country, region, locality)
              .subscribe({
                next: (coordinate: Coordinate | null) => {
                  if (coordinate) {
                    observer.next(coordinate);

                    if (subscription) {
                      subscription.unsubscribe();
                    }
                  } else {
                    observer.error('UNKNOWN');

                    if (subscription) {
                      subscription.unsubscribe();
                    }
                  }
                },
                error: () => {
                  observer.error('SERVER_RENDERING - locationFromBrowser( window )');

                  if (subscription) {
                    subscription.unsubscribe();
                  }
                },
              })
          );
        }
      } catch (error) {
        // Accessing window will throw error when using in Server Side
        subscription.add(
          locationAPIService
            .findLocationByIp(undefined, undefined, country, region, locality)
            .subscribe({
              next: (coordinate: Coordinate | null) => {
                if (coordinate) {
                  observer.next(coordinate);
                  observer.complete();

                  if (subscription) {
                    subscription.unsubscribe();
                  }
                } else {
                  observer.error('UNKNOWN');

                  if (subscription) {
                    subscription.unsubscribe();
                  }
                }
              },
              error: () => {
                observer.error('SERVER_RENDERING - locationFromBrowser( exception )');

                if (subscription) {
                  subscription.unsubscribe();
                }
              },
            })
        );
      }
    });
  }

  /**
   * Turn the error code of `PositionError` into a human readable string
   * @param {PositionError} error the location error
   * @returns {string} the human readable string
   */
  public static locationErrorName(
    error: GeolocationPositionError
  ): 'PERMISSION_DENIED' | 'POSITION_UNAVAILABLE' | 'TIMEOUT' | 'UNKNOWN' {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return 'PERMISSION_DENIED';
      case error.POSITION_UNAVAILABLE:
        return 'POSITION_UNAVAILABLE';
      case error.TIMEOUT:
        return 'TIMEOUT';
      default:
        return 'UNKNOWN';
    }
  }

  /**
   * Generates a short string of the address from a google maps Address Component array
   * example: {postal-code}, {city}, {country} --> DD2 4AQ, Dundee, United Kingdom
   * - If a component of the address is missing, it won't be added to the string.
   * @param {google.maps.GeocoderAddressComponent[]} addressComponents
   * @returns {any}
   * @deprecated Use `GoogleMapsUtils.formatGMapsAddress` instead
   */
  public static formatGMapsAddress(
    addressComponents: google.maps.GeocoderAddressComponent[]
  ): {
    shortAddress: string;
    countryName: string;
    countryCode: string;
    city: string;
    region: string;
    area: string;
    regionCode: string;
    postalCode: string;
    streetNumber: string;
    streetName: string;
  } {
    return GoogleMapsUtils.formatGMapsAddress(addressComponents);
  }
}
