import { PriceModel } from '@app/shared/models/api/price.model';

export class CardOnlyDiscountCodes {
  public country: string;
  public discountCode: string;
  public value: PriceModel;

  constructor() {
    this.country = '';
    this.discountCode = '';
    this.value = new PriceModel();
  }
}

export const parseCardOnlyDiscountCodesFromJsonArrayString = (
  text?: string | null
): Array<CardOnlyDiscountCodes> => {
  let out = new Array(0);
  try {
    out = parseCardOnlyDiscountCodesFromJsonArray(JSON.parse(`${text || ''}`));
  } catch (error) {}
  return out;
};

export const parseCardOnlyDiscountCodesFromJson = (
  data?: any | null
): CardOnlyDiscountCodes | null => {
  let out = null;
  if (data) {
    out = new CardOnlyDiscountCodes();
    out.country = data.country || '';
    out.discountCode = data.discount_code || data.discountCode || '';
    out.value = PriceModel.fromJson(data.value) || new PriceModel();
  }
  return out;
};

export const parseCardOnlyDiscountCodesFromJsonArray = (
  data?: any | null
): Array<CardOnlyDiscountCodes> => {
  const out = new Array(0);
  if (data) {
    for (const item of data) {
      const parsed = parseCardOnlyDiscountCodesFromJson(item);
      if (parsed) {
        out.push(parsed);
      }
    }
  }
  return out;
};
