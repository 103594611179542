import { Injectable, InjectionToken, Optional, Inject, Type } from '@angular/core';
import {
  ModulesConfig,
  FunctionReturningPromise,
  ModuleConfig,
  ExtraOptions,
  LoadableRootOptions,
} from './loadable.config';
import { Observable, Observer, Subscription } from 'rxjs';
import { environment } from '@environments/environment';

export const LOADABLE_CONFIG = new InjectionToken<LoadableService>('LOADABLE_CONFIG');

export const LOADABLE_ROOT_OPTIONS = new InjectionToken<ExtraOptions>(
  'LOADABLE_ROOT_OPTIONS'
);

@Injectable({
  providedIn: 'root',
})
export class LoadableService {
  public modules: ModulesConfig = [];

  constructor(
    @Optional()
    @Inject(LOADABLE_ROOT_OPTIONS)
    private readonly options: LoadableRootOptions
  ) {}

  public addConfig(config: ModulesConfig): Subscription {
    const subscription = new Subscription();
    if (config) {
      this.modules = [...this.modules, ...config];
      for (const module of config) {
        if (module.preload || (this.options && this.options.preload)) {
          try {
            subscription.add(
              this.preload(module.load).subscribe({
                next: () => {},
                error: (err: unknown) => {
                  if (environment.debugMode) {
                    console.log(err);
                  }
                },
              })
            );
          } catch (err: unknown) {
            if (environment.debugMode) {
              console.log(err);
            }
          }
        }
      }
    }
    return subscription;
  }

  public getModule(module: string): ModuleConfig | null {
    return this.modules.find((m) => m.name === module) || null;
  }

  /**
   * Preloads a Lazy Load Module
   * @param {string | FunctionReturningPromise} module The module name or configuration
   * @throws LAZY_MODULE_NOT_FOUND: The module with name was not found
   */
  public preload(module: string | FunctionReturningPromise): Observable<Type<unknown>> {
    return new Observable((observer: Observer<Type<unknown>>) => {
      try {
        if (typeof module === 'string') {
          const moduleLoader = this.getModule(module);
          if (moduleLoader) {
            module = moduleLoader.load;
          } else {
            observer.error(new Error('LAZY_MODULE_NOT_FOUND'));
            observer.complete();

            return;
          }
        }

        module()
          .then((loadedModule: Type<unknown>) => {
            observer.next(loadedModule);
            observer.complete();
          })
          .catch((error: unknown) => {
            observer.error(error);
            observer.complete();
          });
      } catch (error: unknown) {
        observer.error(error);
        observer.complete();
      }
    });
  }
}
