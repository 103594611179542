// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
let passiveSupported = false;
let eventListeners: EventDescription[] = [];

interface EventDescription {
  eventName: string;
  callback: EventListener;
}

/* istanbul ignore next */
try {
  const options = Object.defineProperty({}, `passive`, {
    get() {
      passiveSupported = true;
    },
  });

  window.addEventListener(`test` as any, options as any, options);
  window.removeEventListener(`test` as any, options as any, options);
} catch (err) {
  passiveSupported = false;
}

export function addListener(eventName: string, callback: EventListener) {
  eventListeners.push({
    eventName,
    callback,
  });
  try {
    window.addEventListener(
      eventName,
      callback,
      /* istanbul ignore next */
      passiveSupported ? { passive: true } : false
    );
  } catch (err) {
    // SSR Window not supported
  }
}

export function removeAll() {
  eventListeners.forEach((config) => {
    try {
      window.removeEventListener(config.eventName, config.callback);
    } catch (err) {
      // SSR Window not supported
    }
  });
  eventListeners = [];
}
