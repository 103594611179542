<header class="toolbar-wrapper" *transloco="let t">
  <div class="row first">
    <!--     Burguer Menu -->
    <ng-container *ngIf="!(biggerThan$ | async)?.mobileL">
      <a
        class="popsy-icon flex"
        (click)="openBurguerMenu()"
        [attr.aria-label]="t('aria_action_home')">
        <popsy-icon-burguer-menu class="burguer-icon icon"></popsy-icon-burguer-menu>
      </a>
      <span aria-hidden="true" class="fixed-space-16"></span>
    </ng-container>

    <!-- Popsy Logo -->
    <ng-container>
      <a
        class="popsy-icon name flex"
        href=""
        [routerLink]="homeUrlFragment$ | async"
        [queryParams]="queryParamsToKeep$ | async"
        [attr.aria-label]="t('aria_action_home')">
        <popsy-icon-popsy-duo class="icon"></popsy-icon-popsy-duo>
      </a>
    </ng-container>

    <span class="dynamic-space"></span>

    <!-- Search Box -->
    <ng-container *ngIf="!(isNoSearchView$ | async) && (biggerThan$ | async)?.mobileL">
      <app-search-box
        [attr.aria-label]="t('hint_search')"
        class="search-box"
        searchType="search">
      </app-search-box>
      <span class="dynamic-space"></span>
    </ng-container>

    <!-- Cart -->
    <ng-container
      *ngIf="((cartItemsCount$ | async) || 0) > 0 || (alwaysShowCart$ | async)">
      <!-- Cart -->
      <button
        #cartBtn
        class="popsy-button-icon notifications-button"
        [class.unread]="((cartItemsCount$ | async) || 0) > 0"
        [attr.data-before]="(cartItemsCount$ | async) || undefined"
        [attr.aria-label]="t('label_cart')"
        (click)="goToCart()">
        <popsy-icon-shopping-bag class="icon gray-01"></popsy-icon-shopping-bag>
      </button>
    </ng-container>

    <span aria-hidden="true" class="fixed-space-16"></span>

    <!-- User Avatar -->
    <ng-container *ngIf="user$ | async">
      <a
        class="user-avatar"
        href=""
        [routerLink]="userUrlFragment"
        [queryParams]="queryParamsToKeep$ | async"
        [attr.aria-label]="t('action_see_profile')">
        <app-picture
          class="picture"
          [alt]="(user$ | async)?.firstName"
          [pictureUrl]="userAvatarUrl$ | async"
          [width]="'124px'"
          [height]="'124px'"></app-picture>
        <popsy-icon-single-user
          class="icon gray-03 defaultAvatar"
          *ngIf="showDefaultAvatar"></popsy-icon-single-user>
      </a>
    </ng-container>

    <!-- Auth Buttons -->
    <ng-container *ngIf="!(user$ | async)">
      <a
        #cartBtn
        class="popsy-button-icon sign-in small"
        [class.unread]="true"
        [routerLink]="(isAuthView$ | async) ? signInUrlFragment : null"
        [queryParams]="
          (isAuthView$ | async)
            ? (signUpQueryParams$ | async)
            : (queryParamsToKeep$ | async)
        "
        (click)="openAuthDialog('auth-intro')">
        <popsy-icon-single-user class="icon"></popsy-icon-single-user>
      </a>
    </ng-container>
  </div>
  <div
    *ngIf="!(isNoSearchView$ | async) && !(biggerThan$ | async)?.mobileL"
    class="row second">
    <!-- Search Box -->
    <app-search-box
      [attr.aria-label]="t('hint_search')"
      class="full-width search-box"
      searchType="search">
    </app-search-box>
  </div>
  <div
    [attr.aria-busy]="showActivity"
    *ngIf="showActivity"
    class="loading"
    role="progressbar"
    [attr.aria-valuetext]="t(activityTypeAriaLabel)">
    <div class="lead"></div>
  </div>
</header>
