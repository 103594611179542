import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { map, Observable, of } from 'rxjs';
import { DiscountInfo, parseDiscountInfo } from '../models/discount-info.model';

@Injectable({ providedIn: 'root' })
export class DiscountCodeApiService {
  private readonly http = inject(HttpClient);
  constructor() {}

  /**
   * Gets the price estimation for the delivery provider
   * @param {string} listingId the ID of the listing to be delivered
   * @param {Coordinate} coordinate the coordinate where the item should be delivered
   * @param {DeliveryProvider} provider the name of the delivery provider to query
   * @returns {Observable<PriceEstimateAPIResponse | null>} Rx Observable with the results
   */
  public getDiscountFromEstimation(
    currency?: string | null,
    discountCode?: string | null
  ): Observable<DiscountInfo> {
    if (!discountCode || !currency) {
      return of(new DiscountInfo());
    } else {
      const apiUrl = getApiEndpoint(
        `api/v1/checkout/discount/${discountCode}?currency=${currency}`,
        true
      );

      const headers: Params = {
        'Content-Type': 'application/json; charset=utf-8',
      };

      return this.http
        .get<any>(
          // Assemble the full API URL
          apiUrl,
          // Add the Options
          {
            responseType: 'json',
            headers: headers,
          }
        )
        .pipe(map((results) => parseDiscountInfo(results)));
    }
  }
}
