import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Coordinate } from '@app/akita/api/location/models/coordinate.model';
import { defer, Observable } from 'rxjs';
import localForage from 'localforage';
import { LocalForageInstance } from '../models/localForageInstance.interface';
import { AkitaConfigurationQuery } from '@app/akita/configuration/state/configuration.query';
import { isPlatformBrowser } from '@angular/common';

const COORDINATE_PRECISSION = 6;

const MOCK_COORDINATE_STORE = {
  setItem: (): Promise<any> =>
    new Promise(() => {
      throw new Error('NOT_SUPPORTED');
    }),
  getItem: (): Promise<any> =>
    new Promise(() => {
      throw new Error('NOT_SUPPORTED');
    }),
  iterate: (): Promise<any> =>
    new Promise(() => {
      throw new Error('NOT_SUPPORTED');
    }),
  length: (): Promise<number> =>
    new Promise((resolve) => {
      resolve(0);
    }),
} as any;

/**
 * Class to easily handle cache of data that will never change
 */
@Injectable({
  providedIn: 'root',
})
export class DataCacheService {
  private readonly coordinateStore: LocalForageInstance = MOCK_COORDINATE_STORE;

  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, any>,
    private readonly akitaConfigurationQuery: AkitaConfigurationQuery
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    try {
      this.coordinateStore =
        localForage.createInstance({
          name: 'popsy',
          description: 'Cache of coordinates to avoid using the Geocoder too much',
          version: 1,
          storeName: 'coordinates',
        }) || MOCK_COORDINATE_STORE;
    } catch (error) {
      // Cache not available
    }
  }

  public saveCoordinate(
    coordinate: Coordinate | null,
    latitude?: number | null,
    longitude?: number | null
  ): void {
    if (this.isBrowser && coordinate) {
      if (coordinate.latitude && coordinate.longitude) {
        this.coordinateStore
          .setItem<Coordinate>(
            `${this.akitaConfigurationQuery.locale}:${coordinate.latitude.toFixed(
              COORDINATE_PRECISSION
            )},${coordinate.longitude.toFixed(COORDINATE_PRECISSION)}`,
            coordinate
          )
          .catch(() => {
            // Cache not Available
          });
      }

      if (latitude && longitude) {
        // Save the alternative lat/lng as well
        this.coordinateStore
          .setItem<Coordinate>(
            `${this.akitaConfigurationQuery.locale}:${latitude.toFixed(
              COORDINATE_PRECISSION
            )},${longitude.toFixed(COORDINATE_PRECISSION)}`,
            coordinate
          )
          .catch(() => {
            // Cache not Available
          });
      }
    }
  }

  public readCoordinate(
    latitude: number,
    longitude: number
  ): Observable<Coordinate | null> {
    return defer(async () => {
      try {
        if (this.isBrowser) {
          const latLng = `${this.akitaConfigurationQuery.locale}:${(
            latitude || 0
          ).toFixed(COORDINATE_PRECISSION)},${(longitude || 0).toFixed(
            COORDINATE_PRECISSION
          )}`;
          const rawCoordinate = await this.coordinateStore.getItem<Coordinate>(latLng);
          return Coordinate.fromJson(rawCoordinate);
        }
      } catch (error) {
        // Cache not Available
      }
      return null;
    });
  }

  public get coordinatesMap(): Observable<{ [key: string]: Coordinate | null }> {
    return defer(async () => {
      try {
        if (this.isBrowser) {
          const dictionary: { [latLng: string]: Coordinate | null } = {};
          await this.coordinateStore.iterate<Coordinate, void>(
            (rawCoordinate: Coordinate | null, latLng: string) => {
              try {
                dictionary[latLng] = Coordinate.fromJson(rawCoordinate);
              } catch (error) {}
            }
          );

          return dictionary;
        }
      } catch (error) {
        // Cache not Available
      }
      return {};
    });
  }
}
