import { Injectable } from '@angular/core';
import { Observer, Observable } from 'rxjs';
import { SocialAuthInfo } from '../models/social-auth-info.model';
import { environment } from '@environments/environment';
import { getURIFromBaseURL, getAppleClientID } from '@app/shared/utils/url.utils';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

const STATE_NAME = 'INITIAL';
const SCOPES = 'name email';
const USE_POPUP = true;
const REDIRECT_PATH = 'auth/apple-sign-in';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class SignWithAppleService {
  public readonly isBrowser: boolean;

  constructor(private readonly akitaRouterQuery: AkitaRouterQuery) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;
  }

  public initialize(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (this.isBrowser) {
        const redirectURL = getURIFromBaseURL(environment.apple.baseURL, REDIRECT_PATH);
        AppleID.auth.init({
          clientId: getAppleClientID(),
          redirectURI: redirectURL,
          scope: SCOPES,
          state: STATE_NAME,
          usePopup: USE_POPUP,
        });

        observer.next(true);
        observer.complete();
      }
    });
  }

  public signIn(): Observable<SocialAuthInfo | null> {
    return new Observable((observer: Observer<SocialAuthInfo | null>) => {
      const redirectURL = getURIFromBaseURL(environment.apple.baseURL, REDIRECT_PATH);
      AppleID.auth
        .signIn({
          clientId: getAppleClientID(),
          redirectURI: redirectURL,
          scope: SCOPES,
          state: STATE_NAME,
          usePopup: USE_POPUP,
        })
        .then((data?: AppleSignInAPI.SignInResponseI | null) => {
          observer.next({
            name: `${data?.user?.name?.firstName || ''}`,
            email: `${data?.user?.email || ''}`,
            secret: `${data?.authorization.id_token || ''}`,
          });
          observer.complete();
        })
        .catch((error?: AppleSignInAPI.SignInErrorI | null) => {
          let message = '';
          if (error?.error === 'popup_closed_by_user') {
            message = 'error_social_auth_dialog_closed';
          }

          observer.error({
            error: error?.error || '',
            message: message,
          });
          observer.complete();
        });
    });
  }
}
