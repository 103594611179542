export class UserTiming {
  public static createMark(name: string): void {
    try {
      if (performance && performance.mark === undefined) {
        return; // performance.mark Not supported
      }
      // Create the performance mark
      performance.mark(name);
    } catch (error) {}
  }

  public static measure(name: string, start: string, end: string): void {
    try {
      if (performance && performance.measure === undefined) {
        return; // performance.mark Not supported
      }
      // Create the performance mark
      performance.measure(name, start, end);

      // Clean up the stored markers.
      // UserTiming.clearMarks();
      // UserTiming.clearMeasures();
    } catch (error) {}
  }
}
