import {
  retryWhen,
  switchMap,
  delayWhen,
  throwError,
  of,
  Observable,
  MonoTypeOperatorFunction,
  timer,
} from 'rxjs';
import { HttpStatusCode } from './http-status-codes';

export const DEFAULT_INCREMENT = 300;
const DEFAULT_RETRIES = 3;

const MAX_ERROR_COUNT = 20;
const TIME_TO_RESET = 60000;

export interface NetworkErrorStats {
  errorCount?: number | null;
  lastErrorTime?: Date | null;
  onHold?: boolean | null;
  dateOnHold?: Date | null;
}

export const SKIP_RETRY_ERROR_CODES = [
  HttpStatusCode.BAD_REQUEST,
  HttpStatusCode.UNAUTHORIZED,
  HttpStatusCode.PAYMENT_REQUIRED,
  HttpStatusCode.FORBIDDEN,
  HttpStatusCode.NOT_FOUND,
  HttpStatusCode.METHOD_NOT_ALLOWED,
  HttpStatusCode.NOT_ACCEPTABLE,
  HttpStatusCode.PROXY_AUTHENTICATION_REQUIRED,
  HttpStatusCode.GONE,
  HttpStatusCode.LENGTH_REQUIRED,
  HttpStatusCode.PRECONDITION_FAILED,
  HttpStatusCode.PAYLOAD_TOO_LARGE,
  HttpStatusCode.URI_TOO_LONG,
  HttpStatusCode.UNSUPPORTED_MEDIA_TYPE,
  HttpStatusCode.RANGE_NOT_SATISFIABLE,
  HttpStatusCode.EXPECTATION_FAILED,
  HttpStatusCode.MISDIRECTED_REQUEST,
  HttpStatusCode.UNPROCESSABLE_ENTITY,
  HttpStatusCode.FAILED_DEPENDENCY,
  HttpStatusCode.PRECONDITION_REQUIRED,
  HttpStatusCode.REQUEST_HEADER_FIELDS_TOO_LARGE,
  HttpStatusCode.UNAVAILABLE_FOR_LEGAL_REASONS,
  HttpStatusCode.TOO_MANY_REQUESTS,

  HttpStatusCode.NOT_IMPLEMENTED,
  HttpStatusCode.HTTP_VERSION_NOT_SUPPORTED,
  HttpStatusCode.VARIANT_ALSO_NEGOTIATES,
  HttpStatusCode.INSUFFICIENT_STORAGE,
  HttpStatusCode.LOOP_DETECTED,
  HttpStatusCode.NOT_EXTENDED,
  HttpStatusCode.NETWORK_AUTHENTICATION_REQUIRED,
];

export class NetworkErrorHandler {
  public static handle<T>(
    tries?: number | null,
    shouldRetry?: (error: any, stats?: NetworkErrorStats | null) => boolean | null,
    willRetry?: (error: any, retries: number) => void
  ): MonoTypeOperatorFunction<T> {
    tries = tries === undefined || tries === null ? DEFAULT_RETRIES : tries;

    return retryWhen(
      (errors$: Observable<any>): Observable<any> =>
        errors$.pipe(
          switchMap((error: any, index: number) => {
            const stats = NetworkErrorHandler.getStats();
            const now = new Date();
            const lastError = stats.lastErrorTime ? new Date(stats.lastErrorTime) : null;
            if (
              lastError &&
              now.getTime() - lastError.getTime() > TIME_TO_RESET // 1 min
            ) {
              stats.errorCount = 0;
              stats.lastErrorTime = new Date();
            } else {
              stats.errorCount = stats.errorCount || 0;
              stats.errorCount += 1;
              stats.lastErrorTime = new Date();

              if (stats.errorCount > MAX_ERROR_COUNT) {
                stats.onHold = true;
                stats.dateOnHold = new Date();
                NetworkErrorHandler.updateStats(stats);
                return throwError(() => error);
              }
            }

            NetworkErrorHandler.updateStats(stats);

            console.log(error);
            if (
              shouldRetry &&
              shouldRetry instanceof Function &&
              !shouldRetry(error, stats)
            ) {
              return throwError(() => error);
            } else if (
              error &&
              (SKIP_RETRY_ERROR_CODES.indexOf(error.status) !== -1 ||
                // Don't retry codes 1xx, 2xx or 3xx (do retry error code 0)
                (error.status > 0 && error.status < HttpStatusCode.BAD_REQUEST))
            ) {
              return throwError(() => error);
            }

            // on {tries} error -- propagate the error
            // - if tries === 0 -- try forever
            if (tries && index === tries - 1) {
              return throwError(() => error);
            }

            if (willRetry) {
              willRetry(error, tries || 0);
            }

            // trigger another retry
            return of(error);
          }),
          // will retry with
          // 300, 1200, 2700 ms delays
          // eslint-disable-next-line @typescript-eslint/naming-convention
          delayWhen((_, index) => {
            const amount = (index + 1) * (index + 1) * DEFAULT_INCREMENT;
            console.log(`Failed API Call, Retry in ${amount}ms`);
            return timer(amount);
          })
        )
    );
  }

  public static getStats(): NetworkErrorStats {
    try {
      const rawStats = localStorage.getItem('network_errors') || '{}';
      return JSON.parse(rawStats);
    } catch (error) {
      return {
        errorCount: 0,
        lastErrorTime: null,
        onHold: false,
        dateOnHold: null,
      };
    }
  }

  public static updateStats(stats?: NetworkErrorStats | null): void {
    if (stats) {
      try {
        localStorage.setItem('network_errors', JSON.stringify(stats));
      } catch (error) {}
    }
  }

  public static get tooManyErrors(): boolean {
    const now = new Date();
    const stats = NetworkErrorHandler.getStats();
    const dateOnHold = stats.dateOnHold ? new Date(stats.dateOnHold) : null;
    if (
      stats.onHold &&
      dateOnHold &&
      now.getTime() - dateOnHold.getTime() < TIME_TO_RESET // 1 min
    ) {
      return true;
    }
    stats.errorCount = 0;
    stats.lastErrorTime = new Date();
    stats.onHold = false;
    stats.dateOnHold = null;
    NetworkErrorHandler.updateStats(stats);
    return false;
  }
}
