<div class="spinner-component" [class.contrast]="contrast === true">
  <div class="spinner" [class.small]="compact === true">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
