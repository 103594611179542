<ng-container *transloco="let t">
  <section
    class="announcement-bar-component popsy-negative"
    [class.hide]="hideBar$ | async">
    <nav class="nav">
      <!-- Location -->
      <div class="menu" (click)="toggleLocationPicker()">
        <span #locationPicker class="small">
          {{ (selectedLocation$ | async)?.display || '' }}
        </span>
        <span class="fixed-space-8" aria-hidden="true"></span>

        <popsy-icon-arrow-down-1 class="icon gray-light"></popsy-icon-arrow-down-1>
      </div>

      <span class="fixed-space-16" aria-hidden="true"></span>
      <span class="small">|</span>
      <span class="fixed-space-16" aria-hidden="true"></span>

      <!-- Language -->
      <div class="menu" (click)="toggleLanguagePicker()">
        <span #languagePicker class="small">
          {{ (selectedLanguage$ | async)?.display || '' }}
        </span>
        <span class="fixed-space-8" aria-hidden="true"></span>

        <popsy-icon-arrow-down-1 class="icon gray-light"></popsy-icon-arrow-down-1>
      </div>
    </nav>
  </section>
</ng-container>
