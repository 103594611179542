import { CurrencyInformation } from '@app/shared/data/currencies.data';
import { Coordinate, CoordinatePrecission } from './coordinate.model';

export interface AkitaLocationState {
  requested: boolean;
  loading: boolean;
  currentLocation: Coordinate | null;
  definedByUser: Coordinate | null;
  lastUpdate: Date;
  precission: CoordinatePrecission;
  hasPermission: boolean;
  apiCallRetries: number;
  country: string;
  coordinates: {
    [latLng: string]: Coordinate;
  };
  suggestedDeviceCurrency: CurrencyInformation | null;
  suggestedUserCurrency: CurrencyInformation | null;
}

export function createInitialState(): AkitaLocationState {
  return {
    requested: false,
    loading: false,
    currentLocation: null,
    definedByUser: null,
    lastUpdate: new Date(),
    hasPermission: false,
    precission: '',
    apiCallRetries: 0,
    country: '',
    coordinates: {},
    suggestedDeviceCurrency: null,
    suggestedUserCurrency: null,
  };
}
