/* eslint-disable @typescript-eslint/no-magic-numbers */

// Original from: https://gist.github.com/tschuegge/903b4688a70c2ea34a6270fcc7baac48
export class UUID {
  /**
   * Creates a UUID according to RFC 4122
   */
  public static generate(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
      // eslint-disable-next-line no-bitwise
      const random = (Math.random() * 16) | 0; // Trim decimal places
      // With x Random 0-15 (0-F), with y Random 0-3 + 8 = 8-11 (8-b) according to RFC 4122
      const value = char === 'x' ? random : (random % 4) + 8;
      return value.toString(16); // Return hexadecimal character
    });
  }
}

/* eslint-enable @typescript-eslint/no-magic-numbers */
