import { inject, Injectable } from '@angular/core';
import { TranslocoLoader, Translation } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { AppUriService } from './app-uri.service';
import { WindowUtils } from '@app/shared/utils/window.util';
import { environment } from '@environments/environment';
import { Observable, of, tap, map, catchError } from 'rxjs';
import { UserTiming } from '@app/shared/utils/performance.utils';

import enTranslation from '@assets/i18n/en.json';

const PERFORMANCE_MARK_NAME = 'Translations Loader';
const PERFORMANCE_MARK_START = `${PERFORMANCE_MARK_NAME} - Start`;
const PERFORMANCE_MARK_END = `${PERFORMANCE_MARK_NAME} - End`;

export const SUPPORTED_LANGUAGES = ['ar', 'en'];

@Injectable({ providedIn: 'root' })
export class PopsyTranslocoHttpLoader implements TranslocoLoader {
  public isBrowser: boolean;
  public domain: string;
  private prefix: string;
  private suffix: string;

  private readonly http = inject(HttpClient);
  constructor(private readonly appUriService: AppUriService) {
    this.prefix = '/assets/i18n/';
    this.suffix = '.json';
    this.isBrowser = false;
    this.domain = '';
  }

  private detectContext(): void {
    // Get the URL for angular Universal (or use a relative path)
    const isLocal = (WindowUtils.getLocationHref() || '').indexOf('127.0.0.1') !== -1;

    // If running locally languages are not hashed
    this.suffix = `.${environment.app.commit}.json`;
    if (isLocal) {
      this.suffix = '.json';
    }

    this.prefix = `/assets/i18n/`;
    if (this.appUriService.domain) {
      this.prefix = `${this.appUriService.domain}/assets/i18n/`;
    }
  }

  private isSupportedLanguage(lang: string): boolean {
    return Boolean(SUPPORTED_LANGUAGES.indexOf(lang) !== -1);
  }

  public getTranslation(lang: string): Observable<Translation> {
    return this.getTranslationInt(lang, this.suffix);
  }

  private getTranslationInt(lang: string, suffix: string): Observable<Translation> {
    UserTiming.createMark(PERFORMANCE_MARK_START);

    // If language is english or a Non supported language, re-huse the imported version
    const isSupportedLanguage = this.isSupportedLanguage(lang);
    if (lang === 'en' || !isSupportedLanguage) {
      return of(enTranslation).pipe(
        tap(() => {
          UserTiming.createMark(PERFORMANCE_MARK_END);
          UserTiming.measure(
            PERFORMANCE_MARK_NAME,
            PERFORMANCE_MARK_START,
            PERFORMANCE_MARK_END
          );
        })
      );
    } else {
      this.detectContext();
      return this.http
        .get(`${this.prefix}${lang}${suffix || '.json'}`, { responseType: 'text' })
        .pipe(
          map((data: any) => {
            try {
              UserTiming.createMark(PERFORMANCE_MARK_END);
              UserTiming.measure(
                PERFORMANCE_MARK_NAME,
                PERFORMANCE_MARK_START,
                PERFORMANCE_MARK_END
              );
              return JSON.parse(data);
            } catch (error) {
              // If language fails to load, use the imported english version
              console.warn(
                `The language "${lang || ''}" is not available (Falling back to English)`
              );
              UserTiming.createMark(PERFORMANCE_MARK_END);
              UserTiming.measure(
                PERFORMANCE_MARK_NAME,
                PERFORMANCE_MARK_START,
                PERFORMANCE_MARK_END
              );
              return of(enTranslation);
            }
          }),
          catchError(() => {
            if (`${suffix || ''}`.replace('.json', '') !== '') {
              console.warn(
                `The language file "${lang || ''}${
                  suffix || ''
                }" is not available (Retry with "${lang || ''}.json)"`
              );

              this.suffix = '.json';
              return this.getTranslationInt(lang, '.json');
            } else {
              // If language fails to load, use the imported english version
              console.warn(
                `The language "${lang || ''}" is not available (Falling back to English)`
              );
              UserTiming.createMark(PERFORMANCE_MARK_END);
              UserTiming.measure(
                PERFORMANCE_MARK_NAME,
                PERFORMANCE_MARK_START,
                PERFORMANCE_MARK_END
              );
              return of(enTranslation);
            }
          })
        );
    }
  }
}
