import { Product, parseProduct } from '@app/akita/api/products/models/product.model';
import { PriceModel } from '@app/shared/models/api/price.model';
import { Model } from '@app/shared/models/model.interface';
import { PhoneInfo, parsePhoneInfo } from '../../addresses/models/phone.model';
import { SessionAddressModel } from './session-address.model';
import {
  DiscountInfo,
  parseDiscountInfo,
} from '@app/akita/router/models/discount-info.model';
import { PaymentMethod, PaymentProvider } from './payment.provider.model';
import { Insurance, parseInsuranceList } from '../../products/models/insurance.model';

export class SessionModel implements Model {
  public id: string;
  public cartId: string;
  public orderId: string;
  public isLocked: boolean;
  public customerId?: string | null;
  public order: Array<Product>;
  public insurances?: Array<Insurance> | null;
  public email: string | null;
  public shippingAddress: SessionAddressModel | null;
  public billingAddress: SessionAddressModel | null;
  public phone: PhoneInfo | null;
  public userName: string | null;
  public totalPrice: PriceModel | null;
  public storeCountry: string;
  public storeLanguage: string;
  public storeCurrency: string;
  public discount: DiscountInfo | null;
  public paymentProvider: PaymentProvider | null;
  public paymentMethod: PaymentMethod | null;
  public isProtected: boolean;
  public protectionPrice: PriceModel | null;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.id = '';
    this.cartId = '';
    this.orderId = '';
    this.isLocked = false;
    this.customerId = null;
    this.order = [];
    this.email = '';
    this.userName = null;
    this.billingAddress = null;
    this.shippingAddress = null;
    this.phone = null;
    this.totalPrice = new PriceModel();
    this.storeCountry = '';
    this.storeLanguage = '';
    this.storeCurrency = '';
    this.discount = null;
    this.paymentProvider = null;
    this.paymentMethod = null;
    this.isProtected = false;
    this.protectionPrice = null;
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   *
   * @param data The Raw JSON Object
   * @returns {SessionModel} An instance of the delivery info class.
   */
  public static fromJson(data?: Record<string, any> | null): SessionModel | null {
    let session: SessionModel | null = null;
    if (data) {
      session = new SessionModel();
      if (data.order.length) {
        session.order = this.parseShortProductList(data.order || []);
      }

      if (data.insurances.length) {
        session.insurances = parseInsuranceList(data.insurances || null);
      }

      session.id = data.id || '';
      session.cartId = data.cartId || data.cart_id || '';
      session.orderId = data.orderId || data.oder_id || data.order_id || '';
      session.customerId = data.customerId || data.customer_id || '';
      session.email = data.email || '';

      session.isLocked = data.isLocked || data.is_locked || false;

      if (data.contact_details) {
        session.userName = data.contact_details.name || data.user_name || null;
        session.phone = parsePhoneInfo(data.contact_details.contact || null);
      }
      if (data.shippingAddress || data.shipping_address) {
        session.shippingAddress = SessionAddressModel.fromJson(
          data.shippingAddress || data.shipping_address || null
        );
      }

      if (data.billingAddress || data.billing_address) {
        session.billingAddress = SessionAddressModel.fromJson(
          data.billingAddress || data.billing_address || null
        );
      }

      session.storeCountry = data.storeCountry || data.store_country || '';
      session.storeLanguage = data.storeLanguage || data.store_language || '';
      session.storeCurrency = data.storeCurrency || data.store_currency || '';
      session.totalPrice = PriceModel.fromJson({
        amount: data.totalPrice || data.total_price || 0,
        currency: data.storeCurrency || data.store_currency || '',
      });

      if (data.discount) {
        session.discount = parseDiscountInfo(data.discount);
        session.discount.currency = session.storeCurrency;
      }
      if (data.paymentProvider || data.payment_provider) {
        session.paymentProvider = data.paymentProvider || data.payment_provider || null;
      }
      if (data.paymentMethod || data.payment_method) {
        session.paymentMethod = data.paymentMethod || data.payment_method || null;
      }

      if (data.protectionPrice || data.protection_price) {
        session.protectionPrice = PriceModel.fromJson({
          amount: data.protectionPrice || data.protection_price || 0,
          currency: session.storeCurrency,
        });
        session.isProtected = true;
      }
    }
    return session;
  }

  public static parseShortProductList(
    data?: Array<Record<string, any>> | null
  ): Array<Product> {
    const products: Array<Product> = new Array(0);
    if (data) {
      for (const item of data || []) {
        const parsed = parseProduct(item.short_product);
        if (parsed) {
          parsed.quantity = item.quantity || 1;
          products.push(parsed);
        }
      }
    }
    return products;
  }
}
