const BASE_16 = 16;

export class PopsyColor {
  /**
   * Calculate the background color used as placeholder when the images are still loading
   * @param {number} color The color as a number
   * @param {string} placeholder color to use if the number is invalid
   * @returns {string} the Hex HTML Color
   */
  public static fromBackend(color: number, placeholder?: string): string {
    let hexColor = placeholder || '#FAFAFA';

    if (color) {
      // Convert the overflowed int into the real int
      // eslint-disable-next-line no-bitwise, @typescript-eslint/no-magic-numbers
      const intColor: number = 0xffffff & color;
      hexColor = `#${intColor.toString(BASE_16)}`;
    }

    return hexColor;
  }
}
