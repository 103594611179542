export class DiscountInfo {
  public amount?: number | null;
  public percentage?: number | null;
  public code: string;
  public currency: string;

  constructor() {
    this.code = '';
    this.currency = '';
  }
}

export const parseDiscountInfo = (data?: Record<string, any> | null): DiscountInfo => {
  const discount = new DiscountInfo();
  if (data) {
    if (data?.percentage) {
      discount.percentage = Math.abs(Number(`${data?.percentage || 0}`));
    }
    if (data?.amount) {
      discount.amount = Math.abs(Number(`${data?.amount || 0}`));
    }
    discount.code = data.code || '';
    discount.currency = data.currency || '';
  }
  return discount;
};
