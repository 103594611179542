import { ApiError } from '@app/shared/models/api/api-error.model';
import { CartItem } from './cart.model';
import { Insurance } from '@app/akita/api/products/models/insurance.model';
import { PriceModel } from '@app/shared/models/api/price.model';
import { PackageProtectionModel } from '@app/akita/api/checkout/models/package-protection.model';

export interface AkitaCartState {
  [lang: string]: {
    id: string | null;

    items: Array<CartItem>;
    insurances: Array<Insurance>;

    isLocked: boolean;
    sessionId: string | null;
    lastSessionId: string | null;

    loadingCartItems: boolean;
    errorLoadingCartItems: ApiError | null;

    updatingCart: boolean;

    isProtected: boolean;
    protectionPrice: PriceModel | null;

    availableProtection: PackageProtectionModel | null;

    savingCartItems: boolean;
    errorSavingCartItems: ApiError | null;

    transferingAnonimousCart: boolean;
    errorTransferingAnonymousCart: ApiError | null;

    lastUpdated: Date;
  };
}

export const createCartStateInitialState = (): AkitaCartState => ({});
