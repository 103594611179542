import { Observable } from 'rxjs';
import { Product } from '../../products/models/product.model';

export class PopularDevice {
  public bestSeller: number;
  public goodDeal: number;
  public oftenBoughtWith: number;
  public accessories: Array<number>;
  public product?: Observable<Product | null>;

  constructor() {
    this.bestSeller = 0;
    this.goodDeal = 0;
    this.oftenBoughtWith = 0;
    this.accessories = [];
  }
}

export class PopularDevices {
  [category: string]: {
    [brand: string]: PopularDevice;
  };

  constructor() {}
}

export const parsePopularDevicesFromJson = (data?: any | null): PopularDevices | null => {
  let out = new PopularDevices();
  data = JSON.parse(data);

  if (data && data.categories.length) {
    out = new PopularDevices();
    data.categories.forEach((category: any) => {
      out[category.value] = {};
      category.brands.forEach((brand: any) => {
        try {
          const outCategory = out[category.value] || {};
          outCategory[brand.value] = parsePopularDeviceFromJson(brand);
        } catch (error) {
          console.log('error: ', error);
        }
      });
    });
  }
  return out;
};
/* export const parsePopularDeviceFromJson = (
  text?: string | null
): PopularDevice | null => {
  let out = null;
  try {
    out = parseSharedImageCategoryFromJson(JSON.parse(`${text || ''}`));
  } catch (error) {}
  return out;
}; */

export const parsePopularDeviceFromJson = (data?: any): PopularDevice => {
  let out = new PopularDevice();
  if (data) {
    out = new PopularDevice();
    out.bestSeller = data['best_seller'] || 0;
    out.goodDeal = data['good_deal'] || 0;
    out.oftenBoughtWith = data['often_bought_with'] || 0;
    out.accessories = data['accessories'] || [];
  }
  return out;
};
