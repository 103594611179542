import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiCallsTrackerService {
  private ongoingAPICalls: Array<string>;
  private readonly monitorSubject: Subject<Array<string>>;

  private readonly subscriptions: Subscription;

  constructor() {
    this.subscriptions = new Subscription();
    this.ongoingAPICalls = new Array(0);
    this.monitorSubject = new Subject();

    if (environment.debugMode) {
      this.subscriptions.add(
        this.monitorSubject.subscribe({
          next: (ongoingCalls: Array<string>) => {
            try {
              (window as any).POPSY_ONGOING_CALLS = ongoingCalls;
            } catch {}
          },
        })
      );
    }
  }

  public clearSubscriptions(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  public addApiCall(url?: string | null): void {
    if (url) {
      this.ongoingAPICalls.push(url);
      this.monitorSubject.next(this.ongoingAPICalls);
    }
  }

  public removeApiCall(url?: string | null): void {
    if (url) {
      this.ongoingAPICalls = [
        ...this.ongoingAPICalls.filter((ongoingURL: string): boolean =>
          Boolean(url === ongoingURL)
        ),
      ];
      this.monitorSubject.next(this.ongoingAPICalls);
    }
  }

  public isOngoing(url?: string | null): boolean {
    return Boolean(url && this.ongoingAPICalls.indexOf(url) !== -1);
  }
}
