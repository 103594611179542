import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { NewsletterOverlayDynamicService } from '@app/overlays/newsletter-overlay/services/newsletter-overlay-dynamic.service';
import { currencyFromCountryCode } from '@app/shared/utils/locale.utils';
import { getLangSubdomain } from '@app/shared/utils/url.utils';
import { AkitaAuthQuery } from '../../auth/state/auth.query';
import { Injectable, NgZone } from '@angular/core';

const ALWAYS_SHOW = false;
const NEVER_SHOW = false;
const NOT_SHOW_COUNTRY = [''];
const NO_SHOW_VIEWS = ['SESSION', 'ORDER-INFO'];

const OVERLAY_DEBOUNCE_TIME = 10000;

const NEWSLETTER_OPEN_STATUS_KEY = 'popsy_nos';

const DISCOUNT_AMOUNT_PER_COUNTRY: { [code: string]: number } = {
  US: 10,
  SA: 40,
  AE: 40,
  BR: 55,
  KW: 4,
  BH: 3,
  OM: 3,
  QA: 10,
};

const DRAW_AMOUNT_PER_COUNTRY: { [code: string]: number } = {
  US: 500,
  SA: 2000,
  AE: 2000,
  BR: 60,
  KW: 4,
};

@Injectable({ providedIn: 'root' })
export class AkitaNewsletterService {
  private userActivityChangeCallback: any = null;
  private firstTime = true;

  constructor(
    private readonly zone: NgZone,
    private readonly akitaAuthQuery: AkitaAuthQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly newsletterOverlayDynamicService: NewsletterOverlayDynamicService
  ) {}

  private initListener(): void {
    this.zone.runOutsideAngular(() => {
      this.userActivityChangeCallback = () => this.handleUserActiveState();

      window.document.addEventListener(
        'click',
        this.userActivityChangeCallback.bind(this),
        true
      );
      window.document.addEventListener(
        'wheel',
        this.userActivityChangeCallback.bind(this),
        true
      );
      window.document.addEventListener(
        'touchstart',
        this.userActivityChangeCallback.bind(this),
        true
      );
    });
  }

  private handleUserActiveState(): void {
    if (!NEVER_SHOW && this.firstTime) {
      const country = this.akitaRouterQuery.country;
      this.firstTime = false;
      if (
        this.akitaRouterQuery.isBrowser &&
        country &&
        NOT_SHOW_COUNTRY.indexOf(country) === -1
      ) {
        this.zone.runOutsideAngular(() => {
          try {
            const wasDisplayNewsletterShown = (window as any).localStorage.getItem(
              NEWSLETTER_OPEN_STATUS_KEY
            );
            if (
              (!wasDisplayNewsletterShown &&
                !this.akitaAuthQuery.accessToken &&
                this.isValidView()) ||
              ALWAYS_SHOW
            ) {
              setTimeout(() => {
                this.displayNewsletter();
              }, OVERLAY_DEBOUNCE_TIME);
            }
          } catch (err) {}
        });
      }
    }
  }

  public showNewsletterIfNeeded(): void {
    this.initListener(); // Init the listener to detect user activity
  }

  public openUpNewsletter(): void {
    this.displayNewsletter();
  }

  private get country(): string {
    const countryQuery = `${
      this.akitaRouterQuery.queryParams.country || ''
    }`.toUpperCase();

    // Get the country from the Sub-domain
    const countrySubdomain = `${
      getLangSubdomain(this.akitaRouterQuery.requestURL, true) || ''
    }`.toUpperCase();

    // If the Subdomain is set, or a query param / param is set, the country gets inferred from that
    let country = this.akitaRouterQuery.country || '';
    if (countryQuery) {
      country = countryQuery;
    } else if (countrySubdomain) {
      country = countrySubdomain;
    }

    return country;
  }

  private displayNewsletter(): void {
    this.zone.runOutsideAngular(() => {
      try {
        (window as any).localStorage.setItem(NEWSLETTER_OPEN_STATUS_KEY, 'Yes');
      } catch (err) {}

      const country =
        this.country.toUpperCase() in DISCOUNT_AMOUNT_PER_COUNTRY
          ? `${this.country || ''}`.toUpperCase()
          : 'US';

      const currency = currencyFromCountryCode(country);
      const amount = DISCOUNT_AMOUNT_PER_COUNTRY[country];
      const drawAmount = DRAW_AMOUNT_PER_COUNTRY[country];

      if (country && currency && amount) {
        this.newsletterOverlayDynamicService.open({
          data: {
            drawAmount: drawAmount,
            discountAmount: amount,
            currency: currency,
            country: country,
          },
        });
      }
    });
  }

  private isValidView(): boolean {
    return NO_SHOW_VIEWS.indexOf(this.akitaRouterQuery.viewName) !== -1 ? false : true;
  }
}
