import { inject, Injectable } from '@angular/core';
import { Observable, of, throwError, catchError, map } from 'rxjs';

import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { parseProduct, Product } from '../models/product.model';
import { TranslocoService } from '@ngneat/transloco';
import {
  normalizeFilterValue,
  ProductSearchFilters,
  PRODUCT_SEARCH_FILTERS_MULTIPLE_ALLOWED,
} from '../models/product-search.filters.model';
import {
  parseProductSearchResults,
  ProductSearchResults,
} from '../models/product-search.results.model';
import {
  parseProductSearchFilterOptions,
  ProductSearchFilterOptions,
} from '../models/product-search.filter-options.model';
import { AvailableProductLocaleResponse } from '../models/product-available-country-lang.response.model';
import { DEFAULT_AVAILABLE_LANGUAGES } from '../models/products.state';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { AkitaProductsQuery } from '../state/products.query';
import {
  parseProductVariantFromJsonList,
  ProductVariant,
} from '../models/product-variant.model';
import { Insurance, parseInsuranceList } from '../models/insurance.model';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class ProductsAPIService {
  private readonly translateService = inject(TranslocoService);
  private readonly http = inject(HttpClient);
  constructor(
    private readonly akitaAuthQuery: AkitaAuthQuery,
    private readonly akitaProductsQuery: AkitaProductsQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {}

  /**
   * Gets a Product from the API
   * @param {string} id The Id of the Product
   * @param {string} country The Country of the Product
   * @returns {Observable<Product>} Rx Observable with the results
   */
  public getProduct(
    id?: string | null,
    country?: string | null
  ): Observable<Product | null> {
    if (!id) {
      return of(null);
    }

    const accessToken = this.akitaAuthQuery.accessToken;
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    let queryParams = new HttpParams();
    let language = `${this.translateService.getActiveLang() || ''}`.toLowerCase();
    if (language) {
      language = DEFAULT_AVAILABLE_LANGUAGES.includes(language) ? language : 'en';
      queryParams = queryParams.set('language', language);
    }

    if (country) {
      queryParams = queryParams.set('country', `${country || ''}`.toUpperCase());
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`api/v2/product/${id}`, true),
        // Add the Options
        { responseType: 'json', headers: headers, params: queryParams }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseProduct(results))
      );
  }

  /**
   * Searches Products in the API
   * @param {ProductSearchFilters} filters Filters for the search
   * @returns {Observable<ProductSearchResults>} Rx Observable with the results
   */
  public search(filters?: ProductSearchFilters | null): Observable<ProductSearchResults> {
    const accessToken = this.akitaAuthQuery.accessToken;
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    let queryParams = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters || {})) {
        const value = (filters as Record<string, unknown>)[key];
        if (value) {
          if (PRODUCT_SEARCH_FILTERS_MULTIPLE_ALLOWED.includes(`${key || ''}`)) {
            for (const item of [...((value as Array<string>) || [])]) {
              queryParams = queryParams.append(`${key}`, normalizeFilterValue(item));
            }
          } else {
            queryParams = queryParams.set(`${key}`, normalizeFilterValue(value));
          }
        }
      }
    }
    // if no sorting is provided, default to RANKED_BY_SALES
    if (!filters?.sorting) {
      queryParams = queryParams.set('sorting', 'RANKED_BY_SALES');
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`api/v2/product`, true),
        // Add the Options
        { responseType: 'json', headers: headers, params: queryParams }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseProductSearchResults(results))
      );
  }

  /**
   * Gets a list of available filters for the current search
   * @param {ProductSearchFilters} filters Filters of the search
   * @returns {Observable<ProductSearchFilterOptions>} Rx Observable with the results
   */
  public getAvailableFiltersForSearch(
    filters?: ProductSearchFilters | null
  ): Observable<ProductSearchFilterOptions> {
    const accessToken = this.akitaAuthQuery.accessToken;
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    let queryParams = new HttpParams();
    if (filters) {
      for (const key of Object.keys(filters || {})) {
        const value = (filters as Record<string, unknown>)[key];
        if (value) {
          if (PRODUCT_SEARCH_FILTERS_MULTIPLE_ALLOWED.includes(`${key || ''}`)) {
            for (const item of [...((value as Array<string>) || [])]) {
              queryParams = queryParams.append(`${key}`, `${item || ''}`);
            }
          } else {
            queryParams = queryParams.set(`${key}`, `${value || ''}`);
          }
        }
      }
    }

    // VALIDATE COUNTRY AND LANGUAGE
    if (!filters?.country || !filters?.language) {
      const locale = this.akitaProductsQuery.validateLocale(
        `${this.translateService.getActiveLang() || ''}`.toLowerCase(),
        this.akitaRouterQuery.country || ''
      );
      if (locale.country) {
        queryParams = queryParams.set('country', locale.country);
      }
      if (locale.language) {
        queryParams = queryParams.set('language', locale.language);
      }
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`api/v2/product/options`, true),
        // Add the Options
        { responseType: 'json', headers: headers, params: queryParams }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseProductSearchFilterOptions(results))
      );
  }

  /**
   * Gets a available options for the current search
   * @param {ProductSearchFilters} filters Filters of the search
   * @returns {Observable<ProductSearchFilterOptions>} Rx Observable with the results
   */
  public getProductOptions(
    model?: string | null
  ): Observable<ProductSearchFilterOptions | null> {
    const accessToken = this.akitaAuthQuery.accessToken;
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    let queryParams = new HttpParams();

    if (!model || !model.length) {
      return of(null);
    } else {
      queryParams = queryParams.set(`model`, model);

      const locale = this.akitaProductsQuery.validateLocale(
        `${this.translateService.getActiveLang() || ''}`.toLowerCase(),
        this.akitaRouterQuery.country || ''
      );
      if (locale.country) {
        queryParams = queryParams.set('country', locale.country);
      }
      if (locale.language) {
        queryParams = queryParams.set('language', locale.language);
      }
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`api/v2/product/options`, true),
        // Add the Options
        { responseType: 'json', headers: headers, params: queryParams }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseProductSearchFilterOptions(results))
      );
  }

  /**
   * Gets a list of available countries and languages for products
   * @returns {Observable<AvailableProductLocaleResponse>} Rx Observable with the results
   */
  public getAvailableCountryAndLanguages(): Observable<AvailableProductLocaleResponse> {
    const accessToken = this.akitaAuthQuery.accessToken;
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`api/v2/product/locales`, true),
        // Add the Options
        { responseType: 'json', headers: headers }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => new AvailableProductLocaleResponse(results))
      );
  }

  /**
   * Get Product Variants in the API
   * @param {string} model Filters for the search
   * @param {string} category Filters for the search
   * @returns {Observable<ProductSearchResults>} Rx Observable with the results
   */
  public getVariantsFromModel(
    model?: string | null,
    category?: string | null
  ): Observable<Array<ProductVariant>> {
    const accessToken = this.akitaAuthQuery.accessToken;
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    let queryParams = new HttpParams();

    if (!model || !model.length) {
      // console.log('MODEL PARAMETER IT IS REQUIRED');
      return of(Array(0));
    } else {
      queryParams = queryParams.set(`model`, model);

      const locale = this.akitaProductsQuery.validateLocale(
        `${this.translateService.getActiveLang() || ''}`.toLowerCase(),
        this.akitaRouterQuery.country || ''
      );
      if (locale.country) {
        queryParams = queryParams.set('country', locale.country);
      }
      if (locale.language) {
        queryParams = queryParams.set('language', locale.language);
      }
    }

    if (category) {
      queryParams = queryParams.set(`category`, category);
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`api/v2/product/variants`, true),
        // Add the Options
        { responseType: 'json', headers: headers, params: queryParams }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseProductVariantFromJsonList(results.model))
      );
  }

  /**
   * Get a list of Product Titles for search autocomplete
   * @param {string} lang Filters for the search
   * @returns {Observable<Array<string>>} Rx Observable with the results
   **/
  public getProductTitles(lang: string, country: string): Observable<Array<string>> {
    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${credentials}`,
    });

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`api/v2/product/titles`, true),
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
          params: {
            language: lang,
            country: country,
          },
        }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => results as Array<string>)
      );
  }

  public getInsurances(
    currency: string,
    language: string,
    ids?: Array<string> | null
  ): Observable<Array<Insurance>> {
    const accessToken = this.akitaAuthQuery.accessToken;
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    let queryParams = new HttpParams();
    if (ids) {
      for (const id of ids) {
        queryParams = queryParams.append('insurance_id', id);
      }
    }
    queryParams = queryParams.set('currency', currency);
    queryParams = queryParams.set('language', language);

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`api/v1/insurance`, true),
        // Add the Options
        { responseType: 'json', headers: headers, params: queryParams }
      )
      .pipe(
        catchError((error: unknown) => throwError(() => parseApiError(error))),
        map((results) => parseInsuranceList(results))
      );
  }
}
