export class FeatureBestSellers {
  public bestSellers: Array<number>;
  public newSuppliers: Array<number>;

  constructor() {
    this.bestSellers = [];
    this.newSuppliers = [];
  }
}

export const parseFeatureBestSellersFromJson = (data?: any): FeatureBestSellers => {
  let out = new FeatureBestSellers();
  if (data) {
    data = JSON.parse(data);
    out = new FeatureBestSellers();
    out.bestSellers = data['best_sellers'] || [];
    out.newSuppliers = data['new_suppliers'] || [];
  }
  return out;
};
