import { Directionality } from '@angular/cdk/bidi';
import { isPlatformBrowser } from '@angular/common';
import { APP_ID, Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { InitParams } from '@app/ngx-facebook/models/init-params';
import { FacebookService } from '@app/ngx-facebook/providers/facebook';
import { MetaService } from '@app/shared/services/meta.service';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { getLanguageCode, SentryLocaleMetadata } from '@app/shared/utils/url.utils';
import { WindowUtils } from '@app/shared/utils/window.util';
import { environment } from '@environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { configureScope, Scope } from '@sentry/browser';
import { Subscription } from 'rxjs';
import { logReportWebsiteVersion } from './google-analytics.lazy';
import { GoogleAnalyticsService } from './google-analytics.service';

/**
 * Class to handle some tasks that are meant to be ran when the app initializes
 */
@Injectable({
  providedIn: 'root',
})
export class InitTasksService {
  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, any>,
    @Inject(APP_ID) private readonly appId: string,
    private readonly zone: NgZone,
    private readonly meta: MetaService,
    private readonly directionService: Directionality,
    /*     private readonly mapsApiLoader: GoogleMapsAPILoader,
     */ private readonly translate: TranslocoService,
    private readonly facebookService: FacebookService,
    private readonly analytics: GoogleAnalyticsService,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  public initialize(): Subscription {
    const subscription = new Subscription();
    this.zone.runOutsideAngular(() => {
      configureScope((scope: Scope) => {
        scope.setTag('ssr', this.isBrowser ? 'No' : 'Yes');
        scope.setTag('facebookSDK', 'active');
        scope.setTag('Cookies', 'Enabled');
        scope.setTag('Google Client ID', this.akitaRouterQuery.queryParams.gclid);
        scope.setTag('platformId', `${this.platformId}`);
        scope.setTag('appId', this.appId);
        scope.setTag('serviceWorker', environment.serviceWorker ? 'Yes' : 'No');
        scope.setTag('window:width', `${WindowUtils.windowInnerWidth()}`);
        scope.setTag('window:height', `${WindowUtils.windowInnerHeight()}`);
        scope.setTag('layout:direction', this.directionService.value);
        scope.setTag('SmartLook:Loaded', 'No');
        scope.setTag('HotJar:Loaded', 'No');
      });

      // Polyfills.io loaded?
      if (this.isBrowser) {
        if ((window as any).POPSY_POLYFILLS_LOADED) {
          configureScope((scope: Scope) => {
            scope.setTag('Polyfills.io', 'LOADED!');
          });
        } else {
          configureScope((scope: Scope) => {
            scope.setTag('Polyfills.io', 'NOT LOADED!');
          });
        }
      }

      if (this.isBrowser) {
        logReportWebsiteVersion(
          this.analytics.logEventWrapper,
          `${environment.app.version || '?'}`,
          `${environment.app.commit || '?'}`
        );
      }

      this.translate.setDefaultLang(environment.languageCode);
      this.meta.setTags([
        { name: 'fb:app_id', content: `${environment.fbAppId || ''}` },
        { name: 'ver', content: `${environment.app.version}` },
        { name: 'commit', content: `${environment.app.commit}` },
        { name: 'og:type', content: `website` },
        {
          name: 'og:image',
          content: `https://www.popsy.app/assets/pwa/icon-512x512.png`,
        },
        {
          name: 'og:locale',
          content: `${getLanguageCode(
            'en-US',
            undefined,
            this.akitaRouterQuery.isBrowser,
            this.refreshSentryLocaleMetadata.bind(this)
          )}`,
        },
        {
          name: 'og:locale:alternate',
          content: `en_US,pt_BR,es_ES,fr_FR,ar_SA`,
        },
      ]);

      this.loadFacebookSDK();
      this.detectCookieSupport();
      // subscription.add(this.loadMapsSDK());
    });
    return subscription;
  }

  private detectCookieSupport(): void {
    this.zone.runOutsideAngular(() => {
      if (this.isBrowser && !(window as any)?.navigator?.cookieEnabled) {
        configureScope((scope: Scope) => {
          scope.setTag('Cookies', 'Disabled');
          scope.setTag('Google Client ID', this.akitaRouterQuery.queryParams.gclid);
        });
      } else {
        configureScope((scope: Scope) => {
          scope.setTag('Cookies', 'Enabled');
          scope.setTag('Google Client ID', this.akitaRouterQuery.queryParams.gclid);
        });
      }
    });
  }

  private loadFacebookSDK(): void {
    this.zone.runOutsideAngular(() => {
      if (this.isBrowser) {
        if ((window as any).FB) {
          const initParams: InitParams = {
            appId: environment.fbAppId,
            xfbml: true,
            version: environment.fbAppVersion,
          };

          this.facebookService.init(initParams).catch((error) => {
            configureScope((scope: Scope) => {
              scope.setTag('facebookSDK', 'error');
            });
            SentryUtil.reportException(error, false);
          });
        } else {
          // Facebook SDK blocked by AD Blocker?
          configureScope((scope: Scope) => {
            scope.setTag('facebookSDK', 'blocked?');
          });
        }
      } else {
        configureScope((scope: Scope) => {
          scope.setTag('facebookSDK', 'Disabled (SSR)');
        });
      }
    });
  }

  /*  private loadMapsSDK(): Subscription {
    const subscription = new Subscription();
    this.zone.runOutsideAngular(() => {
      if (this.isBrowser) {
        // Load the Google Maps SDK
        subscription.add(
          this.mapsApiLoader.load().subscribe({
            next: () => {
              configureScope((scope: Scope) => {
                scope.setTag('MapsSDK', 'Loaded');
              });
            },
            error: (err: unknown) => {
              configureScope((scope: Scope) => {
                scope.setTag('MapsSDK', 'Load Error');
              });
              SentryUtil.reportException(err, false);
            },
          })
        );
      }
    });
    return subscription;
  } */

  private refreshSentryLocaleMetadata(metadata: SentryLocaleMetadata): void {
    this.zone.runOutsideAngular(() => {
      configureScope((scope: Scope) => {
        scope.setExtra('i18n:requested', metadata.requestedCode);
        scope.setExtra('i18n:subDomain', metadata.hasSubDomain || '-');
        scope.setExtra('i18n:langParam', metadata.hasLangParam || '-');
        scope.setExtra('i18n:browser', metadata.hasBrowser);
        scope.setExtra('i18n:dateLocale', metadata.hasDateLocale);
        scope.setExtra('i18n:final', metadata.chosenLanguage);
      });
    });
  }
}
