export class OmniSendIdentifyUser {
  public consumerId: string;
  public isNew: boolean;

  constructor() {
    this.consumerId = '';
    this.isNew = false;
  }
}

export const parseOmniSendIdentifyUser = (data?: any | null): OmniSendIdentifyUser => {
  const response = new OmniSendIdentifyUser();
  if (data) {
    response.consumerId = `${data.consumer_id || data.consumerId || ''}`;
    response.isNew = Boolean(data.is_new || data.isNew);
  }
  return response;
};
