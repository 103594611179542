import { Model } from '@app/shared/models/model.interface';

export class PackageProtectionModel implements Model {
  public isCovered: boolean;
  public country: string;
  public currency: string;
  public value: number;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.isCovered = false;
    this.country = '';
    this.currency = '';
    this.value = 0;
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   * @param data The Raw JSON Object
   * @returns {PaymentConfigurationModel} An instance of the payment configuration class.
   */
  public static fromJson(
    data?: Record<string, any> | null
  ): PackageProtectionModel | null {
    let payment: PackageProtectionModel | null = null;
    if (data) {
      payment = new PackageProtectionModel();
      payment.isCovered = data.isCovered || false;
      payment.country = data.country || '';
      payment.currency = data.currency || '';
      payment.value = data.value || 0;
    }
    return payment;
  }
}
