import { inject, Injectable } from '@angular/core';
import { Observable, of, map } from 'rxjs';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserModel } from '@app/shared/models/api/user.model';
import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';
import { Params } from '@angular/router';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class UsersAPIService {
  private readonly http = inject(HttpClient);
  constructor(private readonly akitaAuthQuery: AkitaAuthQuery) {}

  public getUser(id: string): Observable<UserModel | null> {
    if (!id) {
      return of(null);
    }

    const accessToken = this.akitaAuthQuery.accessToken;
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`users/${id}`),
        // Add the Options
        { responseType: 'json', headers: headers }
      )
      .pipe(map((results) => UserModel.fromJson(results)));
  }

  public resetPassword(
    accessToken: string,
    newPasswordHash: string
  ): Observable<UserModel | null> {
    const options = new HttpParams();

    return this.http
      .put<any>(
        // Assemble the full API URL
        getApiEndpoint('users/me'),
        { password: newPasswordHash },
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .pipe(map((results) => UserModel.fromJson(results)));
  }

  public verifySMS(
    accessToken?: string | null,
    firebaseUserId?: string | null,
    firebaseAuthToken?: string | null
  ): Observable<{ is_verified: boolean }> {
    let headers: Params = {
      'Content-Type': 'application/json; charset=utf-8',
    };
    if (accessToken) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return this.http
      .post<any>(
        // Assemble the full API URL
        getApiEndpoint('users/me/sms/verification'),
        {
          firebase_user_id: firebaseUserId || null,
          firebase_auth_token: firebaseAuthToken || null,
        },
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(
        map((results?: any | null) => ({
          is_verified: Boolean(results?.is_verified),
        }))
      );
  }

  public initResetPassword(email: string): Observable<string> {
    const options = new HttpParams().set('email', email);

    return this.http
      .post<any>(
        // Assemble the full API URL
        getApiEndpoint('register/lost-password'),
        {},
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
        }
      )
      .pipe(map(() => ''));
  }
}
