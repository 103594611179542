import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpParams,
  HttpEventType,
} from '@angular/common/http';
import { configureScope } from '@sentry/browser';
import { Observable, EMPTY, tap } from 'rxjs';
import { environment } from '@environments/environment';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { NetworkErrorHandler } from '@app/shared/utils/network.error.handler';
import { HttpStatusCode } from '@app/shared/utils/http-status-codes';
import { AkitaAuthService } from '@app/akita/api/auth/state/auth.service';
import { ApiCallsTrackerService } from '../services/api-calls-tracker.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { Params } from '@angular/router';
import { guid } from '@datorama/akita';
import { Md5 } from 'ts-md5';
import { isPlatformBrowser } from '@angular/common';
import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';

const NO_CREDENTIAL_DOMAINS = ['viacep.com.br'];

// const BYPASS_DOMAIN_LIST = [
//   'api.mypopsy.com',
//   'popsygae-dev.appspot.com',
//   'popsygae-staging.appspot.com',
//   'popsygae.appspot.com',
//   'api.popsy.app',
//   'api-dev.popsy.app',
//   'api-staging.popsy.app',
//   'api.popsy.com.br',
//   'api-dev.popsy.com.br',
//   'api-staging.popsy.com.br',
//   'popsy.zendesk.com',
// ];

// const API_DOMAIN_LIST = [
//   'api.mypopsy.com',
//   'popsygae-dev.appspot.com',
//   'popsygae-staging.appspot.com',
//   'popsygae.appspot.com',
//   'api.popsy.app',
//   'api-dev.popsy.app',
//   'api-staging.popsy.app',
// ];

const BYPASS_CREDENTIALS_LIST = ['popsy.zendesk.com'];

@Injectable({
  providedIn: 'root',
})
export class ErrorHttpInterceptor implements HttpInterceptor {
  public readonly NO_CACHE_RAND_NUMBER = 1000000000;
  private akitaAuthService?: AkitaAuthService | null;
  private akitaAuthQuery?: AkitaAuthQuery | null;
  private sessionId: string;
  private readonly isBrowser: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly apiCallsTrackerService: ApiCallsTrackerService,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, any>
  ) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    this.sessionId = `${Md5.hashStr(guid(), false) as string}`.slice(0, 6);
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      this.akitaAuthService = this.injector.get(AkitaAuthService);
    } catch (err) {
      this.akitaAuthService = null;
    }

    try {
      this.akitaAuthQuery = this.injector.get(AkitaAuthQuery);
    } catch (err) {
      this.akitaAuthQuery = null;
    }

    if (NetworkErrorHandler.tooManyErrors) {
      return EMPTY;
    }

    // Disable Request Cache
    // const urlWithParams = req.urlWithParams || '';
    const newHeaders = req.headers;
    let params: HttpParams = req.params;

    // let ngswShouldBypassThisURL = false;
    // for (const domain of BYPASS_DOMAIN_LIST) {
    //   if (req.url.indexOf(domain) !== -1) {
    //     ngswShouldBypassThisURL = true;
    //     break;
    //   }
    // }

    if (
      this.akitaAuthQuery?.sessionId &&
      this.sessionId !== this.akitaAuthQuery?.sessionId
    ) {
      this.sessionId = this.akitaAuthQuery.sessionId;
    }

    // Add a session ID to URL
    // except for the /api/v2/product

    if (
      this.sessionId &&
      this.akitaAuthService?.setSessionId &&
      req.url.indexOf('/api/v2/product') === -1
    ) {
      this.akitaAuthService.setSessionId(`${this.sessionId}`);
      if (params.get('psid')) {
        params.delete('psid');
      }
      params = params.set('psid', `${this.sessionId}`);
    }

    // Bypass Service Worker for calls to the popsy API
    // if (ngswShouldBypassThisURL) {
    //   // params = params.set('ngsw-bypass', 'true');

    //   if (
    //     !params.has('no-cache') &&
    //     // Avoid disabling cache for categories
    //     urlWithParams.indexOf('countries') === -1 &&
    //     urlWithParams.indexOf('geocoder') === -1 &&
    //     urlWithParams.indexOf('oauth') === -1
    //   ) {
    //     params = params.set(
    //       'no-cache',
    //       `${Math.round(Math.random() * this.NO_CACHE_RAND_NUMBER)}`
    //     );
    //   }
    // }

    let cloneOptions: Params = {
      headers: newHeaders,
      params: params,
      withCredentials: true,
    };

    for (const domain of NO_CREDENTIAL_DOMAINS) {
      if (req.url.indexOf(domain) !== -1) {
        cloneOptions = {
          headers: newHeaders,
          params: params,
          withCredentials: false,
        };
        break;
      }
    }

    // For some URIs adding credentials would create CORS Issues in Chrome (because they have a '*' wildcard)
    for (const domain of BYPASS_CREDENTIALS_LIST) {
      if (req.url.indexOf(domain) !== -1) {
        cloneOptions.withCredentials = undefined;
        break;
      }
    }

    const nextReq = req.clone(cloneOptions);

    if (environment.reportToSentry) {
      configureScope((scope) => {
        scope.setTag('http:lastHttpCall:method', req.method);
        scope.setTag('http:lastHttpCall', req.url);
      });
    }

    this.apiCallsTrackerService.addApiCall(`[${req.method.toUpperCase()}] ${req.url}`);

    // Leave the renew session for SessionRestoreInterceptor
    // if (
    //   this.akitaAuthQuery.isLoggedIn &&
    //   !this.akitaAuthQuery.isSessionActive &&
    //   urlWithParams.indexOf('oauth') === -1
    // ) {
    //   for (const domain of API_DOMAIN_LIST) {
    //     if (req.url.indexOf(domain) !== -1) {
    //       return this.akitaAuthService
    //         .renewSessionSync()
    //         .pipe(switchMap(() => this.retryAPICall(req, next, nextReq)));
    //     }
    //   }
    // }

    return this.retryAPICall(req, next, nextReq);
  }

  public retryAPICall(
    req: HttpRequest<any>,
    next: HttpHandler,
    nextReq: HttpRequest<any>
  ): Observable<HttpEvent<any>> {
    return next.handle(nextReq).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event && event.type === HttpEventType.Response) {
            this.apiCallsTrackerService.removeApiCall(
              `[${req.method.toUpperCase()}] ${req.url}`
            );

            if (!this.isBrowser) {
              console.log(
                JSON.stringify({
                  httpRequest: {
                    requestMethod: `${req?.method || '??'}`,
                    requestUrl: `${req.urlWithParams || req?.url || '??'}`,
                    status: `${event?.status || '??'}`,
                    statusText: event?.statusText || undefined,
                    psid: `${this.sessionId}`,
                  },
                  // textPayload: req?.body || event?.body || undefined,
                  severity: event?.ok ? 'INFO' : 'ERROR',
                  labels: {
                    psid: `${this.sessionId}`,
                    statusText: event?.statusText || undefined,
                  },
                })
              );
            }
          }
        },
        error: (err: unknown) => {
          const parsed = err as any;
          this.apiCallsTrackerService.removeApiCall(
            `[${req.method.toUpperCase()}] ${req.url}`
          );

          if (!this.isBrowser) {
            console.log(
              JSON.stringify({
                httpRequest: {
                  requestMethod: `${req?.method || '??'}`,
                  requestUrl: `${req.urlWithParams || req?.url || '??'}`,
                  status: `${parsed?.status || parsed?.error?.status || '??'}`,
                  psid: `${this.sessionId}`,
                },
                // textPayload: req?.body || parsed?.body || parsed?.error || undefined,
                severity: 'ERROR',
                labels: {
                  psid: `${this.sessionId}`,
                },
              })
            );
          }

          // Ignore errors related to API calls to conversation (the poll runs so often that overflows sentry)
          if (req.url.indexOf('/users/me/conversations') === -1) {
            configureScope((scope) => {
              scope.setTag('http:failedHttpCall:method', req.method);
              scope.setTag('http:failedHttpCall', req.url);
            });

            if (
              this.akitaAuthService &&
              parsed &&
              (parsed.status === HttpStatusCode.UNAUTHORIZED ||
                (parsed.error && parsed.error.status === HttpStatusCode.UNAUTHORIZED))
            ) {
              this.akitaAuthService.renewSession();
            }

            if (parsed.error instanceof Error) {
              SentryUtil.addBreadcrumb({
                category: 'network',
                message: `Error: "${parsed.error.message}" (Status: ${
                  parsed?.error?.status || '??'
                })`,
                level: 'error',
                type: 'http',
              });
              SentryUtil.reportException(parsed, false, () => (parsedError: string) => {
                this.googleAnalyticsService.appException(
                  parsedError,
                  'network',
                  `${parsed?.error?.status || '-'}`,
                  false,
                  'HTTP_INTERCEPTOR',
                  'ErrorHttpInterceptor -> retryAPICall'
                );
              });
            } else if (err instanceof HttpErrorResponse) {
              // Ignore errors related to languages not available
              if (
                (req.url || '').indexOf('/assets/i18n/') !== -1 &&
                err.status === HttpStatusCode.NOT_FOUND
              ) {
                console.warn(
                  `The language "${req.url}" is not available (Falling back to English)`
                );
                return;
              }

              SentryUtil.addBreadcrumb({
                category: 'network',
                message: `HttpErrorResponse (${err.status})-{ ${err.name} } Message { ${err.message} }`,
                level: 'error',
                type: 'http',
              });

              if ((err.message || '').indexOf('Loading chunk') !== -1) {
                console.warn(
                  'There was a network error, browser tab might need a refresh.'
                );
              }

              SentryUtil.reportException(err, false, () => (parsedError: string) => {
                this.googleAnalyticsService.appException(
                  parsedError,
                  'network',
                  `${err?.status || '-'}`,
                  false,
                  'HTTP_INTERCEPTOR',
                  'ErrorHttpInterceptor -> retryAPICall'
                );
              });
            } else {
              SentryUtil.reportException(err, false, () => (parsedError: string) => {
                this.googleAnalyticsService.appException(
                  parsedError,
                  'network',
                  `${parsed?.status || '-'}`,
                  false,
                  'HTTP_INTERCEPTOR',
                  'ErrorHttpInterceptor -> retryAPICall'
                );
              });
            }
          }
        },
      })
    );
  }
}
