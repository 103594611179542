import { Coordinate } from '@app/akita/api/location/models/coordinate.model';
import localForage from 'localforage';
import { Observable, defer, Observer, Subscription } from 'rxjs';
import { LocalForageInstance } from '../models/localForageInstance.interface';

const PREFERRED_MAPS_ADDRESS_TYPES = [
  'street_address',
  'premise',
  'route',
  'establishment',
];

export class GoogleMapsUtils {
  private static get cache(): LocalForageInstance {
    try {
      return localForage.createInstance({
        name: 'popsy',
        description: 'Cache of coordinates to avoid using the Geocoder too much',
        version: 1,
        storeName: 'coordinates',
      });
    } catch (error) {
      // Cache not Available
    }

    return {
      setItem: () =>
        new Promise(() => {
          throw new Error('NOT_SUPPORTED');
        }),
      getItem: () =>
        new Promise(() => {
          throw new Error('NOT_SUPPORTED');
        }),
    } as any;
  }

  public static geoCodeCoordinateAddress(
    rawCoordinate: Coordinate,
    locale: string
  ): Promise<Coordinate | null> {
    const subscription = new Subscription();
    return new Promise((resolve, reject) => {
      if (!rawCoordinate) {
        if (subscription) {
          subscription.unsubscribe();
        }
        reject('Coordinate is empty or null');
        return;
      }

      const coordinate = Coordinate.fromJson(rawCoordinate) || new Coordinate();
      subscription.add(
        GoogleMapsUtils.getAddressCache(
          coordinate.latitude,
          coordinate.longitude,
          locale
        ).subscribe({
          next: (cacheCoordinate: Coordinate | null) => {
            if (cacheCoordinate && cacheCoordinate.googleGeocoder) {
              if (subscription) {
                subscription.unsubscribe();
              }
              resolve(cacheCoordinate);
              return;
            }

            // Guard from Google library not being loaded
            if (typeof google !== 'undefined' && google.maps) {
              const latLng = new google.maps.LatLng(
                coordinate.latitude,
                coordinate.longitude
              );
              const geoCoder = new google.maps.Geocoder();

              // convert coordinates to string location value
              geoCoder
                .geocode(
                  {
                    location: latLng,
                  },
                  (
                    results: google.maps.GeocoderResult[] | null,
                    status: google.maps.GeocoderStatus
                  ): void => {
                    let statusAsText = '';
                    if (status === google.maps.GeocoderStatus.OK) {
                      statusAsText = 'OK';

                      let bestResult = findBestAddress(results);
                      if (!bestResult) {
                        bestResult = (results || [])[0] || null;
                      }

                      const gMapsAddress = GoogleMapsUtils.formatGMapsAddress(
                        bestResult?.address_components || []
                      );
                      const gmapsCoordinate =
                        Coordinate.fromJson(coordinate) || new Coordinate();
                      gmapsCoordinate.address = (results || [])[0].formatted_address;
                      gmapsCoordinate.shortAddress = gMapsAddress.shortAddress;
                      gmapsCoordinate.city = gMapsAddress.city;
                      gmapsCoordinate.area = gMapsAddress.area;
                      gmapsCoordinate.region = gMapsAddress.region;
                      gmapsCoordinate.regionCode = gMapsAddress.regionCode;
                      gmapsCoordinate.country = gMapsAddress.countryCode;
                      gmapsCoordinate.countryName = gMapsAddress.countryName;
                      gmapsCoordinate.streetName = gMapsAddress.streetName;
                      gmapsCoordinate.streetNumber = gMapsAddress.streetNumber;
                      gmapsCoordinate.postalCode = gMapsAddress.postalCode;
                      gmapsCoordinate.googleGeocoder = true;
                      gmapsCoordinate.updatedAt = new Date();

                      // Update the cache
                      subscription.add(
                        GoogleMapsUtils.updateAddressCache(
                          gmapsCoordinate.getLatLng(),
                          gmapsCoordinate,
                          locale
                        ).subscribe({
                          next: () => {
                            if (subscription) {
                              subscription.unsubscribe();
                            }
                            resolve(gmapsCoordinate);
                          },
                          error: () => {
                            if (subscription) {
                              subscription.unsubscribe();
                            }
                            resolve(gmapsCoordinate);
                          },
                        })
                      );
                    } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                      if (subscription) {
                        subscription.unsubscribe();
                      }
                      statusAsText = 'ZERO_RESULTS';
                      resolve(null);
                    } else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                      if (subscription) {
                        subscription.unsubscribe();
                      }
                      statusAsText = 'OVER_QUERY_LIMIT';
                      resolve(null);
                    } else if (status === google.maps.GeocoderStatus.REQUEST_DENIED) {
                      statusAsText = 'REQUEST_DENIED';
                    } else if (status === google.maps.GeocoderStatus.INVALID_REQUEST) {
                      statusAsText = 'INVALID_REQUEST';
                    } else if (status === google.maps.GeocoderStatus.UNKNOWN_ERROR) {
                      statusAsText = 'UNKNOWN_ERROR';
                    } else if (status === google.maps.GeocoderStatus.ERROR) {
                      statusAsText = 'ERROR';
                    }

                    if (
                      status !== google.maps.GeocoderStatus.OK &&
                      status !== google.maps.GeocoderStatus.ZERO_RESULTS &&
                      status !== google.maps.GeocoderStatus.OVER_QUERY_LIMIT
                    ) {
                      if (subscription) {
                        subscription.unsubscribe();
                      }
                      reject(`Google GeoCoder -> ${statusAsText}`);
                    }
                  }
                )
                .then(() => {})
                .catch(() => {});
            } else {
              if (subscription) {
                subscription.unsubscribe();
              }
              reject('Google Maps API not loaded');
            }
          },
          error: () => {},
        })
      );
    });
  }

  /**
   * Generates a short string of the address from a google maps Address Component array
   * example: {postal-code}, {city}, {country} --> DD2 4AQ, Dundee, United Kingdom
   * - If a component of the address is missing, it won't be added to the string.
   * @param {google.maps.GeocoderAddressComponent[]} addressComponents
   * @returns {any}
   */
  public static formatGMapsAddress(
    addressComponents: google.maps.GeocoderAddressComponent[]
  ): {
    shortAddress: string;
    countryName: string;
    countryCode: string;
    city: string;
    region: string;
    area: string;
    regionCode: string;
    postalCode: string;
    streetNumber: string;
    streetName: string;
  } {
    // Get the Street Number
    const streetNumber = addressComponents.find(
      (addressComponent: google.maps.GeocoderAddressComponent) =>
        addressComponent.types.indexOf('street_number') !== -1
    );

    // Get the Street Name
    const streetName = addressComponents.find(
      (addressComponent: google.maps.GeocoderAddressComponent) =>
        addressComponent.types.indexOf('route') !== -1
    );

    // Get the Postal Code
    const postalCode = addressComponents.find(
      (addressComponent: google.maps.GeocoderAddressComponent) =>
        addressComponent.types.indexOf('postal_code') !== -1
    );

    // Get the Country
    const country = addressComponents.find(
      (addressComponent: google.maps.GeocoderAddressComponent) =>
        addressComponent.types.indexOf('country') !== -1
    );

    // Get the City
    let city = addressComponents.find(
      (addressComponent: google.maps.GeocoderAddressComponent) =>
        addressComponent.types.indexOf('locality') !== -1
    );

    if (!city) {
      city = addressComponents.find(
        (addressComponent: google.maps.GeocoderAddressComponent) =>
          addressComponent.types.indexOf('administrative_area_level_2') !== -1
      );

      if (!city) {
        city = addressComponents.find(
          (addressComponent: google.maps.GeocoderAddressComponent) =>
            addressComponent.types.indexOf('administrative_area_level_1') !== -1
        );
      }
    }

    // Get the Region
    const region = addressComponents.find(
      (addressComponent: google.maps.GeocoderAddressComponent) =>
        addressComponent.types.indexOf('administrative_area_level_1') !== -1
    );

    // Get the zone / area / block
    const area = addressComponents.find(
      (addressComponent: google.maps.GeocoderAddressComponent) =>
        addressComponent.types.indexOf('sublocality') !== -1
    );

    return {
      shortAddress: Coordinate.buildShortAddress(
        city ? city.long_name : '',
        country ? country.long_name : '',
        postalCode ? postalCode.long_name : ''
      ),
      countryName: country ? country.long_name : '',
      countryCode: (country ? country.short_name : '').toLowerCase(),
      city: city ? city.long_name : '',
      area: area ? area.long_name : '',
      region: region ? region.long_name : '',
      regionCode: (region ? region.short_name : '').toLowerCase(),
      postalCode: postalCode ? postalCode.long_name : '',
      streetNumber: streetNumber ? streetNumber.long_name : '',
      streetName: streetName ? streetName.short_name : '',
    };
  }

  public static getAddressCache(
    latitude: number,
    longitude: number,
    locale: string
  ): Observable<Coordinate | null> {
    return defer(async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const latLng = `${(latitude || 0).toFixed(6)},${(longitude || 0).toFixed(6)}`;
        const rawCoordinate = await GoogleMapsUtils.cache.getItem<Coordinate>(
          `${locale}:${latLng}`
        );
        const coordinate = Coordinate.fromJson(rawCoordinate) || new Coordinate();

        if (
          !coordinate.city ||
          coordinate.address === `${coordinate.city}, ${coordinate.country}` ||
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          coordinate.countryName.length <= 2
        ) {
          return null;
        }

        return coordinate;
      } catch (error) {
        // Cache not Available
      }
      return null;
    });
  }

  public static updateAddressCache(
    latLng: string,
    coordinate: Coordinate,
    locale: string
  ): Observable<Coordinate | null> {
    return new Observable((observer: Observer<Coordinate | null>) => {
      // Add to cache only if address is valid (Don't add addresses like `Vigo, ES`)
      if (
        coordinate &&
        coordinate.country &&
        (coordinate.city || coordinate.googleGeocoder) &&
        coordinate.address &&
        coordinate.address !== `${coordinate.city || ''}, ${coordinate.country}` &&
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        coordinate.countryName.length > 2
      ) {
        GoogleMapsUtils.cache
          .setItem<Coordinate>(`${locale}:${latLng}`, coordinate)
          .then(() => {
            observer.next(coordinate);
            observer.complete();
          })
          .catch(() => {
            // Cache not Available
            observer.next(null);
            observer.complete();
          });
      }

      observer.next(null);
      observer.complete();
    });
  }
}

export const findBestAddress = (
  results?: google.maps.GeocoderResult[] | null
): google.maps.GeocoderResult | null => {
  if (results) {
    let bestResult: { pos: number; result: google.maps.GeocoderResult } | null = null;

    for (const result of results) {
      for (const typeName of result.types || []) {
        const position = PREFERRED_MAPS_ADDRESS_TYPES.indexOf(typeName);
        if (position === 0) {
          return result;
        } else if (position >= 1) {
          if (bestResult && position < bestResult.pos) {
            bestResult = {
              pos: position,
              result: result,
            };
          }
        }
      }
    }

    if (bestResult) {
      return bestResult.result;
    }
  }
  return null;
};
