import { parseProductList, Product } from './product.model';

export class ProductSearchResults {
  public results: Array<Product>;
  public next: string | null;

  constructor() {
    this.results = new Array(0);
    this.next = null;
  }
}

export const parseProductSearchResults = (
  data?: Record<string, any> | null
): ProductSearchResults => {
  const results = new ProductSearchResults();
  if (data) {
    results.results = parseProductList(data.results);
    results.next = data.next || null;
  }
  return results;
};
