import { inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AkitaAuthQuery } from '../../auth/state/auth.query';
import { TranslocoService } from '@ngneat/transloco';
import {
  OmniSendIdentifyUser,
  parseOmniSendIdentifyUser,
} from '../models/omnisend-itentify-response.model';
import { OmnisendIdentifyParams } from '../models/omnisend-identify-params.model';
import { DEFAULT_AVAILABLE_LANGUAGES } from '../../products/models/products.state';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class OmnisendAPIService {
  private readonly http = inject(HttpClient);
  private readonly translateService = inject(TranslocoService);

  constructor(private readonly akitaAuthQuery: AkitaAuthQuery) {}

  public identifyUser(
    userInfo: OmnisendIdentifyParams
  ): Observable<OmniSendIdentifyUser> {
    const language = userInfo?.lang || this.translateService.getActiveLang();
    const info: OmnisendIdentifyParams = {
      ...(userInfo || {}),
      id: userInfo?.id || this.akitaAuthQuery.user?.id,
      email: userInfo?.email || this.akitaAuthQuery.user?.email,
      lang: DEFAULT_AVAILABLE_LANGUAGES.includes(language) ? language : 'en',
      country: `${
        userInfo?.country || this.akitaAuthQuery.user?.location?.country || 'US'
      }`.toUpperCase(),
      opt_in: userInfo.opt_in || this.akitaAuthQuery.user?.opt_in || false,
    };

    if (!(info?.id || info?.email || info?.phone?.national)) {
      return of(new OmniSendIdentifyUser());
    }

    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${credentials}`,
    });

    return this.http
      .post<unknown>(
        // Assemble the full API URL
        getApiEndpoint(`omnisend`),
        {
          id: info?.id || undefined,
          first_name: info?.firstName || undefined,
          last_name: info?.lastName || undefined,
          email: info?.email || undefined,
          phone: info?.phone
            ? {
                // id/email/phone is required
                phone_country_code: info?.phone?.countryCode || undefined,
                phone_number: info?.phone?.national || undefined,
                phone_area_code: info?.phone?.areaCode || undefined,
              }
            : undefined,
          lang: info.lang,
          country: info.country,
          opt_in: info.opt_in,
        },
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(map((response: unknown) => parseOmniSendIdentifyUser(response)));
  }
}
