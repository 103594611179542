import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AkitaUsersState, AkitaUsersStore } from './users.store';
import { UserModel } from '@app/shared/models/api/user.model';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AkitaUsersQuery extends QueryEntity<AkitaUsersState> {
  constructor(protected store: AkitaUsersStore) {
    super(store);
  }

  public selectUser(userId: string): Observable<UserModel | null> {
    return this.selectEntity(userId).pipe(
      map((user?: UserModel | null) => UserModel.fromJson(user))
    );
  }

  public getUser(userId: string): UserModel | null {
    return UserModel.fromJson(this.getEntity(userId));
  }

  public selectIsUpdatingUserInfo(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaUsersState) => Boolean(state.updatingUserInformation))
    );
  }
}
