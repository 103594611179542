import { PreloadingStrategy, Route } from '@angular/router';

import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PopsyPreloader implements PreloadingStrategy {
  public preload(route: Route | null, load: () => Observable<any>): Observable<any> {
    return route?.data?.preload ? load() : of(null);
  }
}
