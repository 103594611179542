import { Injectable, NgZone } from '@angular/core';

import { environment } from '@environments/environment';
import { getFirebaseConfig } from '@app/shared/utils/url.utils';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { Observable, Observer } from 'rxjs';
import { MessageRegistrationCheckerService } from './message-registration-checker.service';
import { Params } from '@angular/router';

import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import {
  analyticsGet,
  analyticsIsSupported,
  firebaseInitializeApp,
  remoteConfigGet,
  remoteConfigIsSupported,
} from '@app/shared/firebase/firebase.wrapper';

const USER_ID_KEY = 'user_id';

const GTAG_SET_COMMAND = 'set';
const GTAG_EVENT_COMMAND = 'event';
const GTAG_CONFIG_COMMAND = 'config';

const PRODUCT_PAGE_VIEW_AD_CONVERSION = 'aUHgCPrrxuUBEMPj2b0D';
const ADD_TO_CART_AD_CONVERSION = '0WOzCNzTxuUBEMPj2b0D';
const COMPLETE_ADDRESS_AND_PHONE_AD_CONVERSION = 'fY35CJqizuUBEMPj2b0D';
const PAYMENT_METHOD_SELECTED_AD_CONVERSION = 'ieKdCPWvzuUBEMPj2b0D';
const PURCHASE_AD_CONVERSION = 'Hk2pCJjzvOUBEMPj2b0D';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  public static FCM_LINKED_TO_SERVICE_WORKER: boolean;
  private firebaseApp: any | null = null;
  private readonly firebaseAnalytics: any | null = null;
  private firebaseConfig: any | null = null;
  private readonly extraAnalyticsKey: string | null = null;
  private readonly adsTrackingKey: string | null = null;
  private readonly gtmTrackingKey: string | null = null;

  constructor(
    private readonly zone: NgZone,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly messageRegistrationCheckerService: MessageRegistrationCheckerService
  ) {
    this.firebaseApp = null;
    this.firebaseConfig = getFirebaseConfig();

    try {
      if ((window as any).POPSY_EXTRA_ANALYTICS_KEY) {
        this.extraAnalyticsKey = (window as any).POPSY_EXTRA_ANALYTICS_KEY || null;
      }
      if ((window as any).POPSY_ADS_TRACKING_KEY) {
        this.adsTrackingKey = (window as any).POPSY_ADS_TRACKING_KEY || null;
      }
      if ((window as any).POPSY_GTM_TRACKING_KEY) {
        this.gtmTrackingKey = (window as any).POPSY_GTM_TRACKING_KEY || null;
      }
    } catch (error) {}
  }

  public updateConfig(
    config: Gtag.ControlParams | Gtag.EventParams | Gtag.ConfigParams | Gtag.CustomParams
  ): void {
    try {
      if (this.firebaseConfig?.measurementId && gtag) {
        gtag(GTAG_CONFIG_COMMAND, this.firebaseConfig.measurementId, {
          ...config,
          update: true,
        });
      }

      if (this.extraAnalyticsKey && gtag) {
        gtag(GTAG_CONFIG_COMMAND, this.extraAnalyticsKey, {
          ...config,
          update: true,
        });
      }

      if (this.adsTrackingKey && gtag) {
        gtag(GTAG_CONFIG_COMMAND, this.adsTrackingKey, {
          ...config,
          update: true,
        });
      }

      if (this.gtmTrackingKey && gtag) {
        gtag(GTAG_CONFIG_COMMAND, this.gtmTrackingKey, {
          ...config,
          update: true,
        });
      }
    } catch (error) {}
  }

  private recordADConversion(id: string, params?: Params | null): void {
    if (this.adsTrackingKey && gtag) {
      gtag('event', 'conversion', {
        ...(params || {}),
        send_to: `${this.adsTrackingKey}/${id}`,
      });
    }
  }

  public get recordProductPageViewADConversion(): () => void {
    return () => {
      this.recordADConversion(PRODUCT_PAGE_VIEW_AD_CONVERSION);
    };
  }

  public recordAddToCartADConversion(): void {
    this.recordADConversion(ADD_TO_CART_AD_CONVERSION);
  }

  public recordAddressAndPhoneADConversion(): void {
    this.recordADConversion(COMPLETE_ADDRESS_AND_PHONE_AD_CONVERSION, {
      value: 0.0,
      currency: 'USD',
    });
  }

  public recordPaymentMethodSelectedADConversion(): void {
    this.recordADConversion(PAYMENT_METHOD_SELECTED_AD_CONVERSION, {
      value: 1.0,
      currency: 'USD',
    });
  }

  public recordPurchaseADConversion(
    deliveryID: string,
    value?: number | null,
    currency?: string | null
  ): void {
    let conversion = {
      value: 1.0,
      currency: 'USD',
    };

    if (value || value === 0) {
      conversion = {
        value: value,
        currency: currency || 'USD',
      };
    }

    this.recordADConversion(PURCHASE_AD_CONVERSION, {
      value: conversion.value,
      currency: conversion.currency,
      transaction_id: deliveryID,
    });
  }

  public registerFCMInOwnFirebaseServiceWorker(): Observable<void> {
    // Firebase will use it's own SW in the next step, this empty method is just to keep the same flow
    return new Observable((observer: Observer<void>) => {
      this.messageRegistrationCheckerService.markAsRegistered();
      observer.next();
      observer.complete();
    });
  }

  public setUserId(id: string | null): void {
    this.updateConfig({
      [USER_ID_KEY]: id,
    });

    try {
      if (gtag) {
        gtag(GTAG_SET_COMMAND, {
          user_id: `${id}`,
        });
      }
    } catch (error) {}
  }

  public logEventToAnalytics(
    name: Gtag.EventNames | string,
    eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
    category?: string | null,
    label?: string | null
  ): void {
    try {
      if (this.extraAnalyticsKey && gtag) {
        gtag(GTAG_EVENT_COMMAND, name, {
          ...(eventParams || {}),
          event_category: category || 'Custom',
          event_label: label || 'User Action',
          send_to: `${this.extraAnalyticsKey}`,
        });
      }

      if (this.gtmTrackingKey && gtag) {
        gtag(GTAG_EVENT_COMMAND, name, {
          ...(eventParams || {}),
          send_to: `${this.gtmTrackingKey}`,
        });
      }
    } catch (error) {}
  }

  private initializeOrRehuseApp(currentApp?: any | null, config?: any | null): any {
    if (currentApp) {
      return currentApp;
    }

    return firebaseInitializeApp(config || environment.firebase);
  }

  private get app(): any | null {
    try {
      if (!this.firebaseConfig) {
        this.firebaseConfig = environment.firebase;
      }

      this.firebaseApp = this.initializeOrRehuseApp(
        this.firebaseApp,
        this.firebaseConfig
      );
      SentryUtil.addObjectKeysToScope('firebase', {
        app: `${this.firebaseApp?.name || '?'}`,
        ready: 'YES',
      });

      return this.firebaseApp;
    } catch (error) {
      SentryUtil.addObjectKeysToScope('firebase', {
        app: `-`,
        ready: 'NO',
      });
      console.warn(error);
      return null;
    }
  }

  public get analytics(): Promise<any | null> {
    return new Promise(
      (
        resolve: (messaging: any | null) => void,
        reject: (reason?: any | null) => void
      ) => {
        if (this.akitaRouterQuery.isBrowser) {
          this.zone.runOutsideAngular(() => {
            analyticsIsSupported()
              .then((isSupported?: boolean | null) => {
                if (isSupported) {
                  if (this.firebaseAnalytics) {
                    resolve(this.firebaseAnalytics);
                  } else {
                    try {
                      const analyticsInstance = analyticsGet(this.app || undefined);
                      resolve(analyticsInstance);
                    } catch (error: unknown) {
                      reject(error);
                    }
                  }
                } else {
                  resolve(null);
                }
              })
              .catch((error: unknown) => {
                reject(error);
              });
          });
        } else {
          resolve(null);
        }
      }
    );
  }

  public get remoteConfig(): Promise<any | null> {
    return new Promise(
      (
        resolve: (messaging: any | null) => void,
        reject: (reason?: any | null) => void
      ) => {
        if (this.akitaRouterQuery.isBrowser) {
          this.zone.runOutsideAngular(() => {
            remoteConfigIsSupported()
              .then((isSupported?: boolean | null) => {
                if (isSupported) {
                  try {
                    const remoteConfigInstance = remoteConfigGet(this.app || undefined);
                    resolve(remoteConfigInstance);
                  } catch (error: unknown) {
                    reject(error);
                  }
                } else {
                  resolve(null);
                }
              })
              .catch((error: unknown) => {
                reject(error);
              });
          });
        } else {
          resolve(null);
        }
      }
    );
  }

  /* public get auth(): any | null {
    try {
      if (this.akitaRouterQuery.isBrowser) {
        const auth = authGet(this.app || undefined);
        auth.useDeviceLanguage();
        return auth;
      }
      return null;
    } catch (error) {
      // SSR / Tests not supported
      return null;
    }
  } */

  /* public get messaging(): Promise<any | null> {
    return new Promise(
      (
        resolve: (messaging: any | null) => void,
        reject: (reason?: any | null) => void
      ) => {
        if (this.akitaRouterQuery.isBrowser) {
          this.zone.runOutsideAngular(() => {
            messagingIsSupported()
              .then((isSupported?: boolean | null) => {
                if (isSupported) {
                  try {
                    const messagingInstance = messagingGet(this.app || undefined);
                    resolve(messagingInstance);
                  } catch (error: unknown) {
                    reject(error);
                  }
                } else {
                  resolve(null);
                }
              })
              .catch((error: unknown) => {
                reject(error);
              });
          });
        } else {
          resolve(null);
        }
      }
    );
  } */
}
