import {
  fetchAndActivate,
  getBoolean,
  getString,
  activate,
  getRemoteConfig,
  isSupported as isRemoteConfigSupported,
} from '@firebase/remote-config';

import { signInWithPhoneNumber, getAuth } from '@firebase/auth';

import {
  getToken,
  onMessage,
  deleteToken,
  getMessaging,
  isSupported as isMessagingSupported,
} from '@firebase/messaging';

import {
  logEvent,
  setCurrentScreen,
  setUserProperties,
  getAnalytics,
  isSupported as isAnalyticsSupported,
} from '@firebase/analytics';

import { initializeApp } from '@firebase/app';

export const remoteConfigFetchAndActivate = (remoteConfig: any): Promise<boolean> =>
  fetchAndActivate(remoteConfig);

export const remoteConfigGetString = (remoteConfig: any, key: string): string =>
  getString(remoteConfig, key);

export const remoteConfigGetBoolean = (remoteConfig: any, key: string): boolean =>
  getBoolean(remoteConfig, key);

export const remoteConfigActivate = (remoteConfig: any): Promise<boolean> =>
  activate(remoteConfig);

export const remoteConfigGet = (app: any): any => getRemoteConfig(app);
export const remoteConfigIsSupported = (): Promise<boolean> => isRemoteConfigSupported();

export const authSignInWithPhoneNumber = (
  auth: any,
  phoneNumber: string,
  appVerifier: any
): Promise<any> => signInWithPhoneNumber(auth, phoneNumber, appVerifier);

export const authGet = (app: any): any => getAuth(app);

export const messagingGetToken = (messaging: any, options?: any): Promise<string> =>
  getToken(messaging, options);

export const messagingDeleteToken = (messaging: any): Promise<boolean> =>
  deleteToken(messaging);

export const messagingOnMessage = (messaging: any, nextOrObserver: any): any =>
  onMessage(messaging, nextOrObserver);

export const messagingGet = (app: any): any => getMessaging(app);
export const messagingIsSupported = (): Promise<boolean> => isMessagingSupported();

export const analyticsGet = (app: any): any => getAnalytics(app);
export const analyticsIsSupported = (): Promise<boolean> => isAnalyticsSupported();

export const analyticsSetUserProperties = (
  analyticsInstance: any,
  properties: any,
  options: any
): void => setUserProperties(analyticsInstance, properties, options);

export const analyticsSetCurrentScreen = (
  analyticsInstance: any,
  screenName: any,
  options: any
): void => setCurrentScreen(analyticsInstance, screenName, options);

export const analyticsLogEvent = (
  analyticsInstance: any,
  eventName: any,
  eventParams: any,
  options: any
): void => logEvent(analyticsInstance, eventName, eventParams, options);

export const firebaseInitializeApp = (options: any, name?: string): any =>
  initializeApp(options, name);
