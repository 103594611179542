import { MAIN_APP_ROUTES } from './app.routes';

export const APP_ROUTES = [
  /* { path: 'na', children: MAIN_APP_ROUTES, data: { country: 'na', locale: 'af', rtl: false } },
  { path: 'et', children: MAIN_APP_ROUTES, data: { country: 'et', locale: 'am', rtl: false } },
  { path: 'eg', children: MAIN_APP_ROUTES, data: { country: 'eg', locale: 'ar', rtl: true } },
  { path: 'az', children: MAIN_APP_ROUTES, data: { country: 'az', locale: 'az', rtl: false } },
  { path: 'by', children: MAIN_APP_ROUTES, data: { country: 'by', locale: 'be', rtl: false } },
  { path: 'bg', children: MAIN_APP_ROUTES, data: { country: 'bg', locale: 'bg', rtl: false } },
  { path: 'bd', children: MAIN_APP_ROUTES, data: { country: 'bd', locale: 'bn', rtl: false } },
  { path: 'ba', children: MAIN_APP_ROUTES, data: { country: 'ba', locale: 'bs', rtl: false } },
  { path: 'ad', children: MAIN_APP_ROUTES, data: { country: 'ad', locale: 'ca', rtl: false } },
  { path: 'cz', children: MAIN_APP_ROUTES, data: { country: 'cz', locale: 'cs', rtl: false } },
  { path: 'dk', children: MAIN_APP_ROUTES, data: { country: 'dk', locale: 'da', rtl: false } },
  { path: 'de', children: MAIN_APP_ROUTES, data: { country: 'de', locale: 'de', rtl: false } },
  { path: 'gr', children: MAIN_APP_ROUTES, data: { country: 'gr', locale: 'el', rtl: false } },
  { path: 'es', children: MAIN_APP_ROUTES, data: { country: 'es', locale: 'es', rtl: false } },
  { path: 'ee', children: MAIN_APP_ROUTES, data: { country: 'ee', locale: 'et', rtl: false } },
  { path: 'es-eu', children: MAIN_APP_ROUTES, data: { country: 'es-eu', locale: 'eu', rtl: false } },
  { path: 'af', children: MAIN_APP_ROUTES, data: { country: 'af', locale: 'af', rtl: false } },
  { path: 'fi', children: MAIN_APP_ROUTES, data: { country: 'fi', locale: 'fi', rtl: false } },
  { path: 'fr', children: MAIN_APP_ROUTES, data: { country: 'fr', locale: 'fr', rtl: false } },
  { path: 'es-ga', children: MAIN_APP_ROUTES, data: { country: 'es-ga', locale: 'gl', rtl: false } },
  { path: 'in-gj', children: MAIN_APP_ROUTES, data: { country: 'in-gj', locale: 'gu', rtl: false } },
  { path: 'il', children: MAIN_APP_ROUTES, data: { country: 'il', locale: 'he', rtl: true } },
  { path: 'in', children: MAIN_APP_ROUTES, data: { country: 'in', locale: 'hi', rtl: false } },
  { path: 'hr', children: MAIN_APP_ROUTES, data: { country: 'hr', locale: 'hr', rtl: false } },
  { path: 'hu', children: MAIN_APP_ROUTES, data: { country: 'hu', locale: 'hu', rtl: false } },
  { path: 'am', children: MAIN_APP_ROUTES, data: { country: 'am', locale: 'hy', rtl: false } },
  { path: 'id', children: MAIN_APP_ROUTES, data: { country: 'id', locale: 'id', rtl: false } },
  { path: 'it', children: MAIN_APP_ROUTES, data: { country: 'it', locale: 'it', rtl: false } },
  { path: 'jp', children: MAIN_APP_ROUTES, data: { country: 'jp', locale: 'ja', rtl: false } },
  { path: 'ge', children: MAIN_APP_ROUTES, data: { country: 'ge', locale: 'ka', rtl: false } },
  { path: 'kz', children: MAIN_APP_ROUTES, data: { country: 'kz', locale: 'kk', rtl: false } },
  { path: 'kh', children: MAIN_APP_ROUTES, data: { country: 'kh', locale: 'km', rtl: false } },
  { path: 'in-kn', children: MAIN_APP_ROUTES, data: { country: 'in-kn', locale: 'kn', rtl: false } },
  { path: 'kr', children: MAIN_APP_ROUTES, data: { country: 'kr', locale: 'ko', rtl: false } },
  { path: 'kg', children: MAIN_APP_ROUTES, data: { country: 'kg', locale: 'ky', rtl: false } },
  { path: 'la', children: MAIN_APP_ROUTES, data: { country: 'la', locale: 'lo', rtl: false } },
  { path: 'lt', children: MAIN_APP_ROUTES, data: { country: 'lt', locale: 'lt', rtl: false } },
  { path: 'lv', children: MAIN_APP_ROUTES, data: { country: 'lv', locale: 'lv', rtl: false } },
  { path: 'mk', children: MAIN_APP_ROUTES, data: { country: 'mk', locale: 'mk', rtl: false } },
  { path: 'in-kl', children: MAIN_APP_ROUTES, data: { country: 'in-kl', locale: 'ml', rtl: false } },
  { path: 'mn', children: MAIN_APP_ROUTES, data: { country: 'mn', locale: 'mn', rtl: false } },
  { path: 'mu', children: MAIN_APP_ROUTES, data: { country: 'mu', locale: 'mr', rtl: false } },
  { path: 'my', children: MAIN_APP_ROUTES, data: { country: 'my', locale: 'ms', rtl: false } },
  { path: 'mm', children: MAIN_APP_ROUTES, data: { country: 'mm', locale: 'my', rtl: false } },
  { path: 'np', children: MAIN_APP_ROUTES, data: { country: 'np', locale: 'ne', rtl: false } },
  { path: 'nl', children: MAIN_APP_ROUTES, data: { country: 'nl', locale: 'nl', rtl: false } },
  { path: 'no', children: MAIN_APP_ROUTES, data: { country: 'no', locale: 'no', rtl: false } },
  { path: 'pl', children: MAIN_APP_ROUTES, data: { country: 'pl', locale: 'pl', rtl: false } },
  { path: 'br', children: MAIN_APP_ROUTES, data: { country: 'br', locale: 'pt', rtl: false } },
  { path: 'ro', children: MAIN_APP_ROUTES, data: { country: 'ro', locale: 'ro', rtl: false } },
  { path: 'ru', children: MAIN_APP_ROUTES, data: { country: 'ru', locale: 'ru', rtl: false } },
  { path: 'lk', children: MAIN_APP_ROUTES, data: { country: 'lk', locale: 'si', rtl: false } },
  { path: 'sk', children: MAIN_APP_ROUTES, data: { country: 'sk', locale: 'sk', rtl: false } },
  { path: 'si', children: MAIN_APP_ROUTES, data: { country: 'si', locale: 'sl', rtl: false } },
  { path: 'al', children: MAIN_APP_ROUTES, data: { country: 'al', locale: 'sq', rtl: false } },
  { path: 'se', children: MAIN_APP_ROUTES, data: { country: 'se', locale: 'sv', rtl: false } },
  { path: 'tz', children: MAIN_APP_ROUTES, data: { country: 'tz', locale: 'sw', rtl: false } },
  { path: 'lk-ta', children: MAIN_APP_ROUTES, data: { country: 'lk-ta', locale: 'ta', rtl: false } },
  { path: 'in-te', children: MAIN_APP_ROUTES, data: { country: 'in-te', locale: 'te', rtl: false } },
  { path: 'th', children: MAIN_APP_ROUTES, data: { country: 'th', locale: 'th', rtl: false } },
  { path: 'ph', children: MAIN_APP_ROUTES, data: { country: 'ph', locale: 'tl', rtl: false } },
  { path: 'tr', children: MAIN_APP_ROUTES, data: { country: 'tr', locale: 'tr', rtl: false } },
  { path: 'ua', children: MAIN_APP_ROUTES, data: { country: 'ua', locale: 'uk', rtl: false } },
  { path: 'us', children: MAIN_APP_ROUTES, data: { country: 'us', locale: 'en', rtl: false } },
  { path: 'vn', children: MAIN_APP_ROUTES, data: { country: 'vn', locale: 'vi', rtl: false } },
  { path: 'ng', children: MAIN_APP_ROUTES, data: { country: 'ng', locale: 'yo', rtl: false } },
  { path: 'ug', children: MAIN_APP_ROUTES, data: { country: 'ug', locale: 'zh', rtl: false } },
  { path: 'za', children: MAIN_APP_ROUTES, data: { country: 'za', locale: 'zu', rtl: false } },
  { path: 'ag', children: MAIN_APP_ROUTES, data: { country: 'ag', locale: 'en', rtl: false } },
  { path: 'ai', children: MAIN_APP_ROUTES, data: { country: 'ai', locale: 'en', rtl: false } },
  { path: 'ao', children: MAIN_APP_ROUTES, data: { country: 'ao', locale: 'pt', rtl: false } },
  { path: 'aq', children: MAIN_APP_ROUTES, data: { country: 'aq', locale: 'en', rtl: false } },
  { path: 'aw', children: MAIN_APP_ROUTES, data: { country: 'aw', locale: 'nl', rtl: false } },
  { path: 'ax', children: MAIN_APP_ROUTES, data: { country: 'ax', locale: 'sv', rtl: false } },
  { path: 'bb', children: MAIN_APP_ROUTES, data: { country: 'bb', locale: 'en', rtl: false } },
  { path: 'as', children: MAIN_APP_ROUTES, data: { country: 'as', locale: 'as', rtl: false } },
  { path: 'at', children: MAIN_APP_ROUTES, data: { country: 'at', locale: 'de', rtl: false } },
  { path: 'au', children: MAIN_APP_ROUTES, data: { country: 'au', locale: 'en', rtl: false } },
  { path: 'be', children: MAIN_APP_ROUTES, data: { country: 'be', locale: 'be', rtl: false } },
  { path: 'bn', children: MAIN_APP_ROUTES, data: { country: 'bn', locale: 'bn', rtl: false } },
  { path: 'bo', children: MAIN_APP_ROUTES, data: { country: 'bo', locale: 'es', rtl: false } },
  { path: 'bs', children: MAIN_APP_ROUTES, data: { country: 'bs', locale: 'bs', rtl: false } },
  { path: 'bz', children: MAIN_APP_ROUTES, data: { country: 'bz', locale: 'en', rtl: false } },
  { path: 'ca', children: MAIN_APP_ROUTES, data: { country: 'ca', locale: 'ca', rtl: false } },
  { path: 'ch', children: MAIN_APP_ROUTES, data: { country: 'ch', locale: 'fr', rtl: false } },
  { path: 'cl', children: MAIN_APP_ROUTES, data: { country: 'cl', locale: 'es', rtl: false } },
  { path: 'cn', children: MAIN_APP_ROUTES, data: { country: 'cn', locale: 'zh', rtl: false } },
  { path: 'co', children: MAIN_APP_ROUTES, data: { country: 'co', locale: 'es', rtl: false } },
  { path: 'cr', children: MAIN_APP_ROUTES, data: { country: 'cr', locale: 'es', rtl: false } },
  { path: 'cy', children: MAIN_APP_ROUTES, data: { country: 'cy', locale: 'cy', rtl: false } },
  { path: 'do', children: MAIN_APP_ROUTES, data: { country: 'do', locale: 'es', rtl: false } },
  { path: 'dz', children: MAIN_APP_ROUTES, data: { country: 'dz', locale: 'ar', rtl: true } },
  { path: 'ec', children: MAIN_APP_ROUTES, data: { country: 'ec', locale: 'es', rtl: false } },
  { path: 'fo', children: MAIN_APP_ROUTES, data: { country: 'fo', locale: 'fo', rtl: false } },
  { path: 'gb', children: MAIN_APP_ROUTES, data: { country: 'gb', locale: 'en', rtl: false } },
  { path: 'gd', children: MAIN_APP_ROUTES, data: { country: 'gd', locale: 'gd', rtl: false } },
  { path: 'gl', children: MAIN_APP_ROUTES, data: { country: 'gl', locale: 'gl', rtl: false } },
  { path: 'gn', children: MAIN_APP_ROUTES, data: { country: 'gn', locale: 'gn', rtl: false } },
  { path: 'gt', children: MAIN_APP_ROUTES, data: { country: 'gt', locale: 'es', rtl: false } },
  { path: 'gu', children: MAIN_APP_ROUTES, data: { country: 'gu', locale: 'gu', rtl: false } },
  { path: 'hk', children: MAIN_APP_ROUTES, data: { country: 'hk', locale: 'zh', rtl: false } },
  { path: 'hn', children: MAIN_APP_ROUTES, data: { country: 'hn', locale: 'es', rtl: false } },
  { path: 'ie', children: MAIN_APP_ROUTES, data: { country: 'ie', locale: 'en', rtl: false } },
  { path: 'iq', children: MAIN_APP_ROUTES, data: { country: 'iq', locale: 'ar', rtl: true } },
  { path: 'is', children: MAIN_APP_ROUTES, data: { country: 'is', locale: 'is', rtl: false } },
  { path: 'jm', children: MAIN_APP_ROUTES, data: { country: 'jm', locale: 'en', rtl: false } },
  { path: 'jo', children: MAIN_APP_ROUTES, data: { country: 'jo', locale: 'ar', rtl: true } },
  { path: 'km', children: MAIN_APP_ROUTES, data: { country: 'km', locale: 'km', rtl: false } },
  { path: 'kn', children: MAIN_APP_ROUTES, data: { country: 'kn', locale: 'kn', rtl: false } },
  { path: 'lb', children: MAIN_APP_ROUTES, data: { country: 'lb', locale: 'ar', rtl: true } },
  { path: 'li', children: MAIN_APP_ROUTES, data: { country: 'li', locale: 'de', rtl: false } },
  { path: 'lu', children: MAIN_APP_ROUTES, data: { country: 'lu', locale: 'fr', rtl: false } },
  { path: 'ly', children: MAIN_APP_ROUTES, data: { country: 'ly', locale: 'ar', rtl: true } },
  { path: 'ma', children: MAIN_APP_ROUTES, data: { country: 'ma', locale: 'ar', rtl: true } },
  { path: 'ml', children: MAIN_APP_ROUTES, data: { country: 'ml', locale: 'ml', rtl: false } },
  { path: 'mo', children: MAIN_APP_ROUTES, data: { country: 'mo', locale: 'zh', rtl: false } },
  { path: 'mr', children: MAIN_APP_ROUTES, data: { country: 'mr', locale: 'mr', rtl: false } },
  { path: 'mt', children: MAIN_APP_ROUTES, data: { country: 'mt', locale: 'mt', rtl: false } },
  { path: 'mx', children: MAIN_APP_ROUTES, data: { country: 'mx', locale: 'es', rtl: false } },
  { path: 'ne', children: MAIN_APP_ROUTES, data: { country: 'ne', locale: 'ne', rtl: false } },
  { path: 'ni', children: MAIN_APP_ROUTES, data: { country: 'ni', locale: 'es', rtl: false } },
  { path: 'nz', children: MAIN_APP_ROUTES, data: { country: 'nz', locale: 'en', rtl: false } },
  { path: 'pa', children: MAIN_APP_ROUTES, data: { country: 'pa', locale: 'pa', rtl: false } },
  { path: 'pe', children: MAIN_APP_ROUTES, data: { country: 'pe', locale: 'es', rtl: false } },
  { path: 'pr', children: MAIN_APP_ROUTES, data: { country: 'pr', locale: 'es', rtl: false } },
  { path: 'pt', children: MAIN_APP_ROUTES, data: { country: 'pt', locale: 'pt', rtl: false } },
  { path: 'py', children: MAIN_APP_ROUTES, data: { country: 'py', locale: 'es', rtl: false } },
  { path: 'sb', children: MAIN_APP_ROUTES, data: { country: 'sb', locale: 'sb', rtl: false } },
  { path: 'sd', children: MAIN_APP_ROUTES, data: { country: 'sd', locale: 'sd', rtl: false } },
  { path: 'sg', children: MAIN_APP_ROUTES, data: { country: 'sg', locale: 'zh', rtl: false } },
  { path: 'sl', children: MAIN_APP_ROUTES, data: { country: 'sl', locale: 'sl', rtl: false } },
  { path: 'so', children: MAIN_APP_ROUTES, data: { country: 'so', locale: 'so', rtl: false } },
  { path: 'sv', children: MAIN_APP_ROUTES, data: { country: 'sv', locale: 'es', rtl: false } },
  { path: 'sy', children: MAIN_APP_ROUTES, data: { country: 'sy', locale: 'ar', rtl: true } },
  { path: 'tg', children: MAIN_APP_ROUTES, data: { country: 'tg', locale: 'tg', rtl: false } },
  { path: 'tk', children: MAIN_APP_ROUTES, data: { country: 'tk', locale: 'tk', rtl: false } },
  { path: 'tn', children: MAIN_APP_ROUTES, data: { country: 'tn', locale: 'ar', rtl: true } },
  { path: 'tt', children: MAIN_APP_ROUTES, data: { country: 'tt', locale: 'en', rtl: false } },
  { path: 'tw', children: MAIN_APP_ROUTES, data: { country: 'tw', locale: 'zh', rtl: false } },
  { path: 'uy', children: MAIN_APP_ROUTES, data: { country: 'uy', locale: 'es', rtl: false } },
  { path: 'uz', children: MAIN_APP_ROUTES, data: { country: 'uz', locale: 'uz', rtl: false } },
  { path: 've', children: MAIN_APP_ROUTES, data: { country: 've', locale: 'es', rtl: false } },
  { path: 'vi', children: MAIN_APP_ROUTES, data: { country: 'vi', locale: 'vi', rtl: false } },
  { path: 'ye', children: MAIN_APP_ROUTES, data: { country: 'ye', locale: 'ar', rtl: true } },
  { path: 'bf', children: MAIN_APP_ROUTES, data: { country: 'bf', locale: 'fr', rtl: false } },
  { path: 'bi', children: MAIN_APP_ROUTES, data: { country: 'bi', locale: 'fr', rtl: false } },
  { path: 'bj', children: MAIN_APP_ROUTES, data: { country: 'bj', locale: 'fr', rtl: false } },
  { path: 'bl', children: MAIN_APP_ROUTES, data: { country: 'bl', locale: 'fr', rtl: false } },
  { path: 'bm', children: MAIN_APP_ROUTES, data: { country: 'bm', locale: 'en', rtl: false } },
  { path: 'bq', children: MAIN_APP_ROUTES, data: { country: 'bq', locale: 'nl', rtl: false } },
  { path: 'bt', children: MAIN_APP_ROUTES, data: { country: 'bt', locale: 'dz', rtl: false } },
  { path: 'bv', children: MAIN_APP_ROUTES, data: { country: 'bv', locale: 'no', rtl: false } },
  { path: 'bw', children: MAIN_APP_ROUTES, data: { country: 'bw', locale: 'en', rtl: false } },
  { path: 'cc', children: MAIN_APP_ROUTES, data: { country: 'cc', locale: 'en', rtl: false } },
  { path: 'cd', children: MAIN_APP_ROUTES, data: { country: 'cd', locale: 'fr', rtl: false } },
  { path: 'cf', children: MAIN_APP_ROUTES, data: { country: 'cf', locale: 'fr', rtl: false } },
  { path: 'cg', children: MAIN_APP_ROUTES, data: { country: 'cg', locale: 'fr', rtl: false } },
  { path: 'ci', children: MAIN_APP_ROUTES, data: { country: 'ci', locale: 'fr', rtl: false } },
  { path: 'ck', children: MAIN_APP_ROUTES, data: { country: 'ck', locale: 'en', rtl: false } },
  { path: 'cm', children: MAIN_APP_ROUTES, data: { country: 'cm', locale: 'en', rtl: false } },
  { path: 'cu', children: MAIN_APP_ROUTES, data: { country: 'cu', locale: 'es', rtl: false } },
  { path: 'cv', children: MAIN_APP_ROUTES, data: { country: 'cv', locale: 'pt', rtl: false } },
  { path: 'cw', children: MAIN_APP_ROUTES, data: { country: 'cw', locale: 'nl', rtl: false } },
  { path: 'cx', children: MAIN_APP_ROUTES, data: { country: 'cx', locale: 'en', rtl: false } },
  { path: 'dj', children: MAIN_APP_ROUTES, data: { country: 'dj', locale: 'fr', rtl: false } },
  { path: 'dm', children: MAIN_APP_ROUTES, data: { country: 'dm', locale: 'en', rtl: false } },
  { path: 'eh', children: MAIN_APP_ROUTES, data: { country: 'eh', locale: 'es', rtl: false } },
  { path: 'er', children: MAIN_APP_ROUTES, data: { country: 'er', locale: 'ti', rtl: false } },
  { path: 'fj', children: MAIN_APP_ROUTES, data: { country: 'fj', locale: 'en', rtl: false } },
  { path: 'fk', children: MAIN_APP_ROUTES, data: { country: 'fk', locale: 'en', rtl: false } },
  { path: 'fm', children: MAIN_APP_ROUTES, data: { country: 'fm', locale: 'en', rtl: false } },
  { path: 'ga', children: MAIN_APP_ROUTES, data: { country: 'ga', locale: 'fr', rtl: false } },
  { path: 'gf', children: MAIN_APP_ROUTES, data: { country: 'gf', locale: 'fr', rtl: false } },
  { path: 'gg', children: MAIN_APP_ROUTES, data: { country: 'gg', locale: 'en', rtl: false } },
  { path: 'gh', children: MAIN_APP_ROUTES, data: { country: 'gh', locale: 'en', rtl: false } },
  { path: 'gi', children: MAIN_APP_ROUTES, data: { country: 'gi', locale: 'en', rtl: false } },
  { path: 'gm', children: MAIN_APP_ROUTES, data: { country: 'gm', locale: 'en', rtl: false } },
  { path: 'gp', children: MAIN_APP_ROUTES, data: { country: 'gp', locale: 'fr', rtl: false } },
  { path: 'gq', children: MAIN_APP_ROUTES, data: { country: 'gq', locale: 'es', rtl: false } },
  { path: 'gs', children: MAIN_APP_ROUTES, data: { country: 'gs', locale: 'en', rtl: false } },
  { path: 'gw', children: MAIN_APP_ROUTES, data: { country: 'gw', locale: 'pt', rtl: false } },
  { path: 'gy', children: MAIN_APP_ROUTES, data: { country: 'gy', locale: 'en', rtl: false } },
  { path: 'hm', children: MAIN_APP_ROUTES, data: { country: 'hm', locale: 'en', rtl: false } },
  { path: 'ht', children: MAIN_APP_ROUTES, data: { country: 'ht', locale: 'fr', rtl: false } },
  { path: 'im', children: MAIN_APP_ROUTES, data: { country: 'im', locale: 'en', rtl: false } },
  { path: 'io', children: MAIN_APP_ROUTES, data: { country: 'io', locale: 'en', rtl: false } },
  { path: 'ir', children: MAIN_APP_ROUTES, data: { country: 'ir', locale: 'fa', rtl: true } },
  { path: 'je', children: MAIN_APP_ROUTES, data: { country: 'je', locale: 'en', rtl: false } },
  { path: 'ke', children: MAIN_APP_ROUTES, data: { country: 'ke', locale: 'en', rtl: false } },
  { path: 'ki', children: MAIN_APP_ROUTES, data: { country: 'ki', locale: 'en', rtl: false } },
  { path: 'kp', children: MAIN_APP_ROUTES, data: { country: 'kp', locale: 'ko', rtl: false } },
  { path: 'ky', children: MAIN_APP_ROUTES, data: { country: 'ky', locale: 'en', rtl: false } },
  { path: 'lc', children: MAIN_APP_ROUTES, data: { country: 'lc', locale: 'en', rtl: false } },
  { path: 'lr', children: MAIN_APP_ROUTES, data: { country: 'lr', locale: 'en', rtl: false } },
  { path: 'ls', children: MAIN_APP_ROUTES, data: { country: 'ls', locale: 'en', rtl: false } },
  { path: 'mc', children: MAIN_APP_ROUTES, data: { country: 'mc', locale: 'fr', rtl: false } },
  { path: 'md', children: MAIN_APP_ROUTES, data: { country: 'md', locale: 'ro', rtl: false } },
  { path: 'me', children: MAIN_APP_ROUTES, data: { country: 'me', locale: 'sr', rtl: false } },
  { path: 'mf', children: MAIN_APP_ROUTES, data: { country: 'mf', locale: 'en', rtl: false } },
  { path: 'mh', children: MAIN_APP_ROUTES, data: { country: 'mh', locale: 'en', rtl: false } },
  { path: 'mp', children: MAIN_APP_ROUTES, data: { country: 'mp', locale: 'en', rtl: false } },
  { path: 'mq', children: MAIN_APP_ROUTES, data: { country: 'mq', locale: 'fr', rtl: false } },
  { path: 'ms', children: MAIN_APP_ROUTES, data: { country: 'ms', locale: 'en', rtl: false } },
  { path: 'mv', children: MAIN_APP_ROUTES, data: { country: 'mv', locale: 'dv', rtl: false } },
  { path: 'mw', children: MAIN_APP_ROUTES, data: { country: 'mw', locale: 'en', rtl: false } },
  { path: 'mz', children: MAIN_APP_ROUTES, data: { country: 'mz', locale: 'pt', rtl: false } },
  { path: 'nc', children: MAIN_APP_ROUTES, data: { country: 'nc', locale: 'fr', rtl: false } },
  { path: 'nf', children: MAIN_APP_ROUTES, data: { country: 'nf', locale: 'en', rtl: false } },
  { path: 'nr', children: MAIN_APP_ROUTES, data: { country: 'nr', locale: 'en', rtl: false } },
  { path: 'nu', children: MAIN_APP_ROUTES, data: { country: 'nu', locale: 'en', rtl: false } },
  { path: 'pf', children: MAIN_APP_ROUTES, data: { country: 'pf', locale: 'fr', rtl: false } },
  { path: 'pg', children: MAIN_APP_ROUTES, data: { country: 'pg', locale: 'en', rtl: false } },
  { path: 'pk', children: MAIN_APP_ROUTES, data: { country: 'pk', locale: 'en', rtl: false } },
  { path: 'pm', children: MAIN_APP_ROUTES, data: { country: 'pm', locale: 'fr', rtl: false } },
  { path: 'pn', children: MAIN_APP_ROUTES, data: { country: 'pn', locale: 'en', rtl: false } },
  { path: 'ps', children: MAIN_APP_ROUTES, data: { country: 'ps', locale: 'ar', rtl: true } },
  { path: 'pw', children: MAIN_APP_ROUTES, data: { country: 'pw', locale: 'en', rtl: false } },
  { path: 're', children: MAIN_APP_ROUTES, data: { country: 're', locale: 'fr', rtl: false } },
  { path: 'rs', children: MAIN_APP_ROUTES, data: { country: 'rs', locale: 'sr', rtl: false } },
  { path: 'rw', children: MAIN_APP_ROUTES, data: { country: 'rw', locale: 'rw', rtl: false } },
  { path: 'sc', children: MAIN_APP_ROUTES, data: { country: 'sc', locale: 'fr', rtl: false } },
  { path: 'sh', children: MAIN_APP_ROUTES, data: { country: 'sh', locale: 'en', rtl: false } },
  { path: 'sj', children: MAIN_APP_ROUTES, data: { country: 'sj', locale: 'no', rtl: false } },
  { path: 'sm', children: MAIN_APP_ROUTES, data: { country: 'sm', locale: 'it', rtl: false } },
  { path: 'sn', children: MAIN_APP_ROUTES, data: { country: 'sn', locale: 'fr', rtl: false } },
  { path: 'sr', children: MAIN_APP_ROUTES, data: { country: 'sr', locale: 'nl', rtl: false } },
  { path: 'ss', children: MAIN_APP_ROUTES, data: { country: 'ss', locale: 'en', rtl: false } },
  { path: 'st', children: MAIN_APP_ROUTES, data: { country: 'st', locale: 'pt', rtl: false } },
  { path: 'sx', children: MAIN_APP_ROUTES, data: { country: 'sx', locale: 'nl', rtl: false } },
  { path: 'sz', children: MAIN_APP_ROUTES, data: { country: 'sz', locale: 'en', rtl: false } },
  { path: 'tc', children: MAIN_APP_ROUTES, data: { country: 'tc', locale: 'en', rtl: false } },
  { path: 'td', children: MAIN_APP_ROUTES, data: { country: 'td', locale: 'fr', rtl: false } },
  { path: 'tf', children: MAIN_APP_ROUTES, data: { country: 'tf', locale: 'fr', rtl: false } },
  { path: 'tj', children: MAIN_APP_ROUTES, data: { country: 'tj', locale: 'tg', rtl: false } },
  { path: 'tl', children: MAIN_APP_ROUTES, data: { country: 'tl', locale: 'pt', rtl: false } },
  { path: 'tm', children: MAIN_APP_ROUTES, data: { country: 'tm', locale: 'tk', rtl: false } },
  { path: 'to', children: MAIN_APP_ROUTES, data: { country: 'to', locale: 'en', rtl: false } },
  { path: 'tv', children: MAIN_APP_ROUTES, data: { country: 'tv', locale: 'en', rtl: false } },
  { path: 'um', children: MAIN_APP_ROUTES, data: { country: 'um', locale: 'en', rtl: false } },
  { path: 'va', children: MAIN_APP_ROUTES, data: { country: 'va', locale: 'it', rtl: false } },
  { path: 'vc', children: MAIN_APP_ROUTES, data: { country: 'vc', locale: 'en', rtl: false } },
  { path: 'vg', children: MAIN_APP_ROUTES, data: { country: 'vg', locale: 'en', rtl: false } },
  { path: 'vu', children: MAIN_APP_ROUTES, data: { country: 'vu', locale: 'bi', rtl: false } },
  { path: 'wf', children: MAIN_APP_ROUTES, data: { country: 'wf', locale: 'fr', rtl: false } },
  { path: 'ws', children: MAIN_APP_ROUTES, data: { country: 'ws', locale: 'sm', rtl: false } },
  { path: 'yt', children: MAIN_APP_ROUTES, data: { country: 'yt', locale: 'fr', rtl: false } },
  { path: 'zm', children: MAIN_APP_ROUTES, data: { country: 'zm', locale: 'en', rtl: false } },
  { path: 'zw', children: MAIN_APP_ROUTES, data: { country: 'zw', locale: 'en', rtl: false } },
  { path: 'af-NA', children: MAIN_APP_ROUTES, data: { country: 'na', locale: 'af', rtl: false } },
  { path: 'am-ET', children: MAIN_APP_ROUTES, data: { country: 'et', locale: 'am', rtl: false } },
  { path: 'ar-EG', children: MAIN_APP_ROUTES, data: { country: 'eg', locale: 'ar', rtl: true } },
  { path: 'az-AZ', children: MAIN_APP_ROUTES, data: { country: 'az', locale: 'az', rtl: false } },
  { path: 'be-BY', children: MAIN_APP_ROUTES, data: { country: 'by', locale: 'be', rtl: false } },
  { path: 'bg-BG', children: MAIN_APP_ROUTES, data: { country: 'bg', locale: 'bg', rtl: false } },
  { path: 'bn-BD', children: MAIN_APP_ROUTES, data: { country: 'bd', locale: 'bn', rtl: false } },
  { path: 'bs-BA', children: MAIN_APP_ROUTES, data: { country: 'ba', locale: 'bs', rtl: false } },
  { path: 'ca-AD', children: MAIN_APP_ROUTES, data: { country: 'ad', locale: 'ca', rtl: false } },
  { path: 'cs-CZ', children: MAIN_APP_ROUTES, data: { country: 'cz', locale: 'cs', rtl: false } },
  { path: 'da-DK', children: MAIN_APP_ROUTES, data: { country: 'dk', locale: 'da', rtl: false } },
  { path: 'de-DE', children: MAIN_APP_ROUTES, data: { country: 'de', locale: 'de', rtl: false } },
  { path: 'el-GR', children: MAIN_APP_ROUTES, data: { country: 'gr', locale: 'el', rtl: false } },
  { path: 'es-ES', children: MAIN_APP_ROUTES, data: { country: 'es', locale: 'es', rtl: false } },
  { path: 'et-EE', children: MAIN_APP_ROUTES, data: { country: 'ee', locale: 'et', rtl: false } },
  { path: 'eu-ES-EU', children: MAIN_APP_ROUTES, data: { country: 'es-eu', locale: 'eu', rtl: false } },
  { path: 'af-AF', children: MAIN_APP_ROUTES, data: { country: 'af', locale: 'af', rtl: false } },
  { path: 'fi-FI', children: MAIN_APP_ROUTES, data: { country: 'fi', locale: 'fi', rtl: false } },
  { path: 'fr-FR', children: MAIN_APP_ROUTES, data: { country: 'fr', locale: 'fr', rtl: false } },
  { path: 'gl-ES-GA', children: MAIN_APP_ROUTES, data: { country: 'es-ga', locale: 'gl', rtl: false } },
  { path: 'gu-IN-GJ', children: MAIN_APP_ROUTES, data: { country: 'in-gj', locale: 'gu', rtl: false } },
  { path: 'he-IL', children: MAIN_APP_ROUTES, data: { country: 'il', locale: 'he', rtl: true } },
  { path: 'hi-IN', children: MAIN_APP_ROUTES, data: { country: 'in', locale: 'hi', rtl: false } },
  { path: 'hr-HR', children: MAIN_APP_ROUTES, data: { country: 'hr', locale: 'hr', rtl: false } },
  { path: 'hu-HU', children: MAIN_APP_ROUTES, data: { country: 'hu', locale: 'hu', rtl: false } },
  { path: 'hy-AM', children: MAIN_APP_ROUTES, data: { country: 'am', locale: 'hy', rtl: false } },
  { path: 'id-ID', children: MAIN_APP_ROUTES, data: { country: 'id', locale: 'id', rtl: false } },
  { path: 'it-IT', children: MAIN_APP_ROUTES, data: { country: 'it', locale: 'it', rtl: false } },
  { path: 'ja-JP', children: MAIN_APP_ROUTES, data: { country: 'jp', locale: 'ja', rtl: false } },
  { path: 'ka-GE', children: MAIN_APP_ROUTES, data: { country: 'ge', locale: 'ka', rtl: false } },
  { path: 'kk-KZ', children: MAIN_APP_ROUTES, data: { country: 'kz', locale: 'kk', rtl: false } },
  { path: 'km-KH', children: MAIN_APP_ROUTES, data: { country: 'kh', locale: 'km', rtl: false } },
  { path: 'kn-IN-KN', children: MAIN_APP_ROUTES, data: { country: 'in-kn', locale: 'kn', rtl: false } },
  { path: 'ko-KR', children: MAIN_APP_ROUTES, data: { country: 'kr', locale: 'ko', rtl: false } },
  { path: 'ky-KG', children: MAIN_APP_ROUTES, data: { country: 'kg', locale: 'ky', rtl: false } },
  { path: 'lo-LA', children: MAIN_APP_ROUTES, data: { country: 'la', locale: 'lo', rtl: false } },
  { path: 'lt-LT', children: MAIN_APP_ROUTES, data: { country: 'lt', locale: 'lt', rtl: false } },
  { path: 'lv-LV', children: MAIN_APP_ROUTES, data: { country: 'lv', locale: 'lv', rtl: false } },
  { path: 'mk-MK', children: MAIN_APP_ROUTES, data: { country: 'mk', locale: 'mk', rtl: false } },
  { path: 'ml-IN-KL', children: MAIN_APP_ROUTES, data: { country: 'in-kl', locale: 'ml', rtl: false } },
  { path: 'mn-MN', children: MAIN_APP_ROUTES, data: { country: 'mn', locale: 'mn', rtl: false } },
  { path: 'mr-MU', children: MAIN_APP_ROUTES, data: { country: 'mu', locale: 'mr', rtl: false } },
  { path: 'ms-MY', children: MAIN_APP_ROUTES, data: { country: 'my', locale: 'ms', rtl: false } },
  { path: 'my-MM', children: MAIN_APP_ROUTES, data: { country: 'mm', locale: 'my', rtl: false } },
  { path: 'ne-NP', children: MAIN_APP_ROUTES, data: { country: 'np', locale: 'ne', rtl: false } },
  { path: 'nl-NL', children: MAIN_APP_ROUTES, data: { country: 'nl', locale: 'nl', rtl: false } },
  { path: 'no-NO', children: MAIN_APP_ROUTES, data: { country: 'no', locale: 'no', rtl: false } },
  { path: 'pl-PL', children: MAIN_APP_ROUTES, data: { country: 'pl', locale: 'pl', rtl: false } },
  { path: 'pt-BR', children: MAIN_APP_ROUTES, data: { country: 'br', locale: 'pt', rtl: false } },
  { path: 'ro-RO', children: MAIN_APP_ROUTES, data: { country: 'ro', locale: 'ro', rtl: false } },
  { path: 'ru-RU', children: MAIN_APP_ROUTES, data: { country: 'ru', locale: 'ru', rtl: false } },
  { path: 'si-LK', children: MAIN_APP_ROUTES, data: { country: 'lk', locale: 'si', rtl: false } },
  { path: 'sk-SK', children: MAIN_APP_ROUTES, data: { country: 'sk', locale: 'sk', rtl: false } },
  { path: 'sl-SI', children: MAIN_APP_ROUTES, data: { country: 'si', locale: 'sl', rtl: false } },
  { path: 'sq-AL', children: MAIN_APP_ROUTES, data: { country: 'al', locale: 'sq', rtl: false } },
  { path: 'sv-SE', children: MAIN_APP_ROUTES, data: { country: 'se', locale: 'sv', rtl: false } },
  { path: 'sw-TZ', children: MAIN_APP_ROUTES, data: { country: 'tz', locale: 'sw', rtl: false } },
  { path: 'ta-LK-TA', children: MAIN_APP_ROUTES, data: { country: 'lk-ta', locale: 'ta', rtl: false } },
  { path: 'te-IN-TE', children: MAIN_APP_ROUTES, data: { country: 'in-te', locale: 'te', rtl: false } },
  { path: 'th-TH', children: MAIN_APP_ROUTES, data: { country: 'th', locale: 'th', rtl: false } },
  { path: 'tl-PH', children: MAIN_APP_ROUTES, data: { country: 'ph', locale: 'tl', rtl: false } },
  { path: 'tr-TR', children: MAIN_APP_ROUTES, data: { country: 'tr', locale: 'tr', rtl: false } },
  { path: 'uk-UA', children: MAIN_APP_ROUTES, data: { country: 'ua', locale: 'uk', rtl: false } },
  { path: 'en-US', children: MAIN_APP_ROUTES, data: { country: 'us', locale: 'en', rtl: false } },
  { path: 'vi-VN', children: MAIN_APP_ROUTES, data: { country: 'vn', locale: 'vi', rtl: false } },
  { path: 'yo-NG', children: MAIN_APP_ROUTES, data: { country: 'ng', locale: 'yo', rtl: false } },
  { path: 'zh-UG', children: MAIN_APP_ROUTES, data: { country: 'ug', locale: 'zh', rtl: false } },
  { path: 'zu-ZA', children: MAIN_APP_ROUTES, data: { country: 'za', locale: 'zu', rtl: false } },
  { path: 'en-AG', children: MAIN_APP_ROUTES, data: { country: 'ag', locale: 'en', rtl: false } },
  { path: 'en-AI', children: MAIN_APP_ROUTES, data: { country: 'ai', locale: 'en', rtl: false } },
  { path: 'pt-AO', children: MAIN_APP_ROUTES, data: { country: 'ao', locale: 'pt', rtl: false } },
  { path: 'en-AQ', children: MAIN_APP_ROUTES, data: { country: 'aq', locale: 'en', rtl: false } },
  { path: 'nl-AW', children: MAIN_APP_ROUTES, data: { country: 'aw', locale: 'nl', rtl: false } },
  { path: 'sv-AX', children: MAIN_APP_ROUTES, data: { country: 'ax', locale: 'sv', rtl: false } },
  { path: 'en-BB', children: MAIN_APP_ROUTES, data: { country: 'bb', locale: 'en', rtl: false } },
  { path: 'es-AR', children: MAIN_APP_ROUTES, data: { country: 'ar', locale: 'es', rtl: false } },
  { path: 'as-AS', children: MAIN_APP_ROUTES, data: { country: 'as', locale: 'as', rtl: false } },
  { path: 'de-AT', children: MAIN_APP_ROUTES, data: { country: 'at', locale: 'de', rtl: false } },
  { path: 'en-AU', children: MAIN_APP_ROUTES, data: { country: 'au', locale: 'en', rtl: false } },
  { path: 'be-BE', children: MAIN_APP_ROUTES, data: { country: 'be', locale: 'be', rtl: false } },
  { path: 'bn-BN', children: MAIN_APP_ROUTES, data: { country: 'bn', locale: 'bn', rtl: false } },
  { path: 'es-BO', children: MAIN_APP_ROUTES, data: { country: 'bo', locale: 'es', rtl: false } },
  { path: 'bs-BS', children: MAIN_APP_ROUTES, data: { country: 'bs', locale: 'bs', rtl: false } },
  { path: 'en-BZ', children: MAIN_APP_ROUTES, data: { country: 'bz', locale: 'en', rtl: false } },
  { path: 'ca-CA', children: MAIN_APP_ROUTES, data: { country: 'ca', locale: 'ca', rtl: false } },
  { path: 'fr-CH', children: MAIN_APP_ROUTES, data: { country: 'ch', locale: 'fr', rtl: false } },
  { path: 'es-CL', children: MAIN_APP_ROUTES, data: { country: 'cl', locale: 'es', rtl: false } },
  { path: 'zh-CN', children: MAIN_APP_ROUTES, data: { country: 'cn', locale: 'zh', rtl: false } },
  { path: 'es-CO', children: MAIN_APP_ROUTES, data: { country: 'co', locale: 'es', rtl: false } },
  { path: 'es-CR', children: MAIN_APP_ROUTES, data: { country: 'cr', locale: 'es', rtl: false } },
  { path: 'cy-CY', children: MAIN_APP_ROUTES, data: { country: 'cy', locale: 'cy', rtl: false } },
  { path: 'es-DO', children: MAIN_APP_ROUTES, data: { country: 'do', locale: 'es', rtl: false } },
  { path: 'ar-DZ', children: MAIN_APP_ROUTES, data: { country: 'dz', locale: 'ar', rtl: true } },
  { path: 'es-EC', children: MAIN_APP_ROUTES, data: { country: 'ec', locale: 'es', rtl: false } },
  { path: 'fo-FO', children: MAIN_APP_ROUTES, data: { country: 'fo', locale: 'fo', rtl: false } },
  { path: 'en-GB', children: MAIN_APP_ROUTES, data: { country: 'gb', locale: 'en', rtl: false } },
  { path: 'gd-GD', children: MAIN_APP_ROUTES, data: { country: 'gd', locale: 'gd', rtl: false } },
  { path: 'gl-GL', children: MAIN_APP_ROUTES, data: { country: 'gl', locale: 'gl', rtl: false } },
  { path: 'gn-GN', children: MAIN_APP_ROUTES, data: { country: 'gn', locale: 'gn', rtl: false } },
  { path: 'es-GT', children: MAIN_APP_ROUTES, data: { country: 'gt', locale: 'es', rtl: false } },
  { path: 'gu-GU', children: MAIN_APP_ROUTES, data: { country: 'gu', locale: 'gu', rtl: false } },
  { path: 'zh-HK', children: MAIN_APP_ROUTES, data: { country: 'hk', locale: 'zh', rtl: false } },
  { path: 'es-HN', children: MAIN_APP_ROUTES, data: { country: 'hn', locale: 'es', rtl: false } },
  { path: 'en-IE', children: MAIN_APP_ROUTES, data: { country: 'ie', locale: 'en', rtl: false } },
  { path: 'ar-IQ', children: MAIN_APP_ROUTES, data: { country: 'iq', locale: 'ar', rtl: true } },
  { path: 'is-IS', children: MAIN_APP_ROUTES, data: { country: 'is', locale: 'is', rtl: false } },
  { path: 'en-JM', children: MAIN_APP_ROUTES, data: { country: 'jm', locale: 'en', rtl: false } },
  { path: 'ar-JO', children: MAIN_APP_ROUTES, data: { country: 'jo', locale: 'ar', rtl: true } },
  { path: 'km-KM', children: MAIN_APP_ROUTES, data: { country: 'km', locale: 'km', rtl: false } },
  { path: 'kn-KN', children: MAIN_APP_ROUTES, data: { country: 'kn', locale: 'kn', rtl: false } },
  { path: 'ar-LB', children: MAIN_APP_ROUTES, data: { country: 'lb', locale: 'ar', rtl: true } },
  { path: 'de-LI', children: MAIN_APP_ROUTES, data: { country: 'li', locale: 'de', rtl: false } },
  { path: 'fr-LU', children: MAIN_APP_ROUTES, data: { country: 'lu', locale: 'fr', rtl: false } },
  { path: 'ar-LY', children: MAIN_APP_ROUTES, data: { country: 'ly', locale: 'ar', rtl: true } },
  { path: 'ar-MA', children: MAIN_APP_ROUTES, data: { country: 'ma', locale: 'ar', rtl: true } },
  { path: 'ml-ML', children: MAIN_APP_ROUTES, data: { country: 'ml', locale: 'ml', rtl: false } },
  { path: 'zh-MO', children: MAIN_APP_ROUTES, data: { country: 'mo', locale: 'zh', rtl: false } },
  { path: 'mr-MR', children: MAIN_APP_ROUTES, data: { country: 'mr', locale: 'mr', rtl: false } },
  { path: 'mt-MT', children: MAIN_APP_ROUTES, data: { country: 'mt', locale: 'mt', rtl: false } },
  { path: 'es-MX', children: MAIN_APP_ROUTES, data: { country: 'mx', locale: 'es', rtl: false } },
  { path: 'ne-NE', children: MAIN_APP_ROUTES, data: { country: 'ne', locale: 'ne', rtl: false } },
  { path: 'es-NI', children: MAIN_APP_ROUTES, data: { country: 'ni', locale: 'es', rtl: false } },
  { path: 'en-NZ', children: MAIN_APP_ROUTES, data: { country: 'nz', locale: 'en', rtl: false } },
  { path: 'pa-PA', children: MAIN_APP_ROUTES, data: { country: 'pa', locale: 'pa', rtl: false } },
  { path: 'es-PE', children: MAIN_APP_ROUTES, data: { country: 'pe', locale: 'es', rtl: false } },
  { path: 'es-PR', children: MAIN_APP_ROUTES, data: { country: 'pr', locale: 'es', rtl: false } },
  { path: 'pt-PT', children: MAIN_APP_ROUTES, data: { country: 'pt', locale: 'pt', rtl: false } },
  { path: 'es-PY', children: MAIN_APP_ROUTES, data: { country: 'py', locale: 'es', rtl: false } },
  { path: 'sb-SB', children: MAIN_APP_ROUTES, data: { country: 'sb', locale: 'sb', rtl: false } },
  { path: 'sd-SD', children: MAIN_APP_ROUTES, data: { country: 'sd', locale: 'sd', rtl: false } },
  { path: 'zh-SG', children: MAIN_APP_ROUTES, data: { country: 'sg', locale: 'zh', rtl: false } },
  { path: 'sl-SL', children: MAIN_APP_ROUTES, data: { country: 'sl', locale: 'sl', rtl: false } },
  { path: 'so-SO', children: MAIN_APP_ROUTES, data: { country: 'so', locale: 'so', rtl: false } },
  { path: 'es-SV', children: MAIN_APP_ROUTES, data: { country: 'sv', locale: 'es', rtl: false } },
  { path: 'ar-SY', children: MAIN_APP_ROUTES, data: { country: 'sy', locale: 'ar', rtl: true } },
  { path: 'tg-TG', children: MAIN_APP_ROUTES, data: { country: 'tg', locale: 'tg', rtl: false } },
  { path: 'tk-TK', children: MAIN_APP_ROUTES, data: { country: 'tk', locale: 'tk', rtl: false } },
  { path: 'ar-TN', children: MAIN_APP_ROUTES, data: { country: 'tn', locale: 'ar', rtl: true } },
  { path: 'en-TT', children: MAIN_APP_ROUTES, data: { country: 'tt', locale: 'en', rtl: false } },
  { path: 'zh-TW', children: MAIN_APP_ROUTES, data: { country: 'tw', locale: 'zh', rtl: false } },
  { path: 'es-UY', children: MAIN_APP_ROUTES, data: { country: 'uy', locale: 'es', rtl: false } },
  { path: 'uz-UZ', children: MAIN_APP_ROUTES, data: { country: 'uz', locale: 'uz', rtl: false } },
  { path: 'es-VE', children: MAIN_APP_ROUTES, data: { country: 've', locale: 'es', rtl: false } },
  { path: 'vi-VI', children: MAIN_APP_ROUTES, data: { country: 'vi', locale: 'vi', rtl: false } },
  { path: 'ar-YE', children: MAIN_APP_ROUTES, data: { country: 'ye', locale: 'ar', rtl: true } },
  { path: 'fr-BF', children: MAIN_APP_ROUTES, data: { country: 'bf', locale: 'fr', rtl: false } },
  { path: 'fr-BI', children: MAIN_APP_ROUTES, data: { country: 'bi', locale: 'fr', rtl: false } },
  { path: 'fr-BJ', children: MAIN_APP_ROUTES, data: { country: 'bj', locale: 'fr', rtl: false } },
  { path: 'fr-BL', children: MAIN_APP_ROUTES, data: { country: 'bl', locale: 'fr', rtl: false } },
  { path: 'en-BM', children: MAIN_APP_ROUTES, data: { country: 'bm', locale: 'en', rtl: false } },
  { path: 'nl-BQ', children: MAIN_APP_ROUTES, data: { country: 'bq', locale: 'nl', rtl: false } },
  { path: 'dz-BT', children: MAIN_APP_ROUTES, data: { country: 'bt', locale: 'dz', rtl: false } },
  { path: 'no-BV', children: MAIN_APP_ROUTES, data: { country: 'bv', locale: 'no', rtl: false } },
  { path: 'en-BW', children: MAIN_APP_ROUTES, data: { country: 'bw', locale: 'en', rtl: false } },
  { path: 'en-CC', children: MAIN_APP_ROUTES, data: { country: 'cc', locale: 'en', rtl: false } },
  { path: 'fr-CD', children: MAIN_APP_ROUTES, data: { country: 'cd', locale: 'fr', rtl: false } },
  { path: 'fr-CF', children: MAIN_APP_ROUTES, data: { country: 'cf', locale: 'fr', rtl: false } },
  { path: 'fr-CG', children: MAIN_APP_ROUTES, data: { country: 'cg', locale: 'fr', rtl: false } },
  { path: 'fr-CI', children: MAIN_APP_ROUTES, data: { country: 'ci', locale: 'fr', rtl: false } },
  { path: 'en-CK', children: MAIN_APP_ROUTES, data: { country: 'ck', locale: 'en', rtl: false } },
  { path: 'en-CM', children: MAIN_APP_ROUTES, data: { country: 'cm', locale: 'en', rtl: false } },
  { path: 'es-CU', children: MAIN_APP_ROUTES, data: { country: 'cu', locale: 'es', rtl: false } },
  { path: 'pt-CV', children: MAIN_APP_ROUTES, data: { country: 'cv', locale: 'pt', rtl: false } },
  { path: 'nl-CW', children: MAIN_APP_ROUTES, data: { country: 'cw', locale: 'nl', rtl: false } },
  { path: 'en-CX', children: MAIN_APP_ROUTES, data: { country: 'cx', locale: 'en', rtl: false } },
  { path: 'fr-DJ', children: MAIN_APP_ROUTES, data: { country: 'dj', locale: 'fr', rtl: false } },
  { path: 'en-DM', children: MAIN_APP_ROUTES, data: { country: 'dm', locale: 'en', rtl: false } },
  { path: 'es-EH', children: MAIN_APP_ROUTES, data: { country: 'eh', locale: 'es', rtl: false } },
  { path: 'ti-ER', children: MAIN_APP_ROUTES, data: { country: 'er', locale: 'ti', rtl: false } },
  { path: 'en-FJ', children: MAIN_APP_ROUTES, data: { country: 'fj', locale: 'en', rtl: false } },
  { path: 'en-FK', children: MAIN_APP_ROUTES, data: { country: 'fk', locale: 'en', rtl: false } },
  { path: 'en-FM', children: MAIN_APP_ROUTES, data: { country: 'fm', locale: 'en', rtl: false } },
  { path: 'fr-GA', children: MAIN_APP_ROUTES, data: { country: 'ga', locale: 'fr', rtl: false } },
  { path: 'fr-GF', children: MAIN_APP_ROUTES, data: { country: 'gf', locale: 'fr', rtl: false } },
  { path: 'en-GG', children: MAIN_APP_ROUTES, data: { country: 'gg', locale: 'en', rtl: false } },
  { path: 'en-GH', children: MAIN_APP_ROUTES, data: { country: 'gh', locale: 'en', rtl: false } },
  { path: 'en-GI', children: MAIN_APP_ROUTES, data: { country: 'gi', locale: 'en', rtl: false } },
  { path: 'en-GM', children: MAIN_APP_ROUTES, data: { country: 'gm', locale: 'en', rtl: false } },
  { path: 'fr-GP', children: MAIN_APP_ROUTES, data: { country: 'gp', locale: 'fr', rtl: false } },
  { path: 'es-GQ', children: MAIN_APP_ROUTES, data: { country: 'gq', locale: 'es', rtl: false } },
  { path: 'en-GS', children: MAIN_APP_ROUTES, data: { country: 'gs', locale: 'en', rtl: false } },
  { path: 'pt-GW', children: MAIN_APP_ROUTES, data: { country: 'gw', locale: 'pt', rtl: false } },
  { path: 'en-GY', children: MAIN_APP_ROUTES, data: { country: 'gy', locale: 'en', rtl: false } },
  { path: 'en-HM', children: MAIN_APP_ROUTES, data: { country: 'hm', locale: 'en', rtl: false } },
  { path: 'fr-HT', children: MAIN_APP_ROUTES, data: { country: 'ht', locale: 'fr', rtl: false } },
  { path: 'en-IM', children: MAIN_APP_ROUTES, data: { country: 'im', locale: 'en', rtl: false } },
  { path: 'en-IO', children: MAIN_APP_ROUTES, data: { country: 'io', locale: 'en', rtl: false } },
  { path: 'fa-IR', children: MAIN_APP_ROUTES, data: { country: 'ir', locale: 'fa', rtl: true } },
  { path: 'en-JE', children: MAIN_APP_ROUTES, data: { country: 'je', locale: 'en', rtl: false } },
  { path: 'en-KE', children: MAIN_APP_ROUTES, data: { country: 'ke', locale: 'en', rtl: false } },
  { path: 'en-KI', children: MAIN_APP_ROUTES, data: { country: 'ki', locale: 'en', rtl: false } },
  { path: 'ko-KP', children: MAIN_APP_ROUTES, data: { country: 'kp', locale: 'ko', rtl: false } },
  { path: 'en-KY', children: MAIN_APP_ROUTES, data: { country: 'ky', locale: 'en', rtl: false } },
  { path: 'en-LC', children: MAIN_APP_ROUTES, data: { country: 'lc', locale: 'en', rtl: false } },
  { path: 'en-LR', children: MAIN_APP_ROUTES, data: { country: 'lr', locale: 'en', rtl: false } },
  { path: 'en-LS', children: MAIN_APP_ROUTES, data: { country: 'ls', locale: 'en', rtl: false } },
  { path: 'fr-MC', children: MAIN_APP_ROUTES, data: { country: 'mc', locale: 'fr', rtl: false } },
  { path: 'ro-MD', children: MAIN_APP_ROUTES, data: { country: 'md', locale: 'ro', rtl: false } },
  { path: 'sr-ME', children: MAIN_APP_ROUTES, data: { country: 'me', locale: 'sr', rtl: false } },
  { path: 'en-MF', children: MAIN_APP_ROUTES, data: { country: 'mf', locale: 'en', rtl: false } },
  { path: 'en-MH', children: MAIN_APP_ROUTES, data: { country: 'mh', locale: 'en', rtl: false } },
  { path: 'en-MP', children: MAIN_APP_ROUTES, data: { country: 'mp', locale: 'en', rtl: false } },
  { path: 'fr-MQ', children: MAIN_APP_ROUTES, data: { country: 'mq', locale: 'fr', rtl: false } },
  { path: 'en-MS', children: MAIN_APP_ROUTES, data: { country: 'ms', locale: 'en', rtl: false } },
  { path: 'dv-MV', children: MAIN_APP_ROUTES, data: { country: 'mv', locale: 'dv', rtl: false } },
  { path: 'en-MW', children: MAIN_APP_ROUTES, data: { country: 'mw', locale: 'en', rtl: false } },
  { path: 'pt-MZ', children: MAIN_APP_ROUTES, data: { country: 'mz', locale: 'pt', rtl: false } },
  { path: 'fr-NC', children: MAIN_APP_ROUTES, data: { country: 'nc', locale: 'fr', rtl: false } },
  { path: 'en-NF', children: MAIN_APP_ROUTES, data: { country: 'nf', locale: 'en', rtl: false } },
  { path: 'en-NR', children: MAIN_APP_ROUTES, data: { country: 'nr', locale: 'en', rtl: false } },
  { path: 'en-NU', children: MAIN_APP_ROUTES, data: { country: 'nu', locale: 'en', rtl: false } },
  { path: 'fr-PF', children: MAIN_APP_ROUTES, data: { country: 'pf', locale: 'fr', rtl: false } },
  { path: 'en-PG', children: MAIN_APP_ROUTES, data: { country: 'pg', locale: 'en', rtl: false } },
  { path: 'en-PK', children: MAIN_APP_ROUTES, data: { country: 'pk', locale: 'en', rtl: false } },
  { path: 'fr-PM', children: MAIN_APP_ROUTES, data: { country: 'pm', locale: 'fr', rtl: false } },
  { path: 'en-PN', children: MAIN_APP_ROUTES, data: { country: 'pn', locale: 'en', rtl: false } },
  { path: 'ar-PS', children: MAIN_APP_ROUTES, data: { country: 'ps', locale: 'ar', rtl: true } },
  { path: 'en-PW', children: MAIN_APP_ROUTES, data: { country: 'pw', locale: 'en', rtl: false } },
  { path: 'fr-RE', children: MAIN_APP_ROUTES, data: { country: 're', locale: 'fr', rtl: false } },
  { path: 'sr-RS', children: MAIN_APP_ROUTES, data: { country: 'rs', locale: 'sr', rtl: false } },
  { path: 'rw-RW', children: MAIN_APP_ROUTES, data: { country: 'rw', locale: 'rw', rtl: false } },
  { path: 'fr-SC', children: MAIN_APP_ROUTES, data: { country: 'sc', locale: 'fr', rtl: false } },
  { path: 'en-SH', children: MAIN_APP_ROUTES, data: { country: 'sh', locale: 'en', rtl: false } },
  { path: 'no-SJ', children: MAIN_APP_ROUTES, data: { country: 'sj', locale: 'no', rtl: false } },
  { path: 'it-SM', children: MAIN_APP_ROUTES, data: { country: 'sm', locale: 'it', rtl: false } },
  { path: 'fr-SN', children: MAIN_APP_ROUTES, data: { country: 'sn', locale: 'fr', rtl: false } },
  { path: 'nl-SR', children: MAIN_APP_ROUTES, data: { country: 'sr', locale: 'nl', rtl: false } },
  { path: 'en-SS', children: MAIN_APP_ROUTES, data: { country: 'ss', locale: 'en', rtl: false } },
  { path: 'pt-ST', children: MAIN_APP_ROUTES, data: { country: 'st', locale: 'pt', rtl: false } },
  { path: 'nl-SX', children: MAIN_APP_ROUTES, data: { country: 'sx', locale: 'nl', rtl: false } },
  { path: 'en-SZ', children: MAIN_APP_ROUTES, data: { country: 'sz', locale: 'en', rtl: false } },
  { path: 'en-TC', children: MAIN_APP_ROUTES, data: { country: 'tc', locale: 'en', rtl: false } },
  { path: 'fr-TD', children: MAIN_APP_ROUTES, data: { country: 'td', locale: 'fr', rtl: false } },
  { path: 'fr-TF', children: MAIN_APP_ROUTES, data: { country: 'tf', locale: 'fr', rtl: false } },
  { path: 'tg-TJ', children: MAIN_APP_ROUTES, data: { country: 'tj', locale: 'tg', rtl: false } },
  { path: 'pt-TL', children: MAIN_APP_ROUTES, data: { country: 'tl', locale: 'pt', rtl: false } },
  { path: 'tk-TM', children: MAIN_APP_ROUTES, data: { country: 'tm', locale: 'tk', rtl: false } },
  { path: 'en-TO', children: MAIN_APP_ROUTES, data: { country: 'to', locale: 'en', rtl: false } },
  { path: 'en-TV', children: MAIN_APP_ROUTES, data: { country: 'tv', locale: 'en', rtl: false } },
  { path: 'en-UM', children: MAIN_APP_ROUTES, data: { country: 'um', locale: 'en', rtl: false } },
  { path: 'it-VA', children: MAIN_APP_ROUTES, data: { country: 'va', locale: 'it', rtl: false } },
  { path: 'en-VC', children: MAIN_APP_ROUTES, data: { country: 'vc', locale: 'en', rtl: false } },
  { path: 'en-VG', children: MAIN_APP_ROUTES, data: { country: 'vg', locale: 'en', rtl: false } },
  { path: 'bi-VU', children: MAIN_APP_ROUTES, data: { country: 'vu', locale: 'bi', rtl: false } },
  { path: 'fr-WF', children: MAIN_APP_ROUTES, data: { country: 'wf', locale: 'fr', rtl: false } },
  { path: 'sm-WS', children: MAIN_APP_ROUTES, data: { country: 'ws', locale: 'sm', rtl: false } },
  { path: 'fr-YT', children: MAIN_APP_ROUTES, data: { country: 'yt', locale: 'fr', rtl: false } },
  { path: 'en-ZM', children: MAIN_APP_ROUTES, data: { country: 'zm', locale: 'en', rtl: false } },
  { path: 'en-ZW', children: MAIN_APP_ROUTES, data: { country: 'zw', locale: 'en', rtl: false } },
  { path: 'af-na', children: MAIN_APP_ROUTES, data: { country: 'na', locale: 'af', rtl: false } },
  { path: 'am-et', children: MAIN_APP_ROUTES, data: { country: 'et', locale: 'am', rtl: false } },
  { path: 'ar-eg', children: MAIN_APP_ROUTES, data: { country: 'eg', locale: 'ar', rtl: true } },
  { path: 'az-az', children: MAIN_APP_ROUTES, data: { country: 'az', locale: 'az', rtl: false } },
  { path: 'be-by', children: MAIN_APP_ROUTES, data: { country: 'by', locale: 'be', rtl: false } },
  { path: 'bg-bg', children: MAIN_APP_ROUTES, data: { country: 'bg', locale: 'bg', rtl: false } },
  { path: 'bn-bd', children: MAIN_APP_ROUTES, data: { country: 'bd', locale: 'bn', rtl: false } },
  { path: 'bs-ba', children: MAIN_APP_ROUTES, data: { country: 'ba', locale: 'bs', rtl: false } },
  { path: 'ca-ad', children: MAIN_APP_ROUTES, data: { country: 'ad', locale: 'ca', rtl: false } },
  { path: 'cs-cz', children: MAIN_APP_ROUTES, data: { country: 'cz', locale: 'cs', rtl: false } },
  { path: 'da-dk', children: MAIN_APP_ROUTES, data: { country: 'dk', locale: 'da', rtl: false } },
  { path: 'de-de', children: MAIN_APP_ROUTES, data: { country: 'de', locale: 'de', rtl: false } },
  { path: 'el-gr', children: MAIN_APP_ROUTES, data: { country: 'gr', locale: 'el', rtl: false } },
  { path: 'es-es', children: MAIN_APP_ROUTES, data: { country: 'es', locale: 'es', rtl: false } },
  { path: 'et-ee', children: MAIN_APP_ROUTES, data: { country: 'ee', locale: 'et', rtl: false } },
  { path: 'eu-es-eu', children: MAIN_APP_ROUTES, data: { country: 'es-eu', locale: 'eu', rtl: false } },
  { path: 'af-af', children: MAIN_APP_ROUTES, data: { country: 'af', locale: 'af', rtl: false } },
  { path: 'fi-fi', children: MAIN_APP_ROUTES, data: { country: 'fi', locale: 'fi', rtl: false } },
  { path: 'fr-fr', children: MAIN_APP_ROUTES, data: { country: 'fr', locale: 'fr', rtl: false } },
  { path: 'gl-es-ga', children: MAIN_APP_ROUTES, data: { country: 'es-ga', locale: 'gl', rtl: false } },
  { path: 'gu-in-gj', children: MAIN_APP_ROUTES, data: { country: 'in-gj', locale: 'gu', rtl: false } },
  { path: 'he-il', children: MAIN_APP_ROUTES, data: { country: 'il', locale: 'he', rtl: true } },
  { path: 'hi-in', children: MAIN_APP_ROUTES, data: { country: 'in', locale: 'hi', rtl: false } },
  { path: 'hr-hr', children: MAIN_APP_ROUTES, data: { country: 'hr', locale: 'hr', rtl: false } },
  { path: 'hu-hu', children: MAIN_APP_ROUTES, data: { country: 'hu', locale: 'hu', rtl: false } },
  { path: 'hy-am', children: MAIN_APP_ROUTES, data: { country: 'am', locale: 'hy', rtl: false } },
  { path: 'id-id', children: MAIN_APP_ROUTES, data: { country: 'id', locale: 'id', rtl: false } },
  { path: 'it-it', children: MAIN_APP_ROUTES, data: { country: 'it', locale: 'it', rtl: false } },
  { path: 'ja-jp', children: MAIN_APP_ROUTES, data: { country: 'jp', locale: 'ja', rtl: false } },
  { path: 'ka-ge', children: MAIN_APP_ROUTES, data: { country: 'ge', locale: 'ka', rtl: false } },
  { path: 'kk-kz', children: MAIN_APP_ROUTES, data: { country: 'kz', locale: 'kk', rtl: false } },
  { path: 'km-kh', children: MAIN_APP_ROUTES, data: { country: 'kh', locale: 'km', rtl: false } },
  { path: 'kn-in-kn', children: MAIN_APP_ROUTES, data: { country: 'in-kn', locale: 'kn', rtl: false } },
  { path: 'ko-kr', children: MAIN_APP_ROUTES, data: { country: 'kr', locale: 'ko', rtl: false } },
  { path: 'ky-kg', children: MAIN_APP_ROUTES, data: { country: 'kg', locale: 'ky', rtl: false } },
  { path: 'lo-la', children: MAIN_APP_ROUTES, data: { country: 'la', locale: 'lo', rtl: false } },
  { path: 'lt-lt', children: MAIN_APP_ROUTES, data: { country: 'lt', locale: 'lt', rtl: false } },
  { path: 'lv-lv', children: MAIN_APP_ROUTES, data: { country: 'lv', locale: 'lv', rtl: false } },
  { path: 'mk-mk', children: MAIN_APP_ROUTES, data: { country: 'mk', locale: 'mk', rtl: false } },
  { path: 'ml-in-kl', children: MAIN_APP_ROUTES, data: { country: 'in-kl', locale: 'ml', rtl: false } },
  { path: 'mn-mn', children: MAIN_APP_ROUTES, data: { country: 'mn', locale: 'mn', rtl: false } },
  { path: 'mr-mu', children: MAIN_APP_ROUTES, data: { country: 'mu', locale: 'mr', rtl: false } },
  { path: 'ms-my', children: MAIN_APP_ROUTES, data: { country: 'my', locale: 'ms', rtl: false } },
  { path: 'my-mm', children: MAIN_APP_ROUTES, data: { country: 'mm', locale: 'my', rtl: false } },
  { path: 'ne-np', children: MAIN_APP_ROUTES, data: { country: 'np', locale: 'ne', rtl: false } },
  { path: 'nl-nl', children: MAIN_APP_ROUTES, data: { country: 'nl', locale: 'nl', rtl: false } },
  { path: 'no-no', children: MAIN_APP_ROUTES, data: { country: 'no', locale: 'no', rtl: false } },
  { path: 'pl-pl', children: MAIN_APP_ROUTES, data: { country: 'pl', locale: 'pl', rtl: false } },
  { path: 'pt-br', children: MAIN_APP_ROUTES, data: { country: 'br', locale: 'pt', rtl: false } },
  { path: 'ro-ro', children: MAIN_APP_ROUTES, data: { country: 'ro', locale: 'ro', rtl: false } },
  { path: 'ru-ru', children: MAIN_APP_ROUTES, data: { country: 'ru', locale: 'ru', rtl: false } },
  { path: 'si-lk', children: MAIN_APP_ROUTES, data: { country: 'lk', locale: 'si', rtl: false } },
  { path: 'sk-sk', children: MAIN_APP_ROUTES, data: { country: 'sk', locale: 'sk', rtl: false } },
  { path: 'sl-si', children: MAIN_APP_ROUTES, data: { country: 'si', locale: 'sl', rtl: false } },
  { path: 'sq-al', children: MAIN_APP_ROUTES, data: { country: 'al', locale: 'sq', rtl: false } },
  { path: 'sv-se', children: MAIN_APP_ROUTES, data: { country: 'se', locale: 'sv', rtl: false } },
  { path: 'sw-tz', children: MAIN_APP_ROUTES, data: { country: 'tz', locale: 'sw', rtl: false } },
  { path: 'ta-lk-ta', children: MAIN_APP_ROUTES, data: { country: 'lk-ta', locale: 'ta', rtl: false } },
  { path: 'te-in-te', children: MAIN_APP_ROUTES, data: { country: 'in-te', locale: 'te', rtl: false } },
  { path: 'th-th', children: MAIN_APP_ROUTES, data: { country: 'th', locale: 'th', rtl: false } },
  { path: 'tl-ph', children: MAIN_APP_ROUTES, data: { country: 'ph', locale: 'tl', rtl: false } },
  { path: 'tr-tr', children: MAIN_APP_ROUTES, data: { country: 'tr', locale: 'tr', rtl: false } },
  { path: 'uk-ua', children: MAIN_APP_ROUTES, data: { country: 'ua', locale: 'uk', rtl: false } },
  { path: 'en-us', children: MAIN_APP_ROUTES, data: { country: 'us', locale: 'en', rtl: false } },
  { path: 'vi-vn', children: MAIN_APP_ROUTES, data: { country: 'vn', locale: 'vi', rtl: false } },
  { path: 'yo-ng', children: MAIN_APP_ROUTES, data: { country: 'ng', locale: 'yo', rtl: false } },
  { path: 'zh-ug', children: MAIN_APP_ROUTES, data: { country: 'ug', locale: 'zh', rtl: false } },
  { path: 'zu-za', children: MAIN_APP_ROUTES, data: { country: 'za', locale: 'zu', rtl: false } },
  { path: 'en-ag', children: MAIN_APP_ROUTES, data: { country: 'ag', locale: 'en', rtl: false } },
  { path: 'en-ai', children: MAIN_APP_ROUTES, data: { country: 'ai', locale: 'en', rtl: false } },
  { path: 'pt-ao', children: MAIN_APP_ROUTES, data: { country: 'ao', locale: 'pt', rtl: false } },
  { path: 'en-aq', children: MAIN_APP_ROUTES, data: { country: 'aq', locale: 'en', rtl: false } },
  { path: 'nl-aw', children: MAIN_APP_ROUTES, data: { country: 'aw', locale: 'nl', rtl: false } },
  { path: 'sv-ax', children: MAIN_APP_ROUTES, data: { country: 'ax', locale: 'sv', rtl: false } },
  { path: 'en-bb', children: MAIN_APP_ROUTES, data: { country: 'bb', locale: 'en', rtl: false } },
  { path: 'es-ar', children: MAIN_APP_ROUTES, data: { country: 'ar', locale: 'es', rtl: false } },
  { path: 'as-as', children: MAIN_APP_ROUTES, data: { country: 'as', locale: 'as', rtl: false } },
  { path: 'de-at', children: MAIN_APP_ROUTES, data: { country: 'at', locale: 'de', rtl: false } },
  { path: 'en-au', children: MAIN_APP_ROUTES, data: { country: 'au', locale: 'en', rtl: false } },
  { path: 'be-be', children: MAIN_APP_ROUTES, data: { country: 'be', locale: 'be', rtl: false } },
  { path: 'bn-bn', children: MAIN_APP_ROUTES, data: { country: 'bn', locale: 'bn', rtl: false } },
  { path: 'es-bo', children: MAIN_APP_ROUTES, data: { country: 'bo', locale: 'es', rtl: false } },
  { path: 'bs-bs', children: MAIN_APP_ROUTES, data: { country: 'bs', locale: 'bs', rtl: false } },
  { path: 'en-bz', children: MAIN_APP_ROUTES, data: { country: 'bz', locale: 'en', rtl: false } },
  { path: 'ca-ca', children: MAIN_APP_ROUTES, data: { country: 'ca', locale: 'ca', rtl: false } },
  { path: 'fr-ch', children: MAIN_APP_ROUTES, data: { country: 'ch', locale: 'fr', rtl: false } },
  { path: 'es-cl', children: MAIN_APP_ROUTES, data: { country: 'cl', locale: 'es', rtl: false } },
  { path: 'zh-cn', children: MAIN_APP_ROUTES, data: { country: 'cn', locale: 'zh', rtl: false } },
  { path: 'es-co', children: MAIN_APP_ROUTES, data: { country: 'co', locale: 'es', rtl: false } },
  { path: 'es-cr', children: MAIN_APP_ROUTES, data: { country: 'cr', locale: 'es', rtl: false } },
  { path: 'cy-cy', children: MAIN_APP_ROUTES, data: { country: 'cy', locale: 'cy', rtl: false } },
  { path: 'es-do', children: MAIN_APP_ROUTES, data: { country: 'do', locale: 'es', rtl: false } },
  { path: 'ar-dz', children: MAIN_APP_ROUTES, data: { country: 'dz', locale: 'ar', rtl: true } },
  { path: 'es-ec', children: MAIN_APP_ROUTES, data: { country: 'ec', locale: 'es', rtl: false } },
  { path: 'fo-fo', children: MAIN_APP_ROUTES, data: { country: 'fo', locale: 'fo', rtl: false } },
  { path: 'en-gb', children: MAIN_APP_ROUTES, data: { country: 'gb', locale: 'en', rtl: false } },
  { path: 'gd-gd', children: MAIN_APP_ROUTES, data: { country: 'gd', locale: 'gd', rtl: false } },
  { path: 'gl-gl', children: MAIN_APP_ROUTES, data: { country: 'gl', locale: 'gl', rtl: false } },
  { path: 'gn-gn', children: MAIN_APP_ROUTES, data: { country: 'gn', locale: 'gn', rtl: false } },
  { path: 'es-gt', children: MAIN_APP_ROUTES, data: { country: 'gt', locale: 'es', rtl: false } },
  { path: 'gu-gu', children: MAIN_APP_ROUTES, data: { country: 'gu', locale: 'gu', rtl: false } },
  { path: 'zh-hk', children: MAIN_APP_ROUTES, data: { country: 'hk', locale: 'zh', rtl: false } },
  { path: 'es-hn', children: MAIN_APP_ROUTES, data: { country: 'hn', locale: 'es', rtl: false } },
  { path: 'en-ie', children: MAIN_APP_ROUTES, data: { country: 'ie', locale: 'en', rtl: false } },
  { path: 'ar-iq', children: MAIN_APP_ROUTES, data: { country: 'iq', locale: 'ar', rtl: true } },
  { path: 'is-is', children: MAIN_APP_ROUTES, data: { country: 'is', locale: 'is', rtl: false } },
  { path: 'en-jm', children: MAIN_APP_ROUTES, data: { country: 'jm', locale: 'en', rtl: false } },
  { path: 'ar-jo', children: MAIN_APP_ROUTES, data: { country: 'jo', locale: 'ar', rtl: true } },
  { path: 'km-km', children: MAIN_APP_ROUTES, data: { country: 'km', locale: 'km', rtl: false } },
  { path: 'kn-kn', children: MAIN_APP_ROUTES, data: { country: 'kn', locale: 'kn', rtl: false } },
  { path: 'ar-lb', children: MAIN_APP_ROUTES, data: { country: 'lb', locale: 'ar', rtl: true } },
  { path: 'de-li', children: MAIN_APP_ROUTES, data: { country: 'li', locale: 'de', rtl: false } },
  { path: 'fr-lu', children: MAIN_APP_ROUTES, data: { country: 'lu', locale: 'fr', rtl: false } },
  { path: 'ar-ly', children: MAIN_APP_ROUTES, data: { country: 'ly', locale: 'ar', rtl: true } },
  { path: 'ar-ma', children: MAIN_APP_ROUTES, data: { country: 'ma', locale: 'ar', rtl: true } },
  { path: 'ml-ml', children: MAIN_APP_ROUTES, data: { country: 'ml', locale: 'ml', rtl: false } },
  { path: 'zh-mo', children: MAIN_APP_ROUTES, data: { country: 'mo', locale: 'zh', rtl: false } },
  { path: 'mr-mr', children: MAIN_APP_ROUTES, data: { country: 'mr', locale: 'mr', rtl: false } },
  { path: 'mt-mt', children: MAIN_APP_ROUTES, data: { country: 'mt', locale: 'mt', rtl: false } },
  { path: 'es-mx', children: MAIN_APP_ROUTES, data: { country: 'mx', locale: 'es', rtl: false } },
  { path: 'ne-ne', children: MAIN_APP_ROUTES, data: { country: 'ne', locale: 'ne', rtl: false } },
  { path: 'es-ni', children: MAIN_APP_ROUTES, data: { country: 'ni', locale: 'es', rtl: false } },
  { path: 'en-nz', children: MAIN_APP_ROUTES, data: { country: 'nz', locale: 'en', rtl: false } },
  { path: 'pa-pa', children: MAIN_APP_ROUTES, data: { country: 'pa', locale: 'pa', rtl: false } },
  { path: 'es-pe', children: MAIN_APP_ROUTES, data: { country: 'pe', locale: 'es', rtl: false } },
  { path: 'es-pr', children: MAIN_APP_ROUTES, data: { country: 'pr', locale: 'es', rtl: false } },
  { path: 'pt-pt', children: MAIN_APP_ROUTES, data: { country: 'pt', locale: 'pt', rtl: false } },
  { path: 'es-py', children: MAIN_APP_ROUTES, data: { country: 'py', locale: 'es', rtl: false } },
  { path: 'sb-sb', children: MAIN_APP_ROUTES, data: { country: 'sb', locale: 'sb', rtl: false } },
  { path: 'sd-sd', children: MAIN_APP_ROUTES, data: { country: 'sd', locale: 'sd', rtl: false } },
  { path: 'zh-sg', children: MAIN_APP_ROUTES, data: { country: 'sg', locale: 'zh', rtl: false } },
  { path: 'sl-sl', children: MAIN_APP_ROUTES, data: { country: 'sl', locale: 'sl', rtl: false } },
  { path: 'so-so', children: MAIN_APP_ROUTES, data: { country: 'so', locale: 'so', rtl: false } },
  { path: 'es-sv', children: MAIN_APP_ROUTES, data: { country: 'sv', locale: 'es', rtl: false } },
  { path: 'ar-sy', children: MAIN_APP_ROUTES, data: { country: 'sy', locale: 'ar', rtl: true } },
  { path: 'tg-tg', children: MAIN_APP_ROUTES, data: { country: 'tg', locale: 'tg', rtl: false } },
  { path: 'tk-tk', children: MAIN_APP_ROUTES, data: { country: 'tk', locale: 'tk', rtl: false } },
  { path: 'ar-tn', children: MAIN_APP_ROUTES, data: { country: 'tn', locale: 'ar', rtl: true } },
  { path: 'en-tt', children: MAIN_APP_ROUTES, data: { country: 'tt', locale: 'en', rtl: false } },
  { path: 'zh-tw', children: MAIN_APP_ROUTES, data: { country: 'tw', locale: 'zh', rtl: false } },
  { path: 'es-uy', children: MAIN_APP_ROUTES, data: { country: 'uy', locale: 'es', rtl: false } },
  { path: 'uz-uz', children: MAIN_APP_ROUTES, data: { country: 'uz', locale: 'uz', rtl: false } },
  { path: 'es-ve', children: MAIN_APP_ROUTES, data: { country: 've', locale: 'es', rtl: false } },
  { path: 'vi-vi', children: MAIN_APP_ROUTES, data: { country: 'vi', locale: 'vi', rtl: false } },
  { path: 'ar-ye', children: MAIN_APP_ROUTES, data: { country: 'ye', locale: 'ar', rtl: true } },
  { path: 'fr-bf', children: MAIN_APP_ROUTES, data: { country: 'bf', locale: 'fr', rtl: false } },
  { path: 'fr-bi', children: MAIN_APP_ROUTES, data: { country: 'bi', locale: 'fr', rtl: false } },
  { path: 'fr-bj', children: MAIN_APP_ROUTES, data: { country: 'bj', locale: 'fr', rtl: false } },
  { path: 'fr-bl', children: MAIN_APP_ROUTES, data: { country: 'bl', locale: 'fr', rtl: false } },
  { path: 'en-bm', children: MAIN_APP_ROUTES, data: { country: 'bm', locale: 'en', rtl: false } },
  { path: 'nl-bq', children: MAIN_APP_ROUTES, data: { country: 'bq', locale: 'nl', rtl: false } },
  { path: 'dz-bt', children: MAIN_APP_ROUTES, data: { country: 'bt', locale: 'dz', rtl: false } },
  { path: 'no-bv', children: MAIN_APP_ROUTES, data: { country: 'bv', locale: 'no', rtl: false } },
  { path: 'en-bw', children: MAIN_APP_ROUTES, data: { country: 'bw', locale: 'en', rtl: false } },
  { path: 'en-cc', children: MAIN_APP_ROUTES, data: { country: 'cc', locale: 'en', rtl: false } },
  { path: 'fr-cd', children: MAIN_APP_ROUTES, data: { country: 'cd', locale: 'fr', rtl: false } },
  { path: 'fr-cf', children: MAIN_APP_ROUTES, data: { country: 'cf', locale: 'fr', rtl: false } },
  { path: 'fr-cg', children: MAIN_APP_ROUTES, data: { country: 'cg', locale: 'fr', rtl: false } },
  { path: 'fr-ci', children: MAIN_APP_ROUTES, data: { country: 'ci', locale: 'fr', rtl: false } },
  { path: 'en-ck', children: MAIN_APP_ROUTES, data: { country: 'ck', locale: 'en', rtl: false } },
  { path: 'en-cm', children: MAIN_APP_ROUTES, data: { country: 'cm', locale: 'en', rtl: false } },
  { path: 'es-cu', children: MAIN_APP_ROUTES, data: { country: 'cu', locale: 'es', rtl: false } },
  { path: 'pt-cv', children: MAIN_APP_ROUTES, data: { country: 'cv', locale: 'pt', rtl: false } },
  { path: 'nl-cw', children: MAIN_APP_ROUTES, data: { country: 'cw', locale: 'nl', rtl: false } },
  { path: 'en-cx', children: MAIN_APP_ROUTES, data: { country: 'cx', locale: 'en', rtl: false } },
  { path: 'fr-dj', children: MAIN_APP_ROUTES, data: { country: 'dj', locale: 'fr', rtl: false } },
  { path: 'en-dm', children: MAIN_APP_ROUTES, data: { country: 'dm', locale: 'en', rtl: false } },
  { path: 'es-eh', children: MAIN_APP_ROUTES, data: { country: 'eh', locale: 'es', rtl: false } },
  { path: 'ti-er', children: MAIN_APP_ROUTES, data: { country: 'er', locale: 'ti', rtl: false } },
  { path: 'en-fj', children: MAIN_APP_ROUTES, data: { country: 'fj', locale: 'en', rtl: false } },
  { path: 'en-fk', children: MAIN_APP_ROUTES, data: { country: 'fk', locale: 'en', rtl: false } },
  { path: 'en-fm', children: MAIN_APP_ROUTES, data: { country: 'fm', locale: 'en', rtl: false } },
  { path: 'fr-ga', children: MAIN_APP_ROUTES, data: { country: 'ga', locale: 'fr', rtl: false } },
  { path: 'fr-gf', children: MAIN_APP_ROUTES, data: { country: 'gf', locale: 'fr', rtl: false } },
  { path: 'en-gg', children: MAIN_APP_ROUTES, data: { country: 'gg', locale: 'en', rtl: false } },
  { path: 'en-gh', children: MAIN_APP_ROUTES, data: { country: 'gh', locale: 'en', rtl: false } },
  { path: 'en-gi', children: MAIN_APP_ROUTES, data: { country: 'gi', locale: 'en', rtl: false } },
  { path: 'en-gm', children: MAIN_APP_ROUTES, data: { country: 'gm', locale: 'en', rtl: false } },
  { path: 'fr-gp', children: MAIN_APP_ROUTES, data: { country: 'gp', locale: 'fr', rtl: false } },
  { path: 'es-gq', children: MAIN_APP_ROUTES, data: { country: 'gq', locale: 'es', rtl: false } },
  { path: 'en-gs', children: MAIN_APP_ROUTES, data: { country: 'gs', locale: 'en', rtl: false } },
  { path: 'pt-gw', children: MAIN_APP_ROUTES, data: { country: 'gw', locale: 'pt', rtl: false } },
  { path: 'en-gy', children: MAIN_APP_ROUTES, data: { country: 'gy', locale: 'en', rtl: false } },
  { path: 'en-hm', children: MAIN_APP_ROUTES, data: { country: 'hm', locale: 'en', rtl: false } },
  { path: 'fr-ht', children: MAIN_APP_ROUTES, data: { country: 'ht', locale: 'fr', rtl: false } },
  { path: 'en-im', children: MAIN_APP_ROUTES, data: { country: 'im', locale: 'en', rtl: false } },
  { path: 'en-io', children: MAIN_APP_ROUTES, data: { country: 'io', locale: 'en', rtl: false } },
  { path: 'fa-ir', children: MAIN_APP_ROUTES, data: { country: 'ir', locale: 'fa', rtl: true } },
  { path: 'en-je', children: MAIN_APP_ROUTES, data: { country: 'je', locale: 'en', rtl: false } },
  { path: 'en-ke', children: MAIN_APP_ROUTES, data: { country: 'ke', locale: 'en', rtl: false } },
  { path: 'en-ki', children: MAIN_APP_ROUTES, data: { country: 'ki', locale: 'en', rtl: false } },
  { path: 'ko-kp', children: MAIN_APP_ROUTES, data: { country: 'kp', locale: 'ko', rtl: false } },
  { path: 'en-ky', children: MAIN_APP_ROUTES, data: { country: 'ky', locale: 'en', rtl: false } },
  { path: 'en-lc', children: MAIN_APP_ROUTES, data: { country: 'lc', locale: 'en', rtl: false } },
  { path: 'en-lr', children: MAIN_APP_ROUTES, data: { country: 'lr', locale: 'en', rtl: false } },
  { path: 'en-ls', children: MAIN_APP_ROUTES, data: { country: 'ls', locale: 'en', rtl: false } },
  { path: 'fr-mc', children: MAIN_APP_ROUTES, data: { country: 'mc', locale: 'fr', rtl: false } },
  { path: 'ro-md', children: MAIN_APP_ROUTES, data: { country: 'md', locale: 'ro', rtl: false } },
  { path: 'sr-me', children: MAIN_APP_ROUTES, data: { country: 'me', locale: 'sr', rtl: false } },
  { path: 'en-mf', children: MAIN_APP_ROUTES, data: { country: 'mf', locale: 'en', rtl: false } },
  { path: 'en-mh', children: MAIN_APP_ROUTES, data: { country: 'mh', locale: 'en', rtl: false } },
  { path: 'en-mp', children: MAIN_APP_ROUTES, data: { country: 'mp', locale: 'en', rtl: false } },
  { path: 'fr-mq', children: MAIN_APP_ROUTES, data: { country: 'mq', locale: 'fr', rtl: false } },
  { path: 'en-ms', children: MAIN_APP_ROUTES, data: { country: 'ms', locale: 'en', rtl: false } },
  { path: 'dv-mv', children: MAIN_APP_ROUTES, data: { country: 'mv', locale: 'dv', rtl: false } },
  { path: 'en-mw', children: MAIN_APP_ROUTES, data: { country: 'mw', locale: 'en', rtl: false } },
  { path: 'pt-mz', children: MAIN_APP_ROUTES, data: { country: 'mz', locale: 'pt', rtl: false } },
  { path: 'fr-nc', children: MAIN_APP_ROUTES, data: { country: 'nc', locale: 'fr', rtl: false } },
  { path: 'en-nf', children: MAIN_APP_ROUTES, data: { country: 'nf', locale: 'en', rtl: false } },
  { path: 'en-nr', children: MAIN_APP_ROUTES, data: { country: 'nr', locale: 'en', rtl: false } },
  { path: 'en-nu', children: MAIN_APP_ROUTES, data: { country: 'nu', locale: 'en', rtl: false } },
  { path: 'fr-pf', children: MAIN_APP_ROUTES, data: { country: 'pf', locale: 'fr', rtl: false } },
  { path: 'en-pg', children: MAIN_APP_ROUTES, data: { country: 'pg', locale: 'en', rtl: false } },
  { path: 'en-pk', children: MAIN_APP_ROUTES, data: { country: 'pk', locale: 'en', rtl: false } },
  { path: 'fr-pm', children: MAIN_APP_ROUTES, data: { country: 'pm', locale: 'fr', rtl: false } },
  { path: 'en-pn', children: MAIN_APP_ROUTES, data: { country: 'pn', locale: 'en', rtl: false } },
  { path: 'ar-ps', children: MAIN_APP_ROUTES, data: { country: 'ps', locale: 'ar', rtl: true } },
  { path: 'en-pw', children: MAIN_APP_ROUTES, data: { country: 'pw', locale: 'en', rtl: false } },
  { path: 'fr-re', children: MAIN_APP_ROUTES, data: { country: 're', locale: 'fr', rtl: false } },
  { path: 'sr-rs', children: MAIN_APP_ROUTES, data: { country: 'rs', locale: 'sr', rtl: false } },
  { path: 'rw-rw', children: MAIN_APP_ROUTES, data: { country: 'rw', locale: 'rw', rtl: false } },
  { path: 'fr-sc', children: MAIN_APP_ROUTES, data: { country: 'sc', locale: 'fr', rtl: false } },
  { path: 'en-sh', children: MAIN_APP_ROUTES, data: { country: 'sh', locale: 'en', rtl: false } },
  { path: 'no-sj', children: MAIN_APP_ROUTES, data: { country: 'sj', locale: 'no', rtl: false } },
  { path: 'it-sm', children: MAIN_APP_ROUTES, data: { country: 'sm', locale: 'it', rtl: false } },
  { path: 'fr-sn', children: MAIN_APP_ROUTES, data: { country: 'sn', locale: 'fr', rtl: false } },
  { path: 'nl-sr', children: MAIN_APP_ROUTES, data: { country: 'sr', locale: 'nl', rtl: false } },
  { path: 'en-ss', children: MAIN_APP_ROUTES, data: { country: 'ss', locale: 'en', rtl: false } },
  { path: 'pt-st', children: MAIN_APP_ROUTES, data: { country: 'st', locale: 'pt', rtl: false } },
  { path: 'nl-sx', children: MAIN_APP_ROUTES, data: { country: 'sx', locale: 'nl', rtl: false } },
  { path: 'en-sz', children: MAIN_APP_ROUTES, data: { country: 'sz', locale: 'en', rtl: false } },
  { path: 'en-tc', children: MAIN_APP_ROUTES, data: { country: 'tc', locale: 'en', rtl: false } },
  { path: 'fr-td', children: MAIN_APP_ROUTES, data: { country: 'td', locale: 'fr', rtl: false } },
  { path: 'fr-tf', children: MAIN_APP_ROUTES, data: { country: 'tf', locale: 'fr', rtl: false } },
  { path: 'tg-tj', children: MAIN_APP_ROUTES, data: { country: 'tj', locale: 'tg', rtl: false } },
  { path: 'pt-tl', children: MAIN_APP_ROUTES, data: { country: 'tl', locale: 'pt', rtl: false } },
  { path: 'tk-tm', children: MAIN_APP_ROUTES, data: { country: 'tm', locale: 'tk', rtl: false } },
  { path: 'en-to', children: MAIN_APP_ROUTES, data: { country: 'to', locale: 'en', rtl: false } },
  { path: 'en-tv', children: MAIN_APP_ROUTES, data: { country: 'tv', locale: 'en', rtl: false } },
  { path: 'en-um', children: MAIN_APP_ROUTES, data: { country: 'um', locale: 'en', rtl: false } },
  { path: 'it-va', children: MAIN_APP_ROUTES, data: { country: 'va', locale: 'it', rtl: false } },
  { path: 'en-vc', children: MAIN_APP_ROUTES, data: { country: 'vc', locale: 'en', rtl: false } },
  { path: 'en-vg', children: MAIN_APP_ROUTES, data: { country: 'vg', locale: 'en', rtl: false } },
  { path: 'bi-vu', children: MAIN_APP_ROUTES, data: { country: 'vu', locale: 'bi', rtl: false } },
  { path: 'fr-wf', children: MAIN_APP_ROUTES, data: { country: 'wf', locale: 'fr', rtl: false } },
  { path: 'sm-ws', children: MAIN_APP_ROUTES, data: { country: 'ws', locale: 'sm', rtl: false } },
  { path: 'fr-yt', children: MAIN_APP_ROUTES, data: { country: 'yt', locale: 'fr', rtl: false } },
  { path: 'en-zm', children: MAIN_APP_ROUTES, data: { country: 'zm', locale: 'en', rtl: false } },
  { path: 'en-zw', children: MAIN_APP_ROUTES, data: { country: 'zw', locale: 'en', rtl: false } }, */
  // from here the routes for the main app we support
  { path: 'ar', children: MAIN_APP_ROUTES, data: { country: '', locale: 'ar', rtl: true } },
  { path: 'en', children: MAIN_APP_ROUTES, data: { country: '', locale: 'en', rtl: false } },
  { path: 'sa', children: MAIN_APP_ROUTES, data: { country: 'sa', locale: '', rtl: true } },
  { path: 'ar-sa', children: MAIN_APP_ROUTES, data: { country: 'sa', locale: 'ar', rtl: true } },
  { path: 'en-sa', children: MAIN_APP_ROUTES, data: { country: 'sa', locale: 'en', rtl: false } },
  { path: 'ae', children: MAIN_APP_ROUTES, data: { country: 'ae', locale: '', rtl: true } },
  { path: 'ar-ae', children: MAIN_APP_ROUTES, data: { country: 'ae', locale: 'ar', rtl: true } },
  { path: 'en-ae', children: MAIN_APP_ROUTES, data: { country: 'ae', locale: 'en', rtl: false } },
  { path: 'kw', children: MAIN_APP_ROUTES, data: { country: 'kw', locale: '', rtl: true } },
  { path: 'ar-kw', children: MAIN_APP_ROUTES, data: { country: 'kw', locale: 'ar', rtl: true } },
  { path: 'en-kw', children: MAIN_APP_ROUTES, data: { country: 'kw', locale: 'en', rtl: false } },
  { path: 'qa', children: MAIN_APP_ROUTES, data: { country: 'qa', locale: '', rtl: true } },
  { path: 'ar-qa', children: MAIN_APP_ROUTES, data: { country: 'qa', locale: 'ar', rtl: true } },
  { path: 'en-qa', children: MAIN_APP_ROUTES, data: { country: 'qa', locale: 'en', rtl: false } },
  { path: 'om', children: MAIN_APP_ROUTES, data: { country: 'om', locale: '', rtl: true } },
  { path: 'ar-om', children: MAIN_APP_ROUTES, data: { country: 'om', locale: 'ar', rtl: true } },
  { path: 'en-om', children: MAIN_APP_ROUTES, data: { country: 'om', locale: 'en', rtl: false } },
  { path: 'bh', children: MAIN_APP_ROUTES, data: { country: 'bh', locale: '', rtl: true } },
  { path: 'ar-bh', children: MAIN_APP_ROUTES, data: { country: 'bh', locale: 'ar', rtl: true } },
  { path: 'en-bh', children: MAIN_APP_ROUTES, data: { country: 'bh', locale: 'en', rtl: false } },
  { path: '', children: MAIN_APP_ROUTES, data: { country: '', locale: '', rtl: false } },
];
