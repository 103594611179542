export class SharedImageCategory {
  public laptop: string;
  public phone: string;
  public tablet: string;
  public audio: string;
  public accessory: string;
  public watch: string;
  public gaming: string;

  constructor() {
    this.laptop = '';
    this.phone = '';
    this.tablet = '';
    this.audio = '';
    this.accessory = '';
    this.watch = '';
    this.gaming = '';
  }
}

export const parseSharedImageCategoryFromJsonString = (
  text?: string | null
): SharedImageCategory | null => {
  let out = null;
  try {
    out = parseSharedImageCategoryFromJson(JSON.parse(`${text || ''}`));
  } catch (error) {}
  return out;
};

export const parseSharedImageCategoryFromJson = (
  data?: any | null
): SharedImageCategory | null => {
  let out = null;
  if (data) {
    out = new SharedImageCategory();
    out.laptop = data['laptop'] || '';
    out.phone = data['phone'] || '';
    out.tablet = data['tablet'] || '';
    out.audio = data['audio'] || '';
    out.accessory = data['accessory'] || '';
    out.watch = data['watch'] || '';
    out.gaming = data['gaming'] || '';
  }
  return out;
};
