import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {
  Coordinate,
  COORDINATE_PRECISSION,
} from '@app/akita/api/location/models/coordinate.model';
import { getCurrencyInfo, CurrencyInformation } from '@app/shared/data/currencies.data';
import { AkitaLocationState, createInitialState } from '../models/location.state';
import { CURRENCY_BY_COUNTRY } from '@app/shared/utils/locale.utils';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'location', resettable: false })
export class AkitaLocationStore extends Store<AkitaLocationState> {
  constructor() {
    super(createInitialState());
  }

  public setIsLoading(isLoading?: boolean | null): void {
    this.update({
      loading: Boolean(isLoading),
    });
  }

  public updateUserLocation(coordinate: Coordinate | null): void {
    this.update((state: AkitaLocationState) => {
      const newCoordinate = !coordinate
        ? null
        : Coordinate.fromJson({
            ...coordinate,
            latitude: Number((coordinate.latitude || 0).toFixed(COORDINATE_PRECISSION)),
            longitude: Number((coordinate.longitude || 0).toFixed(COORDINATE_PRECISSION)),
          });
      const currency = this.detectActiveCurrency(newCoordinate);

      return {
        definedByUser: newCoordinate,
        suggestedUserCurrency: currency,
        country:
          state.currentLocation?.country || newCoordinate?.country || state.country || '',
      };
    });
  }

  public updateDeviceLocation(coordinate: Coordinate | null): void {
    this.update((state: AkitaLocationState) => {
      const newCoordinate = !coordinate
        ? null
        : Coordinate.fromJson({
            ...coordinate,
            latitude: Number((coordinate.latitude || 0).toFixed(COORDINATE_PRECISSION)),
            longitude: Number((coordinate.longitude || 0).toFixed(COORDINATE_PRECISSION)),
          });
      const currency = this.detectActiveCurrency(newCoordinate);

      return {
        currentLocation: newCoordinate,
        suggestedDeviceCurrency: currency,
        country:
          newCoordinate?.country || state.definedByUser?.country || state.country || '',
      };
    });
  }

  public detectActiveCurrency(coordinate: Coordinate | null): CurrencyInformation | null {
    let currency: string | null = null;
    if (coordinate) {
      currency = CURRENCY_BY_COUNTRY[(coordinate.country || 'us').toUpperCase()];
    }
    return getCurrencyInfo(currency);
  }
}
