import { Injectable } from '@angular/core';
import { AkitaConfigurationStore, AkitaConfigurationState } from './configuration.store';
import { Query } from '@datorama/akita';
import { isLanguageRtl } from '@app/shared/utils/url.utils';
import { Observable, map, distinctUntilChanged } from 'rxjs';
import { CurrencyInformation } from '@app/shared/data/currencies.data';
import { COUNTRIES_BY_LANGUAGE } from '@app/shared/data/countries-by-language.data';

@Injectable({ providedIn: 'root' })
export class AkitaConfigurationQuery extends Query<AkitaConfigurationState> {
  constructor(protected store: AkitaConfigurationStore) {
    super(store);
  }

  public get webPFeatures(): {
    lossy: boolean;
    lossless: boolean;
    alpha: boolean;
    animation: boolean;
  } {
    return (
      this.getValue().webP || {
        lossy: false,
        lossless: false,
        alpha: false,
        animation: false,
      }
    );
  }

  public get locale(): string {
    return this.getValue().languageCode || 'en-US';
  }

  public get currency(): CurrencyInformation {
    return (
      this.getValue().currency || {
        symbol: '$',
        name: 'US Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'USD',
        name_plural: 'US dollars',
      }
    );
  }

  public get currencyCode(): string {
    return this.currency.code;
  }

  public get languageCode(): { locale: string; countryCode: string; rtl: boolean } {
    const tokens = this.locale.split('-');
    const key: string = tokens[0];
    let country = tokens[1];
    if (
      !country &&
      key &&
      COUNTRIES_BY_LANGUAGE[key] &&
      COUNTRIES_BY_LANGUAGE[key].length > 0
    ) {
      country = COUNTRIES_BY_LANGUAGE[key][0];
    }

    const langCode = {
      locale: tokens[0] || 'en',
      countryCode: (country || 'US').toUpperCase(),
      rtl: false,
    };
    langCode.rtl = isLanguageRtl(langCode.locale);
    return langCode;
  }

  // Async

  public selectLocale(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaConfigurationState) => state.languageCode || 'en-US'),
      distinctUntilChanged()
    );
  }

  public selectCurrency(): Observable<CurrencyInformation> {
    return this.select().pipe(
      map(
        (state: AkitaConfigurationState) =>
          state.currency || {
            symbol: '$',
            name: 'US Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'USD',
            name_plural: 'US dollars',
          }
      ),
      distinctUntilChanged()
    );
  }

  public selectLanguageCode(): Observable<{
    locale: string;
    countryCode: string;
    rtl: boolean;
  }> {
    return this.selectLocale().pipe(
      map((locale: string) => {
        const tokens = locale.split('-');
        let country = tokens[1];
        if (
          !country &&
          tokens[0] &&
          COUNTRIES_BY_LANGUAGE[tokens[0]] &&
          COUNTRIES_BY_LANGUAGE[tokens[0]].length > 0
        ) {
          country = COUNTRIES_BY_LANGUAGE[tokens[0]][0];
        }

        const langCode = {
          locale: tokens[0] || 'en',
          countryCode: (country || 'US').toUpperCase(),
          rtl: false,
        };
        langCode.rtl = isLanguageRtl(langCode.locale);
        return langCode;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }
}
