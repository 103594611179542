import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Params } from '@angular/router';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { FooterOverlayDynamicService } from '@app/overlays/footer-overlay/services/footer-overlay-dynamic.service';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { ArrowDown1IconComponent } from '../../shared/components/icons/generated/arrow-down-1/arrow-down-1.component';
import {
  AVAILABLE_LANGUAGE_OPTION,
  AVAILABLE_LOCATION_OPTION,
} from '@app/akita/api/products/models/products.state';
import { LanguageOption, LocationOption } from '@app/akita/router/models/router.state';

const SCROLL_OFFSET = 125;

@Component({
  selector: 'app-announcement-bar',
  templateUrl: './announcement-bar.template.html',
  styleUrls: ['./announcement-bar.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, ArrowDown1IconComponent, AsyncPipe],
})
export class AnnouncementBarComponent implements AfterViewInit, OnDestroy {
  public readonly phoneSearchFragment$: Observable<Array<string>>;
  public readonly queryParamsToKeep$: Observable<Params>;

  private readonly hideBarSubject: BehaviorSubject<boolean>;
  public readonly hideBar$: Observable<boolean>;

  public country: string;

  public selectedLanguage$: Observable<LanguageOption>;
  public languages: Array<LanguageOption> = AVAILABLE_LANGUAGE_OPTION;

  private readonly selectedLocationSubject: BehaviorSubject<LocationOption>;
  public readonly selectedLocation$: Observable<LocationOption>;
  public locations: Array<LocationOption> = AVAILABLE_LOCATION_OPTION;

  @ViewChild('languagePicker', { read: ElementRef })
  public languagePickerElem?: ElementRef | null;

  @ViewChild('locationPicker', { read: ElementRef })
  public locationPickerElem?: ElementRef | null;

  constructor(
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly translateService: TranslocoService,
    private readonly footerOverlayDynamicService: FooterOverlayDynamicService
  ) {
    this.phoneSearchFragment$ = this.akitaRouterQuery.selectPhoneSearchFragment();
    this.queryParamsToKeep$ = this.akitaRouterQuery.selectQueryParamsToKeep();

    this.hideBarSubject = new BehaviorSubject(false as any);
    this.hideBar$ = this.hideBarSubject.asObservable();

    this.selectedLanguage$ = this.translateService.langChanges$.pipe(
      map(this.mapLangToLanguageOption.bind(this))
    );

    this.selectedLocationSubject = new BehaviorSubject(false as any);
    this.selectedLocation$ = this.selectedLocationSubject.asObservable();

    this.selectedLocation$ = this.akitaRouterQuery
      .selectCountry()
      .pipe(map(this.mapCountryToLocationOption.bind(this)));

    this.country = this.akitaRouterQuery.country.toUpperCase();
  }

  public ngAfterViewInit(): void {
    window.addEventListener('scroll', this.onScroll, true);
  }

  public onScroll = (event: any) => {
    if (event?.target?.className === 'main-scroll') {
      if (event?.target?.scrollTop > SCROLL_OFFSET) {
        this.hideBarSubject.next(true);
      } else {
        this.hideBarSubject.next(false);
      }
    }
  };

  private mapLangToLanguageOption(lang: string): LanguageOption {
    let selectedLang = this.languages[0];
    if (lang) {
      for (const language of this.languages) {
        if (language.locale === lang) {
          selectedLang = language;
          break;
        }
      }
    }
    return selectedLang;
  }

  private mapCountryToLocationOption(country: string): LocationOption {
    let selectedLocation = this.locations[0]; // SA default fall-back

    if (country) {
      for (const location of this.locations) {
        if (location.country === country.toLowerCase()) {
          selectedLocation = location;
          break;
        }
      }
    }
    this.selectedLocationSubject.next(selectedLocation);
    return selectedLocation;
  }

  public toggleLocationPicker(): boolean {
    const selectedLocation = this.selectedLocationSubject.value;

    this.footerOverlayDynamicService.open('language', {
      elementRef: this.locationPickerElem,
      data: {
        placeholder: 'button_select_location',
        selectedOption: selectedLocation,
        options: this.locations,
        urlParam: 'country',
        urlValueKey: 'country',
        changeUrlFragment: true,
      },
      isBrowser: this.akitaRouterQuery.isBrowser,
    });

    SentryUtil.addBreadcrumb({
      category: 'ui',
      message: `Opened "Language Overlay" modal`,
      level: 'info',
      type: 'user',
    });

    return false;
  }

  public toggleLanguagePicker(): boolean {
    const selectedLanguage = this.mapLangToLanguageOption(
      this.translateService.getActiveLang()
    );
    this.footerOverlayDynamicService.open('language', {
      elementRef: this.languagePickerElem,
      data: {
        placeholder: 'label_select_language',
        selectedOption: selectedLanguage,
        options: this.languages,
        urlParam: 'locale',
        urlValueKey: 'locale',
        changeUrlFragment: true,
      },
      isBrowser: this.akitaRouterQuery.isBrowser,
    });

    SentryUtil.addBreadcrumb({
      category: 'ui',
      message: `Opened "Language Overlay" modal`,
      level: 'info',
      type: 'user',
    });

    return false;
  }

  public ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }
}
