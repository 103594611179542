import { Model } from '../../model.interface';
import { PopsyDateParser } from '@app/shared/utils/api-date.parser';

/**
 * Class used to represent a AuthToken
 */
export class AuthTokenModel implements Model {
  public accessToken: string;
  public expiresIn: number;
  public expiresAt: Date;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.accessToken = '';
    this.expiresIn = 0;
    this.expiresAt = new Date(0);
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   *
   * @param data The Raw JSON Object
   * @returns {AuthTokenModel} An instance of the AuthTokenModel class.
   */
  public static fromJson(data: unknown): AuthTokenModel | null {
    let authToken: AuthTokenModel | null = null;

    if (data) {
      authToken = new AuthTokenModel();
      authToken.accessToken = (data as any).access_token || (data as any).accessToken;
      authToken.expiresIn = (data as any).expires_in || (data as any).expiresIn;
      authToken.expiresAt =
        PopsyDateParser.parseApiDate('expires_at', 'expiresAt', data) || new Date(0);
    }

    return authToken;
  }
}
