import { Configuration, Sizes, Result } from './types';
import { getOptions } from './options';
import { addListener, removeAll } from './event-listener';

export const updateCssVar = (cssVarName: string, result: { value: any }): void => {
  document.documentElement.style.setProperty(`--${cssVarName}`, `${result.value}px`);
};

export const formatResult = (sizes: Sizes, options: Configuration): Result => ({
  ...sizes,
  unbind: removeAll,
  recompute: options.method,
});

export const vhCheck = (options?: string | Configuration): Result | null => {
  const config = Object.freeze(getOptions(options));
  let result: Result | null = null;
  if (config?.method) {
    result = formatResult(config.method(), config);
  }

  // usefulness check
  if (!result?.isNeeded && !config.force) {
    return result;
  }

  if (result) {
    updateCssVar(`${config?.cssVarName || ''}`, result);

    if (config?.onUpdate) {
      config.onUpdate(result);
    }
  }

  // enabled by default
  if (!config.bind) {
    return result;
  }

  function onWindowChange() {
    try {
      window.requestAnimationFrame(() => {
        if (config?.method) {
          const sizes = config.method();
          updateCssVar(`${config.cssVarName || ''}`, sizes);

          if (config?.onUpdate) {
            config.onUpdate(formatResult(sizes, config));
          }
        }
      });
    } catch (err) {
      // SSR Window not supported
    }
  }

  // be sure we don't duplicates events listeners
  if (result) {
    result.unbind();
  }

  // listen for orientation change
  // - this can't be configured
  // - because it's convenient and not a real performance bottleneck
  addListener('orientationchange', onWindowChange);

  // listen to touch move for scrolling
  // – disabled by default
  // - listening to scrolling can be expansive…
  if (config.updateOnTouch) {
    addListener('touchmove', onWindowChange);
  }

  return result;
};
