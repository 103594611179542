import { PopsyDateParser } from '@app/shared/utils/api-date.parser';
import { CartItem, cartItemListFromJson } from './cart.model';

export class Cart {
  public id: string;
  public orders: Array<CartItem>;
  public createdAt: Date;
  public modifiedAt: Date;
  public isEmpty: boolean;
  public sessionId?: string;

  constructor() {
    this.id = '';
    this.orders = new Array(0);
    this.createdAt = new Date();
    this.modifiedAt = new Date();
    this.isEmpty = false;
  }
}

export const cartFromJson = (data?: Record<string, any> | null): Cart | null => {
  let parsed: Cart | null = null;
  if (data) {
    parsed = new Cart();
    parsed.id = data?.id || '';
    parsed.orders = cartItemListFromJson(data.orders);
    parsed.createdAt =
      PopsyDateParser.parseApiDate('created_at', 'createdAt', data) || new Date();
    parsed.modifiedAt =
      PopsyDateParser.parseApiDate('modified_at', 'modifiedAt', data) || new Date();
    parsed.isEmpty = Boolean(data?.is_empty || data?.isEmpty);
    parsed.sessionId = data?.session_id || data?.sessionId || '';
  }
  return parsed;
};
