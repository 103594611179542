import { inject, Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import { AuthTokenModel } from '@app/shared/models/api/auth/auth-token.model';
import { UserModel } from '@app/shared/models/api/user.model';
import { VerifyUserModel } from '@app/shared/models/api/auth/verify-user.model';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class AuthAPIService {
  private readonly http = inject(HttpClient);
  constructor() {}

  public signIn(email: string, passwordHash: string): Observable<UserModel | null> {
    const options = new HttpParams();

    return this.http
      .post<any>(
        // Assemble the full API URL
        getApiEndpoint('oauth/bridge/basic', true),
        { id: email, secret: passwordHash },
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
        }
      )
      .pipe(
        switchMap(this.parseSignInResults.bind(this)),
        map((results) => results)
      );
  }

  public signUp(
    email: string,
    username?: string | null,
    password?: string | null,
    locale?: string | null
  ): Observable<UserModel | null> {
    const options = new HttpParams()
      .set('user', username || '')
      .set('email', email)
      .set('pw', password || '')
      .set('locale', locale || 'en-US');

    return this.http
      .post<any>(
        // Assemble the full API URL
        getApiEndpoint('register'),
        {},
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
        }
      )
      .pipe(
        map(
          (results) => UserModel.fromJson(results) // Empty Results
        )
      );
  }

  public signUpInBackground(
    email: string,
    username: string,
    password?: string | null,
    locale?: string | null
  ): Observable<UserModel | null> {
    let options = new HttpParams().set('email', email).set('locale', locale || 'en-US');

    if (username) {
      options = options.set('user', username);
    }

    if (password) {
      options = options.set('pw', password);
    }

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint('register/direct'),
        {
          params: options,
        }
      )
      .pipe(map((results) => UserModel.fromJson(results)));
  }

  public signInWithFacebook(fbId: string, fbToken: string): Observable<UserModel | null> {
    const options = new HttpParams();

    return this.http
      .post<any>(
        // Assemble the full API URL
        getApiEndpoint('oauth/bridge/facebook2', true),
        { id: fbId, token: fbToken },
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
        }
      )
      .pipe(
        switchMap(this.parseSignInResults.bind(this)),
        map((results) => results)
      );
  }

  public signInWithApple(secret: string, name: string): Observable<UserModel | null> {
    const options = new HttpParams();

    return this.http
      .post<any>(
        // Assemble the full API URL
        getApiEndpoint('oauth/bridge/apple', true),
        { secret: secret, fullname: name },
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
        }
      )
      .pipe(
        switchMap(this.parseSignInResults.bind(this)),
        map((results) => results)
      );
  }

  public signInWithGoogle(secret: string): Observable<UserModel | null> {
    const options = new HttpParams();

    return this.http
      .post<any>(
        // Assemble the full API URL
        getApiEndpoint('oauth/bridge/google', true),
        { secret: secret },
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
        }
      )
      .pipe(
        switchMap(this.parseSignInResults.bind(this)),
        map((results) => results)
      );
  }

  public getUserInfoFromToken(token: string): Observable<UserModel | null> {
    const options = new HttpParams().set('oa', token);

    return this.http
      .post<any>(
        // Assemble the full API URL
        getApiEndpoint('oauth/bridge/token', true),
        {},
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
        }
      )
      .pipe(
        switchMap((results) => {
          const authUser = UserModel.fromJson(results) || new UserModel();
          return this.getAccessToken(authUser.oauth.publicId, authUser.oauth.secret).pipe(
            map((newToken) => {
              authUser.oauth.token = newToken;
              return authUser;
            })
          );
        })
      );
  }

  public getAccessToken(
    clientId: string,
    clientSecret: string
  ): Observable<AuthTokenModel | null> {
    const options = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', clientId)
      .set('client_secret', clientSecret);

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint('oauth/v2/token', true),
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
        }
      )
      .pipe(map((results) => AuthTokenModel.fromJson(results)));
  }

  public verifyUserDetails(email: string): Observable<VerifyUserModel | null> {
    const params = new HttpParams().set('email', email);

    return this.http
      .get<any>(
        // Assemble the full API URL
        getApiEndpoint(`oauth/validate`, true),
        // Add the Options
        {
          responseType: 'json',
          params: params,
        }
      )
      .pipe(map((results) => VerifyUserModel.fromJson(results)));
  }

  public parseSignInResults(results: UserModel | null): Observable<UserModel> {
    const authUser = UserModel.fromJson(results) || new UserModel();
    return this.getAccessToken(authUser.oauth.publicId, authUser.oauth.secret).pipe(
      map((token) => {
        authUser.oauth.token = token;
        return authUser;
      })
    );
  }
}
