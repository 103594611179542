import { Injectable } from '@angular/core';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { getWindowRef } from '@app/shared/utils/window.util';
import { Observable, Observer } from 'rxjs';
import { CartItem, cartItemListFromJson } from '../models/cart.model';

const LOCAL_STORAGE_CARK_KEY = 'popsy_ci';
const MAX_STORAGE = 6;

@Injectable({ providedIn: 'root' })
export class LocalCartStorageService {
  constructor(private readonly akitaRouterQuery: AkitaRouterQuery) {}

  public readCartItems(): Observable<Array<CartItem>> {
    return new Observable((observer: Observer<Array<CartItem>>) => {
      let items: Array<CartItem> = new Array(0);

      if (this.akitaRouterQuery.isBrowser) {
        try {
          const windowRef = getWindowRef();
          if (windowRef) {
            const itemsStr = windowRef.localStorage.getItem(LOCAL_STORAGE_CARK_KEY);
            if (itemsStr) {
              const itemsRaw = JSON.parse(itemsStr);
              items = cartItemListFromJson(itemsRaw);
            }
          }
        } catch (error) {
          // Errors not relevant, just return empty cart
        }
      }

      observer.next(items);
      observer.complete();
    });
  }

  public saveCartItemIDs(items: Array<CartItem>): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      let success = false;
      const itemIds = items.map((item) => item?.product?.id || '');

      if (this.akitaRouterQuery.isBrowser) {
        try {
          const windowRef = getWindowRef();
          if (windowRef) {
            const itemsStr = windowRef.localStorage.getItem(LOCAL_STORAGE_CARK_KEY);
            if (itemsStr) {
              const itemsRaw = JSON.parse(itemsStr);
              itemsRaw.forEach((item: any) => {
                if (!itemIds.includes(item)) {
                  itemIds.unshift(item);
                }
              });
            }

            windowRef.localStorage.setItem(
              LOCAL_STORAGE_CARK_KEY,
              JSON.stringify(itemIds)
            );
            success = true;
          }
        } catch (error) {
          // Success is already set to false
        }
      }

      observer.next(success);
      observer.complete();
    });
  }

  public saveProductID(productId: string): boolean {
    let success = false;

    if (this.akitaRouterQuery.isBrowser) {
      try {
        const windowRef = getWindowRef();
        if (windowRef) {
          const itemsStr = windowRef.localStorage.getItem(LOCAL_STORAGE_CARK_KEY);
          const items = itemsStr ? JSON.parse(itemsStr) : [];
          if (!items.includes(productId)) {
            items.unshift(productId);
          }

          windowRef.localStorage.setItem(
            LOCAL_STORAGE_CARK_KEY,
            JSON.stringify(items.slice(0, MAX_STORAGE))
          );
          success = true;
        }
      } catch (error) {
        // Success is already set to false
      }
    }

    return success;
  }

  public readProductIDs(): Observable<Array<string>> {
    return new Observable((observer: Observer<Array<string>>) => {
      let items: Array<string> = new Array(0);

      if (this.akitaRouterQuery.isBrowser) {
        try {
          const windowRef = getWindowRef();
          if (windowRef) {
            const itemsStr = windowRef.localStorage.getItem(LOCAL_STORAGE_CARK_KEY);
            if (itemsStr) {
              items = JSON.parse(itemsStr);
            }
          }
        } catch (error) {
          // Errors not relevant, just return empty cart
        }
      }

      observer.next(items);
      observer.complete();
    });
  }
}
