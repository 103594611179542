import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { AkitaConfigurationQuery } from '@app/akita/configuration/state/configuration.query';
import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { ScreenSizes } from '@app/akita/screen/state/screen.store';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

const PICTURE_OPTIMIZATION_WEBP = '=s{size}-rw-w{size}-h{size}-e365-l80?';
const PICTURE_OPTIMIZATION = '=s{size}-rj-w{size}-h{size}-e365-l80?';

const SIZE_XXS = 256;
const SIZE_MOBILE_S = 256;
const SIZE_XS = 256;
const SIZE_MOBILE_L = 256;
const SIZE_MOBILE_XL = 256;
const SIZE_SM = 512;
const SIZE_MD = 512;
const SIZE_LG = 512;
const SIZE_XL = 512;
const SIZE_XXL = 512;

/**
 * Class to handle image errors
 */
@Injectable({
  providedIn: 'root',
})
export class ImagePreloadService {
  public isBrowser: boolean;
  private readonly failedImages: { [path: string]: Error | ErrorEvent | string } = {};
  private webP: {
    lossy: boolean;
    lossless: boolean;
    alpha: boolean;
    animation: boolean;
  };

  constructor(
    private readonly akitaConfigurationQuery: AkitaConfigurationQuery,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;
    this.failedImages = this.fetchLocalStorage();
    this.webP = this.akitaConfigurationQuery.webPFeatures || {
      lossy: false,
      lossless: false,
      alpha: false,
      animation: false,
    };
  }

  public loadImage(url: string, displaySize?: number | null): Promise<string> {
    this.webP = this.akitaConfigurationQuery.webPFeatures || {
      lossy: false,
      lossless: false,
      alpha: false,
      animation: false,
    };

    // Convert App Engine URL to get an optimiced image
    url = this.optimizeAppEngineImage(url, displaySize);

    const urlHash = Md5.hashStr(url, false) as string;
    return new Promise<string>((resolve, reject) => {
      // Image class does not exist in SSR, protect against this
      try {
        if (!this.imageTriedToLoad(urlHash)) {
          const imgElement = new Image();
          imgElement.addEventListener('load', () => {
            this.failedImages[urlHash] = '';
            this.updateLocalStorage();
            resolve(url);
          });
          imgElement.addEventListener('error', (err: ErrorEvent) => {
            SentryUtil.addBreadcrumb({
              category: 'network',
              message: `Error loading image "${url}" with error "${err}"`,
              level: 'info',
              type: 'http',
            });

            this.failedImages[urlHash] = 'ERROR_LOADING';
            this.updateLocalStorage();
            reject(err);
          });
          imgElement.src = url;
        } else if (this.imageFailedToLoad(urlHash)) {
          reject(this.failedImages[urlHash]);
        } else {
          resolve(url);
        }
      } catch (error) {
        this.failedImages[urlHash] = error as any;
        this.updateLocalStorage();
        reject(this.failedImages[urlHash]);
      }
    });
  }

  public imageTriedToLoad(urlHash: string): boolean {
    return Boolean(Object.prototype.hasOwnProperty.call(this.failedImages, `${urlHash}`));
  }

  public imageFailedToLoad(urlHash: string): boolean {
    return Boolean(
      Object.prototype.hasOwnProperty.call(this.failedImages, `${urlHash}`) &&
        this.failedImages[urlHash] !== ''
    );
  }

  public optimizeAppEngineImage(url: string, displaySize?: number | null): string {
    this.webP = this.akitaConfigurationQuery.webPFeatures || {
      lossy: false,
      lossless: false,
      alpha: false,
      animation: false,
    };

    let size = SIZE_MD;

    if (displaySize) {
      size = displaySize;
    } else {
      // Adapt the image sizes based on the Screen Size
      const screenSize = this.akitaScreenQuery.size;
      if (screenSize === ScreenSizes.XXS) {
        size = SIZE_XXS;
      } else if (screenSize === ScreenSizes.MobileS) {
        size = SIZE_MOBILE_S;
      } else if (screenSize === ScreenSizes.XS) {
        size = SIZE_XS;
      } else if (screenSize === ScreenSizes.MobileL) {
        size = SIZE_MOBILE_L;
      } else if (screenSize === ScreenSizes.MobileXL) {
        size = SIZE_MOBILE_XL;
      } else if (screenSize === ScreenSizes.SM) {
        size = SIZE_SM;
      } else if (screenSize === ScreenSizes.MD) {
        size = SIZE_MD;
      } else if (screenSize === ScreenSizes.LG) {
        size = SIZE_LG;
      } else if (screenSize === ScreenSizes.XL) {
        size = SIZE_XL;
      } else if (screenSize === ScreenSizes.XXL) {
        size = SIZE_XXL;
      }
    }

    let newUrl = `${url}`;
    // Convert App Engine URL to get an optimiced image
    // https://stackoverflow.com/questions/25148567/list-of-all-the-app-engine-images-service-get-serving-url-uri-options
    if (this.webP.lossy) {
      newUrl = newUrl.replace(
        /(=\S+\?)/g,
        PICTURE_OPTIMIZATION_WEBP.replace(/{size}/g, `${size}`)
      );
    } else {
      newUrl = newUrl.replace(
        /(=\S+\?)/g,
        PICTURE_OPTIMIZATION.replace(/{size}/g, `${size}`)
      );
    }
    return newUrl;
  }

  public reportBadImage(url: string, error: string | ErrorEvent | Error): void {
    if (url && error) {
      SentryUtil.addBreadcrumb({
        category: 'network',
        message: `Error loading image "${url}" with error "${error}"`,
        level: 'info',
        type: 'http',
      });

      const urlHash = Md5.hashStr(url, false) as string;
      this.failedImages[urlHash] = error;
      this.updateLocalStorage();
    }
  }

  public updateLocalStorage(): void {
    if (this.isBrowser) {
      try {
        window.localStorage.setItem('broken_images', JSON.stringify(this.failedImages));
      } catch (error) {}
    }
  }

  public fetchLocalStorage(): Record<string, any> {
    if (this.isBrowser) {
      try {
        return JSON.parse(window.localStorage.getItem('broken_images') || '{}') || {};
      } catch (error) {}
    }
    return {};
  }
}
