import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { AkitaOmnisendState, createInitialOmnisendState } from '../models/omnisend.state';
import { OmniSendIdentifyUser } from '../models/omnisend-itentify-response.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'omnisend', resettable: true })
export class AkitaOmnisendStore extends Store<AkitaOmnisendState> {
  constructor() {
    super(createInitialOmnisendState());
  }

  public updateOmnisendUserId(userInfo?: OmniSendIdentifyUser | null): void {
    this.update({
      consumerId: userInfo?.consumerId || '',
      isNew: Boolean(userInfo?.isNew),
    });
  }

  public toggleOmnisendConsumerUpdating(isUpdating?: boolean | null): void {
    this.update({
      updatingConsumer: Boolean(isUpdating),
    });
  }
}
