export const COUNTRIES_BY_LANGUAGE: { [code: string]: Array<string> } = {
  af: ['af'],
  sq: ['sq'],
  am: ['am'],
  ar: [
    'sa',
    'dz',
    'bh',
    'eg',
    'iq',
    'jo',
    'kw',
    'lb',
    'ly',
    'ma',
    'om',
    'qa',
    'sy',
    'tn',
    'ae',
    'ye',
  ],
  hy: ['hy'],
  as: ['as'],
  az: ['az'],
  eu: ['eu'],
  be: ['be'],
  bn: ['bn', 'bd'],
  bs: ['bs', 'ba'],
  bg: ['bg'],
  my: ['my'],
  ca: ['ca', 'ad'],
  zh: ['cn', 'hk', 'mo', 'sg', 'tw'],
  hr: ['hr'],
  cs: ['cs'],
  da: ['da'],
  dv: ['dv'],
  nl: ['be', 'nl', 'aw'],
  en: [
    'us',
    'au',
    'bz',
    'ca',
    'cb',
    'gb',
    'in',
    'ie',
    'jm',
    'nz',
    'ph',
    'za',
    'tt',
    'zi',
    'ag',
    'ai',
    'aq',
    'bb',
    'pt',
  ],
  et: ['et'],
  mk: ['mk'],
  fo: ['fo'],
  fa: ['fa'],
  ph: ['ph'],
  fi: ['fi'],
  fr: ['fr', 'be', 'ca', 'lu', 'ch'],
  gd: ['gd', 'ie'],
  gl: ['gl'],
  ka: ['ka'],
  de: ['de', 'at', 'li', 'lu', 'ch'],
  el: ['el'],
  gn: ['gn'],
  gu: ['gu'],
  he: ['he', 'il'],
  hi: ['hi'],
  hu: ['hu'],
  is: ['is'],
  id: ['id'],
  it: ['it', 'ch'],
  ja: ['ja'],
  kn: ['kn'],
  ks: ['ks'],
  km: ['km'],
  kk: ['kk'],
  ko: ['ko'],
  lo: ['lo'],
  la: ['la'],
  lv: ['lv'],
  lt: ['lt'],
  ms: ['my', 'bn'],
  ml: ['ml'],
  mt: ['mt'],
  mi: ['mi'],
  mr: ['mr'],
  mn: ['mn'],
  ne: ['ne'],
  nb: ['no'],
  no: ['no'],
  nn: ['no'],
  or: ['or'],
  pt: ['br', 'pl', 'pt', 'ao'],
  pl: ['pl', 'br', 'pt'],
  pa: ['pa'],
  rm: ['rm'],
  ro: ['ro', 'mo'],
  ru: ['ru', 'mo'],
  sa: ['sa'],
  sr: ['sp'],
  tn: ['tn'],
  sd: ['sd'],
  si: ['si'],
  sk: ['sk'],
  sl: ['sl'],
  so: ['so'],
  sb: ['sb'],
  es: [
    'es',
    'ar',
    'bo',
    'cl',
    'co',
    'cr',
    'do',
    'ec',
    'sv',
    'gt',
    'hn',
    'mx',
    'ni',
    'pa',
    'py',
    'pe',
    'pr',
    'uy',
    've',
  ],
  sw: ['sw'],
  sv: ['fi', 'se', 'ax'],
  tg: ['tg'],
  ta: ['ta'],
  tt: ['tt'],
  te: ['te'],
  th: ['th'],
  bo: ['bo'],
  ts: ['ts'],
  tr: ['tr'],
  tk: ['tk'],
  uk: ['uk'],
  ur: ['ur'],
  uz: ['uz'],
  vi: ['vi'],
  cy: ['cy'],
  xh: ['xh'],
  yi: ['yi'],
  zu: ['zu'],
};
