/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { WindowUtils } from '../utils/window.util';

@Injectable({
  providedIn: 'root',
})
export class MSClarityService {
  constructor(private readonly akitaRouterQuery: AkitaRouterQuery) {}

  public identifyUser(
    userId?: string | null,
    sessionId?: string | null,
    pageName?: string | null
  ): void {
    if (this.akitaRouterQuery.isBrowser) {
      const windowRef: any | null = WindowUtils.window;
      if (windowRef && windowRef.clarity) {
        windowRef.clarity('identify', userId, sessionId, pageName);
      }
    }
  }

  public setTag(key: string, value?: string | Array<string> | null): void {
    if (this.akitaRouterQuery.isBrowser) {
      const windowRef: any | null = WindowUtils.window;
      if (windowRef && windowRef.clarity) {
        windowRef.clarity('set', key, value);
      }
    }
  }

  public upgradeSession(reason: string): void {
    if (this.akitaRouterQuery.isBrowser) {
      const windowRef: any | null = WindowUtils.window;
      if (windowRef && windowRef.clarity) {
        windowRef.clarity('upgrade', reason);
      }
    }
  }

  public consent(): void {
    if (this.akitaRouterQuery.isBrowser) {
      const windowRef: any | null = WindowUtils.window;
      if (windowRef && windowRef.clarity) {
        windowRef.clarity('consent');
      }
    }
  }
}
