export class BannerInfo {
  public title: string;
  public headline: string;
  public buttonText: string;
  public image: string;
  public backgroundColor: string;
  public backgroundImage: string;
  public isLight: boolean;
  public url: string;
  public isButtonOutlined: boolean;

  constructor() {
    this.title = '';
    this.headline = '';
    this.buttonText = '';
    this.image = '';
    this.backgroundColor = '';
    this.backgroundImage = '';
    this.isLight = false;
    this.url = '';
    this.isButtonOutlined = false;
  }
}

export const parseBannerInfoFromJsonString = (
  text?: string | null
): BannerInfo | null => {
  let out = null;
  try {
    out = parseBannerInfoFromJson(JSON.parse(`${text || ''}`));
  } catch (error) {}
  return out;
};

export const parseBannerInfoFromJson = (data?: any | null): BannerInfo | null => {
  let out = null;
  if (data) {
    out = new BannerInfo();
    out.title = data.title || '';
    out.headline = data.headline || '';
    out.buttonText = data.button_text || data.buttonText || '';
    out.image = data.image || '';
    out.backgroundColor = data.background_color || data.backgroundColor || '';
    out.backgroundImage = data.background_image || data.backgroundImage || '';
    out.isLight = Boolean(data.is_light || data.isLight);
    out.url = data.url || '';
    out.isButtonOutlined = Boolean(data.is_button_outlined || data.isButtonOutlined);
  }
  return out;
};

export const parseBannerInfoFromJsonArray = (data?: any | null): Array<BannerInfo> => {
  const out = new Array(0);
  if (data) {
    for (const item of data) {
      const parsed = parseBannerInfoFromJson(item);
      if (parsed) {
        out.push(parsed);
      }
    }
  }
  return out;
};
