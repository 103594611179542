import { Model } from '@app/shared/models/model.interface';

export class SessionAddressModel implements Model {
  public country: string;
  public city: string;
  public address: string;
  public addressDetails: string;
  public postalCode: string;
  public streetNumber: string;
  public province: string;

  /**
   * Constructor to initialize the properties
   * - Make sure the properties are initialized (to avoid unexpected undefined values)
   * @constructor
   */
  constructor() {
    this.country = '';
    this.city = '';
    this.address = '';
    this.addressDetails = '';
    this.postalCode = '';
    this.streetNumber = '';
    this.province = '';
  }

  /**
   * Helper function to sanitize the data that comes from the Backend.
   *
   * @param data The Raw JSON Object
   * @returns {SessionAddressModel} An instance of the delivery info class.
   */
  public static fromJson(data?: Record<string, any>): SessionAddressModel {
    let address: SessionAddressModel = new SessionAddressModel();
    if (data) {
      address = new SessionAddressModel();
      address.country = data.country || '';
      address.city = data.city || '';
      address.address = data.address || '';
      address.addressDetails = data.address_details || data.addressDetails || '';
      address.postalCode = data.postal_code || data.postalCode || '';
      address.streetNumber = data.street_number || data.streetNumber || '';
      address.province = data.province || '';
    }
    return address;
  }
}
