import languages from '@assets/i18n/languages.json';
import buildMetadata from '@assets/metadata.json';

export const environment = {
  name: 'prod',
  domain: 'https://www.popsy.app/',
  app: {
    version: buildMetadata.version,
    commit: buildMetadata.commit,
    branch: buildMetadata.branch,
  },
  inServer: false,
  production: true,
  enableAnalitycs: true,
  debugAnalitycs: false,
  reportToSentry: true,
  sentryAskFeedback: false,
  serviceWorker: true,
  enableHotjar: false,
  enableOmnisend: true,
  enableSmartLook: false,
  api: {
    protocol: 'https://',
    url: 'api.popsy.app',
    port: 443,
    path: '/api',
    version: '/v1',
  },
  blogApi: {
    protocol: 'https://',
    url: 'blog.popsy.app',
    port: 443,
    path: '/wp-json',
    version: '/v2',
  },
  firebase: {
    apiKey: 'AIzaSyAYqkgWAUgReGdZJFwp9T2qFppkTkXjRUU',
    authDomain: 'popsygae.firebaseapp.com',
    databaseURL: 'https://popsygae.firebaseio.com',
    projectId: 'popsygae',
    storageBucket: 'api.popsy.app',
    messagingSenderId: '623123402103',
    appId: '1:623123402103:web:113919f03beba40e02e572',
    measurementId: 'G-XRNEBP8N5C',
  },
  stripe: {
    country: {
      br: 'pk_live_No37ZPvEethwFTCLpfjzXz3x00qIb1KSPW',
      us_account: 'pk_live_nd1v9XdHIXx91Ij6NlC1KXP500NRp2TaDl', // TODO Implement API PublicKey for Stripe too
      us: 'pk_live_51MhflFCWBKdjR5CgkKeUUTdEYLrgvH99ZYG8tuhCjrC0rbx7ncLmZ7dnIpBNuk3Cu2xvdWRKHpwP061tzBfGFW0S00vvKiD9Sc',
    },
  },
  mercadoPago: {
    appId: '4620208812297766',
    key: 'APP_USR-4d9c29b9-5c45-42ad-8653-69d4216b8151',
    accessToken:
      'APP_USR-4620208812297766-031514-c3e3e4078712927bab5c93e5aea042c6-727605448',
  },
  tabby: {
    pubKey: 'pk_6237b658-bf9f-4fa2-8904-3140b3f88d6a',
  },
  tamara: {
    pubKey: 'dcc15b9f-ada1-48b9-be73-35375c57ac7c',
  },
  checkout: {
    pubKey: 'pk_sbox_7s3lbhhllye6oyhy7nl6hha7ny2',
  },
  moip: {
    pubKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqR8FM02JYmVb1iNuqNDe
1oWhcKYflm9UIK0W8RdMW8+06YPO1L6rb6vVnUA/sFRPb2w7nl71KqJDYAxwcKFJ
KhW+PSbvREzffzSEQsZCtsGXoHkuVYm/L5rOMLuQB4jbVHpcwSM/CpGc1p7PlqJ1
KCCgXHQBz3BEY3POsJSUpF1KvC6QWO72KCSPTbq+v9msRaj/qrLPq4pQ0gJjKwel
2RUh9XWQDW6V2ATo1zySnb4ZpAC4e5e+k0I+kYywxdw4jXCx8yOVTH/kFpSno6cM
/+l3mU8MoXACQPo/EbV3x+kyeF1D60t3876vL01K+RvQ0c7RSVZI8Vr/nbA5jHpm
VwIDAQAB
-----END PUBLIC KEY-----`,
  },
  apple: {
    clientId: 'app.popsy.buysell.service',
    baseURL: 'https://www.popsy.app',
  },
  google: {
    clientId: '623123402103-ame6isegnneda535h995iuqpj5f8gu9a.apps.googleusercontent.com',
    baseURL: 'https://www.popsy.app',
    googlePayMerchantId: 'BCR2DN6T5PWJRHKQ',
  },
  debugMode: false,
  hmr: false,
  languageCode: 'en',
  languages: languages,
  fbAppId: '836210163094288',
  fbAppVersion: 'v7.0',
  gmapsApiKey: 'AIzaSyAEzD4ZBaCSwNWZaa9AmeVSWzfYP-3V5eU',
  sentryUrl: 'https://7271105c2e4444a8928e7094fff7ab61@sentry.io/1212910',
  shopify: {
    US: { apiToken: 'e08609a1b815f88e555ee7ea622009c6', domain: 'shop.popsy.app' },
    AE: {
      apiToken: '36daa96b3d8816b7d4894c97ee497db8',
      domain: 'popsyuae.myshopify.com',
    },
  },
};
