import { Injectable } from '@angular/core';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class AppUriService {
  public isBrowser: boolean;
  public LOCAL_SERVER: string;

  constructor(private readonly akitaRouterQuery: AkitaRouterQuery) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;

    const port = '4000';
    if (!this.isBrowser) {
      try {
        /*  const req = this.injector.get(REQUEST);
        const host = (req as any).get('host');

        if (host && host.indexOf(':') !== -1) {
          const hostTokens = host.split(':');
          if (hostTokens && hostTokens.length > 1 && hostTokens[1]) {
            port = hostTokens[1];
          }
        } */
      } catch (err) {}
    }

    this.LOCAL_SERVER = `http://127.0.0.1:${port}`;
  }

  public get domain(): string {
    let domain = this.ssrDomain;
    if (domain === null || domain === undefined) {
      domain = this.isBrowser ? '' : this.LOCAL_SERVER;
    }
    return domain || '';
  }

  public get ssrDomain(): string | null {
    try {
      return (window as any).SSR_DOMAIN;
    } catch (error) {
      return null;
    }
  }
}
