export interface AkitaOmnisendState {
  isNew: boolean;
  consumerId: string;
  updatingConsumer: boolean;
}

export function createInitialOmnisendState(): AkitaOmnisendState {
  return {
    isNew: false,
    consumerId: '',
    updatingConsumer: false,
  };
}
