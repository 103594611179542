import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';

export type ScreenSize =
  | 'xss'
  | 'mobileS'
  | 'xs'
  | 'mobileL'
  | 'mobileXL'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl';

export enum ScreenSizes {
  XXS = 'xxs', // <= 320px
  MobileS = 'mobileS', // > 320px && <= 375px
  XS = 'xs', // > 375px && <= 425px
  MobileL = 'mobileL', // > 425px && <= 599px
  MobileXL = 'mobileXL', // > 600px && <= 799px
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

export enum ScreenOrientations {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

export interface ScreenSizeInfoState {
  mobileS: boolean;
  mobileL: boolean;
  mobileXL: boolean;
  xxs: boolean;
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
}

export interface AkitaScreenState {
  size: ScreenSizes;
  orientation: ScreenOrientations;
  direction: Direction;
  lessThan: ScreenSizeInfoState;
  biggerThan: ScreenSizeInfoState;
  isScreenShort: boolean;
  browser: string | null;
}

export function createInitialState(): AkitaScreenState {
  return {
    browser: null,
    isScreenShort: false,
    size: ScreenSizes.MD,
    orientation: ScreenOrientations.PORTRAIT,
    direction: 'ltr',
    lessThan: {
      xxs: false,
      mobileS: false,
      xs: false,
      mobileL: false,
      mobileXL: false,
      sm: false,
      md: false,
      lg: true,
      xl: true,
      xxl: true,
    },
    biggerThan: {
      xxs: true,
      mobileS: true,
      xs: true,
      mobileL: true,
      mobileXL: true,
      sm: true,
      md: false,
      lg: false,
      xl: false,
      xxl: false,
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'screen', resettable: false })
export class AkitaScreenStore extends Store<AkitaScreenState> {
  constructor() {
    super(createInitialState());
  }

  public setScreenShort(isShort?: boolean | null): void {
    this.update({
      isScreenShort: Boolean(isShort),
    });
  }

  public setBrowser(): void {
    try {
      const agent = window.navigator.userAgent.toLowerCase();
      const browser =
        agent.indexOf('edge') > -1
          ? 'Microsoft Edge'
          : agent.indexOf('edg') > -1
            ? 'Chromium-based Edge'
            : agent.indexOf('opr') > -1
              ? 'Opera'
              : agent.indexOf('chrome') > -1
                ? 'Chrome'
                : agent.indexOf('trident') > -1
                  ? 'Internet Explorer'
                  : agent.indexOf('firefox') > -1
                    ? 'Firefox'
                    : agent.indexOf('safari') > -1
                      ? 'Safari'
                      : 'other';
      this.update({
        browser,
      });
    } catch (error) {}
  }
}
