import {
  ChangeDetectionStrategy,
  Component,
  NgZone,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ImagePreloadService } from '@app/shared/services/image-cache.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ErrorImageIconComponent } from '../icons/generated/error-image/error-image.component';
import { InViewportModule } from 'ng-in-viewport';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.template.html',
  styleUrls: ['./picture.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, InViewportModule, ErrorImageIconComponent, SpinnerComponent],
})
export class PictureComponent implements OnChanges, AfterViewInit {
  @Input() public pictureUrl?: string | null = null;
  @Input() public fallbackPictureUrl?: string | null = null;
  @Input() public alt?: string | null = null;
  @Input() public bgColor?: string | null = null;
  @Input() public width?: string | null = null;
  @Input() public height?: string | null = null;
  @Input() public loading?: boolean | null;
  @Input() public lazyLoad?: boolean | null = true;

  @ViewChild('image') private readonly imageElement?: ElementRef | null;

  public backgroundImage: string | null | undefined = null;
  public readonly isBrowser: boolean;
  public pictureLoading: boolean;
  public imageIsVisible: boolean;
  public useErrorPlaceholder: boolean;

  public loadedPictureUrl?: SafeUrl | null;
  public pictureSafeUrl?: SafeUrl | null;

  constructor(
    private readonly zone: NgZone,
    private readonly imagePreloadService: ImagePreloadService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly domSanitizer: DomSanitizer,
    private readonly renderer: Renderer2,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.pictureLoading = false;
    this.imageIsVisible = false;
    this.useErrorPlaceholder = false;
    this.isBrowser = this.akitaRouterQuery.isBrowser;
  }
  public ngAfterViewInit(): void {
    this.refreshWidth();
    this.refreshHeight();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.zone.runOutsideAngular(() => {
        if (changes.pictureUrl) {
          if (this.pictureUrl) {
            const displaySize = this.detectDisplaySize();
            const optimizedUrl = this.imagePreloadService.optimizeAppEngineImage(
              this.pictureUrl,
              displaySize
            );
            this.pictureSafeUrl =
              this.domSanitizer.bypassSecurityTrustResourceUrl(optimizedUrl);
          }

          if (this.imageIsVisible && this.isBrowser) {
            this.lazyLoadPicture();
          }
        }

        if (changes.width) {
          this.refreshWidth();
        }

        if (changes.height) {
          this.refreshHeight();
        }
      });
    }
  }

  private refreshWidth(): void {
    this.zone.runOutsideAngular(() => {
      if (this.imageElement?.nativeElement) {
        if (this.width) {
          this.renderer.setAttribute(
            this.imageElement.nativeElement,
            'width',
            this.width
          );
        }
      }
    });
  }

  private refreshHeight(): void {
    this.zone.runOutsideAngular(() => {
      if (this.imageElement?.nativeElement) {
        if (this.height) {
          this.renderer.setAttribute(
            this.imageElement.nativeElement,
            'height',
            this.height
          );
        }
      }
    });
  }

  private detectDisplaySize(): number | null | undefined {
    let displaySize: number | null | undefined;
    if (
      this.width &&
      this.height &&
      this.width.indexOf('px') !== -1 &&
      this.height.indexOf('px') !== -1
    ) {
      if (this.width >= this.height) {
        displaySize = parseInt(this.width.replace('px', ''), 10);
      } else {
        displaySize = parseInt(this.height.replace('px', ''), 10);
      }
    }
    return displaySize;
  }

  private lazyLoadPicture(): void {
    if (this.pictureUrl) {
      this.pictureLoading = true;
      this.changeDetector.markForCheck();

      this.zone.runOutsideAngular(() => {
        if (this.pictureUrl && this.lazyLoad) {
          this.imagePreloadService
            .loadImage(this.pictureUrl, this.detectDisplaySize())
            .then(
              (url: string) => {
                this.zone.run(() => {
                  this.backgroundImage = url;
                  this.pictureLoading = false;
                  this.refreshLoadedPictureUrl();
                });
              },
              () => {
                this.zone.run(() => {
                  this.backgroundImage = null;
                  this.pictureLoading = false;
                  this.refreshLoadedPictureUrl();
                });
              }
            );
        } else if (this.pictureUrl) {
          // no lazy-load
          this.zone.run(() => {
            this.backgroundImage = this.pictureUrl;
            this.pictureLoading = false;
            this.refreshLoadedPictureUrl();
          });
        }
      });
    } else {
      this.zone.run(() => {
        this.backgroundImage = null;
        this.pictureLoading = false;
        this.refreshLoadedPictureUrl();
      });
    }
  }

  public onIntersection({
    target,
    visible,
  }: {
    target: Element;
    visible: boolean;
  }): void {
    this.zone.runOutsideAngular(() => {
      this.renderer.setStyle(target, 'visibility', visible ? 'visible' : 'hidden');
      if (visible && this.isBrowser) {
        this.lazyLoadPicture();
      }

      this.zone.run(() => {
        this.imageIsVisible = visible;
        this.changeDetector.markForCheck();
      });
    });
  }

  public onLoadPictureError(): void {
    if (!this.pictureLoading && this.backgroundImage) {
      this.backgroundImage = null;
      this.refreshLoadedPictureUrl();
    }
  }

  private refreshLoadedPictureUrl(): void {
    let url = null;

    if (this.backgroundImage) {
      url = `${this.backgroundImage}`;
    } else if (this.fallbackPictureUrl) {
      url = `${this.fallbackPictureUrl}`;
    }

    if (url) {
      this.useErrorPlaceholder = false;
      this.loadedPictureUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      this.loadedPictureUrl = null;
      this.useErrorPlaceholder = true;
    }

    this.changeDetector.markForCheck();
  }
}
