/* eslint-disable max-classes-per-file */

import { PriceModel } from '@app/shared/models/api/price.model';

export class ProductVariant {
  public static readonly variantType: string = '';
  public id: string;
  public value?: string | null;
  public title?: string;
  public color: string;
  public colorCode: string;
  public storage: string;
  public storageUnit: string;
  public screenSize: string;
  public keyboard: string;
  public wifiCellular: string;
  public sim: string;
  public condition: string;
  public strapColor: string;
  public strapColorCode: string;
  public inStock?: boolean;
  public price?: PriceModel;

  constructor() {
    this.id = '';
    this.color = '';
    this.colorCode = '';
    this.storage = '';
    this.screenSize = '';
    this.storageUnit = '';
    this.keyboard = '';
    this.wifiCellular = '';
    this.condition = '';
    this.strapColor = '';
    this.strapColorCode = '';
    this.sim = '';
    this.inStock = false;
    this.price = new PriceModel();
  }

  public get variantType(): string {
    return ProductVariant.variantType;
  }
}

export const buildProductVariantFromJson = (
  data?: Record<string, any> | null
): ProductVariant | null => {
  const variant = new ProductVariant();
  variant.id = data?.id || '';
  variant.color = data?.color.name || '';
  variant.colorCode = data?.color.code || '';
  variant.storage = data?.storage || '';
  variant.storageUnit = data?.storage_unit || data?.storagUnit || 'GB';
  variant.screenSize = data?.screenSize || data?.screen_size || '';
  variant.keyboard = data?.keyboard || '';
  variant.wifiCellular = data?.wifiCellular || data?.wifi_cellular || '';
  variant.sim = data?.sim || '';
  variant.condition = data?.condition || '';
  variant.strapColor = data?.strapColor?.name || data?.strap_color?.name || '';
  variant.strapColorCode = data?.strapColorCode?.code || data?.strap_color?.code || '';
  variant.inStock = data?.inStock || data?.in_stock || false;
  variant.price = PriceModel.fromJson(data?.price) || new PriceModel();

  return variant;
};

export const parseProductVariantFromJsonList = (
  data?: Array<any> | null
): Array<ProductVariant> => {
  const list: Array<ProductVariant> = new Array(0);
  if (data && data.length > 0) {
    for (const item of data[0].variants) {
      const variant = buildProductVariantFromJson(item);
      if (variant) {
        list.push(variant);
      }
    }
  }

  return list;
};
