import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {
  AkitaProductsState,
  createInitialAkitaProductSearchState,
  createInitialAkitaProductsState,
} from '../models/products.state';
import { Product } from '../models/product.model';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { ProductSearchFilterOptions } from '../models/product-search.filter-options.model';
import {
  parseProductSearchResults,
  ProductSearchResults,
} from '../models/product-search.results.model';
import { ProductVariant } from '../models/product-variant.model';
import { AvailableProductLocaleResponse } from '../models/product-available-country-lang.response.model';
import { Insurance } from '../models/insurance.model';
import { PriceModel } from '@app/shared/models/api/price.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'products', resettable: true })
export class AkitaProductsStore extends Store<AkitaProductsState> {
  constructor() {
    super(createInitialAkitaProductsState());
  }

  public setProduct(
    language?: string | null,
    country?: string | null,
    productId?: string | null,
    product?: Product | null
  ): void {
    if (language && country && productId) {
      this.update((state: AkitaProductsState) => {
        const products = { ...(state.products || {}) };
        const langProducts = products[language] || {};
        const countryProducts = langProducts[country] || {};
        countryProducts[productId] = product || null;
        langProducts[country] = countryProducts;
        products[language] = langProducts;
        return {
          products: products,
        };
      });
    }
  }

  public setIsProductFetching(
    language?: string | null,
    country?: string | null,
    productId?: string | null,
    isLoading?: boolean | null
  ): void {
    if (language && country && productId) {
      this.update((state: AkitaProductsState) => {
        const fetchingProducts = { ...(state.fetchingProducts || {}) };
        const langProducts = fetchingProducts[language] || {};
        const countryProducts = langProducts[country] || {};
        countryProducts[productId] = Boolean(isLoading);
        langProducts[country] = countryProducts;
        fetchingProducts[language] = langProducts;
        return {
          fetchingProducts: fetchingProducts,
        };
      });
    }
  }

  public setErrorFetchingProduct(
    language?: string | null,
    country?: string | null,
    productId?: string | null,
    error?: ApiError | null
  ): void {
    if (language && country && productId) {
      this.update((state: AkitaProductsState) => {
        const errorFetchingProducts = {
          ...(state.errorFetchingProducts || {}),
        };
        const langProducts = errorFetchingProducts[language] || {};
        const countryProducts = langProducts[country] || {};
        countryProducts[productId] = error || null;
        langProducts[country] = countryProducts;
        errorFetchingProducts[language] = langProducts;
        return {
          errorFetchingProducts: errorFetchingProducts,
        };
      });
    }
  }

  public setAvailableProductFilters(
    path?: string | null,
    options?: ProductSearchFilterOptions | null
  ): void {
    this.update((state: AkitaProductsState) => {
      const availableProductFilters = {
        ...(state.availableProductFilters || {}),
      };
      availableProductFilters[path || '-'] = options || null;
      return {
        availableProductFilters: availableProductFilters,
      };
    });
  }

  public setIsFetchingAvailableProductFilters(
    path?: string | null,
    isLoading?: boolean
  ): void {
    this.update((state: AkitaProductsState) => {
      const fetchingAvailableProductFilters = {
        ...(state.fetchingAvailableProductFilters || {}),
      };
      fetchingAvailableProductFilters[path || '-'] = Boolean(isLoading);
      return {
        fetchingAvailableProductFilters: fetchingAvailableProductFilters,
      };
    });
  }

  public setErrorFetchingAvailableProductFilters(
    path?: string | null,
    error?: ApiError | null
  ): void {
    this.update((state: AkitaProductsState) => {
      const errorFetchingAvailableProductFilters = {
        ...(state.errorFetchingAvailableProductFilters || {}),
      };
      errorFetchingAvailableProductFilters[path || '-'] = error || null;
      return {
        errorFetchingAvailableProductFilters: errorFetchingAvailableProductFilters,
      };
    });
  }

  public setSearchResults(
    path?: string | null,
    page?: number | null,
    options?: ProductSearchResults | null
  ): void {
    this.update((state: AkitaProductsState) => {
      const productSearch = { ...(state.productSearch || {}) };
      const productPath =
        productSearch[path || '-'] || createInitialAkitaProductSearchState();
      productPath.pages[page || 0] = parseProductSearchResults(options);
      productPath.hasNextPage = Boolean(productPath.pages[page || 0]?.next);
      productSearch[path || '-'] = productPath;

      return {
        productSearch: productSearch,
      };
    });
  }

  public setIsSearchingResults(
    path?: string | null,
    page?: number | null,
    isLoading?: boolean | null
  ): void {
    this.update((state: AkitaProductsState) => {
      const productSearch = { ...(state.productSearch || {}) };
      const productPath =
        productSearch[path || '-'] || createInitialAkitaProductSearchState();
      productPath.loadingPage[page || 0] = Boolean(isLoading);
      productSearch[path || '-'] = productPath;

      return {
        productSearch: productSearch,
      };
    });
  }

  public setErrorSearchingResults(
    path?: string | null,
    page?: number | null,
    error?: ApiError | null
  ): void {
    this.update((state: AkitaProductsState) => {
      const productSearch = { ...(state.productSearch || {}) };
      const productPath =
        productSearch[path || '-'] || createInitialAkitaProductSearchState();
      productPath.errorLoadingPage[page || 0] = error || null;
      productSearch[path || '-'] = productPath;

      return {
        productSearch: productSearch,
      };
    });
  }

  public setProductVariants(
    language?: string | null,
    country?: string | null,
    productId?: string | null,
    variants?: Array<ProductVariant> | null
  ): void {
    if (language && country && productId && variants && variants.length > 0) {
      this.update((state: AkitaProductsState) => {
        const productVariants = { ...(state.productVariants || {}) };
        const langProducts = productVariants[language] || {};
        const countryProducts = langProducts[country] || {};
        let products = countryProducts[productId] || {};

        products = variants;
        countryProducts[productId] = products;
        langProducts[country] = countryProducts;
        productVariants[language] = langProducts;
        return {
          productVariants: productVariants,
        };
      });
    }
  }

  public setIsSearchingProductVariants(
    language?: string | null,
    country?: string | null,
    model?: string | null,
    isLoading?: boolean | null
  ): void {
    if (language && country && model) {
      this.update((state: AkitaProductsState) => {
        const fetchingProductVariants = { ...(state.fetchingProductVariants || {}) };
        const langProducts = fetchingProductVariants[language] || {};
        const countryProducts = langProducts[country] || {};
        let products = countryProducts[model] || false;
        products = Boolean(isLoading);
        countryProducts[model] = products;
        langProducts[country] = countryProducts;
        fetchingProductVariants[language] = langProducts;
        return {
          fetchingProductVariants: fetchingProductVariants,
        };
      });
    }
  }

  public setErrorSearchingProductVariants(
    language?: string | null,
    country?: string | null,
    model?: string | null,

    error?: ApiError | null
  ): void {
    if (language && country && model) {
      this.update((state: AkitaProductsState) => {
        const errorFetchingProductVariants = {
          ...(state.errorFetchingProductVariants || {}),
        };
        const langProducts = errorFetchingProductVariants[language] || {};
        const countryProducts = langProducts[country] || {};
        let products = countryProducts[model] || null;
        products = error || null;
        countryProducts[model] = products;
        langProducts[country] = countryProducts;
        errorFetchingProductVariants[language] = langProducts;
        return {
          errorFetchingProductVariants: errorFetchingProductVariants,
        };
      });
    }
  }

  public setGettingAvailableProductLocaleInfo(isLoading?: boolean | null): void {
    this.update({
      fetchingAvailableProductLocaleInfo: Boolean(isLoading),
    });
  }

  public setAvailableProductLocaleInfo(
    response?: AvailableProductLocaleResponse | null
  ): void {
    this.update({
      availableProductCountries: [...(response?.country || [])],
      availableProductLocales: [...(response?.language || [])],
    });
  }

  public setErrorGettingAvailableProductLocaleInfo(error?: ApiError | null): void {
    this.update({
      errorFetchingAvailableProductLocaleInfo: error || null,
    });
  }

  public setProductSearchHints(hints?: Array<string> | null): void {
    this.update({
      productSearchHints: hints || [],
    });
  }

  public setGettingProductSearchHints(isLoading?: boolean | null): void {
    this.update({
      fetchingProductSearchHints: Boolean(isLoading),
    });
  }

  public setErrorGettingProductSearchHints(error?: ApiError | null): void {
    this.update({
      errorFetchingProductSearchHints: error || null,
    });
  }

  // maps insurances to the store and to the product object
  public setProductInsurances(
    productId: string,
    language: string,
    country: string,
    insurances: Array<Insurance>
  ): void {
    if (language && country && productId && insurances && insurances.length > 0) {
      this.update((state: AkitaProductsState) => {
        // map insurances to the product object if exists in the store
        const products = { ...(state.products || {}) };
        const langProducts = products[language] || {};
        const countryProducts = langProducts[country] || {};

        if (countryProducts[productId]) {
          const product = {
            ...(countryProducts[productId] || new Product()),
            insurances: insurances,
          };
          countryProducts[productId] = product;
          langProducts[country] = countryProducts;
          products[language] = langProducts;
        }

        // map insurances to the insurance store
        const stateInsurances = { ...(state.insurances || {}) };
        const langInsurances = stateInsurances[language] || {};
        const countryInsurances = langInsurances[country] || {};
        insurances.forEach((insurance) => {
          countryInsurances[insurance.id] = insurance;
        });
        langInsurances[country] = countryInsurances;
        stateInsurances[language] = langInsurances;

        return {
          products: products,
          insurances: stateInsurances,
        };
      });
    }
  }

  public addSelectedInsuranceToProduct(
    productId: string,
    insurance: Insurance | null,
    language: string,
    country: string
  ): void {
    if (productId || language || country) {
      this.update((state: AkitaProductsState) => {
        const products = { ...(state.products || {}) };
        const langProducts = products[language] || {};
        const countryProducts = langProducts[country] || {};

        /* const product = parseProduct({
          ...(countryProducts[productId] || new Product()),
          selectedInsurance: {
            ...insurance,
            price:
              PriceModel.fromJson({
                amount: insurance?.price.amount,
                currency: insurance?.price.currency,
              }) || new PriceModel(),
          },
        }); */
        const product = countryProducts[productId] || new Product();
        const selectedInsurance = insurance || null;
        if (selectedInsurance) {
          selectedInsurance.price =
            PriceModel.fromJson({
              amount: selectedInsurance.price.amount,
              currency: selectedInsurance.price.currency,
            }) || new PriceModel();
        }
        product.selectedInsurance = selectedInsurance;
        countryProducts[productId] = product;
        langProducts[country] = countryProducts;
        products[language] = langProducts;

        return {
          products: products,
        };
      });
    }
  }

  public setFetchingInsurances(isLoading: boolean): void {
    this.update({
      fetchingInsurances: isLoading,
    });
  }

  public setFetchingInsurance(
    lang: string,
    country: string,
    id: string,
    isLoading: boolean
  ): void {
    this.update((state: AkitaProductsState) => {
      const fetchingInsurance = { ...(state.fetchingInsurance || {}) };
      const langProducts = fetchingInsurance[lang] || {};
      const countryProducts = langProducts[country] || {};
      countryProducts[id] = isLoading;
      langProducts[country] = countryProducts;
      fetchingInsurance[lang] = langProducts;
      return {
        fetchingInsurance: fetchingInsurance,
      };
    });
  }
}
