import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { AkitaCartState, createCartStateInitialState } from '../models/cart.state.model';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { CartItem } from '../models/cart.model';
import {
  Insurance,
  parseInsuranceList,
} from '@app/akita/api/products/models/insurance.model';
import { PackageProtectionModel } from '@app/akita/api/checkout/models/package-protection.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cart', resettable: true })
export class AkitaCartStore extends Store<AkitaCartState> {
  constructor() {
    super(createCartStateInitialState());
  }

  public toggleTransferingAnonymousCart(
    country?: string | null,
    loading?: boolean | null
  ): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.transferingAnonimousCart = Boolean(loading);
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public setTransferingAnonymousCartError(
    country?: string | null,
    error?: ApiError | null
  ): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.errorTransferingAnonymousCart = error || null;
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public setCartItems(country?: string | null, items?: Array<CartItem> | null): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.items = items || new Array(0);
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public setCart(country: string, response: any): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.id = response.id;
        countryCart.items = response.items;
        countryCart.insurances = response.insurances;
        countryCart.isLocked = response.isLocked;
        countryCart.sessionId = response.sessionId;
        countryCart.lastSessionId = response.lastSessionId;
        countryCart.isProtected = response.isProtected;
        countryCart.protectionPrice =
          response.protectionPrice || countryCart.protectionPrice;
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public toggleCartLoading(country?: string | null, loading?: boolean | null): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.loadingCartItems = Boolean(loading);
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public toggleCartUpdating(country?: string | null, loading?: boolean | null): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.updatingCart = Boolean(loading);
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public resetCart(country?: string | null): void {
    if (country) {
      this.update(() => {
        const emptyCart = {
          id: null,
          items: new Array(0),
          insurances: new Array(0),
          isLocked: false,
          sessionId: null,
          lastSessionId: null,
          loadingCartItems: false,
          errorLoadingCartItems: null,
          updatingCart: false,
          isProtected: false,
          protectionPrice: null,
          availableProtection: null,
          savingCartItems: false,
          errorSavingCartItems: null,
          transferingAnonimousCart: false,
          errorTransferingAnonymousCart: null,
          lastUpdated: new Date(),
        };

        return {
          [country]: emptyCart,
        };
      });
    }
  }

  public setCartLoadingError(country?: string | null, error?: ApiError | null): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.errorLoadingCartItems = error || null;
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public toggleCartSaving(country?: string | null, saving?: boolean | null): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.savingCartItems = Boolean(saving);
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public setCartSavingError(country?: string | null, error?: ApiError | null): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.errorSavingCartItems = error || null;
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public updateLastSessionId(country: string, id: string | null): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };

        countryCart.lastSessionId = id || null;
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public updateIsLocked(country?: string | null, isLocked?: boolean | null): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.isLocked = Boolean(isLocked);
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public updateSessionId(country?: string | null, sessionId?: string | null): void {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.sessionId = sessionId || null;
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public updateCartInsurances(
    country?: string | null,
    insurances?: Array<Insurance> | null
  ) {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.insurances = parseInsuranceList(insurances) || new Array(0);
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public updateCartId(country?: string | null, id?: string) {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.id = id || '';
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }

  public setAvailableProtection(
    country: string,
    protection: PackageProtectionModel | null
  ) {
    if (country) {
      this.update((state: AkitaCartState) => {
        const countryCart = { ...(state[country] || {}) };
        countryCart.availableProtection = protection;
        countryCart.lastUpdated = new Date();
        return {
          [country]: countryCart,
        };
      });
    }
  }
}
