import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.template.html',
  styleUrls: ['./spinner.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SpinnerComponent {
  @Input() public contrast?: boolean | null = null;
  @Input() public compact?: boolean | null = null;

  constructor() {}
}
