export class EstimateDays {
  public minDays: number;
  public maxDays: number;

  constructor(min?: number, max?: number) {
    this.minDays = min || 0;
    this.maxDays = max || 0;
  }
}

export class DeliveryEstimate {
  public country: string;
  public values: EstimateDays;

  constructor() {
    this.country = '';
    this.values = new EstimateDays();
  }
}

export const parseDeliveryEstimateFromJsonArrayString = (
  text?: string | null
): Array<DeliveryEstimate> => {
  let out = new Array(0);
  try {
    out = parseDeliveryEstimateFromJsonArray(JSON.parse(`${text || ''}`));
  } catch (error) {}
  return out;
};

export const parseDeliveryEstimateFromJson = (
  data?: any | null
): DeliveryEstimate | null => {
  let out = null;
  if (data) {
    out = new DeliveryEstimate();
    out.country = data.country || '';
    out.values.minDays = data.value['min_days'] || data.value.minDays || 0;
    out.values.maxDays = data.value['max_days'] || data.value.maxDays || 0;
  }
  return out;
};

export const parseDeliveryEstimateFromJsonArray = (
  data?: any | null
): Array<DeliveryEstimate> => {
  const out = new Array(0);
  if (data) {
    for (const item of data) {
      const parsed = parseDeliveryEstimateFromJson(item);
      if (parsed) {
        out.push(parsed);
      }
    }
  }
  return out;
};
