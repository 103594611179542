import { Injectable, Injector } from '@angular/core';
import { Subject, Observable, Subscription, take, filter } from 'rxjs';
import { AkitaOverlaysQuery } from '@app/akita/overlays/state/overlays.query';
import { AkitaOverlaysService } from '@app/akita/overlays/state/overlays.service';
import { DOCUMENT } from '@angular/common';

const OVERLAY_NAME = 'auth-overlay-module';

@Injectable({
  providedIn: 'root',
})
export class AuthOverlayDynamicService {
  private readonly document: Document | null;
  private readonly inboxSubject: Subject<any | null>;
  public dismissed?: boolean | null = null;
  public credentials?: string | null = null;

  constructor(
    private readonly injector: Injector,
    private readonly akitaOverlaysQuery: AkitaOverlaysQuery,
    private readonly akitaOverlaysService: AkitaOverlaysService
  ) {
    this.inboxSubject = new Subject();
    try {
      this.document = this.injector.get<Document | null>(DOCUMENT, null);
    } catch (err) {
      this.document = null;
    }
  }

  private isAlreadyOpen(): boolean {
    if (this.document?.getElementById) {
      return Boolean(this.document.getElementById(OVERLAY_NAME));
    }
    return false;
  }

  public open(data?: any | null): void {
    if (!this.isAlreadyOpen()) {
      const hasError = this.akitaOverlaysQuery.overlayFailedToLoad(OVERLAY_NAME);
      const hasLoaded = this.akitaOverlaysQuery.getOverlayStatus(OVERLAY_NAME);
      const isLoading = this.akitaOverlaysQuery.isOverlayLoading(OVERLAY_NAME);

      if (hasError || (!hasLoaded && !isLoading)) {
        this.akitaOverlaysService.loadDialog(OVERLAY_NAME);
        this.openWhenReady(data);
      } else if (hasLoaded) {
        this.inboxSubject.next(data || {});
      } else {
        this.openWhenReady(data);
      }
    }
  }

  public openWhenReady(data?: any | null): void {
    if (!this.isAlreadyOpen()) {
      const loadSubscription = new Subscription();
      loadSubscription.add(
        this.akitaOverlaysQuery
          .selectOverlayLoadStatus(OVERLAY_NAME)
          .pipe(
            filter((loaded) => Boolean(loaded)),
            take(1)
          )
          .subscribe({
            next: () => {
              this.inboxSubject.next(data || {});
              loadSubscription.unsubscribe();
            },
          })
      );
    }
  }

  public close(): void {
    this.inboxSubject.next(null);
  }

  public get observe(): Observable<any | null> {
    return this.inboxSubject.asObservable();
  }
}
