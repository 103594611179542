import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { Configuration } from '@app/shared/models/configuration/configuration.model';
import { CONFIGURATION } from '@app/akita/configuration/models/configuration.model';
import { CurrencyInformation } from '@app/shared/data/currencies.data';

export interface AkitaConfigurationState extends Configuration {
  webP: {
    lossy: boolean;
    lossless: boolean;
    alpha: boolean;
    animation: boolean;
  };
  currency: CurrencyInformation;
}

export function createInitialState(): AkitaConfigurationState {
  return {
    ...CONFIGURATION,
    webP: {
      lossy: false,
      lossless: false,
      alpha: false,
      animation: false,
    },
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'configuration', resettable: true })
export class AkitaConfigurationStore extends Store<AkitaConfigurationState> {
  constructor() {
    super(createInitialState());
  }

  public updateCurrency(currency?: CurrencyInformation | null): void {
    if (currency) {
      this.update({
        currency: currency,
      });
    }
  }
}
