import {
  countryFromTimeZone,
  TimeZoneCountryInfo,
} from '../data/timezone-to-country.data';

const LANGUAGE_CODE_LENGTH = 2;

export const LANGUAGE_CODE_TO_LOCALE: { [key: string]: string } = {
  af: 'af-ZA',
  agq: 'agq-CM',
  ak: 'ak-GH',
  am: 'am-ET',
  ar: 'ar-EG',
  as: 'as-IN',
  asa: 'asa-TZ',
  ast: 'ast-ES',
  az: 'az-AZ',
  bas: 'bas-CM',
  be: 'be-BY',
  bem: 'bem-ZM',
  bez: 'bez-TZ',
  bg: 'bg-BG',
  bm: 'bm-Latn',
  bn: 'bn-BD',
  bo: 'bo-IN',
  br: 'br-FR',
  brx: 'brx-IN',
  bs: 'bs-Cyrl',
  ca: 'ca-IT',
  cgg: 'cgg-UG',
  chr: 'chr-US',
  cs: 'cs-CZ',
  cy: 'cy-GB',
  da: 'da-DK',
  dav: 'dav-KE',
  de: 'de-DE',
  dje: 'dje-NE',
  dsb: 'dsb-DE',
  dua: 'dua-CM',
  dyo: 'dyo-SN',
  dz: 'dz-BT',
  ebu: 'ebu-KE',
  ee: 'ee-GH',
  el: 'el-GR',
  en: 'en-US',
  eo: 'eo-001',
  es: 'es-ES',
  et: 'et-EE',
  eu: 'eu-ES',
  ewo: 'ewo-CM',
  fa: 'fa-AF',
  ff: 'ff-CM',
  fi: 'fi-FI',
  fil: 'fil-PH',
  fo: 'fo-FO',
  fr: 'fr-FR',
  fur: 'fur-IT',
  fy: 'fy-NL',
  ga: 'ga-IE',
  gd: 'gd-GB',
  gl: 'gl-ES',
  gsw: 'gsw-CH',
  gu: 'gu-IN',
  guz: 'guz-KE',
  gv: 'gv-IM',
  ha: 'ha-Latn',
  haw: 'haw-US',
  he: 'he-IL',
  hi: 'hi-IN',
  hr: 'hr-HR',
  hsb: 'hsb-DE',
  hu: 'hu-HU',
  hy: 'hy-AM',
  id: 'id-ID',
  ig: 'ig-NG',
  ii: 'ii-CN',
  is: 'is-IS',
  it: 'it-IT',
  ja: 'ja-JP',
  jgo: 'jgo-CM',
  jmc: 'jmc-TZ',
  ka: 'ka-GE',
  kab: 'kab-DZ',
  kam: 'kam-KE',
  kde: 'kde-TZ',
  kea: 'kea-CV',
  khq: 'khq-ML',
  ki: 'ki-KE',
  kk: 'kk-Cyrl',
  kkj: 'kkj-CM',
  kl: 'kl-GL',
  kln: 'kln-KE',
  km: 'km-KH',
  kn: 'kn-IN',
  ko: 'ko-KP',
  kok: 'kok-IN',
  ks: 'ks-Arab',
  ksb: 'ksb-TZ',
  ksf: 'ksf-CM',
  ksh: 'ksh-DE',
  kw: 'kw-GB',
  ky: 'ky-Cyrl',
  lag: 'lag-TZ',
  lb: 'lb-LU',
  lg: 'lg-UG',
  lkt: 'lkt-US',
  ln: 'ln-CD',
  lo: 'lo-LA',
  lt: 'lt-LT',
  lu: 'lu-CD',
  luo: 'luo-KE',
  luy: 'luy-KE',
  lv: 'lv-LV',
  mas: 'mas-KE',
  mer: 'mer-KE',
  mfe: 'mfe-MU',
  mg: 'mg-MG',
  mgh: 'mgh-MZ',
  mgo: 'mgo-CM',
  mk: 'mk-MK',
  ml: 'ml-IN',
  mn: 'mn-Cyrl',
  mr: 'mr-IN',
  ms: 'ms-Latn',
  mt: 'mt-MT',
  mua: 'mua-CM',
  my: 'my-MM',
  naq: 'naq-NA',
  nb: 'nb-NO',
  nd: 'nd-ZW',
  ne: 'ne-NP',
  nl: 'nl-NL',
  nmg: 'nmg-CM',
  nn: 'nn-NO',
  nnh: 'nnh-CM',
  nus: 'nus-SD',
  nyn: 'nyn-UG',
  om: 'om-KE',
  or: 'or-IN',
  os: 'os-GE',
  pa: 'pa-Arab',
  pl: 'pl-PL',
  ps: 'ps-AF',
  pt: 'pt-PT',
  qu: 'qu-BO',
  rm: 'rm-CH',
  rn: 'rn-BI',
  ro: 'ro-RO',
  rof: 'rof-TZ',
  root: 'root',
  ru: 'ru-RU',
  rw: 'rw-RW',
  rwk: 'rwk-TZ',
  sah: 'sah-RU',
  saq: 'saq-KE',
  sbp: 'sbp-TZ',
  se: 'se-SE',
  seh: 'seh-MZ',
  ses: 'ses-ML',
  sg: 'sg-CF',
  shi: 'shi-Latn',
  si: 'si-LK',
  sk: 'sk-SK',
  sl: 'sl-SI',
  smn: 'smn-FI',
  sn: 'sn-ZW',
  so: 'so-SO',
  sq: 'sq-AL',
  sr: 'sr-Cyrl-RS',
  sv: 'sv-SE',
  sw: 'sw-TZ',
  ta: 'ta-LK',
  te: 'te-IN',
  teo: 'teo-KE',
  th: 'th-TH',
  ti: 'ti-ER',
  to: 'to-TO',
  tr: 'tr-TR',
  twq: 'twq-NE',
  tzm: 'tzm-Latn',
  ug: 'ug-Arab',
  uk: 'uk-UA',
  ur: 'ur-PK',
  uz: 'uz-Arab',
  vai: 'vai-Latn',
  vi: 'vi-VN',
  vun: 'vun-TZ',
  wae: 'wae-CH',
  xog: 'xog-UG',
  yav: 'yav-CM',
  yi: 'yi-001',
  yo: 'yo-NG',
  zgh: 'zgh-MA',
  zh: 'zh-Hans',
  zu: 'zu-ZA',
};

export const CURRENCY_BY_COUNTRY: { [key: string]: string } = {
  AD: 'EUR',
  AE: 'AED',
  AF: 'AFN',
  AG: 'XCD',
  AI: 'XCD',
  AL: 'ALL',
  AM: 'AMD',
  AO: 'AOA',
  AR: 'ARS',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AW: 'AWG',
  AX: 'EUR',
  AZ: 'AZN',
  BA: 'BAM',
  BB: 'BBD',
  BD: 'BDT',
  BE: 'EUR',
  BF: 'XOF',
  BG: 'BGN',
  BH: 'BHD',
  BI: 'BIF',
  BJ: 'XOF',
  BL: 'EUR',
  BM: 'BMD',
  BN: 'BND',
  BO: 'BOB',
  BQ: 'USD',
  BR: 'BRL',
  BS: 'BSD',
  BT: 'INR',
  BV: 'NOK',
  BW: 'BWP',
  BY: 'BYR',
  BZ: 'BZD',
  CA: 'CAD',
  CC: 'AUD',
  CD: 'CDF',
  CF: 'XAF',
  CG: 'XAF',
  CH: 'CHF',
  CI: 'XOF',
  CK: 'NZD',
  CL: 'CLP',
  CM: 'XAF',
  CN: 'CNY',
  CO: 'COP',
  CR: 'CRC',
  CU: 'CUP',
  CV: 'CVE',
  CW: 'ANG',
  CX: 'AUD',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  DJ: 'DJF',
  DK: 'DKK',
  DM: 'XCD',
  DO: 'DOP',
  DZ: 'DZD',
  EC: 'USD',
  EE: 'EUR',
  EG: 'EGP',
  EH: 'MAD',
  ER: 'ERN',
  ES: 'EUR',
  ET: 'ETB',
  FI: 'EUR',
  FJ: 'FJD',
  FK: 'FKP',
  FM: 'USD',
  FO: 'DKK',
  FR: 'EUR',
  GA: 'XAF',
  GB: 'GBP',
  GD: 'XCD',
  GE: 'GEL',
  GF: 'EUR',
  GG: 'GGP',
  GH: 'GHS',
  GI: 'GIP',
  GL: 'DKK',
  GM: 'GMD',
  GN: 'GNF',
  GP: 'EUR',
  GQ: 'XAF',
  GR: 'EUR',
  GS: 'GBP',
  GT: 'GTQ',
  GU: 'USD',
  GW: 'XOF',
  GY: 'GYD',
  HK: 'HKD',
  HM: 'AUD',
  HN: 'HNL',
  HR: 'HRK',
  HT: 'HTG',
  HU: 'HUF',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  IQ: 'IQD',
  IR: 'IRR',
  IS: 'ISK',
  IT: 'EUR',
  JE: 'GBP',
  JM: 'JMD',
  JO: 'JOD',
  JP: 'JPY',
  KE: 'KES',
  KG: 'KGS',
  KH: 'KHR',
  KI: 'AUD',
  KM: 'KMF',
  KN: 'XCD',
  KP: 'KPW',
  KR: 'KRW',
  KW: 'KWD',
  KY: 'KYD',
  KZ: 'KZT',
  LA: 'LAK',
  LB: 'LBP',
  LC: 'XCD',
  LI: 'CHF',
  LK: 'LKR',
  LR: 'LRD',
  LS: 'LSL',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  LY: 'LYD',
  MA: 'MAD',
  MC: 'EUR',
  MD: 'MDL',
  ME: 'EUR',
  MF: 'EUR',
  MG: 'MGA',
  MH: 'USD',
  MK: 'MKD',
  ML: 'XOF',
  MM: 'MMK',
  MN: 'MNT',
  MO: 'MOP',
  MP: 'USD',
  MQ: 'EUR',
  MR: 'MRO',
  MS: 'XCD',
  MT: 'EUR',
  MU: 'MUR',
  MV: 'MVR',
  MW: 'MWK',
  MX: 'MXN',
  MY: 'MYR',
  MZ: 'MZN',
  NA: 'NAD',
  NC: 'XPF',
  NE: 'XOF',
  NF: 'AUD',
  NG: 'NGN',
  NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  NP: 'NPR',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  OM: 'OMR',
  PA: 'PAB',
  PE: 'PEN',
  PF: 'XPF',
  PG: 'PGK',
  PH: 'PHP',
  PK: 'PKR',
  PL: 'PLN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PS: 'JOD',
  PT: 'EUR',
  PW: 'USD',
  PY: 'PYG',
  QA: 'QAR',
  RE: 'EUR',
  RO: 'RON',
  RS: 'RSD',
  RU: 'RUB',
  RW: 'RWF',
  SA: 'SAR',
  SB: 'SBD',
  SC: 'SCR',
  SD: 'SDG',
  SE: 'SEK',
  SG: 'SGD',
  SH: 'SHP',
  SI: 'EUR',
  SJ: 'NOK',
  SK: 'EUR',
  SL: 'SLL',
  SM: 'EUR',
  SN: 'XOF',
  SO: 'SOS',
  SR: 'SRD',
  SS: 'SSP',
  ST: 'STD',
  SV: 'USD',
  SX: 'ANG',
  SY: 'SYP',
  SZ: 'SZL',
  TC: 'USD',
  TD: 'XAF',
  TF: 'EUR',
  TG: 'XOF',
  TH: 'THB',
  TJ: 'TJS',
  TK: 'NZD',
  TL: 'USD',
  TM: 'TMT',
  TN: 'TND',
  TO: 'TOP',
  TR: 'TRY',
  TT: 'TTD',
  TV: 'AUD',
  TW: 'TWD',
  TZ: 'TZS',
  UA: 'UAH',
  UG: 'UGX',
  UM: 'USD',
  US: 'USD',
  UY: 'UYU',
  UZ: 'UZS',
  VA: 'EUR',
  VC: 'XCD',
  VE: 'VEF',
  VG: 'USD',
  VI: 'USD',
  VN: 'VND',
  VU: 'VUV',
  WF: 'XPF',
  WS: 'EUR',
  YE: 'YER',
  YT: 'EUR',
  ZA: 'ZAR',
  ZM: 'ZMW',
  ZW: 'USD',
};

export interface IntlDateTimeLocationInfo {
  timeZone: string | null;
  locale: string | null;
}

/**
 * Gets locale info from system using Intl
 * @returns {IntlDateTimeLocationInfo} The locale info
 * - Returns empty if the browser does not support the Intl standard
 */
export const timeZoneFromIntl = (): IntlDateTimeLocationInfo => {
  const data: IntlDateTimeLocationInfo = { timeZone: null, locale: null };
  try {
    if (Intl && typeof Intl.DateTimeFormat === 'function') {
      const options = Intl.DateTimeFormat().resolvedOptions();
      data.timeZone = options.timeZoneName || options.timeZone || null;
      data.locale = options.locale || null;
    }
  } catch (error) {
    /* Intl not available */
  }
  return data;
};

/**
 * Gets the Country Info based on the TimeZone of the browser
 * @returns {string} The country info (lat, lng, country-code)
 * - Returns null if the browser does not support the Intl standard
 */
export const timeZoneCountryInfo = (): TimeZoneCountryInfo | null => {
  const options = timeZoneFromIntl();
  return countryFromTimeZone(options.timeZone);
};

export class LocaleUtils {
  /**
   * Gets the Date locale configured in the browser. (this is often different from the browser locale)
   * @returns {string} The Date locale (example: `en-US` or `en`)
   * - Returns empty string if the browser does not support the Intl standard
   */
  public static get dateLocale(): string {
    let locale = '';
    try {
      if (Intl && typeof Intl.DateTimeFormat === 'function') {
        locale = Intl.DateTimeFormat().resolvedOptions().locale;
      }
    } catch (error) {
      /* Intl not available */
    }
    return locale;
  }

  /**
   * Gets the main browser language
   * @returns {string} Language ISO Code (example: `en-US` or `en`)
   */
  public static get browserLanguage(): string {
    try {
      if (window && window.navigator) {
        return LocaleUtils.parseISOCode(
          ((window.navigator as any).languages &&
            (window.navigator as any).languages[0]) || // Chrome / Firefox
            (window.navigator as any).language ||
            'en-US'
        ); // All browsers
      }
    } catch (error) {
      // Accessing window will throw error when using in Server Side
      return 'en-US';
    }
    return 'en-US';
  }

  /**
   * Gets the ISO ISO-639 language code from the locale
   * @param {string} locale The full locale
   * @returns {string} The language code
   */
  public static getLanguageCode(locale: string): string {
    const parts = locale.split('-').slice(0, LANGUAGE_CODE_LENGTH);
    return (parts[0] || '').toLowerCase();
  }

  /**
   * Parses a ISO code, and tries to infer the Default Country Code if missing
   * @param {string} locale The ISO code (example: `en-us`, `en-US`, `en`, ...)
   * @returns {string} the locale (example: `en-US` or `en`)
   */
  public static parseISOCode(locale: string): string {
    // 'en-US-u-VA-posix'.split('-').slice(0, 2)
    // ["en", "US"]
    const parts = (locale || '').split('-').slice(0, LANGUAGE_CODE_LENGTH);
    const lang = (parts[0] || '').toLowerCase();
    const country = (parts.length > 1 ? parts[1] : '').toUpperCase();

    // If the locale only has the language, try to infer the default country
    if (!country && LANGUAGE_CODE_TO_LOCALE[lang]) {
      return LocaleUtils.parseISOCode(LANGUAGE_CODE_TO_LOCALE[lang]);
    }

    return country ? `${lang}-${country}` : `${lang}`;
  }
}

export const currencyFromCountryCode = (country?: string | null): string | null => {
  if (country) {
    return CURRENCY_BY_COUNTRY[`${country}`.toUpperCase()] || null;
  } else {
    return null;
  }
};
