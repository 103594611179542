import { Injectable, NgZone } from '@angular/core';
import { AkitaLocationStore } from './location.store';
import {
  catchError,
  switchMap,
  map,
  tap,
  EMPTY,
  Observable,
  from,
  throwError,
  Subscription,
} from 'rxjs';
import { Coordinate } from '@app/akita/api/location/models/coordinate.model';
import { LocationUtils } from '@app/akita/api/location/utils/location.utils';
import { AkitaLocationQuery } from './location.query';
import { AkitaAuthQuery } from '@app/akita/api/auth/state/auth.query';
import { configureScope } from '@sentry/browser';
import { DataCacheService } from '@app/shared/services/data-cache.service';
import { LocationAPIService } from '@app/akita/api/location/services/location.service';
import { applyTransaction, logAction } from '@datorama/akita';
import { GoogleMapsUtils } from '@app/shared/utils/google-maps.utils';
import { AkitaAuthService } from '../../auth/state/auth.service';
import { AkitaConfigurationQuery } from '@app/akita/configuration/state/configuration.query';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { timeZoneCountryInfo } from '@app/shared/utils/locale.utils';

@Injectable({ providedIn: 'root' })
export class AkitaLocationService {
  private readonly locationObserver: Subscription;

  constructor(
    private readonly zone: NgZone,
    private readonly store: AkitaLocationStore,
    private readonly query: AkitaLocationQuery,
    private readonly authQuery: AkitaAuthQuery,
    private readonly akitaConfigurationQuery: AkitaConfigurationQuery,
    private readonly akitaAuthService: AkitaAuthService,
    private readonly locationAPIService: LocationAPIService,
    private readonly dataCacheService: DataCacheService
  ) {
    this.locationObserver = new Subscription();
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('AkitaLocationService() - Init');
        this.store.setIsLoading(false);
        this.store.update({
          requested: false,
        });
      });
    });

    const timeZoneInfo = timeZoneCountryInfo();
    if (timeZoneInfo) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('AkitaLocationService() - TimeZone');
          this.store.update({
            country: timeZoneInfo.country,
            lastUpdate: new Date(),
            currentLocation: Coordinate.fromJson({
              latitude: timeZoneInfo.lat,
              longitude: timeZoneInfo.lng,
              country: timeZoneInfo.country,
              city: timeZoneInfo.city,
              precission: 'ENVIROMENT',
            }),
          });
        });
      });
    } else {
      this.locationObserver.add(
        LocationUtils.locationFromIPOnly(locationAPIService).subscribe({
          next: (coordinate: Coordinate | null) => {
            if (coordinate) {
              this.zone.run(() => {
                applyTransaction(() => {
                  logAction('AkitaLocationService() - IP');
                  this.store.update({
                    country: coordinate.country,
                    lastUpdate: new Date(),
                    currentLocation: Coordinate.fromJson(coordinate),
                    precission: 'IP',
                  });
                });
              });
            }
          },
          error: () => {
            // Failed to fetch IP Based location (common issue - ignore to avoid overloading logs with this)
          },
        })
      );
    }

    this.locationObserver.add(
      LocationUtils.hasLocationPermission.subscribe({
        next: (hasPermission: boolean | null) => {
          if (hasPermission !== null) {
            const currentStatus = Boolean(hasPermission);
            if (currentStatus !== this.query.hasPermission) {
              this.zone.run(() => {
                applyTransaction(() => {
                  logAction('hasLocationPermission() - check');
                  this.store.update({
                    hasPermission: currentStatus,
                  });
                });
              });

              if (currentStatus) {
                this.requestGPSLocationInBackground();
              }
            }
          }
        },
      })
    );
  }

  public stopSubscriptions(): void {
    if (this.locationObserver) {
      this.locationObserver.unsubscribe();
    }
  }

  public requestLocationInBackground(safeToAsk?: boolean | null): Subscription {
    const out = new Subscription();
    if (!this.query.isLoading) {
      out.add(
        this.requestLocation(safeToAsk).subscribe({
          next: () => {},
          error: () => {},
        })
      );
    }
    return out;
  }

  public requestGPSLocationInBackground(): Subscription {
    const out = new Subscription();
    if (!this.query.isLoading) {
      out.add(
        this.requestGPSLocation().subscribe({
          next: () => {},
          error: () => {},
        })
      );
    }
    return out;
  }

  public requestGPSLocation(
    reportError?: boolean | null
  ): Observable<Coordinate | null | undefined> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('requestGPSLocation()');
        this.store.setIsLoading(true);
        this.store.update({
          requested: true,
        });
      });
    });

    return LocationUtils.locationFromBrowserOnly(
      this.akitaConfigurationQuery.locale
    ).pipe(
      catchError((err: unknown) => {
        this.apiCallFinished('requestGPSLocation() -> error', err);
        this.zone.run(() => {
          this.store.setIsLoading(false);
        });
        return reportError ? throwError(() => parseApiError(err)) : EMPTY;
      }),
      tap((data?: Coordinate | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('requestGPSLocation() -> done');
            this.requestLocationSuccess(data);
            this.apiCallFinished('');
            this.store.setIsLoading(false);

            if (data) {
              this.setLocation(data);
            }
          });
        });
      })
    );
  }

  public requestLocation(
    safeToAsk?: boolean | null
  ): Observable<Coordinate | null | undefined> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('requestLocation()');
        this.store.setIsLoading(true);
        this.store.update({
          requested: true,
        });
      });
    });

    const deviceLocation = this.query.deviceLocation;

    return LocationUtils.findCurrentLocation(
      this.locationAPIService,
      this.query.hasPermission,
      Boolean(safeToAsk),
      this.akitaConfigurationQuery.locale,
      deviceLocation?.country,
      deviceLocation?.region,
      deviceLocation?.city
    ).pipe(
      catchError((err: unknown) => {
        this.requestLocationError(err);
        this.apiCallFinished('requestLocation() -> error', err);
        this.zone.run(() => {
          this.store.setIsLoading(false);
        });
        return EMPTY;
      }),
      tap((data?: Coordinate | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('requestLocation() -> done');
            this.requestLocationSuccess(data);
            this.apiCallFinished('');
            this.store.setIsLoading(false);

            if (data) {
              this.setLocation(data);
            }
          });
        });
      })
    );
  }

  public updateLocation(coordinate?: Coordinate | null): Subscription {
    const out = new Subscription();
    if (
      coordinate &&
      coordinate.country &&
      coordinate.latitude !== 0 &&
      coordinate.longitude !== 0 &&
      (coordinate.precission === 'GPS' || coordinate.precission === 'IP')
    ) {
      // Do the API Call
      const accessToken = this.authQuery.accessToken;
      if (accessToken) {
        out.add(
          this.locationAPIService.updateLocation(accessToken, coordinate).subscribe({
            next: () => {
              this.apiCallFinished('updateLocation() -> done', null);
            },
            error: (error: unknown) => {
              this.apiCallFinished('updateLocation() -> error', error);
            },
          })
        );
      } else {
        this.apiCallFinished('', new Error('USER_NOT_LOGGED_IN'));
      }
    } else {
      this.apiCallFinished('', new Error('LOCATION_NOT_ACCURATE_ENOUGH'));
    }
    return out;
  }

  public clearLocation(
    userDefinedLocation: boolean,
    resetPermissions?: boolean | null
  ): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('clearLocation()');
        // Clear the requested Location
        if (userDefinedLocation) {
          this.store.updateUserLocation(null);
        } else {
          this.store.updateDeviceLocation(null);
        }

        this.store.update({
          // Reset the permissions if needed
          hasPermission: resetPermissions ? false : this.query.hasPermission,
          lastUpdate: new Date(),
        });

        this.updateLocationMetadata();
      });
    });
  }

  public setLocation(
    coordinates?: Coordinate | null,
    userDefinedLocation?: boolean | null,
    force?: boolean | null
  ): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setLocation()');

        if (coordinates) {
          // If the coordinate has lower precission than the current coordinate, discard
          const deviceLocation = this.query.deviceLocation;
          const currentPrecission = deviceLocation && deviceLocation.precission;
          if (!force && !userDefinedLocation) {
            if (
              ((coordinates.precission === 'ENVIROMENT' ||
                coordinates.precission === 'FALLBACK') &&
                (currentPrecission === 'SUBDOMAIN' ||
                  currentPrecission === 'IP' ||
                  currentPrecission === 'GPS')) ||
              (coordinates.precission === 'SUBDOMAIN' &&
                (currentPrecission === 'IP' || currentPrecission === 'GPS')) ||
              (coordinates.precission === 'IP' && currentPrecission === 'GPS')
            ) {
              return;
            }
          }

          // Update the user preferences
          if (userDefinedLocation) {
            coordinates.precission = 'USER';
            this.store.updateUserLocation(
              Coordinate.fromJson({
                ...coordinates,
                userDefined: true,
              })
            );
          }

          this.store.updateDeviceLocation(coordinates);
          this.store.update({
            lastUpdate: new Date(),
          });
        } else {
          this.apiCallFinished('', new Error('EMPTY_COORDINATE'));
        }

        this.updateLocationMetadata();

        if (
          coordinates &&
          (!coordinates.address ||
            !coordinates.googleGeocoder ||
            !coordinates.shortAddress ||
            !coordinates.country)
        ) {
          this.findAddressInformation(coordinates);
        }
      });
    });
  }

  public setCountry(country?: string | null): void {
    if (country) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('setCountry()');
          this.store.update({
            country: country,
            lastUpdate: new Date(),
          });
        });
      });
    }
  }

  public reRegisterLocation(): Subscription {
    return this.registerLocation(
      this.query.deviceLocation,
      Boolean(this.query.deviceLocation?.googleGeocoder)
    ).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public registerLocationAsync(
    coordinate?: Coordinate | null,
    googleGeocoder?: boolean | null
  ): Subscription {
    return this.registerLocation(coordinate, googleGeocoder).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public registerLocation(
    coordinate?: Coordinate | null,
    googleGeocoder?: boolean | null
  ): Observable<{ [key: string]: Coordinate }> {
    if (coordinate) {
      return this.dataCacheService
        .readCoordinate(coordinate.latitude, coordinate.longitude)
        .pipe(
          switchMap(() => {
            let shouldUpdate = true;
            if (coordinate.googleGeocoder) {
              shouldUpdate = false;
            }

            const newCoordinate = Coordinate.fromJson(coordinate) || new Coordinate();
            newCoordinate.googleGeocoder = Boolean(googleGeocoder);
            this.dataCacheService.saveCoordinate(newCoordinate);

            return this.dataCacheService.coordinatesMap.pipe(
              // Ensure there are no null coordinates in the cache
              map(
                (dictionary: {
                  [key: string]: Coordinate | null;
                }): { [key: string]: Coordinate } => {
                  const cleanDictionary: { [key: string]: Coordinate } = {};
                  for (const key of Object.keys(dictionary || {})) {
                    const dictCoordenate = dictionary[key];
                    if (dictCoordenate) {
                      cleanDictionary[key] = dictCoordenate;
                    }
                  }
                  return cleanDictionary;
                }
              ),
              tap((dictionary: { [key: string]: Coordinate }) => {
                if (shouldUpdate) {
                  this.zone.run(() => {
                    applyTransaction(() => {
                      logAction('registerLocation()');
                      this.store.update({
                        coordinates: dictionary,
                      });
                    });
                  });
                }
              })
            );
          })
        );
    }

    return EMPTY;
  }

  public findCachedCoordinate(
    latitude: number,
    longitude: number
  ): Observable<Coordinate | null | undefined> {
    return this.dataCacheService.readCoordinate(latitude, longitude);
  }

  public findAddressInformation(coordinate?: Coordinate | null): Subscription {
    const out = new Subscription();
    if (coordinate) {
      out.add(
        from(
          GoogleMapsUtils.geoCodeCoordinateAddress(
            coordinate,
            this.akitaConfigurationQuery.locale
          )
        )
          .pipe(
            tap((googleCoordinate?: Coordinate | null) => {
              if (googleCoordinate) {
                const latLng = googleCoordinate.getLatLng();
                const deviceLocation = this.query.currentLocation;
                if (deviceLocation && latLng === deviceLocation.getLatLng()) {
                  this.zone.run(() => {
                    applyTransaction(() => {
                      logAction('findAddressInformation()');
                      this.store.updateDeviceLocation(googleCoordinate);
                    });
                  });
                }

                const userLocation = this.query.definedByUser;
                if (userLocation && latLng === userLocation.getLatLng()) {
                  this.zone.run(() => {
                    applyTransaction(() => {
                      this.store.updateUserLocation(
                        Coordinate.fromJson({
                          ...googleCoordinate,
                          userDefined: true,
                        })
                      );
                    });
                  });
                }

                this.updateLocationMetadata();
              }
            })
          )
          .subscribe({
            next: () => {
              this.apiCallFinished('findAddressInformation() - done', null);
            },
            error: (error: unknown) => {
              this.apiCallFinished('findAddressInformation() - error', error);
            },
          })
      );
    }
    return out;
  }

  private apiCallFinished(name: string, err?: any | null): void {
    if (name) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction(name);
          this.store.setIsLoading(false);
          this.store.setError(err || null);
          this.store.update({
            lastUpdate: new Date(),
          });
          this.akitaAuthService.refreshUserAsync();
        });
      });
    } else {
      this.zone.run(() => {
        this.store.setIsLoading(false);
        this.store.setError(err || null);
        this.store.update({
          lastUpdate: new Date(),
        });
      });
      this.akitaAuthService.refreshUserAsync();
    }
  }

  private requestLocationError(err: unknown): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('requestLocationError()');
        // If the location failed, fall back to the location by enviroment as a last resource
        if (
          this.query.coordinatePrecission === 'ENVIROMENT' &&
          this.query.currentLocation
        ) {
          const currentLocation =
            Coordinate.fromJson(this.query.currentLocation) || new Coordinate();
          currentLocation.precission = 'FALLBACK';

          this.store.updateDeviceLocation(currentLocation);
          this.store.update({
            precission: currentLocation.precission,
          });
        }

        if (!err) {
          this.store.update({
            apiCallRetries: 0,
          });
        }

        this.store.update({
          requested: false,
          lastUpdate: new Date(),
          hasPermission:
            err && err === 'PERMISSION_DENIED' ? false : this.query.hasPermission,
        });
      });
    });
  }

  private requestLocationSuccess(coordinate?: Coordinate | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('requestLocationSuccess()');
        if (coordinate) {
          if (
            coordinate.precission === 'ENVIROMENT' &&
            (this.query.coordinatePrecission === 'IP' ||
              this.query.coordinatePrecission === 'GPS' ||
              this.query.definedByUser)
          ) {
            return; // If the coordinate was guessed using the environment, but we already have a valid IP, Ignore it
          }

          this.store.updateDeviceLocation(coordinate);
          this.store.update({
            lastUpdate: new Date(),
            hasPermission: coordinate.precission === 'GPS',
            precission: coordinate.precission || '',
          });
        }
      });
    });
  }

  private updateLocationMetadata(): void {
    configureScope((scope) => {
      scope.setExtra('location:country', this.query.country);
      scope.setExtra('location:city', this.query.city);
      scope.setExtra('location:region', this.query.region);
      scope.setExtra('location:precission', this.query.coordinatePrecission);
      scope.setExtra('location:userDefined', Boolean(this.query.definedByUser));
      scope.setExtra('location:hasPermission', this.query.hasPermission);
      scope.setExtra('location:requested', this.query.permissionRequested);
    });
  }
}
