import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription, take, filter } from 'rxjs';
import { AkitaOverlaysQuery } from '@app/akita/overlays/state/overlays.query';
import { AkitaOverlaysService } from '@app/akita/overlays/state/overlays.service';

const OVERLAY_NAME = 'cart-overlay';

@Injectable({
  providedIn: 'root',
})
export class CartOverlayDynamicService {
  private readonly inboxSubject: Subject<any | null>;

  public reported: boolean;

  constructor(
    private readonly akitaOverlaysQuery: AkitaOverlaysQuery,
    private readonly akitaOverlaysService: AkitaOverlaysService
  ) {
    this.inboxSubject = new Subject();
    this.reported = false;
  }

  public open(data?: any | null): void {
    const hasError = this.akitaOverlaysQuery.overlayFailedToLoad(OVERLAY_NAME);
    const hasLoaded = this.akitaOverlaysQuery.getOverlayStatus(OVERLAY_NAME);
    const isLoading = this.akitaOverlaysQuery.isOverlayLoading(OVERLAY_NAME);

    if (hasError || (!hasLoaded && !isLoading)) {
      this.akitaOverlaysService.loadDialog(OVERLAY_NAME);
      this.openWhenReady(data);
    } else if (hasLoaded) {
      this.inboxSubject.next(data || {});
    } else {
      this.openWhenReady(data);
    }
  }

  public openWhenReady(data?: any | null): void {
    const loadSubscription = new Subscription();
    loadSubscription.add(
      this.akitaOverlaysQuery
        .selectOverlayLoadStatus(OVERLAY_NAME)
        .pipe(
          filter((loaded) => Boolean(loaded)),
          take(1)
        )
        .subscribe({
          next: () => {
            this.inboxSubject.next(data || {});
            loadSubscription.unsubscribe();
          },
        })
    );
  }

  public close(): void {
    this.inboxSubject.next(null);
  }

  public get observe(): Observable<any | null> {
    return this.inboxSubject.asObservable();
  }
}
