import { BannerInfo, parseBannerInfoFromJsonArray } from './banner-info.model';

export class FeatureFeaturedConfig {
  public sellerId: string;
  public banners: Array<BannerInfo>;

  constructor() {
    this.sellerId = '';
    this.banners = new Array(0);
  }
}

export const parseFeatureFeaturedConfigFromJsonString = (
  text?: string | null
): FeatureFeaturedConfig | null => {
  let out = null;
  try {
    out = parseFeatureFeaturedConfigFromJson(JSON.parse(`${text || ''}`));
  } catch (error) {}
  return out;
};

export const parseFeatureFeaturedConfigFromJson = (
  data?: any | null
): FeatureFeaturedConfig | null => {
  let out = null;
  if (data) {
    out = new FeatureFeaturedConfig();
    out.sellerId = data.seller_id || data['seller-id'] || data.sellerId || '';
    out.banners = parseBannerInfoFromJsonArray(data.banners);
  }
  return out;
};
