import { inject, Injectable } from '@angular/core';
import { Observable, throwError, map } from 'rxjs';
import { getApiEndpoint } from '@app/shared/utils/url.utils';
import {
  HttpClient,
  HttpParams,
  HttpEvent,
  HttpEventType,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Params } from '@angular/router';
import { PictureModel } from '@app/shared/models/api/picture.model';
import { UserModel } from '@app/shared/models/api/user.model';
import { parseApiError } from '@app/shared/models/api/api-error.model';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class UserInfoAPIService {
  private readonly http = inject(HttpClient);
  constructor() {}

  public uploadUserPicture(
    token: string | null,
    imageData: File | null
  ): Observable<{ proggress: number; picture: PictureModel | null }> {
    if (!token) {
      return throwError(() => parseApiError('INVALID_ACCESS_TOKEN'));
    }

    if (!imageData) {
      return throwError(() => parseApiError('INVALID_IMAGE'));
    }

    const options = new HttpParams().set('token', token);

    const formData = new FormData();
    formData.append('image', imageData);

    // eslint-disable-next-line deprecation/deprecation
    const credentials = btoa(`api:Lax4UpCrx9TAmhea`);
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${credentials}`,
    });

    const request = new HttpRequest(
      'POST',
      getApiEndpoint('api/v3/upload', true),
      formData,
      {
        params: options,
        responseType: 'json',
        headers: headers,
        reportProgress: true,
        // observe: 'events',
      }
    );

    return this.http.request(request).pipe(
      map(($event: HttpEvent<any>) => {
        if (
          // Upload Proggress
          $event.type === HttpEventType.DownloadProgress ||
          $event.type === HttpEventType.UploadProgress
        ) {
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          const percentageLoaded = (100 * $event.loaded) / ($event.total || 1);
          return {
            proggress: percentageLoaded,
            picture: null,
          };
        } else if (
          // Upload Complete
          $event.type === HttpEventType.Response
        ) {
          let picture: PictureModel | null = null;
          if ($event?.body?.url) {
            picture = new PictureModel();
            picture.path = $event.body.url;
          }
          return {
            proggress: 1.0,
            picture: picture,
          };
          // Initial load / errors
        } else {
          return {
            proggress: 0.0,
            picture: null,
          };
        }
      })
    );
  }

  public updateInformation(
    accessToken: string,
    email?: string | null,
    description?: string | null,
    picture?: PictureModel | null
  ): Observable<UserModel | null> {
    const options = new HttpParams();

    const body: Params = {};
    if (email) {
      body.email = email;
    }

    if (description) {
      body.description = description;
    }

    if (picture) {
      body.picture = picture;
    }

    return this.http
      .put<any>(
        // Assemble the full API URL
        getApiEndpoint('users/me'),
        body,
        // Add the Options
        {
          params: options,
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .pipe(map((results) => UserModel.fromJson(results)));
  }

  public updateUser(
    accessToken?: string | null,
    user?: Partial<UserModel> | null
  ): Observable<UserModel | null> {
    if (!accessToken) {
      return throwError(() => new Error(`USER_NOT_LOGGED_IN`));
    }

    if (!user?.phone) {
      return throwError(() => new Error(`PHONE_NOT_SET`));
    }

    let headers: Params = {
      'Content-Type': 'application/json; charset=utf-8',
    };
    if (accessToken) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return this.http
      .put<any>(
        // Assemble the full API URL
        getApiEndpoint('users/me'),
        {
          phone: user?.phone,
        },
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(map((results) => UserModel.fromJson(results)));
  }

  public updateUserLanguage(
    accessToken?: string | null,
    locale?: string | null
  ): Observable<UserModel | null> {
    if (!accessToken) {
      return throwError(() => new Error(`USER_NOT_LOGGED_IN`));
    }

    if (!locale) {
      return throwError(() => new Error(`LOCALE_NOT_SET`));
    }

    let headers: Params = {
      'Content-Type': 'application/json; charset=utf-8',
    };
    if (accessToken) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return this.http
      .put<any>(
        // Assemble the full API URL
        getApiEndpoint('users/me'),
        {
          locale: locale,
        },
        // Add the Options
        {
          responseType: 'json',
          headers: headers,
        }
      )
      .pipe(map((results) => UserModel.fromJson(results)));
  }

  public deleteAccount(accessToken: string | null): Observable<void> {
    if (!accessToken) {
      return throwError(() => new Error('User not loggedIn'));
    }

    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return this.http.delete<any>(
      // Assemble the full API URL
      getApiEndpoint('users/me'),
      {
        responseType: 'json',
        headers: headers,
      }
    );
  }
}
