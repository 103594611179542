import { Injectable, NgZone } from '@angular/core';
import { AkitaUsersStore } from '@app/akita/api/users/state/users.store';
import { AkitaUsersQuery } from '@app/akita/api/users/state/users.query';
import { logAction, applyTransaction } from '@datorama/akita';
import { Observable, EMPTY, throwError, Subscription, catchError, tap } from 'rxjs';
import { UserInfoAPIService } from '../services/user.service';
import { AkitaAuthQuery } from '../../auth/state/auth.query';
import { AkitaAuthService } from '../../auth/state/auth.service';
import { UsersAPIService } from '@app/akita/api/users/services/user-api.service';
import { PictureModel } from '@app/shared/models/api/picture.model';
import { UserModel } from '@app/shared/models/api/user.model';
import { parseApiError } from '@app/shared/models/api/api-error.model';

@Injectable({ providedIn: 'root' })
export class AkitaUsersService {
  constructor(
    private readonly zone: NgZone,
    private readonly store: AkitaUsersStore,
    private readonly query: AkitaUsersQuery,
    private readonly akitaAuthQuery: AkitaAuthQuery,
    private readonly akitaAuthService: AkitaAuthService,
    private readonly usersAPIService: UsersAPIService,
    private readonly userInfoAPIService: UserInfoAPIService
  ) {}

  public deleteAccount(): Observable<void> {
    const accessToken = this.akitaAuthQuery.accessToken;
    if (accessToken) {
      return this.userInfoAPIService.deleteAccount(accessToken).pipe(
        tap(() => {
          this.akitaAuthService.logout();
        })
      );
    }
    return EMPTY;
  }

  public updateUserLanguageAsync(locale?: string | null): Subscription {
    return this.updateUserLanguage(locale).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public updateUserLanguage(locale?: string | null): Observable<UserModel | null> {
    const loggedUser = this.akitaAuthQuery.user;
    if (!loggedUser || loggedUser?.locale === locale) {
      return EMPTY;
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateUserLanguage()');
        this.store.toggleUpdatingUserLocale(true);
        this.store.setUpdateUserLocaleError(null);
      });
    });

    return this.userInfoAPIService
      .updateUserLanguage(this.akitaAuthQuery.accessToken, locale)
      .pipe(
        catchError((error: unknown) => {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateUserLanguage() - error');
              this.store.toggleUpdatingUserLocale(false);
              this.store.setUpdateUserLocaleError(error);
            });
          });
          return throwError(() => parseApiError(error));
        }),
        tap((user: UserModel | null) => {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateUserLanguage() - done');
              this.store.toggleUpdatingUserLocale(false);

              if (user) {
                if (this.query.getUser(user.id)) {
                  this.store.remove(user.id);
                }
                this.store.add(user);
              }
            });
          });
        })
      );
  }

  public updateUserInformation(
    email?: string | null,
    description?: string | null,
    picture?: PictureModel | null
  ): Observable<UserModel | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateUserInformation()');
        this.store.toggleUpdatingUserInformation(true);
        this.store.setUpdateUserInformationError(null);
      });
    });

    const accessToken = this.akitaAuthQuery.accessToken;
    if (accessToken) {
      return this.userInfoAPIService
        .updateInformation(accessToken, email, description, picture)
        .pipe(
          catchError((error: unknown) => {
            const parsedError = parseApiError(error);
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('updateUserInformation() - error');
                this.store.toggleUpdatingUserInformation(false);
                this.store.setUpdateUserInformationError(parsedError);
              });
            });
            return throwError(() => parsedError);
          }),
          tap((user: UserModel | null) => {
            if (user) {
              let newEmail: string | null = null;
              let newDescription: string | null = null;
              let newPicture: PictureModel | null = null;

              if (email) {
                newEmail = user.email;
              }

              if (description) {
                newDescription = user.description;
              }

              if (picture) {
                newPicture = user.picture;
              }

              this.akitaAuthService.udateProfileInformation(
                newEmail,
                newDescription,
                newPicture
              );

              this.zone.run(() => {
                applyTransaction(() => {
                  logAction('updateUserInformation() - done');
                  this.store.toggleUpdatingUserInformation(false);
                  this.store.addUserInfo(user);
                });
              });
            }
          })
        );
    }
    return throwError(() => parseApiError('UNAUTHORIZED'));
  }

  public updateUser(user?: Partial<UserModel> | null): Observable<UserModel | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateUser()');
        this.store.toggleUpdatingUserInformation(true);
        this.store.setUpdateUserInformationError(null);
      });
    });

    const accessToken = this.akitaAuthQuery.accessToken;
    if (accessToken) {
      return this.userInfoAPIService.updateUser(accessToken, user).pipe(
        catchError((error: unknown) => {
          const parsedError = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('updateUser() - error');
              this.store.toggleUpdatingUserInformation(false);
              this.store.setUpdateUserInformationError(parsedError);
            });
          });
          return throwError(() => parsedError);
        }),
        tap((apiUser: UserModel | null) => {
          if (apiUser) {
            this.zone.run(() => {
              applyTransaction(() => {
                logAction('updateUser() - done');
                this.store.toggleUpdatingUserInformation(false);
                this.store.addUserInfo(apiUser);
              });
            });
          }
        })
      );
    }
    return throwError(() => parseApiError('UNAUTHORIZED'));
  }

  public getUserAsync(userId: string): Subscription {
    return this.getUser(userId).subscribe({
      next: () => {},
    });
  }

  public getUser(userId: string): Observable<UserModel | null> {
    return this.usersAPIService.getUser(userId).pipe(
      tap((user: UserModel | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getUser() - done');
            if (user) {
              this.store.upsertMany([
                {
                  ...user,
                  cachedOn: new Date(),
                },
              ]);
            }
          });
        });
      })
    );
  }

  public verifySMS(
    userId?: string | null,
    authToken?: string | null
  ): Observable<{ is_verified: boolean }> {
    applyTransaction(() => {
      logAction('verifySMS()');
      this.store.toggleVerifyingSMS(true);
    });

    const accessToken = this.akitaAuthQuery.accessToken;
    if (accessToken) {
      return this.usersAPIService.verifySMS(accessToken, userId, authToken).pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          applyTransaction(() => {
            logAction('verifySMS() - error');
            this.store.toggleVerifyingSMS(false);
            this.store.setVerifyingSMSError(parsed);
          });
          return throwError(() => parsed);
        }),
        tap(() => {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('verifySMS() - done');
              this.store.toggleVerifyingSMS(false);
            });
          });
        })
      );
    } else {
      const parsed = parseApiError('UNAUTHORIZED');
      applyTransaction(() => {
        logAction('verifySMS() - error');
        this.store.toggleVerifyingSMS(false);
        this.store.setVerifyingSMSError(parsed);
      });
      return throwError(() => parsed);
    }
  }
}
