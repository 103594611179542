import { Injectable, NgZone } from '@angular/core';
import { AkitaRouterService } from '@app/akita/router/state/router.service';
import { OmnisendService } from '@app/shared/services/omnisend.service';
import { applyTransaction, logAction } from '@datorama/akita';
import { catchError, Observable, of, Subscription, tap } from 'rxjs';
import { OmnisendIdentifyParams } from '../models/omnisend-identify-params.model';
import { OmniSendIdentifyUser } from '../models/omnisend-itentify-response.model';
import { OmnisendAPIService } from '../services/omnisend-api.service';
import { AkitaOmnisendStore } from './omnisend.store';

@Injectable({ providedIn: 'root' })
export class AkitaOmnisendService {
  constructor(
    private readonly zone: NgZone,
    private readonly store: AkitaOmnisendStore,
    private readonly akitaRouterService: AkitaRouterService,
    private readonly omnisendAPIService: OmnisendAPIService,
    private readonly omnisendService: OmnisendService
  ) {}

  public identifyUserAsync(userInfo: OmnisendIdentifyParams): Subscription {
    return this.identifyUser(userInfo).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public identifyUser(
    userInfo: OmnisendIdentifyParams
  ): Observable<OmniSendIdentifyUser> {
    const envContactId = this.omnisendService.getContactId();
    if (envContactId) {
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('identifyUser() - env');
          this.store.updateOmnisendUserId({
            consumerId: envContactId,
            isNew: false,
          });
        });
      });
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('identifyUser()');
        this.store.toggleOmnisendConsumerUpdating(true);
      });
    });

    return this.omnisendAPIService.identifyUser(userInfo).pipe(
      catchError(() => {
        const consumerInfo = new OmniSendIdentifyUser();
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('identifyUser() - error');
            this.store.updateOmnisendUserId(consumerInfo);
            this.store.toggleOmnisendConsumerUpdating(false);
          });
        });

        return of(consumerInfo);
      }),
      tap((consumerInfo: OmniSendIdentifyUser) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('identifyUser() - done');
            this.store.updateOmnisendUserId(consumerInfo);
            this.store.toggleOmnisendConsumerUpdating(false);
          });
        });

        if (consumerInfo?.consumerId) {
          this.akitaRouterService.addParamToKeepQueryParams({
            omnisendContactID: consumerInfo.consumerId,
          });
          // this.akitaRouterService.navigate(
          //   [],
          //   {
          //     omnisendContactID: consumerInfo.consumerId,
          //   },
          //   {
          //     skipLocationChange: true,
          //   }
          // );

          this.omnisendService.identifyContact(consumerInfo.consumerId);
        }
      })
    );
  }
}
