import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { WindowUtils } from '@app/shared/utils/window.util';
import { applyTransaction, logAction } from '@datorama/akita';
import { configureScope } from '@sentry/browser';
import { Subscription } from 'rxjs';
import { AkitaScreenStore, ScreenSizes, ScreenOrientations } from './screen.store';

const SSR_MOBILE_SCREEN_WIDTH = 320;

@Injectable({ providedIn: 'root' })
export class AkitaScreenService {
  private readonly isBrowser: boolean;
  private subscriptions: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, any>,
    private readonly zone: NgZone,
    private readonly store: AkitaScreenStore,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.subscriptions = new Subscription();

    this.zone.runOutsideAngular(() => {
      this.monitorScreen();
    });
  }

  public monitorScreen(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.subscriptions = new Subscription();

    if (this.isBrowser) {
      this.setBrowser();
      this.zone.runOutsideAngular(() => {
        // XXS = 'xxs', // <= 320px
        // MobileS = 'mobileS', // > 320px && <= 375px
        // XS = 'xs', // > 375px && <= 425px
        // MobileL = 'mobileL', // > 425px && <= 599px
        // Monitor the Screen Size Changes
        this.subscriptions.add(
          this.breakpointObserver.observe(['(max-height: 550px)']).subscribe({
            next: (result: BreakpointState) => {
              this.setScreenShort(result.matches);
            },
            error: (err: unknown) => {
              this.logErrorMonitoringScreen(err, `breakpointObserver -> heightTooShort`);
            },
          })
        );

        this.subscriptions.add(
          this.breakpointObserver.observe(['(max-width: 320.99px)']).subscribe({
            next: (result: BreakpointState) => {
              if (result.matches) {
                this.setScreenSize(ScreenSizes.XXS);
              }
            },
            error: (err: unknown) => {
              this.logErrorMonitoringScreen(err, `breakpointObserver -> xxs`);
            },
          })
        );

        this.subscriptions.add(
          this.breakpointObserver
            .observe(['(min-width: 321px) and (max-width: 374.99px)'])
            .subscribe({
              next: (result: BreakpointState) => {
                if (result.matches) {
                  this.setScreenSize(ScreenSizes.MobileS);
                }
              },
              error: (err: unknown) => {
                this.logErrorMonitoringScreen(err, `breakpointObserver -> mobileS`);
              },
            })
        );

        this.subscriptions.add(
          this.breakpointObserver
            .observe(['(min-width: 375px) and (max-width: 549.99px)'])
            .subscribe({
              next: (result: BreakpointState) => {
                if (result.matches) {
                  this.setScreenSize(ScreenSizes.XS);
                }
              },
              error: (err: unknown) => {
                this.logErrorMonitoringScreen(err, `breakpointObserver -> xs`);
              },
            })
        );

        this.subscriptions.add(
          this.breakpointObserver
            .observe(['(min-width: 550px) and (max-width: 600.99px)'])
            .subscribe({
              next: (result: BreakpointState) => {
                if (result.matches) {
                  this.setScreenSize(ScreenSizes.MobileL);
                }
              },
              error: (err: unknown) => {
                this.logErrorMonitoringScreen(err, `breakpointObserver -> mobileL`);
              },
            })
        );

        this.subscriptions.add(
          this.breakpointObserver
            .observe(['(min-width: 601px) and (max-width: 799.99px)'])
            .subscribe({
              next: (result: BreakpointState) => {
                if (result.matches) {
                  this.setScreenSize(ScreenSizes.MobileXL);
                }
              },
              error: (err: unknown) => {
                this.logErrorMonitoringScreen(err, `breakpointObserver -> mobileXL`);
              },
            })
        );

        this.subscriptions.add(
          this.breakpointObserver
            .observe(['(min-width: 800px) and (max-width: 959.99px)'])
            .subscribe({
              next: (result: BreakpointState) => {
                if (result.matches) {
                  this.setScreenSize(ScreenSizes.SM);
                }
              },
              error: (err: unknown) => {
                this.logErrorMonitoringScreen(err, `breakpointObserver -> sm`);
              },
            })
        );

        this.subscriptions.add(
          this.breakpointObserver.observe([Breakpoints.Medium]).subscribe({
            next: (result: BreakpointState) => {
              if (result.matches) {
                this.setScreenSize(ScreenSizes.MD);
              }
            },
            error: (err: unknown) => {
              this.logErrorMonitoringScreen(err, `breakpointObserver -> md`);
            },
          })
        );

        this.subscriptions.add(
          this.breakpointObserver.observe([Breakpoints.Large]).subscribe({
            next: (result: BreakpointState) => {
              if (result.matches) {
                this.setScreenSize(ScreenSizes.LG);
              }
            },
            error: (err: unknown) => {
              this.logErrorMonitoringScreen(err, `breakpointObserver -> lg`);
            },
          })
        );

        this.subscriptions.add(
          this.breakpointObserver
            .observe(['(min-width: 1920px) and (max-width: 3839.99px)'])
            .subscribe({
              next: (result: BreakpointState) => {
                if (result.matches) {
                  this.setScreenSize(ScreenSizes.XL);
                }
              },
              error: (err: unknown) => {
                this.logErrorMonitoringScreen(err, `breakpointObserver -> xl`);
              },
            })
        );

        this.subscriptions.add(
          this.breakpointObserver.observe(['(min-width: 3840px)']).subscribe({
            next: (result: BreakpointState) => {
              if (result.matches) {
                this.setScreenSize(ScreenSizes.XXL);
              }
            },
            error: (err: unknown) => {
              this.logErrorMonitoringScreen(err, `breakpointObserver -> xxl`);
            },
          })
        );

        this.subscriptions.add(
          this.breakpointObserver.observe([Breakpoints.HandsetPortrait]).subscribe({
            next: (result: BreakpointState) => {
              if (result.matches) {
                this.setScreenOrientation(ScreenOrientations.PORTRAIT);
              }
            },
            error: (err: unknown) => {
              this.logErrorMonitoringScreen(err, `breakpointObserver -> portrait`);
            },
          })
        );

        this.subscriptions.add(
          this.breakpointObserver.observe([Breakpoints.HandsetLandscape]).subscribe({
            next: (result: BreakpointState) => {
              if (result.matches) {
                this.setScreenOrientation(ScreenOrientations.LANDSCAPE);
              }
            },
            error: (err: unknown) => {
              this.logErrorMonitoringScreen(err, `breakpointObserver -> landscape`);
            },
          })
        );
      });
    } else {
      // On server side, send the size directly at the start (window sizes hardcoded at server)
      this.setScreenOrientation(ScreenOrientations.PORTRAIT);

      if (WindowUtils.windowInnerWidth() <= SSR_MOBILE_SCREEN_WIDTH) {
        this.setScreenSize(ScreenSizes.XS);
      } else {
        this.setScreenSize(ScreenSizes.LG);
      }
    }
  }

  public refreshScreenInfo(): void {
    /// // Re-check screen sizes
    const isScreenVeryShort = this.breakpointObserver.isMatched(['(max-height: 550px)']);
    this.setScreenShort(isScreenVeryShort);

    const isScreenXXSSize = this.breakpointObserver.isMatched(['(max-width: 320.99px)']);
    if (isScreenXXSSize) {
      this.setScreenSize(ScreenSizes.XXS);
    }

    const isScreenMobileSSize = this.breakpointObserver.isMatched([
      '(min-width: 321px) and (max-width: 374.99px)',
    ]);
    if (isScreenMobileSSize) {
      this.setScreenSize(ScreenSizes.MobileS);
    }

    const isScreenXSSize = this.breakpointObserver.isMatched([
      '(min-width: 375px) and (max-width: 549.99px)',
    ]);
    if (isScreenXSSize) {
      this.setScreenSize(ScreenSizes.XS);
    }

    const isScreenMobileLSize = this.breakpointObserver.isMatched([
      '(min-width: 550px) and (max-width: 600.99px)',
    ]);
    if (isScreenMobileLSize) {
      this.setScreenSize(ScreenSizes.MobileL);
    }

    const isScreenMobileXLSize = this.breakpointObserver.isMatched([
      '(min-width: 601px) and (max-width: 799.99px)',
    ]);
    if (isScreenMobileXLSize) {
      this.setScreenSize(ScreenSizes.MobileXL);
    }

    const isScreenSMSize = this.breakpointObserver.isMatched([
      '(min-width: 800px) and (max-width: 959.99px)',
    ]);
    if (isScreenSMSize) {
      this.setScreenSize(ScreenSizes.SM);
    }

    const isScreenMediumSize = this.breakpointObserver.isMatched([Breakpoints.Medium]);
    if (isScreenMediumSize) {
      this.setScreenSize(ScreenSizes.MD);
    }

    const isScreenLGSize = this.breakpointObserver.isMatched([Breakpoints.Large]);
    if (isScreenLGSize) {
      this.setScreenSize(ScreenSizes.LG);
    }

    const isScreenXLSize = this.breakpointObserver.isMatched([
      '(min-width: 1920px) and (max-width: 3839.99px)',
    ]);
    if (isScreenXLSize) {
      this.setScreenSize(ScreenSizes.XL);
    }

    const isScreenXXLSize = this.breakpointObserver.isMatched(['(min-width: 3840px)']);
    if (isScreenXXLSize) {
      this.setScreenSize(ScreenSizes.XXL);
    }
  }

  public stopMonitoringScreen(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  private logErrorMonitoringScreen(err: unknown, name?: string | null): void {
    SentryUtil.reportException(err, false, () => (parsedError: string) => {
      this.googleAnalyticsService.appException(
        parsedError,
        'application',
        `${(err as any)?.status || '-'}`,
        false,
        'ROOT',
        `${name || 'logErrorMonitoringScreen'}`
      );
    });
  }

  public setScreenShort(isShort?: boolean | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setScreenShort()');

        this.store.setScreenShort(isShort);
      });
    });

    configureScope((scope) => {
      scope.setExtra('screen:is-short', `${isShort ? 'Yes' : 'No'}`);
    });
  }

  public setBrowser(): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setBrowser()');

        this.store.setBrowser();
      });
    });

    configureScope((scope) => {
      scope.setExtra('browser', `${this.store.getValue().browser}`);
    });
  }

  public setScreenSize(size: ScreenSizes): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setScreenSize()');

        this.store.update({
          size: size,
          lessThan: {
            xxs: false,
            mobileS: size === ScreenSizes.XXS,
            xs: size === ScreenSizes.XXS || size === ScreenSizes.MobileS,
            mobileL:
              size === ScreenSizes.XXS ||
              size === ScreenSizes.MobileS ||
              size === ScreenSizes.XS,
            mobileXL:
              size === ScreenSizes.XXS ||
              size === ScreenSizes.MobileS ||
              size === ScreenSizes.XS ||
              size === ScreenSizes.MobileL,

            sm:
              size === ScreenSizes.XXS ||
              size === ScreenSizes.MobileS ||
              size === ScreenSizes.XS ||
              size === ScreenSizes.MobileL ||
              size === ScreenSizes.MobileXL,

            md:
              size === ScreenSizes.XXS ||
              size === ScreenSizes.MobileS ||
              size === ScreenSizes.XS ||
              size === ScreenSizes.MobileL ||
              size === ScreenSizes.MobileXL ||
              size === ScreenSizes.SM,
            lg:
              size !== ScreenSizes.XXL &&
              size !== ScreenSizes.XL &&
              size !== ScreenSizes.LG,
            xl: size !== ScreenSizes.XXL && size !== ScreenSizes.XL,
            xxl: size !== ScreenSizes.XXL,
          },
          biggerThan: {
            xxs: size !== ScreenSizes.XXS,
            mobileS: size !== ScreenSizes.XXS && size !== ScreenSizes.MobileS,
            xs:
              size !== ScreenSizes.XXS &&
              size !== ScreenSizes.MobileS &&
              size !== ScreenSizes.XS,
            mobileL:
              size !== ScreenSizes.XXS &&
              size !== ScreenSizes.MobileS &&
              size !== ScreenSizes.XS &&
              size !== ScreenSizes.MobileL,
            mobileXL:
              size !== ScreenSizes.XXS &&
              size !== ScreenSizes.MobileS &&
              size !== ScreenSizes.XS &&
              size !== ScreenSizes.MobileL &&
              size !== ScreenSizes.MobileXL,
            sm:
              size !== ScreenSizes.XXS &&
              size !== ScreenSizes.MobileS &&
              size !== ScreenSizes.XS &&
              size !== ScreenSizes.MobileL &&
              size !== ScreenSizes.MobileXL &&
              size !== ScreenSizes.SM,
            md:
              size === ScreenSizes.XXL ||
              size === ScreenSizes.XL ||
              size === ScreenSizes.LG,
            lg: size === ScreenSizes.XXL || size === ScreenSizes.XL,
            xl: size === ScreenSizes.XXL,
            xxl: false,
          },
        });
      });
    });

    configureScope((scope) => {
      scope.setExtra('screen:size', `${size}`);
    });
  }

  public setScreenOrientation(orientation: ScreenOrientations): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setScreenOrientation()');

        this.store.update({
          orientation: orientation,
        });
      });
    });

    // Sentry
    configureScope((scope) => {
      scope.setExtra('screen:orientation', `${orientation}`);
    });
  }

  public setLayoutDirection(direction: 'ltr' | 'rtl'): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setLayoutDirection()');

        this.store.update({
          direction: direction,
        });
      });
    });

    // Sentry
    configureScope((scope) => {
      scope.setExtra('layout:direction', `${direction}`);
    });
  }
}
