import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

/**
 * Class to handle the registration of the messaging service (to ensure get token is called after registration)
 */
@Injectable({
  providedIn: 'root',
})
export class MessageRegistrationCheckerService {
  private messagingRegistered: boolean;
  private readonly monitorSubject: Subject<boolean>;

  constructor() {
    this.monitorSubject = new Subject();
    this.messagingRegistered = false;
  }

  public markAsRegistered(): void {
    this.messagingRegistered = true;
    this.monitorSubject.next(this.messagingRegistered);
  }

  public get observe(): Observable<boolean> {
    return this.monitorSubject.asObservable();
  }

  public get isRegistered(): boolean {
    return Boolean(this.messagingRegistered);
  }
}
