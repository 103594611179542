import { UserModel } from '@app/shared/models/api/user.model';
import { environment } from '@environments/environment';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { VerifyUserStatus } from '@app/shared/models/api/auth/verify-user.model';

export interface AkitaAuthState {
  sessionId: string | null;
  user: UserModel | null;
  lastTokenRefresh: Date;

  serverType: string;
  error: any | null;
  refreshingToken: boolean;
  lastUpdated: Date;

  // Access tocken read from the cookie (used as a fallback)
  cookiePid: string | null;
  cookieSec: string | null;

  // Metrics
  numberOfListings: number;
  numberOfMessages: number;
  numberOfFavorites: number;

  // Initialize Apple Sign In
  initializingSignInWithApple: boolean;
  signInWithAppleInitialized: boolean;
  initializingSignInWithAppleError: ApiError | null;

  // Sign With Apple
  signingInWithApple: boolean;
  signWithAppleError: ApiError | null;

  signingInWithAppleStep2: boolean;
  signWithAppleStep2Error: ApiError | null;
  signWithAppleInfo: {
    name: string;
    email: string;
    secret: string;
  };

  // Sign With Google
  signingInWithGoogleSDKLoaded: boolean;
  signingInWithGoogle: boolean;
  signWithGoogleError: ApiError | null;

  signingInWithGoogleStep2: boolean;
  signWithGoogleStep2Error: ApiError | null;

  signWithGoogleInfo: {
    name: string;
    email: string;
    picture: string;
    secret: string;
  };

  // Sign With Facebook
  signingInWithFacebook: boolean;
  signWithFacebookError: ApiError | null;

  signingInWithFacebookStep2: boolean;
  signWithFacebookStep2Error: ApiError | null;

  signWithFacebookInfo: {
    name: string;
    email: string;
    picture: string;
    secret: string;
  };

  registeringInBackground: boolean;
  errorRegisteringInBackground: ApiError | null;

  registering: boolean;
  errorRegistering: ApiError | null;

  checkingIfEmailValid: boolean;
  errorCheckingIfEmailValid: ApiError | null;
  validEmailCheckResults: { [email: string]: VerifyUserStatus };
}

export function createInitialState(): AkitaAuthState {
  return {
    user: null,
    sessionId: null,
    lastTokenRefresh: new Date(0),

    serverType: environment.api.url,
    error: null,
    refreshingToken: false,
    lastUpdated: new Date(),

    // Access tocken read from the cookie (used as a fallback)
    cookiePid: null,
    cookieSec: null,

    // Metrics
    numberOfListings: 0,
    numberOfMessages: 0,
    numberOfFavorites: 0,

    // Initialize Apple Sign In
    initializingSignInWithApple: false,
    signInWithAppleInitialized: false,
    initializingSignInWithAppleError: null,

    // Sign with Apple
    signingInWithApple: false,
    signWithAppleError: null,

    signingInWithAppleStep2: false,
    signWithAppleStep2Error: null,

    signWithAppleInfo: {
      name: '',
      email: '',
      secret: '',
    },

    // Sign with Google
    signingInWithGoogleSDKLoaded: false,
    signingInWithGoogle: false,
    signWithGoogleError: null,

    signingInWithGoogleStep2: false,
    signWithGoogleStep2Error: null,

    signWithGoogleInfo: {
      name: '',
      email: '',
      picture: '',
      secret: '',
    },

    // Sign with Facebook
    signingInWithFacebook: false,
    signWithFacebookError: null,

    signingInWithFacebookStep2: false,
    signWithFacebookStep2Error: null,

    signWithFacebookInfo: {
      name: '',
      email: '',
      picture: '',
      secret: '',
    },

    registeringInBackground: false,
    errorRegisteringInBackground: null,

    registering: false,
    errorRegistering: null,

    validEmailCheckResults: {},
    checkingIfEmailValid: false,
    errorCheckingIfEmailValid: null,
  };
}
