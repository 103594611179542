import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AkitaAuthStore } from './auth.store';
import { UserModel, OauthInfo } from '@app/shared/models/api/user.model';
import { AkitaAuthState } from '../models/auth.state';
import { Observable, map, distinctUntilChanged } from 'rxjs';

import { isBefore, addMinutes } from 'date-fns';
import { AuthTokenModel } from '@app/shared/models/api/auth/auth-token.model';
import { PopsyDateParser } from '@app/shared/utils/api-date.parser';
import { environment } from '@environments/environment';
import { ApiError } from '@app/shared/models/api/api-error.model';

@Injectable({ providedIn: 'root' })
export class AkitaAuthQuery extends Query<AkitaAuthState> {
  constructor(protected store: AkitaAuthStore) {
    super(store);
  }

  public get cookieSession(): { pid: string | null; sec: string | null } {
    return {
      pid: this.getValue().cookiePid || null,
      sec: this.getValue().cookieSec || null,
    };
  }

  public get lastTokenRefresh(): Date {
    return this.getValue()?.lastTokenRefresh || new Date(0);
  }

  public get sessionId(): string {
    return `${this.getValue()?.sessionId || ''}`;
  }

  public get shouldRefreshUser(): boolean {
    const data = {
      lastRefresh: this.lastTokenRefresh,
    };
    const lastRefresh = PopsyDateParser.parseApiDate('lastRefresh', 'lastRefresh', data);
    if (lastRefresh) {
      const now = new Date();
      const lastRefreshPlusDelay = addMinutes(lastRefresh, 1);
      return isBefore(lastRefreshPlusDelay, now);
    }
    return true;
  }
  public get user(): UserModel | null {
    return UserModel.fromJson(this.getValue().user);
  }

  public get oauth(): OauthInfo | null {
    const user = this.user;
    if (user && user.oauth) {
      return user.oauth;
    }
    return null;
  }

  public get token(): AuthTokenModel | null {
    const oauth = this.oauth;
    if (oauth && oauth.token) {
      return oauth.token;
    }
    return null;
  }

  public get serverType(): string {
    return this.getValue().serverType || '';
  }

  public get userTokenExpirationDate(): Date {
    const token = this.token;
    return PopsyDateParser.parseApiDate('expires_at', 'expiresAt', token) || new Date(0);
  }

  public get isSessionActive(): boolean {
    return isBefore(new Date(), this.userTokenExpirationDate);
  }

  public get accessToken(): string | null {
    const token = this.token;
    if (token && token.accessToken) {
      return token.accessToken;
    }
    return null;
  }

  public get isLoggedIn(): boolean {
    return Boolean(this.accessToken && this.serverType === environment.api.url);
  }

  // Async

  public selectUser(): Observable<UserModel | null> {
    return this.select().pipe(
      map((state: AkitaAuthState): UserModel | null => state.user),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectUserID(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaAuthState): string => {
        if (state.user && state.user.id) {
          return state.user.id;
        }
        return '';
      }),
      distinctUntilChanged((a, b) => a === b)
    );
  }

  public selectIsLoggedIn(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaAuthState): boolean =>
        Boolean(
          state.user &&
            state.user.oauth &&
            state.user.oauth.token &&
            state.user.oauth.token.accessToken &&
            state.serverType === environment.api.url
        )
      ),
      distinctUntilChanged((a, b) => a === b)
    );
  }

  public selectAuthGoogleSDKLoaded(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaAuthState): boolean =>
        Boolean(state.signingInWithGoogleSDKLoaded)
      ),
      distinctUntilChanged()
    );
  }

  public selectSigningInWithGoogle(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaAuthState): boolean =>
        Boolean(state.signingInWithGoogle && state.signingInWithGoogleStep2)
      ),
      distinctUntilChanged()
    );
  }

  public selectSigningInWithApple(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaAuthState): boolean =>
        Boolean(state.signingInWithApple && state.signingInWithAppleStep2)
      ),
      distinctUntilChanged()
    );
  }

  public selectSigningInWithFacebook(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaAuthState): boolean =>
        Boolean(state.signingInWithFacebook && state.signingInWithFacebookStep2)
      ),
      distinctUntilChanged()
    );
  }

  public selectSignWithGoogleError(): Observable<ApiError | null> {
    return this.select().pipe(
      map(
        (state: AkitaAuthState): ApiError | null =>
          state.signWithGoogleError || state.signWithGoogleStep2Error
      ),
      distinctUntilChanged((a, b) => a === b)
    );
  }

  public selectSignWithAppleError(): Observable<ApiError | null> {
    return this.select().pipe(
      map(
        (state: AkitaAuthState): ApiError | null =>
          state.signWithAppleError || state.signWithAppleStep2Error
      ),
      distinctUntilChanged((a, b) => a === b)
    );
  }

  public selectSignWithFacebookError(): Observable<ApiError | null> {
    return this.select().pipe(
      map(
        (state: AkitaAuthState): ApiError | null =>
          state.signWithFacebookError || state.signWithFacebookStep2Error
      ),
      distinctUntilChanged((a, b) => a === b)
    );
  }

  public selectSessionId(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaAuthState): string | null => state.sessionId || null),
      distinctUntilChanged()
    );
  }
}
