import { Sizes } from './types';

// don't know a better way to get the size of a CSS 100vh…
export const createTestElement = (): HTMLElement => {
  const testElement: HTMLElement = document.createElement('div');
  testElement.style.cssText =
    'position: fixed; top: 0; height: 100vh; pointer-events: none;';
  document.documentElement.insertBefore(testElement, document.documentElement.firstChild);
  return testElement;
};

export const removeTestElement = (element: HTMLElement) => {
  document.documentElement.removeChild(element);
};

//  in some browsers this will be bigger than window.innerHeight
export const checkSizes = (): Sizes => {
  const vhTest = createTestElement();
  let windowHeight = 500;
  try {
    windowHeight = window.innerHeight;
  } catch (err) {
    // SSR Window not supported
  }
  const vh = vhTest.offsetHeight;
  const offset = vh - windowHeight;
  removeTestElement(vhTest);
  return {
    vh,
    windowHeight,
    offset,
    isNeeded: offset !== 0,
    value: 0,
  };
};

// export

export const noop = (): void => {};

export const computeDifference = (): Sizes => {
  const sizes = checkSizes();
  sizes.value = sizes.offset;
  return sizes;
};

export const redefineVhUnit = (): Sizes => {
  const sizes = checkSizes();
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  sizes.value = sizes.windowHeight * 0.01;
  return sizes;
};
