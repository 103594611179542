import { TranslocoMissingHandler, TranslocoMissingHandlerData } from '@ngneat/transloco';
import { SUPPORTED_LANGUAGES } from './popsy-transloco-http-loader.service';

import enTranslation from '@assets/i18n/en.json';

export class PopsyTranslocoMissingHandler implements TranslocoMissingHandler {
  // If the label does not exist, return empty string
  public handle(
    key: string,
    data: TranslocoMissingHandlerData,
    params?: any | null
  ): string {
    const activeLang = data?.activeLang || '';
    if (SUPPORTED_LANGUAGES.includes(activeLang) !== true) {
      const name = key.replace(`${activeLang}.`, '');
      let translation: string = (enTranslation as { [id: string]: string })[name];

      // Replace any variables from the translation
      const paramKeys = Object.keys(params || {});
      if (paramKeys.length > 0) {
        for (const paramName of paramKeys) {
          // Escape the parameter special characters for the RegExp
          const escapedParamName = `{{${paramName || ''}}}`.replace(
            /[-/\\^$*+?.()|[\]{}]/g,
            '\\$&'
          );

          // Create a RegExp to replace the parameter
          const expression = new RegExp(escapedParamName, 'g');

          // Replace the translation variable
          translation = `${translation || ''}`.replace(
            expression,
            `${params[paramName] || ''}`
          );
        }
      }
      return translation;
    }

    return key;
  }
}
